import { fromEvent as observableFromEvent, Subscription } from 'rxjs';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FleetUnit } from '../fleet-units/fleet-unit/fleet-unit.model';

import { FleetDepotService } from '../fleet-depot/fleet-depot.service';
import { FleetOverviewService } from './fleet-overview.service';
import { FleetService } from '../fleet.service';
import { MandatorService } from '../mandator/mandator.service';
import { UserService } from '../user/user.service';
import { ViewportService } from '../../shared/services/viewport.service';

import { Router } from '@angular/router';

import * as _ from 'lodash';
import { FleetOverviewSearchFiltersComponent } from '@fleet/fleet-overview/fleet-overview-search-filters/fleet-overview-search-filters.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fleet-overview',
  templateUrl: './fleet-overview.component.html',
  styleUrls: ['./fleet-overview.component.scss']
})
export class FleetOverviewComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('search', { static: true }) searchElement: ElementRef;
  @ViewChild(FleetOverviewSearchFiltersComponent, { static: true }) searchFilters;

  private subscriptions: Subscription[] = [];

  private frequency = 60000;
  private intervalId: number;

  public alarmingFleetUnits: FleetUnit[];
  public alarmingFleetUnitsCount: number;

  public fleetUnitsCount: number;
  public filteredFleetUnits: FleetUnit[];
  public filteredFleetUnitsCount: number;

  public showFilterThreshold = 20;

  public showFilterPanel: boolean;

  constructor(public fleet: FleetService,
              public fleetDepot: FleetDepotService,
              public mandator: MandatorService,
              public router: Router,
              public overview: FleetOverviewService,
              public viewport: ViewportService,
              private user: UserService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.initRecentDrivesIntervall();

    this.subscriptions.push(
      // this.fleet.alarmingFleetUnits.subscribe((alarmingFleetUnits) => {
      this.fleet.queriedAlarmingFleetUnits.subscribe((alarmingFleetUnits) => {
        this.alarmingFleetUnits = alarmingFleetUnits;
        this.alarmingFleetUnitsCount = _.get(alarmingFleetUnits, 'length', 0);
      })
    );

    this.subscriptions.push(
      // this.fleet.filteredFleetUnits.subscribe((filteredFleetUnits) => {
      this.fleet.queriedNotAlarmingFleetUnits.subscribe((filteredFleetUnits) => {
        // const imeis = JSON.stringify(_.map(filteredFleetUnits, (unit) => {
        //   return _.get(unit, 'tractor.ifmsBox.imei');
        // }), null, 2);
        // console.log(imeis);

        this.filteredFleetUnits = filteredFleetUnits;
        this.filteredFleetUnitsCount = _.get(filteredFleetUnits, 'length', 0);
      })
    );

    this.subscriptions.push(
      this.fleet.notAlarmingFleetUnits.subscribe((fleetUnits) => {
        this.fleetUnitsCount = _.get(fleetUnits, 'length', 0);
      })
    );
  }

  ngAfterViewInit() {
    if (_.has(this.searchElement, 'nativeElement')) {
      const searchQuerySub = observableFromEvent(this.searchElement.nativeElement, 'keyup').pipe(
        map((event) => {
          return _.get(event, 'target.value');
        }),
        debounceTime(200),
        distinctUntilChanged(), )
        .subscribe((needle: string) => {
          this.fleet.setSearchQueryNeedle(needle);
        });

      this.subscriptions.push(searchQuerySub);
    }
  }

  ngOnDestroy() {
    _.forEach(this.subscriptions, subscription => subscription.unsubscribe());

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.overview.clear();
    this.fleet.clear();
  }

  initRecentDrivesIntervall() {
    this.overview.getRecentDrives();
    this.intervalId = setInterval(() => {
      this.overview.getRecentDrives();
    }, this.frequency);
  }

  selectRecentDrive(fleetUnit: FleetUnit) {
    if (_.get(fleetUnit, 'fleetUnitId') && _.get(fleetUnit, 'vehicleGroupId')) {
      const { vehicleGroupId, fleetUnitId } = fleetUnit;
      this.router.navigate([`fleet/vehicle/${vehicleGroupId}/${fleetUnitId}`]);
    }
  }

  reload() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.overview.getRecentDrives();
      }, this.frequency);
    }
    this.overview.getRecentDrives();
  }

}
