import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

import { DepotSharedModule } from '@depot/shared/fleet-depot-shared.module';
import { VehicleMonitoringModule } from './vehicle-monitoring/vehicle-monitoring.module';
import { VehiclePartsModule } from './parts/parts.module';

import { VehicleComponent } from '@unit-state/vehicle.component';
import { BoxCommandsHistoryComponent } from '@unit-state/box-commands-history/box-commands-history.component';
import { FleetVehicleDetailedStatusComponent } from '@unit-state/fleet-vehicle-detailed-status/fleet-vehicle-detailed-status.component';
import { VehicleCalibrationComponent } from '@unit-state/vehicle-calibration/vehicle-calibration.component';
import { VehicleCalibrationAxlesComponent } from '@unit-state/vehicle-calibration/vehicle-calibration-axles/vehicle-calibration-axles.component';
import { VehicleContactsComponent } from '@unit-state/vehicle-contacts/vehicle-contacts.component';
import { VehicleDetailsComponent } from '@unit-state/vehicle-details/vehicle-details.component';
import { VehicleLedStateComponent } from '@unit-state/vehicle-led-state/vehicle-led-state.component';
import { VehicleLocationComponent } from '@unit-state/vehicle-location/vehicle-location.component';
import { VehicleLocationHistoryComponent } from '@unit-state/vehicle-location-history/vehicle-location-history.component';
import { VehicleStateComponent } from '@unit-state/vehicle-state/vehicle-state.component';
import { VehicleTripHistoryLinkComponent } from '@unit-state/vehicle-trip-history-link/vehicle-trip-history-link.component';
import { VehicleViewDetailsComponent } from '@unit-state/vehicle-view-details/vehicle-view-details.component';

import { BoxCommandsHistoryService } from '@unit-state/box-commands-history/box-commands-history.service';
import { VehicleLeakRateAndTimeToCriticalComponent } from './vehicle-leak-rate-and-time-to-critical/vehicle-leak-rate-and-time-to-critical.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,

		DepotSharedModule,
		VehicleMonitoringModule,
		VehiclePartsModule,
		NgbTooltipModule,
		AngularSvgIconModule,
	],
  declarations: [
    VehicleComponent,

    BoxCommandsHistoryComponent,
    FleetVehicleDetailedStatusComponent,

    VehicleCalibrationComponent,
    VehicleCalibrationAxlesComponent,

    VehicleContactsComponent,
    VehicleDetailsComponent,
    VehicleLedStateComponent,
    VehicleLocationComponent,
    VehicleLocationHistoryComponent,
    VehicleStateComponent,
    VehicleTripHistoryLinkComponent,
    VehicleViewDetailsComponent,
    VehicleLeakRateAndTimeToCriticalComponent,
  ],
  exports: [
    VehicleComponent,

    BoxCommandsHistoryComponent,
    FleetVehicleDetailedStatusComponent,

    VehicleCalibrationComponent,
    VehicleCalibrationAxlesComponent,

    VehicleContactsComponent,
    VehicleDetailsComponent,
    VehicleLedStateComponent,
    VehicleLocationComponent,
    VehicleLocationHistoryComponent,
    VehicleStateComponent,
    VehicleTripHistoryLinkComponent,
    VehicleViewDetailsComponent,

    VehicleMonitoringModule,
    VehiclePartsModule,
    VehicleViewDetailsComponent,
    VehicleLocationComponent,
  ],
  providers: [
    BoxCommandsHistoryService,
  ]
})
export class UnitStateModule {
  /*constructor (
    @Optional() @SkipSelf() parentModule: UnitStateModule
  ) {
    if (parentModule) {
      throw new Error('UnitStateModule is already loaded. Import only in FleetModule');
    }
  }*/
}
