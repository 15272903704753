import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SensorEquipment } from '../sensor-equipment/sensor-equipment.model';

@Component({
  selector: 'ifms-sensor-equipment-horizontal-card',
  templateUrl: './sensor-equipment-horizontal-card.component.html',
  styleUrls: ['./sensor-equipment-horizontal-card.component.scss']
})
export class SensorEquipmentHorizontalCardComponent implements OnInit {

  @Input() equipment: SensorEquipment;
  @Input() disabled: boolean;

  @Output() select: EventEmitter<void> = new EventEmitter<void>();
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
