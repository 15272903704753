import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Tire } from '@parts/tire/tire.model';
import { TyreChange, TyreToFleetVehicle } from './fleet-depot.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import * as _ from 'lodash';

@Injectable()
export class TyreService {

  constructor(private http: HttpClient) { }

  getAllTiresForFleetVehicle(fleetVehicleId: number): Observable<Tire[]> {
      return <Observable<Tire[]>>this.http.get(`${environment.apiUrl}/tyre?fleetVehicleId=${fleetVehicleId}`);
  }

  getAllStoredTyresOfFleetVehicle(fleetVehicleId: number): Observable<TyreToFleetVehicle[]> {
      return <Observable<TyreToFleetVehicle[]>>this.http.get(`${environment.apiUrl}/tyre/getAllStoredTyresToFleetVehicle/${fleetVehicleId}`);
  }

  getAllTyresToVehicleGroup(vehicleGroupId: number): Observable<TyreToFleetVehicle[]> {
      return <Observable<TyreToFleetVehicle[]>>this.http.get(`${environment.apiUrl}/tyre/getAllTyresToVehicleGroup/${vehicleGroupId}`);
  }

  createTyreAndAttachToFleetvehicle(tyre: Tire): Observable<Tire> {
      return <Observable<Tire>>this.http.post(`${environment.apiUrl}/tyre/createAndAttachToFleetVehicle`, tyre);
  }

  createTyre(tyre: Tire): Observable<Tire> {
      return <Observable<Tire>>this.http.post(`${environment.apiUrl}/tyre/`, tyre);
  }

  updateTyre(tyre: Tire): Observable<Tire> {
      const tyreId = _.get(tyre, 'tyreId');
      return <Observable<Tire>>this.http.put(`${environment.apiUrl}/tyre/${tyreId}`, tyre);
  }

  attachTyreToFleetvehicle(tyreId: number, tyre: Tire) {
      return <Observable<Tire>>this.http.put(`${environment.apiUrl}/tyre/${tyreId}/attachToFleetVehicle`, tyre);
  }

  changeTyre(tyreChange: TyreChange): Observable<Tire> {
      return <Observable<Tire>>this.http.put(`${environment.apiUrl}/tyre/${tyreChange.oldTyre.tyreId}/changeTyre`, tyreChange);
  }

  detachTpmsSensorFromTyre(tyreId: number): Observable<Tire> {
      return <Observable<Tire>>this.http.put(`${environment.apiUrl}/tyre/${tyreId}/detachTpmsSensor`, {});
  }

  detachTyreFromFleetvehicle(tyreId: number, tyreToFleetVehicle: TyreToFleetVehicle) {
      return <Observable<Tire>>this.http.put(`${environment.apiUrl}/tyre/${tyreId}/detachFromFleetVehicle`, tyreToFleetVehicle);
  }

}
