import { Component, Input } from '@angular/core';

@Component({
  selector: '[ifms-vehicle-trip-history-link]',
  templateUrl: './vehicle-trip-history-link.component.html',
  styleUrls: ['./vehicle-trip-history-link.component.scss']
})
export class VehicleTripHistoryLinkComponent {
  @Input() fleetVehicleId: number;

  constructor() { }

}
