import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToCriticalFormat'
})
export class TimeToCriticalFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === undefined || value === null) {
      return '-';
    } else {
      switch (true) {
        case (value >= 480):
          return '> 8h';
        case (value >= 420):
          return '> 7h';
        case (value >= 360):
          return '> 6h';
        case (value >= 300):
          return '> 5h';
        case (value >= 240):
          return '> 4h';
        case (value >= 180):
          return '> 3h';
        case (value >= 120):
          return '> 2h';
        case (value >= 90):
          return '> 90 min';
        case (value >= 60):
          return '> 60 min';
        case (value >= 30):
          return '> 30 min';
        case (value < 30 && value !== null):
          return '< 30 min';
        default:
          return '-';
      }
    }
  }

}
