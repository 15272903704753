import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PersonListService } from '@shared/components/person-list/person-list.service';

import * as _ from 'lodash';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Condition } from '@unit-state/models/condition.model';
import { PersonList, WarningLevels } from './person-list.interfaces.js';

@Component({
  selector: 'ifms-person-list',
  templateUrl: './person-list.component.html',
  styleUrls: ['./person-list.component.scss']
})
export class PersonListComponent implements OnInit, OnChanges {

  @Input() controllerURL: string;
  @Input() id: number;
  @Input() useTags = false;

  public personList: PersonList[] = [];
  public unallocatedPersonList: PersonList[];
  public mergedPersonList: any;

  private static sortTags(warningLevels: WarningLevels[]) {
      const warningLevelsOrder = ['MAINTENANCE', 'WARNING', 'DANGER'];
      const warningLevelSorted = [];

      for (const warningLevelOrder of warningLevelsOrder) {
          const warningLevelFound = _.find(warningLevels, (o) => {
              return o.warningLevel === warningLevelOrder;
          });

          if (warningLevelFound) {
              warningLevelSorted.push(warningLevelFound);
          }
      }

      return warningLevelSorted;
  }

  constructor(private personListService: PersonListService, private notification: NotificationsService,  private modalService: NgbModal) {

  }

  ngOnInit() {
     this.load();
  }

  ngOnChanges(changes) {
      if (changes.id.previousValue !== undefined) {
          if (changes.id.previousValue !== changes.id.currentValue) {
              this.load();
          }
      }
  }

  load() {
    this.personList = [];
    this.unallocatedPersonList = [];
    this.mergedPersonList = [];

    this.personListService.getSelectedPersons(this.controllerURL, this.id).subscribe((data: PersonList[]) => {
        this.personList = data;

        _.forEach(this.personList, (person: PersonList) => {
            person['selected'] = true;
            person.warningLevels = PersonListComponent.sortTags(person.warningLevels);
        });
    });
  }

  showAllPersons(content) {
    this.personListService.getUnallocatedPersons(this.controllerURL, this.id).subscribe((data: PersonList[]) => {
      this.unallocatedPersonList = data;
      this.mergedPersonList = this.personList.concat(this.unallocatedPersonList);

      _.forEach(this.mergedPersonList, (person: PersonList) => {
        if (_.find(this.personList, {personId: person.personId})) {
          person['selected'] = true;
        } else {
          person['selected'] = false;
        }
        person.warningLevels = PersonListComponent.sortTags(person.warningLevels);
      });

      this.modalService.dismissAll();
      this.modalService.open(content, { centered: true});
    });
  }

  modalSelectPersonToggle(person) {
    person.selected = !person.selected;

    if (this.useTags) {
      this.toggleAllTags(person);
    }
  }

  private toggleAllTags(person) {
    for (const warningLevel of person.warningLevels) {
      warningLevel.notifyPerson = person.selected;
    }
  }

  unselectSelectedPerson(person) {
      this.modalSelectPersonToggle(person);
      this.save(this.personList);
  }

  save(list) {
    const selectedPersonList = [];
    const personListWithWarningLevels = [];
    for (const p of list) {
      if (p.selected) {
        selectedPersonList.push(p);
      } else if ( this.useTags ) {
        for (const warningLevel of p.warningLevels) {
          if (warningLevel.notifyPerson) {
            personListWithWarningLevels.push(p);
            break;
          }
        }
      }
    }

    this.personListService.update(this.controllerURL, this.id, _.concat(selectedPersonList, personListWithWarningLevels)).subscribe((d) => {
      this.notification.success('Success', 'Person(s) saved');
      this.personList = selectedPersonList;
      this.modalService.dismissAll();
    });
  }

  getColor(tag) {
    const warningLevel = tag.warningLevel.toLowerCase();
    if (tag.notifyPerson && Condition.COLORS[warningLevel]) {
      return `${Condition.COLORS[warningLevel]}`;
    } else if (!tag.notifyPerson) {
      return `${Condition.COLORS.grey}`;
    } else {
      return `${Condition.COLORS.blue}`;
    }
  }

  tagClick(person, warningLevel, event) {
    event.stopPropagation();

    warningLevel.notifyPerson = !warningLevel.notifyPerson;

    const activatedTags = _.filter(person.warningLevels, {notifyPerson: true});

    if (activatedTags.length === 0) {
      person.selected = false;
    } else if (activatedTags.length === 1) {
      person.selected = true;
    }
  }
}
