import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { getSrcPath } from '@configurator/vehicle-types/vehicle-types.helper';

import { has } from 'lodash';
import { UserService } from '@user/user.service';

@Component({
  selector: 'ifms-vehicle-view-details',
  templateUrl: './vehicle-view-details.component.html',
  styleUrls: ['./vehicle-view-details.component.scss']
})
export class VehicleViewDetailsComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() translatePath: string;
  @Input() vehicle: any;
  @Input() vehicleGroupId: number;

  public imgSrcPath: string;

  constructor(public userService: UserService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.vehicle && has(this.vehicle, 'vehicleType')) {
      const fileType = (navigator.appVersion.indexOf('MSIE 10') !== -1) ? 'png' : 'svg';
      this.imgSrcPath = getSrcPath(this.vehicle.vehicleType, fileType);
    }
  }

}
