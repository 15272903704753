import { VehicleState } from './vehicle.model';
import { VehicleAxleHelper } from '@configurator/vehicle-configurator.helper';

const defaultState: VehicleState = {
  selected: null,

  currentLocation: null,
  locationHistory: null,

  vehicleGroup: null,

  unitVehicles: null,

  tireDetails: null,

  sensorHistory: [],

  tractorBox: null,

  pendingCommands: null,
};

export class VehicleActions {
  public static SET_DEFAULT = 'VEHICLE-SET_DEFAULT';
  public static SELECT = 'VEHICLE-SELECT';

  public static LOCATION_HISTORY_LOADED = 'VEHICLE-LOCATION_HISTORY_LOADED';
  public static CURRENT_LOCATION_LOADED = 'VEHICLE-CURRENT_LOCATION_LOADED';
  public static CLEAR_CURRENT_LOCATION = 'VEHICLE-CLEAR_CURRENT_LOCATION';
  public static UNIT_VEHICLES_LOADED = 'VEHICLE-UNIT_VEHICLES_LOADED';

  public static VEHICLE_GROUPY_LOADED = 'VEHICLE-VEHICLE_GROUPY_LOADED';
  public static VEHICLE_TEMPLATE_LOADED = 'VEHICLE-VEHICLE_TEMPLATE_LOADED';
  public static IFMS_BOX_LOADED = 'VEHICLE-IFMS_BOX_LOADED';
  public static SENSOR_EQUIPMENT_LOADED = 'VEHICLE-SENSOR_EQUIPMENT_LOADED';

  public static CURRENT_SENSOR_DATA_LOADED = 'VEHICLE-CURRENT_SENSOR_DATA_LOADED';
  public static TIRE_DETAILS_LOADED = 'VEHICLE-TIRE_DETAILS_LOADED';

  public static SENSOR_HISTORY_LOADED = 'VEHICLE-SENSOR_HISTORY_LOADED';

  public static TRACTOR_BOX_LOADED = 'VEHICLE-TRACTOR_BOX_LOADED';

  public static SET_PENDING_COMMANDS = 'VEHICLE-SET_PENDING_COMMANDS';
}

export function vehicleReducer (state: VehicleState = defaultState, { type, payload }) {
  switch (type) {
    case <string>VehicleActions.SET_DEFAULT:
      return Object.assign({}, defaultState);

    case <string>VehicleActions.SELECT:
      if (payload && payload.vehicleAxles) {
        VehicleAxleHelper.prepareImagesOfVehicleAxles(payload.vehicleAxles);
      }

      return Object.assign({}, state, { selected: payload });

    case <string>VehicleActions.LOCATION_HISTORY_LOADED:
      payload.gpsData = payload.gpsData.reverse();
      return Object.assign({}, state, { locationHistory: payload });

    case <string>VehicleActions.CURRENT_LOCATION_LOADED:
      return Object.assign({}, state, { currentLocation: payload });

    case <string>VehicleActions.CLEAR_CURRENT_LOCATION:
      return Object.assign({}, state, { currentLocation: null });

    case <string>VehicleActions.VEHICLE_GROUPY_LOADED:
      return Object.assign({}, state, { vehicleGroup: payload });

    case <string>VehicleActions.VEHICLE_TEMPLATE_LOADED:
      return Object.assign({}, state, { vehicle: payload });

    case <string>VehicleActions.UNIT_VEHICLES_LOADED:
      return Object.assign({}, state, { unitVehicles: payload });

    case <string>VehicleActions.TIRE_DETAILS_LOADED:
      return Object.assign({}, state, { tireDetails: payload });

    case <string>VehicleActions.SENSOR_HISTORY_LOADED:
      return Object.assign({}, state, { sensorHistory: payload });

    case <string>VehicleActions.TRACTOR_BOX_LOADED:
      return Object.assign({}, state, { tractorBox: payload });

    case <string>VehicleActions.SET_PENDING_COMMANDS:
      return Object.assign({}, state, { pendingCommands: payload });

    default:
      return state;
  }
}
