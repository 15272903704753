import { Component, Input, OnInit } from '@angular/core';

import { SensorEquipment } from '../sensor-equipment/sensor-equipment.model';

@Component({
  selector: 'ifms-sensor-equipment-card',
  templateUrl: './sensor-equipment-card.component.html',
  styleUrls: ['./sensor-equipment-card.component.scss']
})
export class SensorEquipmentCardComponent implements OnInit {

  @Input() equipment: SensorEquipment;
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit() {
  }

}
