import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Person } from '@fleet/person/person.model';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class PersonListService {

  constructor(private http: HttpClient) { }

  update(controllerURL, id, data: Person[]) {
    return this.http.post(`${environment.apiUrl}/${controllerURL}/${id}/update-contact-persons`, data);
  }

  getSelectedPersons(controllerURL, id) {
    return this.http.get(`${environment.apiUrl}/${controllerURL}/${id}/contact-persons`);
  }

  getUnallocatedPersons(controllerURL, id) {
    return this.http.get(`${environment.apiUrl}/${controllerURL}/${id}/unallocated-persons`);
  }
}
