import { Routes } from '@angular/router';
import { RootComponent } from './root/root.component';
import { LoginComponent } from './auth/login/login.component';
import { PasswordForgottenComponent } from './auth/password-forgotten/password-forgotten.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { FleetComponent } from './fleet/fleet.component';
import { FleetOverviewComponent } from './fleet/fleet-overview/fleet-overview.component';
import { VehicleComponent } from '@unit-state/vehicle.component';
import { SettingsComponent } from './fleet/settings/settings.component';
import { N4translateComponent } from './fleet/n4translate/n4translate.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MandatorManagementComponent } from '@fleet/mandator-management/mandator-management.component';
import { UserManagementComponent } from '@fleet/user-management/user-management.component';
import { HistoryActivate } from '@shared/services/history.active';
import { LoginActivate } from './auth/services/login.active';
import { LogoutComponent } from '@app/auth/logout/logout.component';
import { RolesGuard } from '@app/auth/services/roles.guard';

export const appRoutes: Routes = [
    {path: 'one-time-login', loadChildren: () => import('./fleet/one-time-login/one-time-login.module').then(m => m.OneTimeLoginModule)},
    {
        path: '',
        component: RootComponent,
        canActivate: [LoginActivate],
        canActivateChild: [RolesGuard],
        children: [
            {
                path: 'dev',
                loadChildren: () => import('./hidden/hidden.module').then(m => m.HiddenModule),
                data: { roles: ['ROLE_FM_ADMIN'] },
            },
            {
                path: '',
                canActivateChild: [HistoryActivate],
                redirectTo: '/fleet/overview',
                pathMatch: 'full',
            },
            {
                path: 'fleet',
                canActivateChild: [HistoryActivate],
                component: FleetComponent,
                children: [
                    {
                        path: 'hardware',
                        canActivateChild: [HistoryActivate],
                        loadChildren: () => import('./fleet/hardware/hardware.module').then(m => m.HardwareModule),
                        data: { roles: ['ROLE_FM_ADMIN'] },
                    },
                    {
                        path: 'settings',
                        canActivateChild: [HistoryActivate],
                        component: SettingsComponent
                    },
                    {
                        path: 'mandators',
                        canActivateChild: [HistoryActivate],
                        component: MandatorManagementComponent,
                        data: { roles: ['ROLE_FM_ADMIN'] }
                    },
                    {
                        path: 'mandators/:id',
                        canActivateChild: [HistoryActivate],
                        component: MandatorManagementComponent,
                        data: { roles: ['ROLE_FM_ADMIN'] }
                    },
                    {
                        path: 'users',
                        canActivateChild: [HistoryActivate],
                        component: UserManagementComponent,
                        data: { roles: ['ROLE_FM_ADMIN'] }
                    },
                    {
                        path: 'n4translate',
                        canActivateChild: [HistoryActivate],
                        component: N4translateComponent,
                        data: { roles: ['ROLE_FM_ADMIN'] },
                    },
                    {
                        path: 'overview',
                        canActivateChild: [HistoryActivate],
                        component: FleetOverviewComponent
                    },
                    {
                        path: 'units',
                        canActivateChild: [HistoryActivate],
                        loadChildren: () => import('./fleet/fleet-units/fleet-units.module').then(m => m.FleetUnitsModule)
                    },
                    {
                        path: 'depot',
                        canActivateChild: [HistoryActivate],
                        loadChildren: () => import('./fleet/fleet-depot/fleet-depot.module').then(m => m.DepotModule)
                    },
                    {
                        path: 'vehicle/:vehicleGroupId/:vehicleId',
                        canActivateChild: [HistoryActivate],
                        component: VehicleComponent
                    },
                    {
                        path: 'configurator',
                        canActivateChild: [HistoryActivate],
                        loadChildren: () => import('./fleet/configurator/vehicle-configurator.module')
                            .then(m => m.VehicleConfiguratorModule),
                    },
                    {
                      path: 'page-not-found',
                      component: PageNotFoundComponent
                    },
                    {
                      path: 'support',
                      canActivateChild: [HistoryActivate],
                      loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
                    },
                ]
            },
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
      path: 'logout',
      component: LogoutComponent
    },
    {
        path: 'password-forgotten',
        component: PasswordForgottenComponent
    },
    {
        path: 'reset-password/:token',
        component: ResetPasswordComponent
    },
    /*{
      path: '**',
      canActivate: [LoginActivate],
      redirectTo: '/fleet/page-not-found'
    },*/
];
