import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Person } from './person.model';

@Component({
  selector: 'ifms-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {

  @Input() person: Person;
  @Input() disabled = false;

  @Output() cancel: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
