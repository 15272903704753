import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { VehicleService } from '@unit-state/vehicle.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'vehicle-location',
  templateUrl: './vehicle-location.component.html',
  styleUrls: ['./vehicle-location.component.scss']
})
export class VehicleLocationComponent implements OnInit, OnDestroy {

  @ViewChild('mapTarget', { static: true }) mapTarget: ElementRef;

  public currentDate: any;

  private locationsSub: Subscription;

  private fleetUnitSub: Subscription;

  public markers: any[];
  public mapAccess: any;

  constructor(public vehicle: VehicleService, private http: HttpClient) { }

  ngOnInit() {
    this.locationsSub = this.vehicle
      .currentLocation
      .subscribe((location: any) => {
        this.currentDate = _.get(location, 'location.gpsData[0].timestamp');

          this.fleetUnitSub = this.vehicle.selected
              .pipe(
                  filter(unit => _.has(unit, 'fleetUnitId')),
                  distinctUntilChanged((unit, compared) => {
                      return _.get(unit, 'fleetUnitId') === _.get(compared, 'fleetUnitId');
                  })
              ).subscribe((fleetUnit) => {
                  if (location) {
                      this.markers = [];
                      if (location.location && location.location.gpsData.length === 1) {
                          this.markers.push(
                              {
                                  long: location.location.gpsData[0].longitude,
                                  lat: location.location.gpsData[0].latitude,
                                  fleetUnit: fleetUnit,
                                  clickData: fleetUnit
                              }
                          );
                      }

                      this.mapAccess = { image: location.location.image, status: location.location.status };
                  }
              });
      });
  }

  ngOnDestroy() {
    if (this.locationsSub) {
      this.locationsSub.unsubscribe();
    }

    if (this.fleetUnitSub) {
      this.fleetUnitSub.unsubscribe();
    }
  }
}
