import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';

import { TranslateModule } from '@ngx-translate/core';

import { ViewportService } from './services/viewport.service';

import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { TabsetComponent } from './components/tabset/tabset.component';
import { TabComponent } from './components/tabset/tab/tab.component';
import { TabViewModule } from 'primeng/tabview';

import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SearchableDropdownComponent } from './components/searchable-dropdown/searchable-dropdown.component';

import { HistoryActivate } from '@shared/services/history.active';
import { PendingChangesGuard } from '@shared/services/pending-changes.guard';
import { LoginActivate } from '../auth/services/login.active';
import { GlobalErrorHandler } from './services/global-error.handler';
// shared custom services used in lazy loaded modules
import { FaviconsService } from './services/favicons.service';
import { HostConfigService } from './services/host-config/host-config.service';
import { IfmsBoxesService } from '@hardware/ifms-boxes/ifms-boxes.service';
import { SensorEquipmentsService } from '@hardware/sensor-equipments/sensor-equipments.service';
import { TpmsSensorService } from '@hardware/sensor-equipments/sensor-equipment/tpms-sensor/tpms-sensor.service';

import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { IsNumberPipe } from './pipes/is-number.pipe';
import { GetNumberPipe } from './pipes/get-number.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { AsArrayPipe } from './pipes/as-array.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { BounceValuePipe } from './pipes/bounce-value.pipe';
import { UtcToLocalDatePipe } from './pipes/utc-to-local-date.pipe';
import { NoWhitespaceValidatorDirective } from './directives/no-whitespace-validator.directive';
import { MailingListComponent } from './components/mailing-list/mailing-list.component';
import { TrimInputNumberDirective } from './directives/trim-input-number.directive';
import { OnlyNumberMaxFloatMaxValueDirective } from '@shared/directives/only-number-max-float-max-value.directive';
import { TimeToCriticalFormatPipe } from './pipes/time-to-critical-format.pipe';
import { PersonListComponent } from './components/person-list/person-list.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CheckPermissionsDirective } from './directives/check-permissions.directive';
import { ImageCropperComponent } from '@shared/components/image-cropper/image-cropper.component';
import { VersionCheckBannerComponent } from './components/version-check-banner/version-check-banner.component';
import { TranslationVariablesPipe } from './pipes/translation-variables.pipe';
import { OlMapComponent } from './components/ol-map/ol-map.component';


@NgModule({
  declarations: [
    CheckboxComponent,
    DropdownComponent,
    SearchableDropdownComponent,

    EllipsisPipe,
    TabsetComponent,
    TabComponent,
    IsNumberPipe,
    GetNumberPipe,
    ReplacePipe,
    AsArrayPipe,
    TruncatePipe,
    OnlyNumberDirective,
    BounceValuePipe,
    UtcToLocalDatePipe,
    NoWhitespaceValidatorDirective,
    MailingListComponent,
    TrimInputNumberDirective,
    OnlyNumberMaxFloatMaxValueDirective,
    TimeToCriticalFormatPipe,
    PersonListComponent,
    DatepickerComponent,
    CheckPermissionsDirective,
    ImageCropperComponent,
    VersionCheckBannerComponent,
    TranslationVariablesPipe,
    OlMapComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule,
    CalendarModule,
    ConfirmDialogModule,
    DialogModule,
    ChartModule,
    DropdownModule,
    NgxLoadingModule,
    TabViewModule,
    TranslateModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
    exports: [
        FormsModule,
        AccordionModule,
        CalendarModule,
        ConfirmDialogModule,
        DialogModule,
        ChartModule,
        DropdownModule,
        TranslateModule,
        NgxLoadingModule,

        CheckboxComponent,
        DropdownComponent,
        SearchableDropdownComponent,
        EllipsisPipe,
        GetNumberPipe,
        IsNumberPipe,
        ReplacePipe,
        AsArrayPipe,
        TruncatePipe,
        TabsetComponent,
        TabComponent,
        OnlyNumberDirective,
        BounceValuePipe,
        UtcToLocalDatePipe,
        NoWhitespaceValidatorDirective,
        TrimInputNumberDirective,
        MailingListComponent,
        OnlyNumberMaxFloatMaxValueDirective,
        TimeToCriticalFormatPipe,
        PersonListComponent,
        DatepickerComponent,
        CheckPermissionsDirective,
        ImageCropperComponent,
        VersionCheckBannerComponent,
        TranslationVariablesPipe,
        OlMapComponent,
    ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
          // deps: [Router]
        },
        ViewportService,
        ConfirmationService,
        HistoryActivate,
        PendingChangesGuard,
        LoginActivate,

        // shared custom services
        FaviconsService,
        HostConfigService,
        IfmsBoxesService,
        SensorEquipmentsService,
        TpmsSensorService,
      ]
    };
  }
}
