import { environment } from '@env/environment';

(function() {
    const consoleInstance = console.log;
    console.log = function (message) {
        if (!environment.production) {
            consoleInstance.apply(console, ['%c DEBUG LOG:', 'background: #222; color: #bada55', ...arguments]);
        }
    };
})();
