import { ModuleWithProviders, NgModule } from '@angular/core';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
  imports: [
    AutoCompleteModule,
    RadioButtonModule,
    CheckboxModule,
    MultiSelectModule,
    TooltipModule,
    SelectButtonModule,
  ],
  exports: [
    AutoCompleteModule,
    RadioButtonModule,
    CheckboxModule,
    MultiSelectModule,
    TooltipModule,
    SelectButtonModule,
  ]
})
export class PrimengModule {
  static forRoot(): ModuleWithProviders<PrimengModule> {
    return {
      ngModule: PrimengModule,
      providers: [

      ]
    };
  }
}
