import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import * as _ from 'lodash';

@Injectable()
export class HistoryActivate implements CanActivate, CanActivateChild {
  private history = [];
  private limit = 10;

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.addToHistoryBy(state);
    return true;
  }

  addToHistoryBy(state: RouterStateSnapshot) {
    this.history = _.takeRight([...this.history, state.url], this.limit);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  getHistory(): string[] {
    return this.history;
  }

  getPreviousUrl(): string {
    return this.history[this.history.length - 1] || '/';
  }
}
