import { Component, Input, OnInit } from '@angular/core';

import { Badge } from '../notifications.model';

@Component({
  selector: 'ifms-fleet-unit-notification-icon',
  templateUrl: './fleet-unit-notification-icon.component.html',
  styleUrls: ['./fleet-unit-notification-icon.component.scss']
})
export class FleetUnitNotificationIconComponent implements OnInit {

  @Input() badge: Badge;
  @Input() color: string;

  constructor() { }

  ngOnInit() {
  }

}
