import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IfmsBox } from '../ifms-box/ifms-box.model';

@Component({
  selector: 'ifms-box-horizontal-card',
  templateUrl: './ifms-box-horizontal-card.component.html',
  styleUrls: ['./ifms-box-horizontal-card.component.scss']
})
export class IfmsBoxHorizontalCardComponent implements OnInit {

  @Input() box: IfmsBox;
  @Input() disabled: boolean;

  @Output() select: EventEmitter<void> = new EventEmitter<void>();
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
