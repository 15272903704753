import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { SensorEquipment } from '../sensor-equipment/sensor-equipment.model';

import { SensorEquipmentsService } from '../sensor-equipments.service';

@Component({
  selector: 'ifms-sensor-equipments-lazy-loading-list',
  templateUrl: './sensor-equipments-lazy-loading-list.component.html',
  styleUrls: ['./sensor-equipments-lazy-loading-list.component.scss']
})
export class SensorEquipmentsLazyLoadingListComponent implements OnInit {

  @Output() selected: EventEmitter<SensorEquipment> = new EventEmitter();

  public equipments: SensorEquipment[];
  public equipment: SensorEquipment;
  public loading = false;
  public onCreatingEquipment = false;

  constructor(private sensorEquipmentsService: SensorEquipmentsService) { }

  ngOnInit() {
    this.load();
  }

  private load() {
    this.loading = true;

    this.sensorEquipmentsService.getAllUnassigned().pipe(
      finalize(() => {
        this.loading = false;
      }))
      .subscribe((equipments: SensorEquipment[]) => {
        this.equipments = equipments;
      })
  }

}
