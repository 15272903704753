import { Component, OnInit } from '@angular/core';
import { PasswordRestService } from '../services/password.rest.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
    selector: 'app-password-forgotten',
    templateUrl: './password-forgotten.component.html',
    styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent implements OnInit {
    public username: string;
    public sendSuccess = false;

    constructor(
        private notificationService: NotificationsService,
        private passwordRestService: PasswordRestService
    ) {
    }

    ngOnInit() {
    }

    onResetPassword() {
        this.passwordRestService.resetPassword(this.username).subscribe(
            (response) => {
                this.sendSuccess = true;
            },
            (error) => {
                console.log(error);
                this.notificationService.error('Error', 'Invalid username!');
            }
        );
    }

}
