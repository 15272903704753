import {
    combineLatest as observableCombineLatest,
    forkJoin,
    Observable,
    of,
    Subscription,
    timer as observableTimer
} from 'rxjs';


import { distinctUntilChanged, filter, map, mergeMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { CommandPending, SensorEquipment } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';
import { FleetVehicle, VehicleGroup } from '../fleet-depot/fleet-depot.model';
import { FleetUnit } from '../fleet-units/fleet-unit/fleet-unit.model';
import { IfmsBox } from '@hardware/ifms-boxes/ifms-box/ifms-box.model';

import { HistorySensorData } from './parts/sensor-data/sensor-data.model';
import { Tire } from './parts/tire/tire.model';
import { DatedGpsPosition, GpsPosition, TireDetails, UnitVehicles, VehicleState } from './vehicle.model';
import { TpmsSensorService } from '@hardware/sensor-equipments/sensor-equipment/tpms-sensor/tpms-sensor.service';
import { VehicleActions } from './vehicle.reducer';
import { AxlePositionMapping, VehicleTemplate } from '@configurator/vehicle-configurator.model';
import { UserService } from '@user/user.service';

import {
    FLEET_UNIT_REFRESH_MILLISECONDS,
    getTireMapping,
    getTiresByIndex,
    prepareTirePositions,
    TireMapping
} from './vehicle.helper';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { AxleThresholdsService } from '@parts/axle/axle-thresholds/axle-thresholds.service';

const COMMANDS_ENABLED = environment.features.COMMANDS_ENABLED;

@Injectable()
export class VehicleService {
  public state: Observable<VehicleState>;
  public selected: Observable<FleetUnit>;
  public emptyFleetUnit: Observable<boolean>;

  public locationHistory: Observable<GpsPosition[]>;
  public currentLocation: Observable<DatedGpsPosition>;
  public currentLocationTimerSub: Subscription;

  public vehicleGroup: Observable<VehicleGroup>;

  public unitVehicles: Observable<UnitVehicles>;

  public tractor: Observable<FleetVehicle>;
  public trailer: Observable<FleetVehicle>;

  public tractorVehicle: Observable<VehicleTemplate>;
  public trailerVehicle: Observable<VehicleTemplate>;

  public tireDetails: Observable<TireDetails>;
  public tractorTireDetails: Observable<Tire[]>;
  public trailerTireDetails: Observable<Tire[]>;
  public tireDetailsTimerSub: Subscription;

  public tractorAxleTires: Observable<TireMapping[]>;
  public trailerAxleTires: Observable<TireMapping[]>;

  public sensorHistory: Observable<HistorySensorData[]>;
  public tractorBox: Observable<IfmsBox>;

  public tractorAxlePositionMapping: Observable<AxlePositionMapping[]>;
  public tractorTiresByAxlePositionMapping: Observable<TireMapping[][]>;

  public trailerAxlePositionMapping: Observable<AxlePositionMapping[]>;
  public trailerTiresByAxlePositionMapping: Observable<TireMapping[][]>;

  public allTpmsSensorIds: Observable<number[]>;

  public tractorCalibrationPending: Observable<number[]>;
  public trailerCalibrationPending: Observable<number[]>;
  public calibrationPending: Observable<boolean>;

  public tractorSensorEquipment: Observable<SensorEquipment>;
  public trailerSensorEquipment: Observable<SensorEquipment>;

  public pendingCommands: Observable<CommandPending[]>;
  private pendingCommandsTimerSub: Subscription;
  private oneTimeLogin = false;

  public axleThreshold: Observable<any>;

  constructor (public store: Store<any>,
               private http: HttpClient,
               private router: Router,
               private userService: UserService,
               private tpmsSensorService: TpmsSensorService,
               private axleThresholdService: AxleThresholdsService) {

    this.state = this.store.select('vehicle');

    this.selected = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.selected;
        }
      }),
      distinctUntilChanged()
    );

    this.emptyFleetUnit = this.selected.pipe(
      map((fleetUnit: FleetUnit) => {
        return _.isNull(fleetUnit);
      }),
      distinctUntilChanged()
    );

    this.unitVehicles = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.unitVehicles;
        }
      }),
      distinctUntilChanged()
    );

    this.tractor = this.selected.pipe(
      map((state: FleetUnit) => {
        return _.get(state, 'tractor');
      }),
      distinctUntilChanged()
    );

    this.trailer = this.selected.pipe(
      map((state: FleetUnit) => {
        return _.get(state, 'trailer');
      }),
      distinctUntilChanged()
    );

    this.tractorVehicle = this.unitVehicles.pipe(
      map((unitVehicles: UnitVehicles) => {
        if (unitVehicles) {
          return unitVehicles.tractorVehicleDto;
        }
      }),
      distinctUntilChanged()
    );

    this.trailerVehicle = this.unitVehicles.pipe(
      map((unitVehicles: UnitVehicles) => {
        if (unitVehicles) {
          return unitVehicles.trailerVehicleDto;
        }
      }),
      distinctUntilChanged()
    );

    this.locationHistory = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.locationHistory;
        }
      }),
      distinctUntilChanged()
    );

    this.currentLocation = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.currentLocation;
        }
      }),
      distinctUntilChanged()
    );

    this.vehicleGroup = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.vehicleGroup;
        }
      }),
      distinctUntilChanged()
    );

    this.tireDetails = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.tireDetails;
        }
      }),
      distinctUntilChanged()
    );

    this.tractorTireDetails = this.tireDetails.pipe(
      map((tireDetails: TireDetails) => {
        if (tireDetails) {
          return tireDetails.tractorTyresDetails;
        }
      }),
      distinctUntilChanged()
    );

    this.tractorAxleTires = observableCombineLatest(
      this.tractorVehicle,
      this.tractorTireDetails,
    ).pipe(
      map(([tractorVehicle, tractorTireDetails]) => {
        return getTireMapping(tractorVehicle, tractorTireDetails);
      }),
      distinctUntilChanged(), );

    this.trailerTireDetails = this.tireDetails.pipe(
      map((tireDetails: TireDetails) => {
        if (tireDetails) {
          return tireDetails.trailerTyresDetails;
        }
      }),
      distinctUntilChanged()
    );

    this.trailerAxleTires = observableCombineLatest(
      this.trailerVehicle,
      this.trailerTireDetails,
    ).pipe(
      map(([vehicle, tireDetails]) => {
        return getTireMapping(vehicle, tireDetails);
      }),
      distinctUntilChanged(), );

    this.sensorHistory = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.sensorHistory;
        }
      }),
      distinctUntilChanged()
    );

    this.tractorBox = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.tractorBox;
        }
      }),
      distinctUntilChanged()
    );

    this.tractorAxlePositionMapping = this.tractorVehicle.pipe(
      map((tractorVehicle: VehicleTemplate) => {
        return _.get(tractorVehicle, 'axleConfig.axlePositionMaps');
      }),
      distinctUntilChanged()
    );

    this.tractorTiresByAxlePositionMapping = observableCombineLatest(
      this.tractorAxleTires,
      this.tractorAxlePositionMapping
    ).pipe(map(([tires, axlePositionMapping]) => {
        return _.map(axlePositionMapping, (axlePosition, index) => {
          return getTiresByIndex(tires, index);
        });
      }),
      distinctUntilChanged()
    );

    this.trailerAxlePositionMapping = this.trailerVehicle.pipe(
      map((trailerVehicle: VehicleTemplate) => {
        return _.get(trailerVehicle, 'axleConfig.axlePositionMaps');
      }));

    this.trailerTiresByAxlePositionMapping = observableCombineLatest(
      this.trailerAxleTires,
      this.trailerAxlePositionMapping
    ).pipe(map(([tires, axlePositionMapping]) => {
        return _.map(axlePositionMapping, (axlePosition, index) => {
          return getTiresByIndex(tires, index);
        });
      }),
      distinctUntilChanged()
    );

    this.allTpmsSensorIds = observableCombineLatest(
      this.tractorTiresByAxlePositionMapping,
      this.trailerTiresByAxlePositionMapping
    ).pipe(map(([tractorTires, trailerTires]) => {
        return _
          .chain([...tractorTires, ...trailerTires])
          .flatten()
          .filter(tire => tire)
          .map('tpmsSensor.tpmsSensorId')
          .sortBy()
          .value();
      }),
      distinctUntilChanged((previousTpmsSensorIds, tpmsSensorIds) => {
        return previousTpmsSensorIds.length === tpmsSensorIds.length &&
          _.intersection(previousTpmsSensorIds, tpmsSensorIds).length === tpmsSensorIds.length;
      }), );

    this.tractorSensorEquipment = this.selected.pipe(
      map((fleetUnit: FleetUnit) => {
        return _.get(fleetUnit, 'tractor.sensorEquipment');
      }),
      distinctUntilChanged()
    );

    this.trailerSensorEquipment = this.selected.pipe(
      map((fleetUnit: FleetUnit) => {
        return _.get(fleetUnit, 'trailer.sensorEquipment');
      }),
      distinctUntilChanged()
    );

    this.pendingCommands = this.state.pipe(
      map((state: VehicleState) => {
        if (state) {
          return state.pendingCommands;
        }
      }),
      distinctUntilChanged()
    );

        this.axleThreshold = this.selected.pipe(
            map((state: FleetUnit) => {
                if (state) {
                    const thresholdData: any = {};
                    const thresholds = _.get(state, 'axleThreshold');

                    if (state.tractor) {
                        thresholdData.tractor = _.find(thresholds, (o) => {
                            return o.fleetVehicleId === state.tractor.fleetVehicleId
                        });
                    }

                    if (state.trailer) {
                        thresholdData.trailer = _.find(thresholds, (o) => {
                            return o.fleetVehicleId === state.trailer.fleetVehicleId
                        });
                    }

                    thresholdData.fleetUnitId = state.fleetUnitId;

                    return thresholdData;
                }

                return null;
            }),
        );

        if (COMMANDS_ENABLED) {
            this.calibrationPending = this.pendingCommands.pipe(
                map((commands) => {
                    return _.get(commands, 'length', 0) > 0;
                }),
                distinctUntilChanged(), );
        } else {
            this.tractorCalibrationPending = this.selected.pipe(
                map((fleetUnit: FleetUnit) => {
                    return _.get(fleetUnit, 'tractor.sensorEquipment.calibrationPending', false);
                }),
                distinctUntilChanged()
            );

      this.trailerCalibrationPending = this.selected.pipe(
        map((fleetUnit: FleetUnit) => {
          return _.get(fleetUnit, 'trailer.sensorEquipment.calibrationPending', false);
        }),
        distinctUntilChanged()
      );

      this.calibrationPending = observableCombineLatest(
        this.tractorCalibrationPending,
        this.trailerCalibrationPending,
      ).pipe(
        map((pendings) => {
          return _.includes(pendings, true);
        }),
        distinctUntilChanged(), );
    }

  }

    getVehicle(vehicleGroupId: number, fleetUnitId: number) {
        const url = this.getFleetUnitUrl(vehicleGroupId, fleetUnitId);

        forkJoin([
            this.axleThresholdService.getThresholds(fleetUnitId),
            this.http.get(url),
        ]).subscribe((data) => {
            const fleetUnit: any = data[1];

            fleetUnit.axleThreshold = data[0];

            this.store.dispatch({
                type: VehicleActions.SELECT,
                payload: fleetUnit
            });

          this.getUnitVehicles(vehicleGroupId, fleetUnitId);

          const tractorVehicleId = _.get(fleetUnit, 'tractor.fleetVehicleId');
          const trailerVehicleId = _.get(fleetUnit, 'trailer.fleetVehicleId');
          if (tractorVehicleId) {
              this.getGpsHistory(vehicleGroupId, fleetUnitId, tractorVehicleId);
          } else if (trailerVehicleId) {
              this.getGpsHistory(vehicleGroupId, fleetUnitId, trailerVehicleId);
          }

          this.intervalCurrentLocation();
          this.intervalTireDetails();
          this.intervalPendingCommands();
      }, (err) => {
          this.router.navigate(['fleet/page-not-found'], {
              replaceUrl: true,
              queryParams: { error: 'MISSING_FLEET_UNIT' }
          });
      });
  }

  fetchSelectedFleetUnitInstance() {
    this.selected.pipe(take(1)).subscribe((fleetUnit: FleetUnit) => {
      if (fleetUnit) {
        const vehicleGroupId = _.get(fleetUnit, 'vehicleGroupId');
        const fleetUnitId = _.get(fleetUnit, 'fleetUnitId');

        this.clear();
        this.getVehicle(vehicleGroupId, fleetUnitId);
      }
    });
  }

  getVehicleWithUnits(vehicleGroupId: number, fleetUnitId: number) {
    const url = this.getFleetUnitUrl(vehicleGroupId, fleetUnitId);

    this.http.get(url).subscribe((res) => {
      this.store.dispatch({
        type: VehicleActions.SELECT,
        payload: res
      });

      this.getUnitVehicles(vehicleGroupId, fleetUnitId)
    });
  }

  private getFleetUnitUrl(vehicleGroupId: number, fleetUnitId: number): string {
    return `${environment.apiUrl}/vehiclegroups/${vehicleGroupId}/fleetUnits/${fleetUnitId}`;
  }

  getVehicleCurrentGpsPosition(vehicleGroupId: number, fleetUnitId: number) {
    const url = this.getFleetUnitUrl(vehicleGroupId, fleetUnitId);

    console.log('getVehicleCurrentGpsPosition URL TIMER:', `${url}/currentPosition`);

    this.http.get(`${url}/currentPosition`)
      .subscribe((location) => {
        if (location) {
          this.store.dispatch({
            type: VehicleActions.CURRENT_LOCATION_LOADED,
            payload: {
              date: new Date(),
              location
            }
          });
        }
      });
  }

  clearCurrentGpsPosition() {
    this.store.dispatch({
      type: VehicleActions.CLEAR_CURRENT_LOCATION
    });
  }

  getUnitVehicles(vehicleGroupId: number, fleetUnitId: number) {
    const url = this.getFleetUnitUrl(vehicleGroupId, fleetUnitId);

    console.log('getUnitVehiclesURL', `${url}/vehicles`);

    this.http.get(`${url}/vehicles`).pipe(
      map((unitVehicles) => {
        this.store.dispatch({
          type: VehicleActions.UNIT_VEHICLES_LOADED,
          payload: unitVehicles
        });
      }))
      .subscribe();
  }

  intervalCurrentLocation() {
    this.unsubscribeCurrentLocationInterval();

    this.currentLocationTimerSub = observableTimer(0, FLEET_UNIT_REFRESH_MILLISECONDS)
      .subscribe(() => {
        this.selected.pipe(take(1)).subscribe((vehicle: FleetUnit) => {
          if (vehicle) {
            this.getVehicleCurrentGpsPosition(vehicle.vehicleGroupId, vehicle.fleetUnitId);
            this.getTractorIfmsBoxBy(vehicle);
          }
        });
      });
  }

  private getTractorIfmsBoxBy(fleetUnit: FleetUnit) {
    const ifmsBoxId = _.get(fleetUnit, 'tractor.ifmsBox.ifmsBoxId');
    const ifmsBoxTrailerId = _.get(fleetUnit, 'trailer.ifmsBox.ifmsBoxId');

    if (_.isNumber(ifmsBoxId)) {
      this.getIfmsBox(ifmsBoxId).subscribe((box: IfmsBox) => {
        this.store.dispatch({
          type: VehicleActions.TRACTOR_BOX_LOADED,
          payload: box
        });
      });
    } else if (_.isNumber(ifmsBoxTrailerId)) {
      this.getIfmsBox(ifmsBoxTrailerId).subscribe((box: IfmsBox) => {
        this.store.dispatch({
          type: VehicleActions.TRACTOR_BOX_LOADED,
          payload: box
        });
      });
    }
  }

  unsubscribeCurrentLocationInterval() {
    if (this.currentLocationTimerSub) {
      this.currentLocationTimerSub.unsubscribe();
    }
  }

  intervalTireDetails() {
    this.unsubscribeTireDetails();

    this.tireDetailsTimerSub = observableTimer(0, FLEET_UNIT_REFRESH_MILLISECONDS)
      .subscribe(() => {
        this.getTireDetailsOfSelectedFleetUnit();
      });
  }

  unsubscribeTireDetails() {
    if (this.tireDetailsTimerSub) {
      this.tireDetailsTimerSub.unsubscribe();
    }
  }

  getGpsHistory(vehicleGroupId: number, fleetUnitId: number, fleetVehicleId: number) {
    const url = this.getFleetUnitUrl(vehicleGroupId, fleetUnitId);

    console.log('getGpsHistoryURL', `${url}/gpshistories?fleetVehicleId=${fleetVehicleId}`);

    this.http.get(`${url}/gpshistories?fleetVehicleId=${fleetVehicleId}`)
      .subscribe((locationHistory) => {
        this.store.dispatch({
          type: VehicleActions.LOCATION_HISTORY_LOADED,
          payload: locationHistory
        });
      });
  }

  getTireDetailsOfSelectedFleetUnit() {
    this.selected.pipe(take(1)).subscribe((vehicle: FleetUnit) => {
      if (_.has(vehicle, 'vehicleGroupId') && _.has(vehicle, 'fleetUnitId')) {
        this.getTireDetails(vehicle.vehicleGroupId, vehicle.fleetUnitId);
      }
    });
  }

  getTireDetails(vehicleGroupId: number, fleetUnitId: number) {
    const url = this.getFleetUnitUrl(vehicleGroupId, fleetUnitId);

    console.log('getTireDetails URL TIMER', `${url}/tyres`);

    this.http.get(`${url}/tyres`)
      .subscribe((tireDetails: TireDetails) => {
        this.store.dispatch({
          type: VehicleActions.TIRE_DETAILS_LOADED,
          payload: tireDetails
        });
      });
  }

  getTires(tiresSource: Observable<Tire[]>, axlePositionMapping: AxlePositionMapping): Observable<Tire[]> {
    return tiresSource.pipe(
      map((tires: Tire[]) => {
        tires = tires || [];

        return tires.filter((tire) => {
          return axlePositionMapping.axlePosition === tire.tpmsSensor.axlePosition;
        });
      }),
      map((tires: Tire[]) => {
        return prepareTirePositions(tires);
      }),
      distinctUntilChanged(), );
  }

  getTyreSensorHistory(vehicleGroupId: number, fleetUnitId: number, tyreId: number, range?: any, oneTimeLoginKey?: string): Observable<HistorySensorData[]> {
    if (!this.oneTimeLogin) {
      const url = this.getFleetUnitUrl(vehicleGroupId, fleetUnitId);
      return <Observable<HistorySensorData[]>>this.http.post(`${url}/tyres/${tyreId}/SensorHistoryByTimeSpan`, range || {})
    } else if (this.oneTimeLogin && oneTimeLoginKey) {
      const url = `${environment.apiUrl}/one-time-login/sensorHistoryByTimeSpan?token=${oneTimeLoginKey}&vehiclegroup=${vehicleGroupId}&fleetUnit=${fleetUnitId}&tyre=${tyreId}`;
      return <Observable<HistorySensorData[]>>this.http.post(url, range || {})
    }
  }

  dispatchSensorHistory(sensorHistory: HistorySensorData[]) {
    this.store.dispatch({
      type: VehicleActions.SENSOR_HISTORY_LOADED,
      payload: sensorHistory
    });
  }

  clear() {
    this.store.dispatch({
      type: VehicleActions.SET_DEFAULT,
    });
  }

  getTiresByIndex(source: Observable<TireMapping[]>, index: number): Observable<Tire[]> {
    return source.pipe(
      map((axleTires: TireMapping[]) => {
        return getTiresByIndex(axleTires, index);
      }),
      distinctUntilChanged(), );
  }

  getIfmsBox(id: number): Observable<IfmsBox> {
    console.log('getIfmsBox URL TIMER', `${environment.apiUrl}/ifmsboxes/${id}`);
    return <Observable<IfmsBox>>this.http.get(`${environment.apiUrl}/ifmsboxes/${id}`)
  }

  calibrateAll(body: any, action?: string): Observable<any> {
    let target = `${environment.apiUrl}/tpmsSensor/calibrateall`;

    if (action === 'save-only') {
      target = `${target}?updateDbOnly=true`;
    } else {
      target = `${target}?updateDbOnly=false`;
    }

    return this.http.put(target, body);
  }

  getCurrentPendingCommands() {
    this.getPendingCommands().pipe(
      take(1))
      .subscribe((commands: CommandPending[]) => {
        this.store.dispatch({
          type: VehicleActions.SET_PENDING_COMMANDS,
          payload: commands
        });
      })
  }

  private getPendingCommands(): Observable<any> {
    if (COMMANDS_ENABLED) {
      return this.userService
        .isAdmin.pipe(
          filter(_.isBoolean),
          mergeMap((isAdmin: boolean) => {
            if (isAdmin) {
              return this.selected;
            } else {
              return of(null);
            }
          }),
          map((fleetUnit: any) => {
            // console.log('fleetUnit', fleetUnit);
            return _
              .chain([_.get(fleetUnit, 'tractor'), _.get(fleetUnit, 'trailer')])
              .filter(fleetVehicle => _.get(fleetVehicle, 'fleetVehicleId'))
              .value();
          }),
          distinctUntilChanged(_.isEqual),
          mergeMap((fleetVehicles: FleetVehicle[]) => {
            if (fleetVehicles.length > 0) {
              const requests = _
                .chain(fleetVehicles)
                .map('fleetVehicleId')
                .map((fleetVehicleId, index) => {
                  return this.tpmsSensorService.getPendingCommands(fleetVehicleId).pipe(
                    map((command) => {
                      return {fleetVehicle: fleetVehicles[index], command};
                    }));
                })
                .value();

              return forkJoin(requests)
                .pipe(map((commands) => {
                  return _.filter(commands, command => _.get(command, 'command'));
                }));
            } else {
              return of(null);
            }
          }), );
    } else {
      return of(null);
    }
  }

  private intervalPendingCommands() {
    this.unsubscribePendingCommands();

    this.pendingCommandsTimerSub = observableTimer(0, FLEET_UNIT_REFRESH_MILLISECONDS)
      .subscribe(() => {
        this.getCurrentPendingCommands();
      });
  }

  private unsubscribePendingCommands() {
      if (this.pendingCommandsTimerSub) {
          this.pendingCommandsTimerSub.unsubscribe();
      }
  }

  public setThresholds(data) {
      const thresholds = [];
      if (data.tractor) {
          thresholds.push(data.tractor);

      }
      if (data.trailer) {
          thresholds.push(data.trailer);
      }
      this.axleThresholdService.setThresholds(data.fleetUnitId, thresholds);
  }

  public getVehicleOneTimeLogin(key) {
    this.oneTimeLogin = true;
    this.http.get(`${environment.apiUrl}/one-time-login/summary/${key}`).subscribe((data) => {
      const vehicleData: any = data;
      this.store.dispatch({
        type: VehicleActions.SELECT,
        payload: vehicleData.fleetVehicleUnitOverviewDto
      });

      this.store.dispatch({
        type: VehicleActions.UNIT_VEHICLES_LOADED,
        payload: vehicleData.unitVehicleDetailsDto
      });
    });

    this.http.get(`${environment.apiUrl}/one-time-login/summary-update/${key}`).subscribe((data) => {
      const summaryTimerData: any = data;
      if (this.currentLocationTimerSub) {
        this.currentLocationTimerSub.unsubscribe();
      }
      this.currentLocationTimerSub = observableTimer(0, FLEET_UNIT_REFRESH_MILLISECONDS)
        .subscribe(() => {
          this.selected.pipe(take(1)).subscribe((vehicle: FleetUnit) => {
            if (vehicle) {
              this.store.dispatch({
                type: VehicleActions.CURRENT_LOCATION_LOADED,
                payload: {
                  date: new Date(),
                  location: summaryTimerData.gpsPositionDto
                }
              });
            }
          });
        });

      if (this.tireDetailsTimerSub) {
        this.tireDetailsTimerSub.unsubscribe();
      }
      this.tireDetailsTimerSub = observableTimer(0, FLEET_UNIT_REFRESH_MILLISECONDS)
        .subscribe(() => {
          this.selected.pipe(take(1)).subscribe((vehicle: FleetUnit) => {
            if (_.has(vehicle, 'vehicleGroupId') && _.has(vehicle, 'fleetUnitId')) {
              this.store.dispatch({
                type: VehicleActions.TIRE_DETAILS_LOADED,
                payload: summaryTimerData.unitTyreDetailsDto
              });
            }
          });
        });
    });
  }

  public oneTimeLoginUnsubscribe() {
    if (this.pendingCommandsTimerSub) {
      this.pendingCommandsTimerSub.unsubscribe();
    }
  }
}
