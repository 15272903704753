import { map, mergeMap, skipWhile, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';


import { CalculationGraph } from '../fleet-depot/fleet-depot.model';
import { FleetUnit, UnitStatus } from '../fleet-units/fleet-unit/fleet-unit.model';

import { FleetState } from '../fleet.model';
import { FleetActions } from '../fleet.reducer';

import * as _ from 'lodash';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { FleetDepotService } from '../fleet-depot/fleet-depot.service';
import { FleetOverviewSearchFiltersService } from '@fleet/fleet-overview/fleet-overview-search-filters/fleet-overview-search-filters.service';


interface FleetUnitFilterRequestBody {
  alarmStatus: string[];
  pageSize?: number;
}

@Injectable()
export class FleetOverviewService {
  public state: Observable<FleetState>;
  public errorOnGetRecentDrives = false;

  constructor(private http: HttpClient,
              private store: Store<any>,
              private fleetDepot: FleetDepotService,
              private fleetOverviewSearchFilters: FleetOverviewSearchFiltersService) {
    this.state = this.store.select('fleet');


  }

  getRecentDrives() {
    this.getAllFleetUnits()
      .subscribe(() => {
        this.errorOnGetRecentDrives = false;
      }, () => {
        this.errorOnGetRecentDrives = true;

        this.store.dispatch({
          type: FleetActions.RESET_LOADED_FLEET_UNITS,
        });
      });
  }

  private getVehicleGroupRootIds(): Observable<number[]> {
    return this.fleetDepot.getCalculationGraph().pipe(
      map((graph: CalculationGraph) => {
        if (_.has(graph, 'vehicleGroupDtos')) {
          return graph.vehicleGroupDtos.map(vehicleGroup => vehicleGroup.vehicleGroupId);
        }
        return [];
      }));
  }

  getAllFleetUnits(): Observable<FleetUnit[]> {
    return this.getFleetUnits().pipe(
      map(units => {
        const alarmingStates = [
          UnitStatus.sensorDataOkOutdated,
          UnitStatus.pressureDanger,
          UnitStatus.pressureWarning,
          UnitStatus.pressureMaintenance,
          UnitStatus.temperatureWarning,
          UnitStatus.temperatureDanger,
          UnitStatus.wheelLoss
        ];
        const alarmingFleetUnits = _.remove(units, (unit) => { return _.includes(alarmingStates, unit.unitStatus); });
        const notAlarmingFleetUnits = _.remove(units, (unit) => { return !_.includes(alarmingStates, unit.unitStatus); });
        this.dispatchFleetUnits(FleetActions.SET_ALARMING_FLEET_UNITS, alarmingFleetUnits);
        this.dispatchFleetUnits(FleetActions.SET_NOT_ALARMING_FLEET_UNITS, notAlarmingFleetUnits);
        return units;
      }));
  }

  getFleetUnits(filter?: FleetUnitFilterRequestBody): Observable<FleetUnit[]> {
    return this.fleetOverviewSearchFilters.getFilters().pipe(
      skipWhile((data) => data === null),
      take(1),
      mergeMap((data) => {
        filter = _.assign(data, filter);
        return this.http.post(`${environment.apiUrl}/fleetUnits/test-new-action-reports`, filter).pipe(
          map((res: FleetUnit[]) => {
            return res;
          }));
      })
    );
  }

  private dispatchFleetUnits(action: string, units: FleetUnit[]): FleetUnit[] {
    this.store.dispatch({
      type: action,
      payload: units
    });

    return units;
  }

  clear() {
    this.errorOnGetRecentDrives = false;
  }
}
