import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import * as _ from 'lodash';

@Directive({
  selector: '[ifmsNoWhitespace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NoWhitespaceValidatorDirective, multi: true }],
})
export class NoWhitespaceValidatorDirective implements Validator {

  constructor() { }

  validate(control: FormControl): ValidationErrors {
    const message = {
      'ifmsNoWhitespace': {
        'message': 'ERROR_MESSAGES.WHITESPACE_IS_NOT_ALLOWED'
      }
    };

    return this.isValid(control.value) ? null : message;
 }

 private isValid(value: any): boolean {
   if (_.isEmpty(value) === false) {
     if (_.isNumber(value)) {
       value = _.toString(value);
     }

     return /\s/g.test(value) === false;
   }

   return true;
 }

}
