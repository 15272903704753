import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import * as _ from 'lodash';

@Injectable()
export class FleetVehicleEmailerSettingsService {

  constructor(private http: HttpClient) { }

  getCurrentSettings(fleetVehicleId: number): Observable<any> {
    return <Observable<any>>this.http
      .get(`${environment.apiUrl}/emailer-settings/${fleetVehicleId}`, { responseType: 'text' }).pipe(
      map((active: string) => {
        return <any>{ fleetVehicleId, active: active === 'true' };
      }));
  }

  saveCurrentSettings(settings: any): Observable<any> {
    const fleetVehicleId = _.get(settings, 'fleetVehicleId');
    const active = _.get(settings, 'active');

    return this.http.put(`${environment.apiUrl}/emailer-settings/${fleetVehicleId}/${active}`, {});
  }

}
