import { Component, OnInit } from '@angular/core';
import { VehicleService } from '@unit-state/vehicle.service';

@Component({
  selector: 'vehicle-contacts',
  templateUrl: './vehicle-contacts.component.html',
  styleUrls: ['./vehicle-contacts.component.scss']
})
export class VehicleContactsComponent implements OnInit {

  constructor(public vehicle: VehicleService) { }

  ngOnInit() {
  }

}
