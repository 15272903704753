import { finalize } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { CommandHistory } from './command.model';
import { FleetVehicle } from '../../fleet-depot/fleet-depot.model';

import { BoxCommandsHistoryService } from './box-commands-history.service';
import { ViewportService } from '@shared/services/viewport.service';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-box-commands-history',
  templateUrl: './box-commands-history.component.html',
  styleUrls: ['./box-commands-history.component.scss']
})
export class BoxCommandsHistoryComponent implements OnInit, OnDestroy {

  @Input() fleetVehicle: FleetVehicle;

  public commands: CommandHistory[] = [];

  public loading = false;
  public showHistory = false;

  public width = 320;
  private widthSub: Subscription;

  constructor(private commandsHistoryService: BoxCommandsHistoryService,
              private viewportService: ViewportService) { }

  ngOnInit() {
    this.widthSub = this.viewportService.width.subscribe((width: number) => {
      this.width = width - 60;
    });
  }

  ngOnDestroy() {
    if (this.widthSub) {
      this.widthSub.unsubscribe();
    }
  }

  getHistory() {
    const imei = _.get(this.fleetVehicle, 'ifmsBox.imei');

    if (imei) {
      this.loading = true;

      this.commandsHistoryService.getHistory(imei).pipe(
        finalize(() => {
          this.loading = false;
        }))
        .subscribe((commands: CommandHistory[]) => {
          this.commands = _
            .chain(commands)
            .map((history) => {
              try {
                const response = _.get(history, 'response');
                history.response = JSON.parse(response);
              } catch (err ) { }

              return history;
            })
            .value();

            this.showHistory = true;
        }, (err) => {
          this.commands = [];
          console.log(err);
        });
    }
  }

}
