import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { AxleComponent } from '@parts/axle/axle.component';
import { AxleFrameworkComponent } from '@parts/axle/axle-framework/axle-framework.component';
import { AxleFrameworksListingComponent } from '@parts/axle/axle-frameworks-listing/axle-frameworks-listing.component';
import { AxleTirePartComponent } from '@parts/axle/axle-tire-part/axle-tire-part.component';
import { AxleTireReportIconComponent } from '@parts/axle/axle-tire-part/axle-tire-report-icon/axle-tire-report-icon.component';
import { AxleTireNotificationIconComponent } from '@parts/axle/axle-tire-part/axle-tire-notification-icon/axle-tire-notification-icon.component';

import { SensorComponent } from '@parts/sensor/sensor.component';
import { SensorCalibrationComponent } from '@parts/sensor/sensor-calibration/sensor-calibration.component';

import { SensorDataComponent } from '@parts/sensor-data/sensor-data.component';
import { SensorHistoryChartComponent } from '@parts/sensor-data/sensor-history-chart/sensor-history-chart.component';

import { TireComponent } from '@parts/tire/tire.component';

import { AxleFrameworksService } from '@parts/axle/axle-framework/axle-frameworks.service';
import { SensorCalibrationService } from '@parts/sensor/sensor-calibration/sensor-calibration.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    AxleComponent,
    AxleFrameworkComponent,
    AxleFrameworksListingComponent,
    AxleTirePartComponent,
    AxleTireReportIconComponent,
    AxleTireNotificationIconComponent,

    SensorComponent,
    SensorCalibrationComponent,

    SensorDataComponent,
    SensorHistoryChartComponent,

    TireComponent,
  ],
  exports: [
    AxleComponent,
    AxleFrameworkComponent,
    AxleFrameworksListingComponent,
    AxleTirePartComponent,
    AxleTireReportIconComponent,
    AxleTireNotificationIconComponent,

    SensorComponent,
    SensorCalibrationComponent,

    SensorDataComponent,
    SensorHistoryChartComponent,

    TireComponent,
  ],
  providers: [
    AxleFrameworksService,
    SensorCalibrationService,
  ]
})
export class VehiclePartsModule {
  /*constructor (
    @Optional() @SkipSelf() parentModule: VehiclePartsModule
  ) {
    if (parentModule) {
      throw new Error('VehiclePartsModule is already loaded. Import only in UnitStateModule');
    }
  }*/
}
