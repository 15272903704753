import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

declare let window;

@Component({
  selector: 'app-marker-editor',
  templateUrl: './marker-editor.component.html',
  styleUrls: ['./marker-editor.component.scss']
})
export class MarkerEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('target', { static: true }) target: ElementRef;

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    const svgString = new XMLSerializer().serializeToString(this.target.nativeElement);
    console.log(svgString);

    const canvas: any = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    const DOMURL: any = window.URL || window.webkitURL || window;
    const img = new Image();
    const svg = new Blob([svgString], {type: 'image/svg+xml;charset=utf-8'});
    const url = DOMURL.createObjectURL(svg);
    img.onload = function() {
        console.log('ready');
        ctx.drawImage(img, 0, 0);
        const png = canvas.toDataURL('image/png');
        document.querySelector('#png-container').innerHTML = '<img src="' + png + '"/>';
        DOMURL.revokeObjectURL(png);
    };
    img.src = url;
  }

}
