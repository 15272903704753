import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, finalize, flatMap, take } from 'rxjs/operators';

import { User } from '@user/user.model';
import { Mandator, MandatorCredentials } from '@fleet/mandator/mandator.model';

import { MandatorService } from '@fleet/mandator/mandator.service';
import { UserService } from '@user/user.service';
import { NotificationsService } from 'angular2-notifications';

import { createMandatorCredentials, findParent } from '@fleet/mandator/mandator.helper';

import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { MandatorLogoInterface } from '@fleet/mandator-management/mandator-management.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ifms-mandator-management',
  templateUrl: './mandator-management.component.html',
  styleUrls: ['./mandator-management.component.scss']
})
export class MandatorManagementComponent implements OnInit, OnDestroy {

  public mandators: Mandator;
  public mandator: MandatorCredentials;
  public parent: Mandator;
  public selectMandator: Function;

  public pending = false;
  private mandatorLogo: MandatorLogoInterface;
  public uploadURL: string;
  private activatedRouteSub: Subscription;
  public mandatorRouteId: number;
  public mandatorChildsDeleteList: any;


    private static setMandatorLogo(logo: string) {
        return `data:image/png;base64,${logo}`;
    }

  constructor(private mandatorService: MandatorService,
              private userService: UserService,
              private notification: NotificationsService,
              public modalService: NgbModal,
              private http: HttpClient,
              private notificationsService: NotificationsService,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              private router: Router) {

    const that = this;
    this.selectMandator = ({ node }: { node: Mandator }) => {
      that.navigateToSelectedMandator(node);
    };
  }

  ngOnInit() {
      this.activatedRouteSub = this.route.params.subscribe((data) => {
          if (data && data.id) {
              this.mandatorRouteId = parseInt(data.id, 10);
              this.selectMandatorFromRouteId(this.mandatorRouteId);
          }
      })
      this.getMandators();
  }

  ngOnDestroy() {
        this.activatedRouteSub.unsubscribe();
  }

  getMandators() {
    this.pending = true;

    this.userService.instance
      .pipe(
        filter((user: User) =>  { return _.has(user, 'mandator'); }),
        take(1),
        flatMap((user: User) => {
          return this.mandatorService.getGraph(user.mandator.mandatorId)
        }),
        finalize(() => { this.pending = false; })
      )
      .subscribe((mandators: Mandator) => {
        this.mandators = mandators;
        if (this.route.snapshot.paramMap.get('id')) {
            this.mandatorRouteId = parseInt(this.route.snapshot.paramMap.get('id'), 10)
            this.selectMandatorFromRouteId(this.mandatorRouteId);
        }
      });
  }

  select(mandator: Mandator) {
    this.pending = true;

    if (_.has(mandator, 'mandatorId')) {
      this.mandatorService.getMandatorCredentials(mandator)
        .pipe(
          finalize(() => { this.pending = false; })
        )
        .subscribe((mandator: MandatorCredentials) => {
          this.mandator = mandator;
          this.getMandatorLogo();
          this.uploadURL = `${environment.apiUrl}/mandators/${this.mandator.mandatorId}/upload-logo`;

          if (_.has(mandator, 'mandatorId')) {
            this.parent = findParent(this.mandators, mandator.mandatorId);
          }
        });
    } else {
      this.mandator = _.assign(mandator, {
        mainContactPerson: {
          contact: {}
        }
      });

      this.parent = null;
    }
  }

  save() {
    const mandator = createMandatorCredentials(this.mandator, this.parent);

    this.mandatorService.saveMandator(mandator)
      .pipe(
        finalize(() => { this.pending = false; })
      )
      .subscribe((mandator: Mandator) => {
        this.notification.success('Success', 'Mandator has been saved!');

        this.mandator = null;
        this.parent = null;
        this.getMandators();
      }, (err) => {
        this.notification.remove();
        this.notification.error('Error on save', 'Errors occured during saving mandator!');
      });
  }

    openMandatorBrandImageUploadModal(modal) {
        this.modalService.dismissAll();
        this.modalService.open(modal, { backdrop: 'static', centered: true, windowClass: 'mandator-logo__modal'});
    }

    private getMandatorLogo() {
        this.mandatorLogo = {} as MandatorLogoInterface;
        this.http.get(`${environment.apiUrl}/mandators/${this.mandator.mandatorId}/logo`)
            .subscribe((image: MandatorLogoInterface) => {
                this.mandatorLogo = image;
                this.mandatorLogo.logo = MandatorManagementComponent.setMandatorLogo(image.logo);
            })
    }

    public reloadMandatorLogo(mandatorLogo: MandatorLogoInterface) {
        this.mandatorLogo = mandatorLogo;
        this.mandatorLogo.logo = MandatorManagementComponent.setMandatorLogo(mandatorLogo.logo);
        this.modalService.dismissAll();
    }

    public deleteMandatorLogo() {
        this.http.delete(`${environment.apiUrl}/mandators/${this.mandator.mandatorId}/logo`)
            .subscribe((res: MandatorLogoInterface) => {
                // TODO get data from response
                this.mandatorLogo = res;
                this.mandatorLogo.logo = MandatorManagementComponent.setMandatorLogo(res.logo);
                if (res) {
                    this.notificationsService.success(this.translateService.instant('FLEET.DEPOT.TIRE_AND_WHEEL_MONITORING_REPORT.LOGO.UPLOADED_MESSAGE'));
                } else {
                    this.notificationsService.error(this.translateService.instant('FLEET.DEPOT.TIRE_AND_WHEEL_MONITORING_REPORT.LOGO.UPLOADED_MESSAGE_ERROR'));
                }
            })
    }

    private recursiveFindMandatorWithId(mandators: Mandator[], mandatorRouteId) {
        for (const mandator of mandators) {
            if (mandator.mandatorId === mandatorRouteId) {
                return mandator;
            } else if (mandator.childMandators.length > 0) {
                const mandatorFound = this.recursiveFindMandatorWithId(mandator.childMandators, mandatorRouteId);
                if (mandatorFound) {
                    return mandatorFound;
                }
            }
        }
    }

    private selectMandatorFromRouteId(routeId) {
        if (this.mandators) {
            if (this.mandators.mandatorId === routeId) {
                this.select(this.mandators);
            } else {
                const foundMandator = this.recursiveFindMandatorWithId(this.mandators.childMandators, routeId);
                if (foundMandator) {
                  this.select(foundMandator);
                } else {
                    this.router.navigateByUrl(`fleet/mandators`);
                }
            }
        }
    }

    private navigateToSelectedMandator(node: Mandator) {
        this.router.navigateByUrl(`fleet/mandators/${node.mandatorId}`);
    }

    public openMandatorDeleteModal(modal) {
        this.getChildrenBeforeDelete();
        this.modalService.dismissAll();
        this.modalService.open(modal, { centered: true});
    }

    public getChildrenBeforeDelete() {
        this.http.get(`${environment.apiUrl}/mandators/getChildren/${this.mandator.mandatorId}`)
            .subscribe((mandatorChilds) => {
                this.mandatorChildsDeleteList = mandatorChilds;
            });
    }

    public delete() {
        this.http.get(`${environment.apiUrl}/mandators/moveMandatorToTrash/${this.mandator.mandatorId}`)
            .subscribe(() => {
                const deleteSuccessMessage = `${this.translateService.instant('FLEET.MANDATOR.MANDATOR_DELETE.SUCCESS')}`
                this.notificationsService.success(`${this.mandator.mandatorName}: ${deleteSuccessMessage}`);
                this.modalService.dismissAll();
                this.router.navigate([`/fleet/mandators/`])
            });
    }
}
