import { BehaviorSubject, combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';

import { distinctUntilChanged, finalize, map } from 'rxjs/operators';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';

import { FleetVehicle } from '@depot/fleet-depot.model';
import { FMS_Data, FMS_Entry, FMS_FleetVehicleProperty } from './vehicle-monitoring.model';

import { VehicleService } from '@unit-state/vehicle.service';
import { VehicleMonitoringService } from './vehicle-monitoring.service';
import { ViewportService } from '@shared/services/viewport.service';

import * as _ from 'lodash';
import { getSrcPath } from '@configurator/vehicle-types/vehicle-types.helper';

@Component({
  selector: 'ifms-vehicle-monitoring',
  templateUrl: './vehicle-monitoring.component.html',
  styleUrls: ['./vehicle-monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleMonitoringComponent implements OnInit, OnChanges, OnDestroy {

  @Input() fleetVehicle: FleetVehicle;
  @Input() tripId: number;

  public windowWidthSub: Subscription;
  public dialogWidth: number;

  public fmsData: FMS_Data;
  public entries: FMS_Entry[];
  public chunks: FMS_Entry[][];

  public vehicleIdEntry: FMS_Entry;

  public selectedEntry: FMS_Entry;
  public history: any;
  public numericHistorySeries = false;
  public showEntryHistory = false;
  private fmsRefresherSub: Subscription;

  private endpoint: string;

  public showPropertySettings = false;

  private allowedEntitiesSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private allowedEntities: Observable<string[]> = this.allowedEntitiesSubject.asObservable();

  private fmsEntriesSubject: BehaviorSubject<FMS_Entry[]> = new BehaviorSubject([]);
  private fmsEntries: Observable<FMS_Entry[]> = this.fmsEntriesSubject.asObservable();

  private fmsChunksSub: Subscription;

  private fmsEntities: any;
  private obdiiEntities: any;

  public loading = false;
  public imgSrcPath: any;

  constructor(public vehicle: VehicleService,
              public vehicleMonitoringService: VehicleMonitoringService,
              public viewportService: ViewportService,
              private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.windowWidthSub = this.viewportService
      .width
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((width: number) => {
        this.dialogWidth = _.min([width, 900]);
        this.ref.markForCheck();
      });

    this.fmsChunksSub = observableCombineLatest(
        this.allowedEntities,
        this.fmsEntries
      ).pipe(
      map(([entities, entries]) => {
        return _
          .chain(entries)
          .filter((entry) => {
            return _.includes(entities, _.get(entry, 'artifact.key'))
          })
          .chunk(4)
          .value();
      }))
      .subscribe((chunks) => {
        this.chunks = chunks;
        this.ref.markForCheck();
      });

    this.getFleetVehicleProperty();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fleetVehicle) {
      if (this.fleetVehicle) {
        const fileType = (navigator.appVersion.indexOf('MSIE 10') !== -1) ? 'png' : 'svg';
        this.imgSrcPath = getSrcPath(this.fleetVehicle.vehicleType, fileType);
        this.init();
      }
    }

    if (changes.tripId) {
      if (this.fleetVehicle) {
        this.init();
      }
    }
  }

  ngOnDestroy() {
    if (this.fmsChunksSub) {
      this.fmsChunksSub.unsubscribe();
    }

    if (this.windowWidthSub) {
      this.windowWidthSub.unsubscribe();
    }

    this.clearRefresher();
  }

  private init() {
    this.initRefresher();
    this.getFleetVehicleProperty();
  }

  private initRefresher() {
    this.clearRefresher();

    this.fmsRefresherSub = this.vehicleMonitoringService
      // .createFMSDataRefresher(this.fleetVehicle)
      .createActiveCanBusDataRefresher(this.fleetVehicle)
      .subscribe((result: any) => {
        this.fmsData = _.get(result, 'fmsData');
        this.entries = _.get(result, 'entries');
        this.endpoint = _.get(result, 'endpoint');

        if (this.tripId) {
          _.set(this.fmsData, 'tripId', this.tripId);
        }

        this.vehicleIdEntry = _.last(_.remove(this.entries, (entry) => {
          return _.get(entry, 'artifact.key') === 'vehicleIdentificationNr';
        }));

        this.fmsEntriesSubject.next(this.entries);
      });
  }

  private clearRefresher() {
    if (this.fmsRefresherSub) {
      this.fmsRefresherSub.unsubscribe();
    }
  }

  private getFleetVehicleProperty() {
    if (_.has(this.fleetVehicle, 'fleetVehicleId')) {
      this.vehicleMonitoringService.getFleetVehicleProperty(this.fleetVehicle)
        .subscribe((property: FMS_FleetVehicleProperty) => {
          // console.log('property', property);

          this.fmsEntities = _.get(property, 'contentFms');
          this.obdiiEntities = _.get(property, 'contentObdii');

          const entities = _
            .chain(_.assign({}, this.fmsEntities, this.obdiiEntities))
            .map((show, entity) => {
              return show ? entity : null;
            })
            .filter(_.isString)
            .value();

          this.allowedEntitiesSubject.next(entities);
        }, (err) => {
          console.log(err);
        })
    }
  }

  showHistoryBy(entry: FMS_Entry) {
    this.showEntryHistory = true;
    this.selectedEntry = entry;

    const endpoint = _.isNull(this.fmsEntities) ? 'obdii' : 'fms';

    this.history = null;

    if (this.fmsData) {
      this.loading = true;

      this.vehicleMonitoringService.getHistoryBy(endpoint, this.fmsData, entry).pipe(
        finalize(() => {
          this.loading = false;
        }))
        .subscribe((history: any) => {
          this.history = history;
          this.numericHistorySeries = _.includes(['Integer', 'Double', 'Long'], _.get(this.history, 'classType'));
          this.ref.markForCheck();
        });
    }

  }

  savedVehiclePropertySettings(property: FMS_FleetVehicleProperty) {
    this.showPropertySettings = false;
    this.vehicle.fetchSelectedFleetUnitInstance();
  }

}
