import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';

import {
    FleetVehicleTrip,
    FleetVehicleTripsRequestBody,
    FleetVehicleTripsResponse,
    GpsHistory,
    TireSensorHistory
} from './fleet-vehicle-trip.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import * as _ from 'lodash';

@Injectable()
export class FleetVehicleTripHistoryService {
  public loading = false;

  constructor(private http: HttpClient) { }

  getTrips(fleetVehicleId: number, body: FleetVehicleTripsRequestBody): Observable<FleetVehicleTripsResponse> {
    this.loading = true;

    return <Observable<FleetVehicleTripsResponse>>this.http.post(`${environment.apiUrl}/fleetvehicles/${fleetVehicleId}/trips`, body).pipe(
      finalize(() => {
        this.loading = false;
      }));
  }

  getFleetVehicleGpsHistoryBy(fleetVehicleId: number, tripId: number): Observable<GpsHistory[]> {
    return <Observable<GpsHistory[]>>this.http.get(`${environment.apiUrl}/fleetvehicles/${fleetVehicleId}/trips/${tripId}/gps`);
  }

  getAllFleetVehicleGpsHistoryBy(fleetVehicleId: number, trips: FleetVehicleTrip[]): Observable<GpsHistory[][]> {
    const gpsHistories = trips.map((trip) => { return this.getFleetVehicleGpsHistoryBy(fleetVehicleId, _.get(trip, 'tripId')) });

    return forkJoin(gpsHistories);
  }

  getTripTireSensorHistory(fleetVehicleId: number, tripId: number): Observable<TireSensorHistory[]> {
    return <Observable<TireSensorHistory[]>>this.http.get(`${environment.apiUrl}/fleetvehicles/getHistory/${tripId}/${fleetVehicleId}`) // ${fleetVehicleId}/trips/${tripId}/tyreSensorHistory
  }

}
