import { VehicleConfiguratorState, VehicleTemplate } from './vehicle-configurator.model';
import { VehicleAxleHelper } from './vehicle-configurator.helper';

const defaultState: VehicleConfiguratorState = {
  // configurations: [],

  vehicles: [],
  vehicle: null,

  axleConfigs: [],

  axles: [],
  axle: null,
};

export class VehicleConfiguratorActions {
  public static SET_DEFAULT = 'VEHICLE_CONFIGURATOR-SET_DEFAULT';

  public static VEHICLE_TEMPLATES_LOADED = 'VEHICLE_CONFIGURATOR-VEHICLE_TEMPLATES_LOADED';
  public static VEHICLE_TEMPLATE_LOADED = 'VEHICLE_CONFIGURATOR-VEHICLE_TEMPLATE_LOADED';
  public static SET_EMPTY_VEHICLE_TEMPLATE = 'VEHICLE_CONFIGURATOR-SET_EMPTY_VEHICLE_TEMPLATE';

  public static AXLE_CONFIGS_LOADED = 'VEHICLE_CONFIGURATOR-AXLE_CONFIGS_LOADED';

  public static AXLES_LOADED = 'VEHICLE_CONFIGURATOR-AXLES_LOADED';
  public static AXLE_LOADED = 'VEHICLE_CONFIGURATOR-AXLE_LOADED';
}

export function vehicleConfiguratorReducer (state: VehicleConfiguratorState = defaultState, { type, payload }) {
  switch (type) {
    case <string>VehicleConfiguratorActions.SET_DEFAULT:
      return Object.assign({}, defaultState);

    case <string>VehicleConfiguratorActions.VEHICLE_TEMPLATES_LOADED:
      return Object.assign({}, state, { vehicles: payload });

    case <string>VehicleConfiguratorActions.VEHICLE_TEMPLATE_LOADED:
      return Object.assign({}, state, { vehicle: payload });

    case <string>VehicleConfiguratorActions.SET_EMPTY_VEHICLE_TEMPLATE:
      const vehicle: VehicleTemplate = {
        vehicleId: undefined,
        name: null,
        producer: null,
        type: null,
        axleConfig: null,
      };

      return Object.assign({}, state, { vehicle });

    case <string>VehicleConfiguratorActions.AXLE_CONFIGS_LOADED:
      VehicleAxleHelper.sortAxleByPositionsOfTemplates(payload);
      return Object.assign({}, state, { axleConfigs: payload });

    case <string>VehicleConfiguratorActions.AXLES_LOADED:
      return Object.assign({}, state, { axles: payload });

    case <string>VehicleConfiguratorActions.AXLE_LOADED:
      return Object.assign({}, state, { axle: payload });

    default:
      return state;
  }
}
