import { Injectable } from '@angular/core';

import { AxlePositionMapping } from '@configurator/vehicle-configurator.model';
import { AXLE_FRAMES, AxleFramework, AxleFrameworkImageMapping, AxleItemsFrameGrid } from './axle-framework.model';
import { Tire } from '@parts/tire/tire.model';
import { TpmsSensor } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';

import {
    createFrameworkByAxlePositionsAndPositionables,
    getTirePlaceHoldersByAxlePositions,
    getTiresGridByAxlePositions
} from './axle-frameworks.helper';

@Injectable()
export class AxleFrameworksService {

  public frameworks: AxleFrameworkImageMapping = AXLE_FRAMES;

  get(pathToImage: string): AxleFramework {
    return this.frameworks[pathToImage];
  }

  getTirePlaceHoldersByAxlePositions(axlePositions: AxlePositionMapping[], tpmsSensors?: TpmsSensor[]): TpmsSensor[][] {
    return getTirePlaceHoldersByAxlePositions(this.frameworks, axlePositions, tpmsSensors);
  }

  getTiresGridByAxlePositions(axlePositions: AxlePositionMapping[], tires?: Tire[]): Tire[][] {
    return getTiresGridByAxlePositions(this.frameworks, axlePositions, tires);
  };

  createFrameworkByAxlePositionsAndPositionables(axlePositions: AxlePositionMapping[], items?: any[]): AxleItemsFrameGrid {
    return createFrameworkByAxlePositionsAndPositionables(this.frameworks, axlePositions, items);
  };

}
