import { Component, OnDestroy, OnInit } from '@angular/core';
import { HostConfigService } from '@shared/services/host-config/host-config.service';
import { UserService } from '@user/user.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'ifms-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    public mobileMenuToggled: boolean;
    public username: string;
    private usernameSub: Subscription;

    constructor(
        private hostConfigService: HostConfigService,
        private userService: UserService,
    ) {
        this.mobileMenuToggled = false;
    }

    ngOnInit() {
        this.usernameSub = this.userService.instance.pipe(
            filter(user => user !== null)
        ).subscribe((user) => {
            this.username = user.username;
        });
    }

    ngOnDestroy() {
        this.usernameSub.unsubscribe();
    }

    toggleMobileMenu() {
        const className = 'mobile-menu-open';
        this.mobileMenuToggled = !this.mobileMenuToggled;
        const body = document.getElementsByTagName('body')[0];
        if (this.mobileMenuToggled) {
            body.classList.add(className);
        } else {
            body.classList.remove(className);
        }
    }

    get logo() {
        return this.hostConfigService.logo;
    }

    get supportByTyreWatchLogo() {
        if (this.hostConfigService.showSupportedByTyrewatchLogo) {
            return this.hostConfigService.supportByTyreWatchLogo;
        } else {
            return null;
        }
    }

    get showSupportedByTyrewatchLogo() {
        return this.hostConfigService.showSupportedByTyrewatchLogo;
    }
}
