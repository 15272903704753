import { FleetDepotState, FleetVehicle } from './fleet-depot.model';

const defaultState: FleetDepotState = {
  depot: null,
  vehicles: null,
  currencies: null,
};

export class FleetDepotActions {
  public static SET_DEFAULT = 'FLEET_DEPOT-SET_DEFAULT';
  public static DEPOT_LOADED = 'FLEET_DEPOT-DEPOT_LOADED';

  public static VEHICLES_LOADED = 'FLEET_DEPOT-VEHICLES_LOADED';
  public static SET_VEHICLE = 'FLEET_DEPOT-SET_VEHICLE';

  public static DEPOT_ADD_VEHICLE_GROUP = 'FLEET_DEPOT-ADD_VEHICLE_GROUP';
  public static DEPOT_REMOVE_VEHICLE_GROUP = 'FLEET_DEPOT-REMOVE_VEHICLE_GROUP';

  public static DEPOT_ADD_VEHICLE = 'FLEET_DEPOT-ADD_VEHICLE';
  public static DEPOT_REMOVE_VEHICLE = 'FLEET_DEPOT-REMOVE_VEHICLE';

  public static DEPOT_LOAD_CURRENCIES = 'FLEET_DEPOT-LOAD_CURRENCIES';
}

const setVehicle = (state: FleetDepotState, vehicle: FleetVehicle) => {
  if (state && state.vehicles && vehicle) {
    const vehicles = state.vehicles;
    const ids = vehicles.map(fleetVehicle => fleetVehicle.fleetVehicleId);
    const index = ids.indexOf(vehicle.fleetVehicleId);

    if (index !== -1 && index < ids.length) {
      vehicles[index] = vehicle;
    } else {
      vehicles.push(vehicle);
    }
  }
};

export function fleetDepotReducer (state: FleetDepotState = defaultState, { type, payload }) {
  switch (type) {
    case <string>FleetDepotActions.SET_DEFAULT:
      return Object.assign({}, defaultState);

    case <string>FleetDepotActions.VEHICLES_LOADED:
      return Object.assign({}, state, { vehicles: payload });

    case <string>FleetDepotActions.DEPOT_LOADED:
      return Object.assign({}, state, { depot: payload });

    case <string>FleetDepotActions.DEPOT_ADD_VEHICLE_GROUP:
      return Object.assign({}, state);

    case <string>FleetDepotActions.DEPOT_LOAD_CURRENCIES:
      return Object.assign({}, state, { currencies: payload });

    case <string>FleetDepotActions.SET_VEHICLE:
      setVehicle(state, payload);
      return Object.assign({}, state);

    default:
      return state;
  }
}
