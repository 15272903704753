import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { Mandator } from '@fleet/mandator/mandator.model';
import { User } from '@user/user.model';

import { UserService } from '@user/user.service';
import { UserManagementService } from './user-management.service';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  public users: User[];
  public filteredUsers: User[];
  public user: User;
  public mandators: Mandator;
  public mandator: Mandator;
  public userRole = 'NONE';

  public pending = false;
  public needle: string;
  public isRecursive: boolean

  constructor(public userService: UserService,
              private management: UserManagementService) { }

  ngOnInit() {
    this.isRecursive = true;

    this.management.getMandator().subscribe((mandator: Mandator) => {
      this.mandator = mandator;

      this.getMandators();
      this.getUsers();
    });
  }

  selectUser(user: User) {
    this.user = _.cloneDeep(user);
  }

  selectEmptyUser() {
    this.user = {
      username: null,
      mandator: _.cloneDeep(this.mandator),
      userRole: 'ROLE_FM_USER',
      locked: false,
      active: true,
      person: {
        personId: null,
        personName: null,
        personSurname: null,
        locale: null,
        mandatorName: null,

        contact: {
          contactId: null,
          contactAdresses: null,
          email: null,
          phone1: null,
          phone2: null,
          timezone: null,
        }
      }
    };
  }

  getUsers() {
    console.log('this.isRecursive', this.isRecursive);
    this.pending = true;

    this.management.getUsers(_.get(this.mandator, 'mandatorId'), this.isRecursive).pipe(
      finalize(() => {
        this.pending = false;
      }))
      .subscribe((users) => {
        // console.log('users', users);
        this.users = users;

        if (this.needle) {
          this.filter();
        } else {
          this.filteredUsers = users;
        }
      }, (err) => {
        console.log(err);
      });
  }

  getMandators() {
    this.management.getMandators()
      .subscribe((mandators) => {
        // console.log('mandators', mandators);
        this.mandators = mandators;
      }, (err) => {
        console.log(err);
      });
  }

  clear() {
    this.user = null;
    this.users = null;

    this.getUsers();
  }

  filter() {
    if (this.users) {
      const needle = _.trim(_.toLower(this.needle));
      this.filteredUsers = _.filter(this.users, (user) => {
        return _.includes(_.trim(_.toLower(user.username)), needle)
            && (this.userRole === 'NONE' || this.userRole === _.get(user, 'userRole'));
      });
    }
  }

  filterByMandator(mandator: Mandator) {
    this.mandator = mandator;
    this.getUsers();
  }

  selectRole(role: string) {
    // this.userRole = role === 'NONE' ? undefined : role;
    this.userRole = role;
    this.filter();
  }

  toggleShowMore() {
    this.isRecursive = !this.isRecursive;
    this.getUsers();
  }
}
