export interface AxleFramework {
  name: string;
  elements: AxleElement[];

  positions?: number;
  style?: { [property: string]: string; };
}

export interface AxleFrameworkImageMapping {
  [pathToImage: string]: AxleFramework;
}

export interface AxleElement {
  tag: string;
  position?: number;

  dPath?: string;
  d?: string;
  points?: string;
}

export interface AxleItemsFrameGrid {
  grid: any[][];
  floatingAxlePosition: number;
  leftovers: any[];
}

export const AXLE_FRAMES: AxleFrameworkImageMapping = {
  '/assets/images/vehicle/axel/LKW-01.svg': {
    'name': 'non-steering-axle-with-engine',
    'positions': 2,
    'elements': [
      {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M96,4H67.83c-6,0-10.9,8.42-10.9,18.81V186.23c0,10.39,4.88,18.81,10.9,18.81H96c6,0,10.9-8.42,10.9-18.81V22.81C106.93,12.42,102.05,4,96,4Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M533,4h-28.2c-6,0-10.9,8.42-10.9,18.81V186.23c0,10.39,4.88,18.81,10.9,18.81H533c6,0,10.9-8.42,10.9-18.81V22.81C543.85,12.42,539,4,533,4Z'
      }, {
        'tag': 'polygon',
        'points': '379.99 105.15 380 111.85 487.99 111.85 487.99 90.76 379.99 90.76 379.99 105.15'
      }, {
        'tag': 'polygon',
        'points': '220.78 111.85 220.78 105.15 220.78 90.76 112.78 90.76 112.78 111.85 220.78 111.85'
      }, {
        'tag': 'path',
        'd': 'M339.81,193.53c0-.89,0-8.25,0-9.13h-5.7V180h12.75a6.87,6.87,0,0,0,6.87-6.88v-2.53a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3V133a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3V104a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3v-2.6a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3V57.8a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23a3,3,0,0,0-3-3H358.16a17.42,17.42,0,0,1-13-5.86l-1.81-2a28.58,28.58,0,0,0-21.38-9.62H277.57a28.57,28.57,0,0,0-21.38,9.62l-1.81,2a17.42,17.42,0,0,1-13,5.86H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.6a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3V98a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.6a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.53a6.87,6.87,0,0,0,6.87,6.88h12.76v4.35h-5.7c0,.88,0,8.25,0,9.13C259.69,225.13,339.81,225.13,339.81,193.53Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-02.svg': {
    'name': 'slim-axle-bridge',
    'style': {
      'margin-left': '2px'
    },
    'elements': [
      {
        'tag': 'polygon',
        'points': '288.39 -1 288.39 224.75 294.11 224.75 306.39 224.75 306.39 -1 288.39 -1'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-03.svg': {
    'name': 'axle-bridge',
    'elements': [
      {
        'tag': 'polygon',
        'points': '289.24 225 295.93 225 310.32 225 310.32 -1.47 289.24 -1.47 289.24 225'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-04.svg': {
    'name': 'dual-wheels-axle',
    'positions': 4,
    'elements': [
      {
        'tag': 'polygon',
        'points': '290.4,0 290.4,110 296.1,110 308.4,110 308.4,0'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M96.07,4H67.87C61.85,4,57,12.42,57,22.81V186.23C57,196.62,61.85,205,67.87,205h28.2c6,0,10.9-8.42,10.9-18.81V22.81C107,12.42,102.09,4,96.07,4Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 1,
        'dPath': 'M157.07,4h-28.2c-6,0-10.9,8.42-10.9,18.81V186.23c0,10.39,4.88,18.81,10.9,18.81h28.2c6,0,10.9-8.42,10.9-18.81V22.81C168,12.42,163.09,4,157.07,4Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 2,
        'dPath': 'M472,4h-28.2c-6,0-10.9,8.42-10.9,18.81V186.23c0,10.39,4.88,18.81,10.9,18.81H472c6,0,10.9-8.42,10.9-18.81V22.81C482.85,12.42,478,4,472,4Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M533,4h-28.2c-6,0-10.9,8.42-10.9,18.81V186.23c0,10.39,4.88,18.81,10.9,18.81H533c6,0,10.9-8.42,10.9-18.81V22.81C543.86,12.42,539,4,533,4Z'
      }, {
        'tag': 'path',
        'd': 'M370.58,92.38a100.35,100.35,0,0,1-54.7-15.74c-2.67-1.73-5.56-3.44-8.8-3.84a72.49,72.49,0,0,0-14.48,0,22.46,22.46,0,0,0-8.8,3.84,100.34,100.34,0,0,1-54.7,15.74H178.52v18.06h50.9c19.52,0,38.39,6,55,16.16a29.53,29.53,0,0,0,30.74,0c16.65-10.19,35.52-16.16,55-16.16h51.9V92.38Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-05.svg': {
    'name': 'twin-drive-middleware-wheels',
    'positions': 4,
    'elements': [
      {
        'tag': 'polygon',
        'points': '290.4,115 290.4,225 296.1,225 308.4,225 308.4,115'
      }, {
        'tag': 'polygon',
        'points': '290.4,0 290.4,110 296.1,110 308.4,110 308.4,0'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M96.07,8H67.87C61.85,8,57,16.42,57,26.81V190.23C57,200.62,61.85,209,67.87,209h28.2c6,0,10.9-8.42,10.9-18.81V26.81C107,16.42,102.09,8,96.07,8Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 1,
        'dPath': 'M157.07,8h-28.2c-6,0-10.9,8.42-10.9,18.81V190.23c0,10.39,4.88,18.81,10.9,18.81h28.2c6,0,10.9-8.42,10.9-18.81V26.81C168,16.42,163.09,8,157.07,8Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 2,
        'dPath': 'M472,8h-28.2c-6,0-10.9,8.42-10.9,18.81V190.23c0,10.39,4.88,18.81,10.9,18.81H472c6,0,10.9-8.42,10.9-18.81V26.81C482.85,16.42,478,8,472,8Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M533,8h-28.2c-6,0-10.9,8.42-10.9,18.81V190.23c0,10.39,4.88,18.81,10.9,18.81H533c6,0,10.9-8.42,10.9-18.81V26.81C543.86,16.42,539,8,533,8Z'
      }, {
        'tag': 'path',
        'd': 'M370.58,96.38a100.35,100.35,0,0,1-54.7-15.74c-2.67-1.73-5.56-3.44-8.8-3.84a72.49,72.49,0,0,0-14.48,0,22.46,22.46,0,0,0-8.8,3.84,100.34,100.34,0,0,1-54.7,15.74H178.52v18.06h50.9c19.52,0,38.39,6,55,16.16a29.53,29.53,0,0,0,30.74,0c16.65-10.19,35.52-16.16,55-16.16h51.9V96.38Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-06.svg': {
    'name': 'twin-drive-middleware-with-motor-wheels',
    'positions': 4,
    'elements': [
      {
        'tag': 'polygon',
        'points': '376.33 111.82 376.33 118.51 428.67 118.51 428.67 97.43 376.33 97.43 376.33 111.82'
      }, {
        'tag': 'polygon',
        'points': '171.31 111.82 171.31 118.51 223.65 118.51 223.65 97.43 171.31 97.43 171.31 111.82'
      }, {
        'tag': 'path',
        'd': 'M370,25.89H358.16a17.42,17.42,0,0,1-13-5.86l-1.81-2a28.58,28.58,0,0,0-21.38-9.62H277.57A28.57,28.57,0,0,0,256.19,18l-1.81,2a17.42,17.42,0,0,1-13,5.86H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.6a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.6a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.6a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.53a6.87,6.87,0,0,0,6.87,6.88h12.76v4.35h-5.7c0,.88,0,8.25,0,9.13,0,31.6,80.12,31.6,80.12,0,0-.89,0-8.25,0-9.13h-5.7v-4.35h12.75a6.87,6.87,0,0,0,6.87-6.88v-2.53a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3v-2.6a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3v-2.6a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3V57.9a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23A3,3,0,0,0,370,25.89Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M96.07,8H67.87C61.85,8,57,16.42,57,26.81V190.23C57,200.62,61.85,209,67.87,209h28.2c6,0,10.9-8.42,10.9-18.81V26.81C107,16.42,102.09,8,96.07,8Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 1,
        'dPath': 'M157.07,8h-28.2c-6,0-10.9,8.42-10.9,18.81V190.23c0,10.39,4.88,18.81,10.9,18.81h28.2c6,0,10.9-8.42,10.9-18.81V26.81C168,16.42,163.09,8,157.07,8Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 2,
        'dPath': 'M533,8h-28.2c-6,0-10.9,8.42-10.9,18.81V190.23c0,10.39,4.88,18.81,10.9,18.81H533c6,0,10.9-8.42,10.9-18.81V26.81C543.86,16.42,539,8,533,8Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M472,8h-28.2c-6,0-10.9,8.42-10.9,18.81V190.23c0,10.39,4.88,18.81,10.9,18.81H472c6,0,10.9-8.42,10.9-18.81V26.81C482.85,16.42,478,8,472,8Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-07.svg': {
    'name': 'steering-with-motor-wheels',
    'positions': 2,
    'elements': [
      {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M110.72,16.46l-28-3.64c-6-.78-11.9,6.94-13.24,17.24L48.42,192.13c-1.34,10.3,2.41,19.28,8.38,20.06l28,3.64c6,.78,11.9-6.95,13.24-17.25L119.1,36.52C120.44,26.22,116.69,17.24,110.72,16.46Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M544,15.86l-28-3.64c-6-.78-11.9,6.94-13.24,17.24l-21.1,162.06c-1.34,10.3,2.41,19.28,8.38,20.06l28,3.64c6,.78,11.9-6.95,13.24-17.24l21.1-162.06C553.71,25.62,550,16.64,544,15.86Z'
      }, {
        'tag': 'polygon',
        'points': '124.67 123.85 213.78 123.85 213.78 117.15 213.78 102.76 124.67 102.76 124.67 123.85'
      }, {
        'tag': 'polygon',
        'points': '389.69 123.85 478.81 123.85 478.81 117.15 478.81 102.76 389.69 102.76 389.69 123.85'
      }, {
        'tag': 'path',
        'd': 'M370,25.8H358.16a17.42,17.42,0,0,1-13-5.86l-1.81-2a28.58,28.58,0,0,0-21.38-9.62H277.57a28.57,28.57,0,0,0-21.38,9.62l-1.81,2a17.42,17.42,0,0,1-13,5.86H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.6a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3V98a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.6a3,3,0,0,1-3,3H229.46a3,3,0,0,0-3,3v23a3,3,0,0,0,3,3h13.32a3,3,0,0,1,3,3v2.53a6.87,6.87,0,0,0,6.87,6.88h12.76v4.35h-5.7c0,.88,0,8.25,0,9.13,0,31.6,80.12,31.6,80.12,0,0-.89,0-8.25,0-9.13h-5.7V180h12.75a6.87,6.87,0,0,0,6.87-6.88v-2.53a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3V133a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3V104a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3v-2.6a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23a3,3,0,0,0-3-3H356.73a3,3,0,0,1-3-3V57.8a3,3,0,0,1,3-3H370a3,3,0,0,0,3-3v-23A3,3,0,0,0,370,25.8Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-08.svg': {
    'name': 'steering-wheels',
    'positions': 2,
    'elements': [
      {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M110.72,16.46l-28-3.64c-6-.78-11.9,6.94-13.24,17.24L48.42,192.13c-1.34,10.3,2.41,19.28,8.38,20.06l28,3.64c6,.78,11.9-6.95,13.24-17.25L119.1,36.52C120.44,26.22,116.69,17.24,110.72,16.46Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M544,15.86l-28-3.64c-6-.78-11.9,6.94-13.24,17.24l-21.1,162.06c-1.34,10.3,2.41,19.28,8.38,20.06l28,3.64c6,.78,11.9-6.95,13.24-17.24l21.1-162.06C553.71,25.62,550,16.64,544,15.86Z'
      }, {
        'tag': 'polygon',
        'points': '124.67 122.37 477 122.37 477 116.66 477 104.38 124.67 104.37 124.67 122.37'
      }, {
        'tag': 'polygon',
        'points': '291.83 0 291.83 130.67 297.55 130.67 309.83 130.67 309.83 0 291.83 0'
      }, {
        'tag': 'path',
        'd': 'M370.58,104.38a100.35,100.35,0,0,1-54.7-15.74c-2.67-1.73-5.56-3.44-8.8-3.84a72.49,72.49,0,0,0-14.48,0,22.46,22.46,0,0,0-8.8,3.84,100.34,100.34,0,0,1-54.7,15.74H178.52v18.06h50.9c19.52,0,38.39,6,55,16.16a29.53,29.53,0,0,0,30.74,0c16.65-10.19,35.52-16.16,55-16.16h51.9V104.38Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-09.svg': {
    'name': 'steering-middleware-wheels',
    'positions': 2,
    'style': {
      'margin-right': '1px',
    },
    'elements': [
      {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M110.72,16.46l-28-3.64c-6-.78-11.9,6.94-13.24,17.24L48.42,192.13c-1.34,10.3,2.41,19.28,8.38,20.06l28,3.64c6,.78,11.9-6.95,13.24-17.25L119.1,36.52C120.44,26.22,116.69,17.24,110.72,16.46Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M544,15.86l-28-3.64c-6-.78-11.9,6.94-13.24,17.24l-21.1,162.06c-1.34,10.3,2.41,19.28,8.38,20.06l28,3.64c6,.78,11.9-6.95,13.24-17.24l21.1-162.06C553.71,25.62,550,16.64,544,15.86Z'
      }, {
        'tag': 'polygon',
        'points': '124.67 122.37 477 122.37 477 116.66 477 104.38 124.67 104.37 124.67 122.37'
      }, {
        'tag': 'polygon',
        'points': '291.83 0 291.83 224.67 297.55 224.67 309.83 224.67 309.83 0 291.83 0'
      }, {
        'tag': 'path',
        'd': 'M370.58,104.38a100.35,100.35,0,0,1-54.7-15.74c-2.67-1.73-5.56-3.44-8.8-3.84a72.49,72.49,0,0,0-14.48,0,22.46,22.46,0,0,0-8.8,3.84,100.34,100.34,0,0,1-54.7,15.74H178.52v18.06h50.9c19.52,0,38.39,6,55,16.16a29.53,29.53,0,0,0,30.74,0c16.65-10.19,35.52-16.16,55-16.16h51.9V104.38Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-10.svg': {
    'name': 'steering-backend-wheels',
    'positions': 2,
    'elements': [
      {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M110.72,16.46l-28-3.64c-6-.78-11.9,6.94-13.24,17.24L48.42,192.13c-1.34,10.3,2.41,19.28,8.38,20.06l28,3.64c6,.78,11.9-6.95,13.24-17.25L119.1,36.52C120.44,26.22,116.69,17.24,110.72,16.46Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M544,15.86l-28-3.64c-6-.78-11.9,6.94-13.24,17.24l-21.1,162.06c-1.34,10.3,2.41,19.28,8.38,20.06l28,3.64c6,.78,11.9-6.95,13.24-17.24l21.1-162.06C553.71,25.62,550,16.64,544,15.86Z'
      }, {
        'tag': 'polygon',
        'points': '124.67 122.37 477 122.37 477 116.66 477 104.38 124.67 104.37 124.67 122.37'
      }, {
        'tag': 'polygon',
        'points': '291.83 0 291.83 130.67 297.55 130.67 309.83 130.67 309.83 0 291.83 0'
      }, {
        'tag': 'path',
        'd': 'M370.58,104.38a100.35,100.35,0,0,1-54.7-15.74c-2.67-1.73-5.56-3.44-8.8-3.84a72.49,72.49,0,0,0-14.48,0,22.46,22.46,0,0,0-8.8,3.84,100.34,100.34,0,0,1-54.7,15.74H178.52v18.06h50.9c19.52,0,38.39,6,55,16.16a29.53,29.53,0,0,0,30.74,0c16.65-10.19,35.52-16.16,55-16.16h51.9V104.38Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-11.svg': {
    'name': 'non-steering',
    'positions': 2,
    'elements': [
      {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M96,12H67.83c-6,0-10.9,8.42-10.9,18.81V194.23c0,10.39,4.88,18.81,10.9,18.81H96c6,0,10.9-8.42,10.9-18.81V30.81C106.93,20.42,102.05,12,96,12Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M533,12h-28.2c-6,0-10.9,8.42-10.9,18.81V194.23c0,10.39,4.88,18.81,10.9,18.81H533c6,0,10.9-8.42,10.9-18.81V30.81C543.85,20.42,539,12,533,12Z'
      }, {
        'tag': 'polygon',
        'points': '112.78 118.33 486 118.33 486 112.62 486 100.33 112.78 100.33 112.78 118.33'
      }, {
        'tag': 'path',
        'd': 'M370.58,100.38a100.31,100.31,0,0,1-54.7-15.74c-2.67-1.73-5.56-3.44-8.81-3.84a72.13,72.13,0,0,0-14.47,0,22.52,22.52,0,0,0-8.81,3.84,100.31,100.31,0,0,1-54.7,15.74H178.52v18.06h50.9c19.52,0,38.39,6,55,16.16a29.5,29.5,0,0,0,30.74,0c16.65-10.18,35.53-16.16,55.05-16.16h51.89V100.38Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-12.svg': {
    'name': 'non-steering-with-bridge',
    'positions': 2,
    'elements': [
      {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M96,12H67.83c-6,0-10.9,8.42-10.9,18.81V194.23c0,10.39,4.88,18.81,10.9,18.81H96c6,0,10.9-8.42,10.9-18.81V30.81C106.93,20.42,102.05,12,96,12Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M533,12h-28.2c-6,0-10.9,8.42-10.9,18.81V194.23c0,10.39,4.88,18.81,10.9,18.81H533c6,0,10.9-8.42,10.9-18.81V30.81C543.85,20.42,539,12,533,12Z'
      }, {
        'tag': 'polygon',
        'points': '112.78 118.33 486 118.33 486 112.62 486 100.33 112.78 100.33 112.78 118.33'
      }, {
        'tag': 'polygon',
        'points': '290.39 -0.33 290.39 224.33 296.11 224.33 308.39 224.33 308.39 -0.33 290.39 -0.33'
      }, {
        'tag': 'path',
        'd': 'M370.58,100.38a100.31,100.31,0,0,1-54.7-15.74c-2.67-1.73-5.56-3.44-8.81-3.84a72.13,72.13,0,0,0-14.47,0,22.52,22.52,0,0,0-8.81,3.84,100.31,100.31,0,0,1-54.7,15.74H178.52v18.06h50.9c19.52,0,38.39,6,55,16.16a29.5,29.5,0,0,0,30.74,0c16.65-10.18,35.53-16.16,55.05-16.16h51.89V100.38Z'
      }
    ]
  },
  '/assets/images/vehicle/axel/LKW-13.svg': {
    'name': 'non-steering-backend',
    'positions': 2,
    'elements': [
      {
        'tag': 'ifms-axle-tire-part',
        'position': 0,
        'dPath': 'M96,12H67.83c-6,0-10.9,8.42-10.9,18.81V194.23c0,10.39,4.88,18.81,10.9,18.81H96c6,0,10.9-8.42,10.9-18.81V30.81C106.93,20.42,102.05,12,96,12Z'
      }, {
        'tag': 'ifms-axle-tire-part',
        'position': 3,
        'dPath': 'M533,12h-28.2c-6,0-10.9,8.42-10.9,18.81V194.23c0,10.39,4.88,18.81,10.9,18.81H533c6,0,10.9-8.42,10.9-18.81V30.81C543.85,20.42,539,12,533,12Z'
      }, {
        'tag': 'polygon',
        'points': '112.78 118.33 486 118.33 486 112.62 486 100.33 112.78 100.33 112.78 118.33'
      }, {
        'tag': 'polygon',
        'points': '290.39 -0.33 290.39 109.67 296.11 109.67 308.39 109.67 308.39 -0.33 290.39 -0.33'
      }, {
        'tag': 'path',
        'd': 'M370.58,100.38a100.31,100.31,0,0,1-54.7-15.74c-2.67-1.73-5.56-3.44-8.81-3.84a72.13,72.13,0,0,0-14.47,0,22.52,22.52,0,0,0-8.81,3.84,100.31,100.31,0,0,1-54.7,15.74H178.52v18.06h50.9c19.52,0,38.39,6,55,16.16a29.5,29.5,0,0,0,30.74,0c16.65-10.18,35.53-16.16,55.05-16.16h51.89V100.38Z'
      }
    ]
  }
};
