import { FleetVehicle, VehicleGroup } from '../../fleet-depot/fleet-depot.model';
import {
    IdentifierNotificationCount,
    NotificationList
} from '@fleet/fleet-units/fleet-unit/fleet-unit-notifications/notifications.model';
import { GpsPosition, UnitVehicle } from '@unit-state/vehicle.model';
import { Mandator } from '@fleet/mandator/mandator.model';
import { AxleThresholds } from '@parts/axle/axle-thresholds/axle-thresholds';

export interface FleetUnit {
  fleetUnitId: number;
  vehicleGroupId: number;

  vehicleGroup?: VehicleGroup;

  tractor?: FleetVehicle;
  trailer?: FleetVehicle;

  unitStatus?: UnitStatus;
  comments?: string[];
  notificationList: NotificationList[];
  axleThreshold: AxleThresholds[];
}

export interface FleetVehicleUnitOverview {
    comments: string[];
    countedNotification: IdentifierNotificationCount;
    currentGpsPosition: GpsPosition;
    fleetUnitId: number;
    mandator: Mandator;
    mandatorName: string;
    notificationList: NotificationList[];
    tractor: UnitVehicle;
    trailer: UnitVehicle;
    unitStatus: UnitStatus;
    vehicleGroupId: number;
    vehicleGroupName: string;
    highestRiskNotification: NotificationList;
}

export enum UnitStatus {
    inactive = 'FLEET_VEHICLE_INACTIVE',
    service = 'FLEET_VEHICLE_SERVICE',
    sensorDataOutdated = 'SENSOR_DATA_OUT_DATED',
    pressureOk = 'PRESSURE_OK',
    sensorDataOkOutdated = 'FLEET_VEHICLE_OK_OUTDATED',
    pressureMaintenance = 'PRESSURE_MAINTENANCE',
    pressureWarning = 'PRESSURE_WARNING',
    pressureDanger = 'PRESSURE_DANGER',
    temperatureOk = 'TEMPERATURE_OK',
    temperatureWarning = 'TEMPERATURE_WARNING',
    temperatureDanger = 'TEMPERATURE_DANGER',
    calibrationNeeded = 'CALIBRATION_NEEDED',
    ok = 'FLEET_VEHICLE_OK',
    sensorNumberOutdated = 'SENSOR_NUMBER_OUTDATED',
    wheelLoss = 'WHEEL_LOSS',
}

export enum UnitStatusToIcon {
  fleet_vehicle_inactive = 'fleet_vehicle_inactive',
  fleet_vehicle_service = 'fleet_vehicle_service',
  sensor_data_out_dated = 'sensor_data_out_dated',
  pressure_ok = 'ok',
  pressure_maintenance = 'warning',
  pressure_warning = 'warning',
  pressure_danger = 'danger',
  temperature_ok = 'ok',
  temperature_warning = 'warning',
  temperature_danger = 'danger',
  calibration_needed = 'ok',
  fleet_vehicle_ok = 'ok',
  sensor_number_outdated = 'ok',
}
