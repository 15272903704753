import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';

import { CoreModule } from './shared/modules/core.module';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { SharedModule } from './shared/shared.module';
import { FleetModule } from './fleet/fleet.module';
import { PrimengModule } from './shared/modules/primeng.module';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { AppComponent } from './app.component';
import { RootComponent } from './root/root.component';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from './header/menu/menu.component';
import { ThemeSwitcherComponent } from './hidden/theme-switcher/theme-switcher.component';
import Decimal from 'decimal.js';

@NgModule({
    declarations: [
        // Main
        AppComponent,
        RootComponent,

        // Header
        HeaderComponent,
        MenuComponent,
        ThemeSwitcherComponent,


        // Shared

    ],
    imports: [
        CommonModule,
        CoreModule,
        RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' }),
        SharedModule.forRoot(),
        FleetModule,
        PrimengModule.forRoot(),
        SimpleNotificationsModule.forRoot(),
        NgbModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'en-US' }, // set en-US as default locale
        { provide: 'windowObject', useValue: window },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
