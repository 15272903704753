import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AxleEmitter } from './axle.model';
import { AxlePositionMapping } from '@configurator/vehicle-configurator.model';
import { AxleTire } from './axle-tire.model';
import { Tire, TireEntry } from '../tire/tire.model';
import * as _ from 'lodash';

@Component({
  selector: 'ifms-axle',
  templateUrl: './axle.component.html',
  styleUrls: ['./axle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AxleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() axlePositionMapping: AxlePositionMapping;
  @Input() tires: Tire[];
  @Input() type: string;
  @Input() oneTimeLoginTireColor: {sensorId: number, property: { [property: string]: string; }};

  @Output() tireClick: EventEmitter<Tire> = new EventEmitter();
  @Output() calibrate: EventEmitter<{ pathToImage: string; tires: AxleTire[] }> = new EventEmitter();
  @Output() oneTimeLoginWheelNotificationColor = new EventEmitter();

  public axle: AxleEmitter;
  public axleTires: AxleTire[];
  public axleNeedsCalibration = false;
  public worstStateColor: string;

  private tireClickSub: Subscription;

  constructor() {

  }

  ngOnInit() {
    this.axle = new AxleEmitter();

    if (this.type) {
      this.axle.setType(this.type);
    }

    this.setTiresOfAxleBy([null, null, null, null]);

    this.tireClickSub = this.axle.tireClick
      .subscribe((tire: TireEntry) => {
        if (tire.payload) {
          this.tireClick.emit(tire.payload);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tires && this.tiresChanged(changes)) {
      this.setTiresOfAxleBy(this.tires);
    }
  }

  private tiresChanged(changes: SimpleChanges): boolean {
    const previous = _.get(changes, 'tires.previousValue');
    const current = _.get(changes, 'tires.currentValue');

    const test = _.reject(current, (data) => {
      return !data;
    });

    const isEqual = _.isEqual(previous, current);

    if (test.length > 0 && isEqual === true) {
      return true;
    } else {
      return isEqual === false;
    }
  }

  ngOnDestroy () {
    if (this.tireClickSub) {
      this.tireClickSub.unsubscribe();
    }
  }

  private setTiresOfAxleBy(tires?: Tire[]) {
    if (this.axle && _.isArray(tires)) {
      this.axle.setPayloads(tires);
      this.axleTires = this.axle.createAxleTires();


      this.setTireColorForOneTimeLogin();
      this.axleNeedsCalibration = this.axle.needsCalibration();
      this.worstStateColor = _.get(this.axle.getWorstStateColorStyle(), 'fill');
    }
  }

  emitCalibration() {
    const body = {
      pathToImage: _.get(this.axlePositionMapping, 'axle.pathToImage'),
      tires: this.axleTires,
    };

    this.calibrate.emit(body);
  }

  private setTireColorForOneTimeLogin() {
    if (this.oneTimeLoginTireColor) {
      for (const ax of this.axleTires) {
        ax.showInfoBox = false;
                if (ax.calibration) {
          if (ax.calibration.tpmsSensorId !== this.oneTimeLoginTireColor.sensorId) {
            ax.style = { fill: '#ffffff'};
          } else {
            this.oneTimeLoginWheelNotificationColor.emit({sensorId: this.oneTimeLoginTireColor.sensorId, color: ax.style.fill});
          }
        }

      }
    }
  }
}
