import { EventEmitter } from '@angular/core';
import { Condition } from '../../models/condition.model';

import { AxleTire, AxleTireCalibration } from './axle-tire.model';
import { Tire, TireEntry } from '../tire/tire.model';
import { createCalibrationData, includesSensorTimeNotifications } from './axle.helper';

import * as _ from 'lodash';
import { NotificationWithComment } from '@fleet/fleet-units/fleet-unit/fleet-unit-notifications/notifications.model';

const viewBox = { width: 600, height: 225 };
const tireWidth = 90;
const tirePadding = 10;
const tireGap = 32.5;

const TIRE_POSITIONS = {
  0: tirePadding,
  1: tireGap + tireWidth,
  2: viewBox.width - 2 * tireWidth - (tireGap + 1),
  3: viewBox.width - tireWidth - tirePadding - 2,
};

const DEFAULT_FILL = 'rgba(0,60,136,.5)';
const OKAY_FILL = Condition.COLORS.okay;

export class AxleEmitter {
  public TIRE_POSITIONS = TIRE_POSITIONS;
  public type: string;

  public tireClick;

  private tires: TireEntry[] = [
    {
      position: 'a',
    },
    {
      position: 'b',
    },
    {
      position: 'c',
    },
    {
      position: 'd',
    },
  ];

  constructor() {
    this.tireClick = new EventEmitter();
  }

  setType(type: string) {
    this.type = type;
  }

  emitPosition(index: number) {
    if (this.tires && index < this.tires.length) {
      const tire: TireEntry = this.tires[index];
      this.tireClick.emit(tire);
    }
  }

  setPayload(index: number, payload: Tire) {
    const tire = this.tires[index];

    if (tire) {
      tire.payload = payload;
    }
  }

  setPayloads(payloads: Tire[]) {
    if (payloads) {
      payloads.forEach((payload, index) => {
        this.setPayload(index, payload);
      });
    }
  }

  getPayload(index: number): Tire {
    if (index < this.tires.length) {
      return this.tires[index].payload;
    }
  }

  hasPayload(index: number): boolean {
    const tire = this.tires[index];

    if (tire) {
      return !!(tire.payload);
    }

    return false;
  }

  getCurrentPressure(index: number) {
    const payload = this.getPayload(index);

    if (_.has(payload, 'tpmsSensor.sensorData.currentPressure')) {
      return payload.tpmsSensor.sensorData.currentPressure;
    }
  }

  getCurrentTemperature(index: number) {
    const payload = this.getPayload(index);

    if (_.has(payload, 'tpmsSensor.sensorData.currentTemperature')) {
      return payload.tpmsSensor.sensorData.currentTemperature;
    }
  }

  getNotifications(index: number): NotificationWithComment[] {
    const payload = this.getPayload(index);
    return _.get(payload, 'tpmsSensor.tpmsSensorNotification') || [];
  }

  hasNotification(index: number): boolean {
    return this.getNotifications(index).length > 0;
  }

  hasTemperatureAlarm(index: number): boolean {
    return this.hasAlarmStatusByProperty(index, 'temperatureAlarm');
  }

  hasPressureAlarm(index: number): boolean {
    return this.hasAlarmStatusByProperty(index, 'pressureAlarm');
  }

  private hasAlarmStatusByProperty(index: number, property: string): boolean {
    return this.getAlarmStatus(index, property) !== 'OK';
  }

  private getAlarmStatus(index: number, property: string): string {
    const tire = this.tires[index];
    return _.get(tire, `payload.tpmsSensor.sensorData.${property}`);
  }

  hasAlarm(index: number): boolean {
    const tire = this.tires[index];

    if (_.has(tire, 'payload.tpmsSensor.sensorData')) {
      const states = this.getStates(index);
      return Condition.getWorstCaseStateBy(states) !== 'OK';
    }

    return false;
  }

  private getStates(index: number) {
    const tire = this.tires[index];
    if (_.has(tire, 'payload.tpmsSensor.tpmsSensorNotification')) {
        return tire.payload.tpmsSensor.tpmsSensorNotification.map(notification => notification.notification);
    }
    return [];
  }

  showInfoBoxOfTireBy(index: number): boolean {
    return this.hasNotification(index) || this.hasAlarm(index);
  }

  getStyle(index: number) {
    const tire = this.tires[index];

    const notifications = this.getNotifications(index);

    // const notifications: any = [
    //   { notification: 'CALIBRATION_NEEDED', comment: '1' },
    //   { notification: 'PRESSURE_WARNING', comment: '1' },
    // ];

    // console.log('notifications', notifications);

    const payload = this.getPayload(index);
    if (_.has(payload, 'tpmsSensor') === false) {
      return { fill: DEFAULT_FILL };
    }

    if (includesSensorTimeNotifications(notifications)) {
      return { fill: DEFAULT_FILL };
    }

    if (_.get(notifications, 'length', 0) > 0) {
      const states = notifications.map(notification => notification.notification);
      const worstCase = Condition.getWorstCaseStateBy(states);
      // console.log('states', states);
      // console.log('worstCase', worstCase);
      return { fill: Condition.getColorBy(worstCase) || DEFAULT_FILL };
    }

    return { fill: OKAY_FILL };
  }

  getWorstStateColorStyle() {
    const worstStates = _.map(this.tires, (tire, position) => {
      return Condition.getWorstCaseStateBy(this.getStates(position))
    });

    return { fill: Condition.getWorstCaseColorBy(worstStates) };
  }

  createAxleTireBy(options: { position: number; dPath?: string; }): AxleTire {
    const position = _.get(options, 'position');
    const dPath = _.get(options, 'dPath');

    return {
      position,
      style: this.getStyle(position),
      type: this.type,

      x: this.TIRE_POSITIONS[position],
      dPath,

      currentPressure: this.getCurrentPressure(position),
      currentTemperature: this.getCurrentTemperature(position),

      notifications: this.getNotifications(position).map(notification => notification.notification),
      hasNotification: this.hasNotification(position),
      hasPayload: this.hasPayload(position),
      hasPressureAlarm: this.hasPressureAlarm(position),
      hasTemperatureAlarm: this.hasTemperatureAlarm(position),
      showInfoBox: this.showInfoBoxOfTireBy(position),

      calibration: this.createTireCalibration(position),
    };
  }

  createAxleTires(): AxleTire[] {
    return _.map([0, 1, 2, 3], (position) => {
      return this.createAxleTireBy({ position });
    });
  }

  createTireCalibration(index: number): AxleTireCalibration {
    const payload = this.getPayload(index);
    return createCalibrationData(payload);
  }

  needsCalibration(): boolean {
    return _
      .chain(this.tires)
      .map((tire, index) => {
        return this.getNotifications(index);
      })
      .flatten()
      .includes('PRESSURE_CALIBRATION')
      .value();
  }
}
