import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { MonitoringTimeseriesChartComponent } from '@unit-state/vehicle-monitoring/monitoring-timeseries-chart/monitoring-timeseries-chart.component';
import { MonitoringFmsEntitiesComponent } from '@unit-state/vehicle-monitoring/monitoring-fms-entities/monitoring-fms-entities.component';

import { VehicleMonitoringComponent } from '@unit-state/vehicle-monitoring/vehicle-monitoring.component';
import { VehicleMonitoringPropertySettingsComponent } from '@unit-state/vehicle-monitoring/vehicle-monitoring-property-settings/vehicle-monitoring-property-settings.component';

import { VehicleMonitoringService } from '@unit-state/vehicle-monitoring/vehicle-monitoring.service';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		AngularSvgIconModule,
	],
  declarations: [
    MonitoringTimeseriesChartComponent,
    MonitoringFmsEntitiesComponent,
    VehicleMonitoringComponent,
    VehicleMonitoringPropertySettingsComponent,
  ],
  exports: [
    MonitoringTimeseriesChartComponent,
    MonitoringFmsEntitiesComponent,
    VehicleMonitoringComponent,
    VehicleMonitoringPropertySettingsComponent,
  ],
  providers: [
    VehicleMonitoringService,
  ]
})
export class VehicleMonitoringModule {
  /*constructor (
    @Optional() @SkipSelf() parentModule: VehicleMonitoringModule
  ) {
    if (parentModule) {
      throw new Error('VehicleMonitoringModule is already loaded. Import only in UnitStateModule');
    }
  }*/
}
