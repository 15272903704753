import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '@user/user.service';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private userService: UserService, private notification: NotificationsService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (route.data.roles) {
      return this.userService.hasAtLeastOneRole(route.data.roles).pipe(
        take(1),
        map((hasRole) => {
          if (!hasRole) {
            this.notification.error('No access', 'You don\'t have access');
            this.router.navigateByUrl('/');
          }
          return hasRole;
        })
      );
    } else {
      return true;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
