import { FleetVehicleMonitoringAnalysisState } from './fleet-vehicle-monitoring-analysis.model';

import * as _ from 'lodash';

const defaultState: FleetVehicleMonitoringAnalysisState = {
  fleetVehicle: null,
  property: null,
  canBusData: null,
  entries: null,
};

export class FleetVehicleMonitoringAnalysisActions {
  public static SET_DEFAULT = 'FLEET_VEHICLE_MONITORING_ANALYSIS-SET_DEFAULT';

  public static SET_FLEET_VEHICLE = 'FLEET_VEHICLE_MONITORING_ANALYSIS-SET_FLEET_VEHICLE';
  public static SET_FLEET_PROPERTY = 'FLEET_VEHICLE_MONITORING_ANALYSIS-SET_FLEET_PROPERTY';
  public static SET_CAN_BUS_DATA = 'FLEET_VEHICLE_MONITORING_ANALYSIS-SET_CAN_BUS_DATA';
}

export function fleetVehicleMonitoringAnalysisReducer (state: FleetVehicleMonitoringAnalysisState = defaultState, { type, payload }) {
  switch (type) {
    case <string>FleetVehicleMonitoringAnalysisActions.SET_DEFAULT:
      return Object.assign({}, defaultState);

    case <string>FleetVehicleMonitoringAnalysisActions.SET_FLEET_VEHICLE:
      return Object.assign({}, state, { fleetVehicle: payload });

    case <string>FleetVehicleMonitoringAnalysisActions.SET_FLEET_PROPERTY:
      return Object.assign({}, state, { property: payload });

    case <string>FleetVehicleMonitoringAnalysisActions.SET_CAN_BUS_DATA:
      return Object.assign({}, state, {
        canBusData: _.get(payload, 'fmsData', null),
        entries: _.get(payload, 'entries', null),
      });

    default:
      return state;
  }
}
