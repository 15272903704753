import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagInputModule } from 'ngx-chips';

import { OpenlayersMapComponent } from './components/openlayers/openlayers-map/openlayers-map.component';
import { MarkerEditorComponent } from './components/openlayers/marker-editor/marker-editor.component';
import { AddButtonComponent } from './components/add-button/add-button.component';
import { BackButtonComponent } from './components/back-button/back-button.component';

import { CheckboxModule } from 'primeng/checkbox';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  imports: [
    CommonModule,
    PaginatorModule,
    TagInputModule,
    CheckboxModule,
  ],
  exports: [
    AddButtonComponent,
    BackButtonComponent,
    CheckboxModule,
    MarkerEditorComponent,
    OpenlayersMapComponent,
    PaginatorModule,
    TagInputModule,
  ],
  declarations: [
    OpenlayersMapComponent,
    MarkerEditorComponent,
    AddButtonComponent,
    BackButtonComponent
  ]
})

export class FleetSharedModule {
  static forRoot(): ModuleWithProviders<FleetSharedModule> {
    return {
      ngModule: FleetSharedModule,
      providers: [

      ]
    };
  }
}
