import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ifms-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  public message = 'PAGE_NOT_FOUND.DEFAULT';
  private queryParamsSub: Subscription;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.queryParamsSub = this.route.params.subscribe((params) => {
      if (params.error) {
        console.log(params.error);
      }
    });

    this.queryParamsSub = this.route
      .queryParams
      .subscribe((params) => {
        if (params.error) {
          this.message = `PAGE_NOT_FOUND.${params.error}`;
        }
      });
  }

  ngOnDestroy() {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }
}
