import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { FleetVehicle } from '@depot/fleet-depot.model';
import { SensorEquipment } from '../sensor-equipment/sensor-equipment.model';

import { SensorEquipmentsService } from '../sensor-equipments.service';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-sensor-equipment-editor',
  templateUrl: './sensor-equipment-editor.component.html',
  styleUrls: ['./sensor-equipment-editor.component.scss']
})
export class SensorEquipmentEditorComponent implements OnInit, OnDestroy {
  @Input() fleetVehicle: FleetVehicle;

  @Output() created: EventEmitter<SensorEquipment> = new EventEmitter<SensorEquipment>();
  @Output() updated: EventEmitter<SensorEquipment> = new EventEmitter<SensorEquipment>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  public equipment: SensorEquipment;
  public loadingBox = false;
  public errorMessage: string;

  private routeSub: Subscription;

  constructor(private sensorEquipmentsService: SensorEquipmentsService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      let sensorEquipmentId = params.sensorEquipmentId;

      if (sensorEquipmentId) {
        sensorEquipmentId = parseInt(sensorEquipmentId, 10);

        if (sensorEquipmentId === 0) {
          this.equipment = {

          };
        } else {
          this.getEquipment(sensorEquipmentId);
        }
      } else {
        this.equipment = {

        };
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  emitCancel(event: void) {
    this.navigateToHardware();
    this.cancel.emit(event);
  }

  emitCreated(event: SensorEquipment) {
    this.navigateToHardware();
    this.created.emit(event);
  }

  emitUpdated(event: SensorEquipment) {
    this.navigateToHardware();
    this.updated.emit(event);
  }

  private navigateToHardware() {
    if (_.includes(this.router.url, '/hardware/sensor-equipments/')) {
      this.router.navigate(['/fleet/hardware/sensor-equipments']);
    }
  }

  private getEquipment(id: number) {
    this.loadingBox = true;

    this.sensorEquipmentsService.getBy(id).pipe(
      finalize(() => {
        this.loadingBox = false;
      }))
      .subscribe((equipment: SensorEquipment) => {
        this.equipment = equipment;
      }, err => this.catchError(err));
  }

  private catchError(err: HttpErrorResponse) {
    if (err.status === 403) {
      this.errorMessage = 'FORBIDDEN';
    }
  }

}
