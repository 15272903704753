import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FleetOverviewSearchFiltersService } from '@fleet/fleet-overview/fleet-overview-search-filters/fleet-overview-search-filters.service';
import * as _ from 'lodash';

@Component({
  selector: 'ifms-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent implements OnInit {

  @Input() data: any = [];
  @Output() selectedData = new EventEmitter();

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  public showList = false;
  private initialData: any = [];
  private searchValue: string = null;
  private showInput = false;


  constructor(private elementRef: ElementRef,
              private changeDetector: ChangeDetectorRef,
              private fleetOverviewSearchFiltersService: FleetOverviewSearchFiltersService) {

  }

  ngOnInit() {
    this.showInput = false;
    this.initialData = this.data.data;
  }

  public selected(id) {
    this.selectedData.emit({'key': this.data.name, 'id': id});
  }

  public unselectAll() {
    this.fleetOverviewSearchFiltersService.remove(this.data.name);
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.showList = false;
      this.showInput = false;
      this.searchValue = null;
      this.initialData = this.data.data;
    }
  }

  public toggleShowInput(value) {
    this.showInput = value;
    this.showList = true;
    this.changeDetector.detectChanges();
    this.searchInput.nativeElement.focus();

    // Alternative use
    /*setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0)*/
  }

  public filter($event) {

    this.searchValue = $event.target.value;

    const filteredData = _.filter(this.data.data, (data) => {
      return data.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1;
    });

    if (filteredData.length > 0) {
      this.initialData = filteredData;
    }
  }

}
