import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TireService {

  constructor() {
  }

  formatTimeToCritical(timeToCritical) {
    switch (true) {
      case (timeToCritical >= 480):
        return '> 8h';
      case (timeToCritical >= 420):
        return '> 7h';
      case (timeToCritical >= 360):
        return '> 6h';
      case (timeToCritical >= 300):
        return '> 5h';
      case (timeToCritical >= 240):
        return '> 4h';
      case (timeToCritical >= 180):
        return '> 3h';
      case (timeToCritical >= 120):
        return '> 2h';
      case (timeToCritical >= 90):
        return '> 90 min';
      case (timeToCritical >= 60):
        return '> 60 min';
      case (timeToCritical >= 30):
        return '> 30 min';
      case (timeToCritical < 30 && timeToCritical !== null):
        return '< 30 min';
      default:
        return '-';
    }
  }
}
