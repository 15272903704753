import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FleetOverviewSearchFiltersService } from '@fleet/fleet-overview/fleet-overview-search-filters/fleet-overview-search-filters.service';

@Component({
  selector: 'ifms-fleet-overview-search-filters',
  templateUrl: './fleet-overview-search-filters.component.html',
  styleUrls: ['./fleet-overview-search-filters.component.scss']
})
export class FleetOverviewSearchFiltersComponent implements OnInit {

  @Output() reload = new EventEmitter();

  constructor(public fleetOverviewSearchFiltersService: FleetOverviewSearchFiltersService) { }

  ngOnInit() {
    this.fleetOverviewSearchFiltersService.initData();
  }

  public selectedData(event) {
    this.fleetOverviewSearchFiltersService.setData(event);
  }

  public selectedCheckbox(event) {
    this.fleetOverviewSearchFiltersService.setCheckbox(event);
  }

  public applyFilters() {
    this.fleetOverviewSearchFiltersService.applyFilters();
    this.reload.emit();
  }

  public removeAll() {
      this.fleetOverviewSearchFiltersService.removeAll();
      this.applyFilters();
  }
}
