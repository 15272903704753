import * as _ from 'lodash';

export const shift = (value, precision, reverseShift = false) => {
  if (_.isNumber(value) === false) {
    return value;
  }

  if (reverseShift) {
    precision = -precision;
  }
  const numArray = ('' + value).split('e');
  return +(numArray[0] + 'e' + (numArray[1] ? (+numArray[1] + precision) : precision));
};

export const round = (value, precision, reverseShift = false) => {
  if (_.isNumber(value) === false) {
    return value;
  }

  return shift(Math.floor(shift(value, precision, false)), precision, true);
};

export const assert = (value, shouldValue) => {
  console.log(round(value, 1) === shouldValue, value, round(value, 1));
};

// assert(72.34, 72.3);
// assert(72.99, 72.9);
// assert(5.66, 5.6);
//
// assert(72, 72);
// assert(5, 5);
//
// assert(0, 0);
// assert(0.22, 0.2);
// assert(0.7, 0.7);
// assert(0.77, 0.7);
// assert(undefined, undefined);
// assert(null, null);
