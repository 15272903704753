import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { VehicleService } from './vehicle.service';
import { ViewportService } from '../../shared/services/viewport.service';
import { UserService } from '@user/user.service';
import { FleetUnitsService } from '@fleet/fleet-units/fleet-units.service';
import * as _ from 'lodash';
import { FleetUnit } from '@fleet/fleet-units/fleet-unit/fleet-unit.model';

@Component({
  selector: 'vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit, OnDestroy {
  private activatedRouteSubscrition: Subscription;
    public showConfirmToggleService = false;
    public selectedVehicle: FleetUnit;

  constructor (public userService: UserService,
               public vehicle: VehicleService,
               public viewport: ViewportService,
               private route: ActivatedRoute,
               private fleetUnitsService: FleetUnitsService) {
  }

  ngOnInit() {
    this.activatedRouteSubscrition = this.route.params.subscribe((params) => {
      if (params.vehicleId && params.vehicleGroupId) {
        this.vehicle.getVehicle(params.vehicleGroupId, params.vehicleId);
      }
    });

    this.vehicle.selected.subscribe((fleetUnit) => {
       this.selectedVehicle = fleetUnit;
    });
  }

  ngOnDestroy() {
    if (this.activatedRouteSubscrition) {
      this.activatedRouteSubscrition.unsubscribe();
    }

    this.vehicle.unsubscribeCurrentLocationInterval();
    this.vehicle.unsubscribeTireDetails();

    this.vehicle.clear();
  }

    get vehicleIsInService() {
      return this.fleetUnitsService.isInService(this.selectedVehicle);
    }

    public toggleServiceModal() {
        this.showConfirmToggleService = !this.showConfirmToggleService;
    }

    public toggleServiceState() {
        this.fleetUnitsService.toggleServiceState(this.selectedVehicle);
        this.toggleServiceModal();
    }
}
