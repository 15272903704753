import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translationVariables'
})
export class TranslationVariablesPipe implements PipeTransform {

    transform(value: string, variables: [{variable: string, replaceValue: string}]): unknown {
        for (const v of variables) {
            const regex = new RegExp(`\%${v.variable}\%`);
            value = value.replace(regex, v.replaceValue);
        }

        return value
    }
}
