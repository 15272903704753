import { Condition } from './models/condition.model';

import { FleetUnit, FleetVehicleUnitOverview } from '../fleet-units/fleet-unit/fleet-unit.model';
import { CommandPending, SensorEquipment } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';
import { IfmsBox } from '@hardware/ifms-boxes/ifms-box/ifms-box.model';
import { VehicleGroup } from '../fleet-depot/fleet-depot.model';
import { VehicleTemplate } from '@configurator/vehicle-configurator.model';
import { Tire } from './parts/tire/tire.model';
import { HistorySensorData } from './parts/sensor-data/sensor-data.model';

export interface UnitVehicles {
  tractorVehicleDto: VehicleTemplate;
  trailerVehicleDto: VehicleTemplate;
}

export interface UnitVehicle {
    countryCode: string;
    description: string;
    externalId: string;
    fleetVehicleId: number;
    ifmsBox: IfmsBox;
    name: string;
    numberPlate: string;
    route: string;
    sensorEquipment: SensorEquipment;
    usage: string;
    userPrivilegeFleetMaps: UserPrivilegeVehicleUnit[];
    vehicleName: string;
    vehicleType: string;
}

export interface UserPrivilegeVehicleUnit {
    active: boolean;
    userPrivilegeFleetMapId: number;
    userPrivileges: NamesPrivileges[];
}

export interface NamesPrivileges {
    personSurname: string;
    privilegeName: string;
    userId: number;
    userName: string;
    userPrivilegeId: number;
}

export interface TireDetails {
  tractorTyresDetails: Tire[];
  trailerTyresDetails: Tire[];
}

export interface GpsPosition {
  fix: boolean;
  gpsPositionId: number;
  heading: number;
  height: number;
  lastvalid: boolean;
  latitude: number;
  longitude: number;
  speed: number;
  timestamp: string;
}

export interface DatedGpsPosition {
  date: Date;
  location: GpsPosition;
}

export interface VehicleState {
  selected: FleetUnit;

  currentLocation: DatedGpsPosition;
  locationHistory: GpsPosition[];

  vehicleGroup: VehicleGroup;

  unitVehicles: UnitVehicles;

  tireDetails: TireDetails;

  sensorHistory: HistorySensorData[];

  tractorBox: IfmsBox;

  pendingCommands: CommandPending[];
}

export interface UnitCalibrationSettings {
  action?: string;
  tractor: SensorCalibrationSetting[];
  trailer: SensorCalibrationSetting[];
}

export interface SensorCalibrationSetting {
  tpmsSensorId: number;
  pressureSetpoint: number;
}

export class Vehicle {
    public static getColor(vehicle: FleetVehicleUnitOverview): string {
      return Condition.getColorBy(vehicle.unitStatus);
    }

    public static getColorKeyBy(color: string): string {
      return Condition.getColorKeyBy(color);
    }
}
