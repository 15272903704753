import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@user/user.service';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[checkPermissions]'
})
export class CheckPermissionsDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService) {
  }

  @Input()
  set checkPermissions(permissions: string[]) {
    this.updateView(permissions);
  }

  private updateView(permissions: string[]) {
    if (permissions.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.userService.hasAtLeastOneRole(permissions).pipe(
        take(1)
      ).subscribe((hasAtLeastOneRole) => {
        if (hasAtLeastOneRole) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
    }
  }
}
