import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SensorEquipment, TpmsSensor, TpmsSensorEntry } from '../sensor-equipment.model';

import { TpmsSensorService } from './tpms-sensor.service';

import { NotificationsService } from 'angular2-notifications';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-tpms-sensor',
  templateUrl: './tpms-sensor.component.html',
  styleUrls: ['./tpms-sensor.component.scss']
})
export class TpmsSensorComponent implements OnInit {

  @Input() entry: TpmsSensorEntry;
  @Input() equipment: SensorEquipment;

  @Output() initiated: EventEmitter<TpmsSensor> = new EventEmitter<TpmsSensor>();

  public loading = false;

  constructor(private notification: NotificationsService,
              private tpmsSensorService: TpmsSensorService) { }

  ngOnInit() {
  }

  initializeTpmsSensor() {
    this.loading = true;

    const body = _.assign({}, this.entry, { sensorEquipment: this.equipment });

    this.tpmsSensorService.initTpmsSensor(body).pipe(
      finalize(() => {
        this.loading = false;
      }))
      .subscribe((tpmsSensor: TpmsSensor) => {
        this.initiated.emit(tpmsSensor);
      });
  }

}
