export const internationalPrefix = [
    {'value': '1', 'name': '+1'},
    {'value': '1340', 'name': '+1 340'},
    {'value': '1670', 'name': '+1 670'},
    {'value': '1787', 'name': '+1 787'},
    {'value': '1868', 'name': '+1 868'},
    {'value': '20', 'name': '+20'},
    {'value': '212', 'name': '+212'},
    {'value': '213', 'name': '+213'},
    {'value': '216', 'name': '+216'},
    {'value': '218', 'name': '+218'},
    {'value': '220', 'name': '+220'},
    {'value': '221', 'name': '+221'},
    {'value': '222', 'name': '+222'},
    {'value': '223', 'name': '+223'},
    {'value': '224', 'name': '+224'},
    {'value': '225', 'name': '+225'},
    {'value': '226', 'name': '+226'},
    {'value': '227', 'name': '+227'},
    {'value': '228', 'name': '+228'},
    {'value': '229', 'name': '+229'},
    {'value': '230', 'name': '+230'},
    {'value': '231', 'name': '+231'},
    {'value': '232', 'name': '+232'},
    {'value': '233', 'name': '+233'},
    {'value': '234', 'name': '+234'},
    {'value': '235', 'name': '+235'},
    {'value': '236', 'name': '+236'},
    {'value': '237', 'name': '+237'},
    {'value': '238', 'name': '+238'},
    {'value': '238', 'name': '+238'},
    {'value': '239', 'name': '+239'},
    {'value': '240', 'name': '+240'},
    {'value': '241', 'name': '+241'},
    {'value': '242', 'name': '+242'},
    {'value': '242', 'name': '+242'},
    {'value': '243', 'name': '+243'},
    {'value': '244', 'name': '+244'},
    {'value': '245', 'name': '+245'},
    {'value': '246', 'name': '+246'},
    {'value': '246', 'name': '+246'},
    {'value': '247', 'name': '+247'},
    {'value': '248', 'name': '+248'},
    {'value': '249', 'name': '+249'},
    {'value': '250', 'name': '+250'},
    {'value': '251', 'name': '+251'},
    {'value': '252', 'name': '+252'},
    {'value': '253', 'name': '+253'},
    {'value': '254', 'name': '+254'},
    {'value': '255', 'name': '+255'},
    {'value': '256', 'name': '+256'},
    {'value': '257', 'name': '+257'},
    {'value': '258', 'name': '+258'},
    {'value': '260', 'name': '+260'},
    {'value': '261', 'name': '+261'},
    {'value': '262', 'name': '+262'},
    {'value': '263', 'name': '+263'},
    {'value': '264', 'name': '+264'},
    {'value': '265', 'name': '+265'},
    {'value': '266', 'name': '+266'},
    {'value': '267', 'name': '+267'},
    {'value': '268', 'name': '+268'},
    {'value': '268', 'name': '+268'},
    {'value': '269', 'name': '+269'},
    {'value': '269', 'name': '+269'},
    {'value': '27', 'name': '+27'},
    {'value': '284', 'name': '+284'},
    {'value': '284', 'name': '+284'},
    {'value': '290', 'name': '+290'},
    {'value': '291', 'name': '+291'},
    {'value': '297', 'name': '+297'},
    {'value': '298', 'name': '+298'},
    {'value': '299', 'name': '+299'},
    {'value': '30', 'name': '+30'},
    {'value': '31', 'name': '+31'},
    {'value': '32', 'name': '+32'},
    {'value': '33', 'name': '+33'},
    {'value': '34', 'name': '+34'},
    {'value': '345', 'name': '+345'},
    {'value': '350', 'name': '+350'},
    {'value': '351', 'name': '+351'},
    {'value': '352', 'name': '+352'},
    {'value': '353', 'name': '+353'},
    {'value': '354', 'name': '+354'},
    {'value': '355', 'name': '+355'},
    {'value': '356', 'name': '+356'},
    {'value': '357', 'name': '+357'},
    {'value': '358', 'name': '+358'},
    {'value': '359', 'name': '+359'},
    {'value': '36', 'name': '+36'},
    {'value': '370', 'name': '+370'},
    {'value': '371', 'name': '+371'},
    {'value': '372', 'name': '+372'},
    {'value': '373', 'name': '+373'},
    {'value': '374', 'name': '+374'},
    {'value': '375', 'name': '+375'},
    {'value': '376', 'name': '+376'},
    {'value': '378', 'name': '+378'},
    {'value': '380', 'name': '+380'},
    {'value': '381', 'name': '+381'},
    {'value': '385', 'name': '+385'},
    {'value': '386', 'name': '+386'},
    {'value': '387', 'name': '+387'},
    {'value': '389', 'name': '+389'},
    {'value': '39', 'name': '+39'},
    {'value': '39', 'name': '+39'},
    {'value': '40', 'name': '+40'},
    {'value': '41', 'name': '+41'},
    {'value': '420', 'name': '+420'},
    {'value': '421', 'name': '+421'},
    {'value': '423', 'name': '+423'},
    {'value': '43', 'name': '+43'},
    {'value': '44', 'name': '+44'},
    {'value': '45', 'name': '+45'},
    {'value': '46', 'name': '+46'},
    {'value': '47', 'name': '+47'},
    {'value': '473', 'name': '+473'},
    {'value': '473', 'name': '+473'},
    {'value': '48', 'name': '+48'},
    {'value': '49', 'name': '+49'},
    {'value': '500', 'name': '+500'},
    {'value': '501', 'name': '+501'},
    {'value': '502', 'name': '+502'},
    {'value': '503', 'name': '+503'},
    {'value': '504', 'name': '+504'},
    {'value': '505', 'name': '+505'},
    {'value': '506', 'name': '+506'},
    {'value': '507', 'name': '+507'},
    {'value': '508', 'name': '+508'},
    {'value': '509', 'name': '+509'},
    {'value': '51', 'name': '+51'},
    {'value': '52', 'name': '+52'},
    {'value': '53', 'name': '+53'},
    {'value': '54', 'name': '+54'},
    {'value': '55', 'name': '+55'},
    {'value': '56', 'name': '+56'},
    {'value': '57', 'name': '+57'},
    {'value': '58', 'name': '+58'},
    {'value': '591', 'name': '+591'},
    {'value': '592', 'name': '+592'},
    {'value': '593', 'name': '+593'},
    {'value': '594', 'name': '+594'},
    {'value': '595', 'name': '+595'},
    {'value': '596', 'name': '+596'},
    {'value': '596', 'name': '+596'},
    {'value': '597', 'name': '+597'},
    {'value': '598', 'name': '+598'},
    {'value': '599', 'name': '+599'},
    {'value': '60', 'name': '+60'},
    {'value': '61', 'name': '+61'},
    {'value': '62', 'name': '+62'},
    {'value': '63', 'name': '+63'},
    {'value': '64', 'name': '+64'},
    {'value': '65', 'name': '+65'},
    {'value': '66', 'name': '+66'},
    {'value': '670', 'name': '+670'},
    {'value': '671', 'name': '+671'},
    {'value': '672', 'name': '+672'},
    {'value': '673', 'name': '+673'},
    {'value': '674', 'name': '+674'},
    {'value': '675', 'name': '+675'},
    {'value': '676', 'name': '+676'},
    {'value': '677', 'name': '+677'},
    {'value': '678', 'name': '+678'},
    {'value': '679', 'name': '+679'},
    {'value': '680', 'name': '+680'},
    {'value': '681', 'name': '+681'},
    {'value': '682', 'name': '+682'},
    {'value': '683', 'name': '+683'},
    {'value': '684', 'name': '+684'},
    {'value': '685', 'name': '+685'},
    {'value': '686', 'name': '+686'},
    {'value': '687', 'name': '+687'},
    {'value': '688', 'name': '+688'},
    {'value': '689', 'name': '+689'},
    {'value': '690', 'name': '+690'},
    {'value': '691', 'name': '+691'},
    {'value': '692', 'name': '+692'},
    {'value': '7', 'name': '+7'},
    {'value': '767', 'name': '+767'},
    {'value': '809', 'name': '+809'},
    {'value': '81', 'name': '+81'},
    {'value': '82', 'name': '+82'},
    {'value': '84', 'name': '+84'},
    {'value': '850', 'name': '+850'},
    {'value': '850', 'name': '+850'},
    {'value': '852', 'name': '+852'},
    {'value': '853', 'name': '+853'},
    {'value': '855', 'name': '+855'},
    {'value': '856', 'name': '+856'},
    {'value': '86', 'name': '+86'},
    {'value': '869', 'name': '+869'},
    {'value': '869', 'name': '+869'},
    {'value': '876', 'name': '+876'},
    {'value': '880', 'name': '+880'},
    {'value': '886', 'name': '+886'},
    {'value': '886', 'name': '+886'},
    {'value': '90', 'name': '+90'},
    {'value': '91', 'name': '+91'},
    {'value': '92', 'name': '+92'},
    {'value': '93', 'name': '+93'},
    {'value': '94', 'name': '+94'},
    {'value': '95', 'name': '+95'},
    {'value': '960', 'name': '+960'},
    {'value': '961', 'name': '+961'},
    {'value': '962', 'name': '+962'},
    {'value': '963', 'name': '+963'},
    {'value': '964', 'name': '+964'},
    {'value': '965', 'name': '+965'},
    {'value': '966', 'name': '+966'},
    {'value': '967', 'name': '+967'},
    {'value': '968', 'name': '+968'},
    {'value': '971', 'name': '+971'},
    {'value': '972', 'name': '+972'},
    {'value': '973', 'name': '+973'},
    {'value': '974', 'name': '+974'},
    {'value': '975', 'name': '+975'},
    {'value': '976', 'name': '+976'},
    {'value': '977', 'name': '+977'},
    {'value': '98', 'name': '+98'},
    {'value': '993', 'name': '+993'},
    {'value': '994', 'name': '+994'},
    {'value': '995', 'name': '+995'},
    {'value': '996', 'name': '+996'},
];
