import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Mandator } from '../mandator.model';

interface MandatorNode {
  parent: Mandator;
  node: Mandator;
}

@Component({
  selector: 'ifms-mandator-form-input',
  templateUrl: './mandator-form-input.component.html',
  styleUrls: ['./mandator-form-input.component.scss']
})
export class MandatorFormInputComponent implements OnInit {
  @Input() parent: Mandator;
  @Input() mandator: Mandator = <Mandator>{};
  @Input() label = 'FLEET.MANDATOR.NAME';
  @Input() selectNote: string;
  @Input() dialog = false;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() editMode = true;

  @Output() clickMandator: EventEmitter<Mandator> = new EventEmitter<Mandator>();

  public clickNode: Function;
  public showDialog = false;

  constructor() { }

  ngOnInit() {
    const that = this;

    this.clickNode = ({ node }: MandatorNode) => {
      that.showDialog = false;
      that.mandator = node;
      that.clickMandator.emit(node);
    };
  }

  clear() {
    this.mandator = null;
    this.clickMandator.emit(this.mandator);
  }

  openDialog() {
    this.mandator = <Mandator>{};
    this.showDialog = true;
  }

}
