import { Component, OnInit } from '@angular/core';
import { ISupportContactRequest } from './interfaces/support-contact-request';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
    public selectedSupportOption: string;
    public contactRequest: ISupportContactRequest = {
        name: '',
        phoneNumber: '',
        message: '',
        orderNumber: '',
        callback: false
    };

    constructor() {
    }

    ngOnInit() {
    }

    public onSelectSupportOption(option: string) {
        this.selectedSupportOption = option;
    }

    public submitForm(callback: boolean = false) {
        if (callback) {
            this.contactRequest.callback = true;
        }

        // TODO: Send request to server
    }

}
