import { Observable, of, timer as observableTimer } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { FleetVehicle } from '@depot/fleet-depot.model';
import { CanBusDataFilter, FMS_Data, FMS_Entry, FMS_FleetVehicleProperty } from './vehicle-monitoring.model';

import { FLEET_UNIT_REFRESH_MILLISECONDS } from '@unit-state/vehicle.helper';
import { prepareEntries, prepareTimeseriesHistoryResponse } from './vehicle-monitoring.helper';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import * as _ from 'lodash';

declare const moment: any;

@Injectable()
export class VehicleMonitoringService {

  constructor(private http: HttpClient) { }

  createFMSDataRefresher(fleetVehicle: FleetVehicle): Observable<any> {
    return observableTimer(0, FLEET_UNIT_REFRESH_MILLISECONDS).pipe(
      mergeMap(() => {
        return this.getFMSData(fleetVehicle);
      }),
      mergeMap((data) => {
        if (!_.get(data, 'fmsData')) {
          return this.getObdiiData(fleetVehicle);
        }
        return of(data);
      }), );
  }

  createActiveCanBusDataRefresher(fleetVehicle: FleetVehicle): Observable<any> {
    return observableTimer(0, FLEET_UNIT_REFRESH_MILLISECONDS).pipe(
      mergeMap(() => {
        return this.getActiveCanBusData(fleetVehicle);
      }))
  }

  getFMSData(fleetVehicle: FleetVehicle): Observable<any> {
    return this.http.get(`${environment.apiUrl}/can-bus/fms/${fleetVehicle.fleetVehicleId}`).pipe(
      map((fmsData: FMS_Data) => {
        return {
          fmsData,
          entries: prepareEntries(fmsData),
          endpoint: 'fms',
        };
      }));
  }

  getObdiiData(fleetVehicle: FleetVehicle): Observable<any> {
    return this.http.get(`${environment.apiUrl}/can-bus/obdii/${fleetVehicle.fleetVehicleId}`).pipe(
      map((fmsData: FMS_Data) => {
        return {
          fmsData,
          entries: prepareEntries(fmsData),
          endpoint: 'obdii',
        };
      }));
  }

  getActiveCanBusData(fleetVehicle: FleetVehicle): Observable<any> {
    return this.http.get(`${environment.apiUrl}/can-bus/data/${fleetVehicle.fleetVehicleId}`).pipe(
      map((fmsData: FMS_Data) => {
        return {
          fmsData,
          entries: prepareEntries(fmsData),
        };
      }));
  }

  getHistoryBy(endpoint: string, filter: CanBusDataFilter, entry: FMS_Entry): Observable<any> {
    // possible endpoints: 'can-bus-data', 'fms', 'obdii'
    // console.log(filter);
    // console.log(entry);

    const body = {
      property: _.get(entry, 'artifact.key'),

      tripId: _.get(filter, 'tripId'),
      before: _.get(filter, 'before'),
      after: _.get(filter, 'after'),
    };

    return this.http.post(`${environment.apiUrl}/can-bus/${endpoint}/${filter.fleetVehicleId}`, body).pipe(
      map(prepareTimeseriesHistoryResponse))
  }


  getFleetVehicleProperty(fleetVehicle: FleetVehicle): Observable<FMS_FleetVehicleProperty> {
    const url = `${environment.apiUrl}/fleetvehicles/${fleetVehicle.fleetVehicleId}/vehicle-property`;
    return <Observable<FMS_FleetVehicleProperty>>this.http.get(url);
  }

  saveFleetVehicleProperty(property: FMS_FleetVehicleProperty): Observable<FMS_FleetVehicleProperty> {
    const url = `${environment.apiUrl}/fleetvehicles/${property.fleetVehicleId}/vehicle-property`;
    return <Observable<FMS_FleetVehicleProperty>>this.http.post(url, property);
  }

  getWheelLoss(fleetVehicle: FleetVehicle): Observable<boolean> {
    const url = `${environment.apiUrl}/fleetvehicles/wheelLoss/${fleetVehicle.fleetVehicleId}`;
    return <Observable<boolean>>this.http.get(url);
  }
}
