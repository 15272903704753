import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { LocationHistory } from './location-history.openlayer';

import { VehicleService } from '@unit-state/vehicle.service';
import { HttpClient } from '@angular/common/http';
import { FleetUnit } from '@fleet/fleet-units/fleet-unit/fleet-unit.model';

@Component({
  selector: 'vehicle-location-history',
  templateUrl: './vehicle-location-history.component.html',
  styleUrls: ['./vehicle-location-history.component.scss']
})
export class VehicleLocationHistoryComponent implements OnInit, OnDestroy {

  @ViewChild('mapTarget', { static: true }) mapTarget: ElementRef;

  public currentDate: any;

  public history: [{data: LocationHistory[]}];

  private historyLocationsSub: Subscription;
  private currentLocationSub: Subscription;

  private markerStyleSub: Subscription;

    public markers: any[];
    public mapAccess: any;

  constructor(public vehicle: VehicleService, private http: HttpClient) { }

  ngOnInit() {

      this.currentLocationSub = this.vehicle
      .currentLocation.pipe(
      filter((location: any) => {
        return _.isEmpty(location) === false;
      }),
      distinctUntilChanged((payload: any[], candidate: any[]) => {
        return _.get(payload, 'location.gpsData.latitude') === _.get(candidate, 'location.gpsData.latitude') &&
               _.get(payload, 'location.gpsData.longitude') === _.get(candidate, 'location.gpsData.longitude');
      }), )
      .subscribe((currentLocation: any) => {
        this.currentDate = _.get(currentLocation, 'date');

          this.markerStyleSub = this.vehicle.selected
              .pipe(
                  filter(unit => _.has(unit, 'fleetUnitId')),
                  distinctUntilChanged((unit, compared) => {
                      return _.get(unit, 'fleetUnitId') === _.get(compared, 'fleetUnitId');
                  }),
              ).subscribe((fleetUnit: FleetUnit) => {
                  if (location) {
                      this.markers = [];
                      if (currentLocation.location && currentLocation.location.gpsData.length === 1) {
                          this.markers.push(
                              {
                                  long: currentLocation.location.gpsData[0].longitude,
                                  lat: currentLocation.location.gpsData[0].latitude,
                                  fleetUnit: fleetUnit,
                                  clickData: fleetUnit
                              }
                          );
                      }
                  }
              });

      });

      this.historyLocationsSub = this.vehicle
          .locationHistory.pipe(
              filter((locationHistory: any) => {
                  return _.isEmpty(locationHistory) === false;
              }),
              distinctUntilChanged((locationHistory: any, candidates: any[]) => {
                  return locationHistory.gpsData.length === candidates.length;
              }),
              map((locationHistory) => {
                  this.history = [{data: locationHistory.gpsData}];
                  this.mapAccess = { image: locationHistory.image, status: locationHistory.status };
              })
          )
          .subscribe();

  }

  ngOnDestroy () {

    if (this.currentLocationSub) {
      this.currentLocationSub.unsubscribe();
    }

    if (this.historyLocationsSub) {
      this.historyLocationsSub.unsubscribe();
    }

    if (this.markerStyleSub) {
      this.markerStyleSub.unsubscribe();
    }
  }
}
