import { FleetState } from './fleet.model';

const defaultState: FleetState = {
  vehicleGroupId: null,

  vehicle: null,
  recentDrives: null,
  panelValues: null,

  alarmingFleetUnits: null,
  notAlarmingFleetUnits: null,

  querySearchNeedle: null,
};

export class FleetActions {
  public static SET_DEFAULT = 'FLEET-SET_DEFAULT';

  public static SELECT_VEHICLE = 'FLEET-SELECT_VEHICLE';
  public static SET_PANEL_VALUES = 'FLEET-SET_PANEL_VALUES';

  public static SET_ALARMING_FLEET_UNITS = 'FLEET-SET_ALARMING_FLEET_UNITS';
  public static SET_NOT_ALARMING_FLEET_UNITS = 'FLEET-SET_NOT_ALARMING_FLEET_UNITS';

  public static RESET_LOADED_FLEET_UNITS = 'FLEET-RESET_LOADED_FLEET_UNITS';

  public static SET_VEHICLE_GROUP_ID = 'FLEET-SET_VEHICLE_GROUP_ID';

  public static SET_QUERY_SEARCH_NEEDLE = 'FLEET-SET_QUERY_SEARCH_NEEDLE';
}

export function fleetReducer (state: FleetState = defaultState, { type, payload }) {
  switch (type) {
    case <string>FleetActions.SET_DEFAULT:
      return Object.assign({}, defaultState, { vehicleGroupId: state.vehicleGroupId });

    case <string>FleetActions.SELECT_VEHICLE:
      return Object.assign({}, state, { vehicle: payload });

    case <string>FleetActions.RESET_LOADED_FLEET_UNITS:
      return Object.assign({}, state, {
        recentDrives: [],
        alarmingFleetUnits: [],
        notAlarmingFleetUnits: [],
      });

    case <string>FleetActions.SET_ALARMING_FLEET_UNITS:
      return Object.assign({}, state, { alarmingFleetUnits: payload });

    case <string>FleetActions.SET_NOT_ALARMING_FLEET_UNITS:
      return Object.assign({}, state, { notAlarmingFleetUnits: payload });

    case <string>FleetActions.SET_PANEL_VALUES:
      return Object.assign({}, state, { panelValues: payload });

    case <string>FleetActions.SET_VEHICLE_GROUP_ID:
      return Object.assign({}, state, { vehicleGroupId: payload });

    case <string>FleetActions.SET_QUERY_SEARCH_NEEDLE:
      return Object.assign({}, state, { querySearchNeedle: payload });

    default:
      return state;
  }
}
