import { Component, Input } from '@angular/core';

@Component({
  selector: 'ifms-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent  {
  @Input() text: string;

  constructor() { }

}
