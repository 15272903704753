import { catchError, filter, finalize, map, mergeMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { AuthService } from './auth.service';
import { HistoryActivate } from '@shared/services/history.active';

import * as _ from 'lodash';

@Injectable()
export class LoginActivate implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService,
              private router: Router,
              private history: HistoryActivate) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.history.addToHistoryBy(state);

    return this.authService.isLoggedInObservable.pipe(
      filter(_.isBoolean),
      take(1),
      mergeMap((isLoggedIn: boolean) => {
        if (isLoggedIn === false) {
          return this.authService.getCurrentUser().pipe(
            map(() => {
              return this.authService.isLoggedIn;
            }),
            catchError((error) => {
              return of(this.authService.isLoggedIn);
            }),
            finalize(() => {
              return this.authService.isLoggedIn;
            }), );
        } else {
          return of(isLoggedIn);
        }
      }),
      map((isLoggedIn: boolean) => {
        if (isLoggedIn === false) {
          this.router.navigate(['login']);
        }

        return isLoggedIn;
      }), );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  getHistory(): string[] {
    return this.history.getHistory();
  }

  getPreviousUrl(): string {
    return this.history.getPreviousUrl();
  }
}
