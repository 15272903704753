export const menuData = [
  {label: 'FLEET.NAVIGATION.RECENT_DRIVES', link: '/fleet/overview', icons: ['fa fa-road'], acls: []},
  {label: 'FLEET.NAVIGATION.UNITS', link: '/fleet/units/', icons: ['fa fa-truck', 'fa fa-plus', 'fa fa-truck'], acls: []},
  {label: 'FLEET.NAVIGATION.DEPOT', link: '/fleet/depot/view/', icons: ['fa fa-truck'], acls: []},
  {label: 'FLEET.NAVIGATION.EDIT_DEPOTS', link: '/fleet/depot/edit/', icons: ['fa fa-warehouse'], acls: []},
  {
    label: 'FLEET.NAVIGATION.VEHICLES', link: '', icons: ['fa fa-cogs'], acls: [],
    subMenuData: [
      {
        label: 'FLEET.NAVIGATION.VEHICLE_TEMPLATES',
        link: '/fleet/configurator/vehicle-templates',
        icons: ['fa fa-truck'],
        acls: []
      },
      {
        label: 'FLEET.NAVIGATION.VEHICLE_AXLE_CONFIGS',
        link: '/fleet/configurator/axle-configs',
        icons: ['fa fa-sitemap'],
        acls: []
      },
      {label: 'FLEET.NAVIGATION.VEHICLE_AXLES', link: '/fleet/configurator/axles', icons: ['fa fa-list'], acls: []},
      {label: 'FLEET.HARDWARE.TITLE', link: '/fleet/hardware', icons: ['far fa-hdd'], acls: ['ROLE_FM_ADMIN']},
    ]
  },
  {
    label: 'FLEET.NAVIGATION.MY_IFMS', link: '', icons: ['fa fa-user-circle'], acls: [],
    subMenuData: [
      {label: 'FLEET.NAVIGATION.SETTINGS', link: '/fleet/settings', icons: ['fa fa-wrench'], acls: []},
      {label: 'FLEET.NAVIGATION.MANDATORS', link: '/fleet/mandators', icons: ['fa fa-users'], acls: ['ROLE_FM_ADMIN']},
      {label: 'FLEET.NAVIGATION.USERS', link: '/fleet/users', icons: ['fa fa-user-plus'], acls: ['ROLE_FM_ADMIN']},
      {
        label: 'FLEET.NAVIGATION.DEACTIVATED_FLEET_VEHICLES',
        link: '/fleet/depot/view/deactivated',
        icons: ['fa fa-upload', 'fa fa-truck'],
        acls: ['ROLE_SUPER_ADMIN']
      },
      {
        label: 'FLEET.NAVIGATION.ADMIN_N4_TRANSLATE',
        link: '/fleet/n4translate',
        icons: ['fa fa-language'],
        acls: ['ROLE_SUPER_ADMIN']
      },
      {label: 'FLEET.NAVIGATION.LOGOUT', link: '/logout', icons: ['fa fa-power-off'], acls: []},
    ]
  },
];

