import { finalize } from 'rxjs/operators';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { FleetVehicle } from '@depot/fleet-depot.model';

import { FleetDepotService } from '@depot/fleet-depot.service';
import { NotificationsService } from 'angular2-notifications';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-fleet-vehicle-detailed-status',
  templateUrl: './fleet-vehicle-detailed-status.component.html',
  styleUrls: ['./fleet-vehicle-detailed-status.component.scss']
})
export class FleetVehicleDetailedStatusComponent implements OnInit, OnChanges {

  @Input() fleetVehicle: FleetVehicle;

  public details: any;

  public loading = false;
  public error = false;

  constructor(private depotService: FleetDepotService,
              private notification: NotificationsService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fleetVehicle) {
      this.getDetails();
    }
  }

  getDetails() {
    this.details = null;
    this.error = false;

    const fleetVehicleId = _.get(this.fleetVehicle, 'fleetVehicleId');

    if (fleetVehicleId) {
      this.loading = true;

      this.depotService.getFleetVehicleDetailedStatusBy(fleetVehicleId).pipe(
        finalize(() => {
          this.loading = false;
        }))
        .subscribe((details: any) => {
          this.details = details;
          // console.log(this.details);
        }, (err) => {
          this.error = true;
          // console.log('getFleetVehicleDetailedStatusBy', err);
          this.notification.remove();
        });
    } else {
      this.details = null;
    }
  }

}
