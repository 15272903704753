import { Pipe, PipeTransform } from '@angular/core';

import { isArray, isEmpty } from 'lodash';

@Pipe({
  name: 'asArray'
})
export class AsArrayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (isArray(value)) {
      return value;
    }

    return isEmpty(value) ? [] : [value];
  }

}
