import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { UserService } from '@user/user.service';

@Component({
  selector: 'ifms-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.scss']
})
export class SensorComponent implements OnInit, OnDestroy {
  @Input() sensor: any;

  public pressureUnit: string;
  private pressureUnitSub: Subscription;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.pressureUnitSub = this.userService.pressureUnitSign
      .subscribe((pressureUnit: string) => {
        this.pressureUnit = pressureUnit;
      })
  }

  ngOnDestroy() {
    if (this.pressureUnitSub) {
      this.pressureUnitSub.unsubscribe();
    }
  }

}
