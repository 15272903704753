import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FaviconsService } from '@shared/services/favicons.service';
import { themes } from '@shared/services/host-config/themes';
import { ITheme } from '@shared/services/host-config/theme.interface';
import * as _ from 'lodash';
import { get, includes } from 'lodash';

@Injectable()
export class HostConfigService {

  public title: string;
  public branding: string;
  public brandText: string;
  public logo: string;
  private theme: string;
  public favIcon: string;
  public supportByTyreWatchLogo: string;
  public showSupportedByTyrewatchLogo: boolean;

  private selectedTheme: ITheme;
  private localStorageThemeKey: string;

  private static isTestTyrewatch(): boolean {
    return includes(HostConfigService.getHost(), 'test-tyrewatch');
  }

  private static isTyrewatch(): boolean {
    return includes(HostConfigService.getHost(), 'tyrewatch.ifmscloud');
  }

  private static isMichelinSolutions(): boolean {
    return includes(HostConfigService.getHost(), 'michelin.ifmscloud');
  }

  private static isGiti(): boolean {
    return includes(HostConfigService.getHost(), 'giti.ifmscloud');
  }

  private static isHankook(): boolean {
    return includes(HostConfigService.getHost(), 'hankook.ifmscloud');
  }

  private static isVaculug(): boolean {
      return includes(HostConfigService.getHost(), 'vaculug.ifmscloud');
  }

  private static isVaculugT(): boolean {
      return includes(HostConfigService.getHost(), 'vaculugt.ifmscloud');
  }

  private static isValculug(): boolean {
    return includes(HostConfigService.getHost(), 'valculug.ifmscloud');
  }

  private static isSentyre(): boolean {
    return includes(HostConfigService.getHost(), 'sentyre');
  }

  private static isProfileNL(): boolean {
    return includes(HostConfigService.getHost(), 'profile.ifmscloud.com');
  }

  private static isTyrehero(): boolean {
    return includes(HostConfigService.getHost(), 'tyrehero.ifmscloud.com');
  }

  private static isPrometeon(): boolean {
    return includes(HostConfigService.getHost(), 'prometeon.ifmscloud.com');
  }

  private static addClassToBody(className) {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add(className);
  }

  private static removeAllClassFromBody() {
      const body = document.getElementsByTagName('body')[0];
      body.removeAttribute('class');
  }

  public static getHost() {
      return get(window, 'location.hostname');
  }

  constructor(
    private router: Router,
    private titleService: Title,
    private faviconsService: FaviconsService) {
      this.localStorageThemeKey = 'theme_overwrite1328478129864329054398712';
      this.supportByTyreWatchLogo = 'assets/images/tyre-watch-supported-by.png';
  }

  public initConfig() {
    const themeOverWrite = localStorage.getItem(this.localStorageThemeKey);

    if (themeOverWrite) {
      this.setTheme(themeOverWrite);
      return;
    }

    if (HostConfigService.isTyrewatch()) {
        this.setTheme('tyrewatch');
        return;
    }
    if (HostConfigService.isTestTyrewatch()) {
        this.setTheme('tyrewatch');
        return;
    }
    if (HostConfigService.isMichelinSolutions()) {
        this.setTheme('michelin');
        return;
    }
    if (HostConfigService.isGiti()) {
        this.setTheme('giti');
        return;
    }
    if (HostConfigService.isHankook()) {
        this.setTheme('hankook');
        return;
    }
    if (HostConfigService.isVaculug()) {
        this.setTheme('vaculug');
        return;
    }
    if (HostConfigService.isVaculugT()) {
        this.setTheme('vaculugt');
        return;
    }
    if (HostConfigService.isValculug()) {
        this.setTheme('valculug');
        return;
    }
    if (HostConfigService.isSentyre()) {
        this.setTheme('sentyre');
        return;
    }
    if (HostConfigService.isProfileNL()) {
        this.setTheme('profile-nl');
        return;
    }
    if (HostConfigService.isTyrehero()) {
        this.setTheme('tyrehero');
        return;
    }
      if (HostConfigService.isPrometeon()) {
          this.setTheme('prometeon');
          return;
      }

    this.setTheme('default');
  }

  public getSelectedTheme() {
      return this.selectedTheme;
  }

  private setTheme(name: string) {
      const theme = _.filter(themes, {name: name});
      this.setThemeValues(theme);
  }

  private setThemeValues(theme: ITheme[]) {
      if (theme.length === 1) {
          this.selectedTheme = theme[0];

          this.title = this.selectedTheme.title;
          this.branding = this.selectedTheme.branding;
          this.brandText = this.selectedTheme.brandText;
          this.logo = this.selectedTheme.logo;
          this.favIcon = this.selectedTheme.favIcon;
          this.theme = this.selectedTheme.theme;
          this.showSupportedByTyrewatchLogo = this.selectedTheme.showSupportedByTyrewatchLogo;
      }

      if (this.theme) {
          HostConfigService.addClassToBody(this.theme);
      }
      this.titleService.setTitle(this.title);
      this.faviconsService.setFavicon(this.favIcon);
  }

  public switchTheme(theme: ITheme) {
      HostConfigService.removeAllClassFromBody();
      this.setThemeValues([theme]);
      if (theme.name === 'default') {
          localStorage.removeItem(this.localStorageThemeKey);
      } else {
          localStorage.setItem(this.localStorageThemeKey, theme.name);
      }
  }
}
