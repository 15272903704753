import { catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';

import { AuthService } from '../../auth/services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class CustomHttp implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = req.headers.append('Accept', 'application/json;charset=UTF-8')
      .set('X-Requested-With', 'XMLHttpRequest');

    if (req.url.indexOf('api/login') > -1) {
      headers.set('Content-Type', 'application/json;charset=UTF-8');
    }

    const authReq = req.clone({
      withCredentials: true,
      headers
    });

    return next.handle(authReq).pipe(
      catchError((err, caught) => {
        if (err.status === 401 || err.status === 0) {
          const authService = this.injector.get(AuthService);
          authService.setLoggedInState(false);

          const router = this.injector.get(Router);
          router.navigate(['/login']);
        } else {
          const notification = this.injector.get(NotificationsService);
          notification.error('Error', 'Unknown Error');
        }

        return throwError(err);
      })) as any;
  }

}
