import { FleetVehicle, FleetVehicleTree, VehicleGroup } from './fleet-depot.model';

import * as _ from 'lodash';

export const findVehicleGroup = (groups: VehicleGroup[], groupId: number): VehicleGroup => {
  if (!groups) { return null; }

  for (let i = 0; i < groups.length; i += 1) {
    const group = groups[i];

    if (group.vehicleGroupId === groupId) {
      return group;
    } else {
      const candidate = findVehicleGroup(group.childVehicleGroups, groupId);
      if (candidate) { return candidate; }
    }
  }

  return null;
};

export const getAllGroupIds = (group: VehicleGroup): number[] => {
  let ids = [group.vehicleGroupId];

  for (let i = 0; i < group.childVehicleGroups.length; i += 1) {
    const childGroup = group.childVehicleGroups[i];
    ids = ids.concat(getAllGroupIds(childGroup));
  }

  return ids;
};

export const findVehicleGroupParent = (groups: VehicleGroup[], groupId: number, parent: VehicleGroup = null): VehicleGroup => {
  if (!groups) { return null; }
  for (let i = 0; i < groups.length; i += 1) {
    const group = groups[i];

    if (group.vehicleGroupId === groupId) {
      return parent;
    } else {
      const candidate = findVehicleGroupParent(group.childVehicleGroups, groupId, group);
      if (candidate) { return candidate; }
    }
  }

  return null;
};

export const getAllGroupsAsList = (group: VehicleGroup): VehicleGroup[] => {
  let groups = [group];

  if (group.childVehicleGroups) {
    for (let i = 0; i < group.childVehicleGroups.length; i += 1) {
      const childGroup = group.childVehicleGroups[i];
      groups = groups.concat(getAllGroupsAsList(childGroup));
    }
  }

  return groups;
};

export const flattenGroups = (groups: VehicleGroup[]): VehicleGroup[] => {
  return _
    .chain(groups)
    .map(getAllGroupsAsList)
    .flatten()
    .value();
};

export const mapGroups = (groups: VehicleGroup[]): VehicleGroup[] => {
  const list = flattenGroups(groups);
  return _.keyBy(list, 'vehicleGroupId');
};

const sortFleetVehicles = (fleetVehicles: FleetVehicle[]): FleetVehicle[] => {
  return _.sortBy(fleetVehicles, (fleetVehicle) => {
    return _.toLower(fleetVehicle.numberPlate);
  });
};

const treefyFleetVehiclesByGroups = (groups: VehicleGroup[], fleetVehiclesByGroupId: { [groupId: number]: FleetVehicle[] }) => {
  return _.map(groups, (group: VehicleGroup) => {
    const groupId = _.get(group, 'vehicleGroupId');

    const node: FleetVehicleTree = {
      group: null,
      fleetVehicles: [],
    };

    if (groupId) {
      node.group = group;
      node.fleetVehicles = sortFleetVehicles(fleetVehiclesByGroupId[groupId]);

      if (_.has(group, 'childVehicleGroups') && group.childVehicleGroups.length > 0) {
        node.children = treefyFleetVehiclesByGroups(group.childVehicleGroups, fleetVehiclesByGroupId);
      }

      return node;
    }
  });
};

export const treefyFleetVehicles = (group: VehicleGroup, fleetVehicles: FleetVehicle[]): FleetVehicleTree[] => {
  const fleetVehiclesByGroupId = _.groupBy(fleetVehicles, 'vehicleGroupId');
  return treefyFleetVehiclesByGroups([group], fleetVehiclesByGroupId);
};

export const flattenFleetVehiclesTree = (node: FleetVehicleTree, list?: FleetVehicle[][]): FleetVehicle[][] => {
  list = list || [];
  list.push(node.fleetVehicles);

  const children = _
    .chain(node.children)
    .map(child => flattenFleetVehiclesTree(child))
    .flatten()
    .value();

  return _
    .chain(list)
    .concat(children)
    .filter(units => units.length > 0)
    .value();
};
