import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs';
import { UnitVehicle, Vehicle } from '@unit-state/vehicle.model';
import { FleetUnit, FleetVehicleUnitOverview } from '../../fleet-units/fleet-unit/fleet-unit.model';
import { getSrcPath } from '@configurator/vehicle-types/vehicle-types.helper';

import * as _ from 'lodash';
import { Condition } from '@unit-state/models/condition.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@user/user.service';

interface CssStyle {
  color?: string;
  background?: string;
  'border-color'?: string;
}

@Component({
  selector: 'ifms-recent-drive-unit',
  templateUrl: './recent-drive-unit.component.html',
  styleUrls: ['./recent-drive-unit.component.scss']
})
export class RecentDriveUnitComponent implements OnInit, OnChanges {
  @Input() fleetUnit: FleetVehicleUnitOverview;
  @Input() showMostImportantNotification: boolean;
  @Input() notificationPopup: boolean;
  @Output() selectedFleetUnit: EventEmitter<FleetUnit> = new EventEmitter();

  public fleetVehicle: UnitVehicle;
  public truncate = 28;
  public src: string;
  public style: CssStyle = {};
  public status: string;
  public backgroundColor: CssStyle = {};
  public borderColor: CssStyle = {};
  public color: string;
  public colNumber: number;

  public pressureUnit: string;
  private pressureUnitSub: Subscription;

  constructor(private modalService: NgbModal, private userService: UserService) {
  }

  ngOnInit() {
    if (this.notificationPopup === undefined) {
      this.notificationPopup = true;
    }
    this.pressureUnitSub = this.userService.pressureUnitSign
      .subscribe((pressureUnit: string) => {
        this.pressureUnit = pressureUnit;
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refresh();
  }

  private refresh() {
    this.fleetUnit.notificationList = _.orderBy(this.fleetUnit.notificationList, ['axlePosition', 'position'], ['asc', 'asc']);
    this.fleetVehicle = this.fleetUnit.tractor ? this.fleetUnit.tractor : this.fleetUnit.trailer;

    const unitStatus = this.fleetUnit.unitStatus || 'NORMAL';
    const alert = (unitStatus !== 'NORMAL') ? _.toLower(unitStatus) : undefined;

    let vehicleType: string;

    if (_.get(this.fleetUnit, 'tractor') !== null) {
      if (_.get(this.fleetUnit, 'trailer') !== null) {
        vehicleType = 'TRUCK_TRAILER';
      } else {
        vehicleType = _.get(this.fleetUnit, 'tractor.type');
      }
    } else {
      vehicleType = _.get(this.fleetUnit, 'trailer.type');
    }

    const fileType = (navigator.appVersion.indexOf('MSIE 10') !== -1) ? 'png' : 'svg';

    this.src = getSrcPath(vehicleType, fileType);
    this.style = this.getDriveStatusStyle(this.fleetUnit);
    this.status = Vehicle.getColorKeyBy(_.get(this.style, 'color'));
    this.backgroundColor = {'background': Vehicle.getColor(this.fleetUnit)};
    this.borderColor = {'border-color': Vehicle.getColor(this.fleetUnit)};
    this.color = Vehicle.getColor(this.fleetUnit);

    this.colNumber = this.fleetUnit.notificationList.length <= 6 ? 4 : 3;

  }

  private getDriveStatusStyle(fleetUnit: FleetVehicleUnitOverview): CssStyle {
    return {
      'color': Vehicle.getColor(fleetUnit)
    };
  }

  public getAlarmColor(alarm) {
    return {'color': Condition.getColorBy(alarm)}
  }

  showNotificationDetails(content) {
    this.modalService.dismissAll();
    this.modalService.open(content, {centered: true});
  }

  goToDetailsPage(fleetUnit) {
    this.selectedFleetUnit.emit(fleetUnit)
  }

  ngOnDestroy() {
    if (this.pressureUnitSub) {
      this.pressureUnitSub.unsubscribe();
    }
  }
}
