import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { FMS_Entry } from '@unit-state/vehicle-monitoring/vehicle-monitoring.model';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ifms-monitoring-timeseries-chart',
  templateUrl: './monitoring-timeseries-chart.component.html',
  styleUrls: ['./monitoring-timeseries-chart.component.scss']
})
export class MonitoringTimeseriesChartComponent implements OnInit, OnChanges {

  @Input() entry: FMS_Entry;
  @Input() history: any;

  public unit = 'day';
  public timeFormat = 'MM/DD/YYYY HH:mm';

  public options: any = {
    animation: {
      duration: 0,
    },
    scales: {
      xAxes: {
        type: 'time',
        time: {
          parser: this.timeFormat,
          tooltipFormat: 'll HH:mm'
        },
        scaleLabel: {
          display: true,
          labelString: 'Date'
        }
      }
    },
  };

  private dataCore = {
    // labels: scope.labels,
    datasets: [{
      label: '- none -',
      fill: false,
      borderColor: '#4bc0c0',
    }]
  };

  public data;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (_.has(changes, 'history')) {
      this.data = _.cloneDeep(this.dataCore);
      _.set(this.data, 'datasets.0.data', this.history);

      const label = this.translate.instant(`FLEET.DEPOT.VEHICLE.FMS.PROPERTIES.${_.get(this.entry, 'artifact.id')}.LABEL`);
      _.set(this.data, 'datasets.0.label', label);
    }
  }

  // public createRange():any[] {
  //   return [];
  // }

}
