export const has = (obj, paths) => {
  if (!obj) {
    return false
  }

  const properties = paths.split('.');

  for (let i = 0; i < properties.length; i++) {
    if (!obj || !obj.hasOwnProperty(properties[i])) {
      return false;
    }

    obj = obj[properties[i]];
  }

  return (typeof obj !== 'undefined') && (obj !== null);
};

export const cloneDeep = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};
