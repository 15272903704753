import { finalize, map, mergeMap, take } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { UnitCalibrationSettings } from '@unit-state/vehicle.model';
import { Tire } from '../parts/tire/tire.model';
import { TpmsSensor } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';
import { VehicleService } from '@unit-state/vehicle.service';
import { SensorCalibrationService } from '../parts/sensor/sensor-calibration/sensor-calibration.service';
import { TpmsSensorService } from '@hardware/sensor-equipments/sensor-equipment/tpms-sensor/tpms-sensor.service';
import { ViewportService } from '@shared/services/viewport.service';
import { UserService } from '@user/user.service';

import * as VehicleHelper from '@unit-state/vehicle.helper';
import * as _ from 'lodash';

import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'vehicle-state',
  templateUrl: './vehicle-state.component.html',
  styleUrls: ['./vehicle-state.component.scss']
})
export class VehicleStateComponent implements OnInit, OnDestroy {

  @ViewChild('showCalibrationModal', { static: true }) showCalibrationModal;

  @Input() allowPopup;
  @Input() oneTimeLoginKey;

  public tire: Tire;
  public showTireInfoBox = false;

  public showCalibration = false;
  public showCancelCalibrationDialog = false;

  public axlePositions: any = {};

  public tractorAxlePositionMapping: any;
  public trailerAxlePositionMapping: any;

  public tractorAxlePositionMappingSub: Subscription;
  public trailerAxlePositionMappingSub: Subscription;

  public tractorTires: any;
  public trailerTires: any;

  public tractorTiresSub: Subscription;
  public trailerTiresSub: Subscription;

  public hasTpmsSensors: Observable<boolean>;
  public tpmsSensors: { [tpmsSensorId: number]: TpmsSensor };

  public loadingCalibration = false;

  public queryParamsSub: Subscription;
  public showFleetVehicleStatus = false;

  private pendingCommandSub: Subscription;
  public fleetVehicleCommands: any[];
  public calibrationBlockedByCommands = false;
  public axleThresholdSub: any;
  private axleThreshold: any;

  constructor(private tpmsSensorService: TpmsSensorService,
              public vehicle: VehicleService,
              public viewportService: ViewportService,
              public userService: UserService,
              private route: ActivatedRoute,
              private calibrationService: SensorCalibrationService,
              private notification: NotificationsService,
              private modalService: NgbModal) { }

  ngOnInit() {

    this.allowPopup = true;
    this.axleThresholdSub = this.vehicle.axleThreshold.subscribe((data: any) => {
      if (data) {
        this.axleThreshold = data; }
    });

    this.tractorAxlePositionMappingSub = this.vehicle.tractorAxlePositionMapping
      .subscribe((tractorAxlePositionMapping: any) => {
        this.tractorAxlePositionMapping = tractorAxlePositionMapping;
      });

    this.trailerAxlePositionMappingSub = this.vehicle.trailerAxlePositionMapping
      .subscribe((trailerAxlePositionMapping: any) => {
        this.trailerAxlePositionMapping = trailerAxlePositionMapping;
      });

    this.tractorTiresSub = this.vehicle.tractorTiresByAxlePositionMapping
      .subscribe((tractorTires: any) => {
        this.tractorTires = tractorTires;
      });

    this.trailerTiresSub = this.vehicle.trailerTiresByAxlePositionMapping
      .subscribe((trailerTires: any) => {
        this.trailerTires = trailerTires;
      });

    this.hasTpmsSensors = this.vehicle.allTpmsSensorIds.pipe(
      map((ids: number[]) => {
        return ids.length > 0;
      }));

    this.queryParamsSub = this.route.queryParams
      .subscribe((params) => {
        this.showFleetVehicleStatus = params.status === 'true';
      });

    this.pendingCommandSub = this.vehicle.pendingCommands
      .subscribe((commands: any[]) => {
        // console.log('commands', commands);

        this.fleetVehicleCommands = commands;
        this.calibrationBlockedByCommands = _.get(this.fleetVehicleCommands, 'length', 0) > 0;
      }, (err) => {
        console.log('getPendingCommands', err);
        this.calibrationBlockedByCommands = false;
      });

  }

  ngOnDestroy() {
    if (this.tractorAxlePositionMappingSub) {
      this.tractorAxlePositionMappingSub.unsubscribe();
    }

    if (this.trailerAxlePositionMappingSub) {
      this.trailerAxlePositionMappingSub.unsubscribe();
    }

    if (this.tractorTiresSub) {
      this.tractorTiresSub.unsubscribe();
    }

    if (this.trailerTiresSub) {
      this.trailerTiresSub.unsubscribe();
    }

    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }

    if (this.pendingCommandSub) {
      this.pendingCommandSub.unsubscribe();
    }

    if (this.axleThresholdSub) {
      this.axleThresholdSub.unsubscribe();
    }
  }

  getDialogWidth(): string | number {
    return Math.min(this.viewportService.windowWidth, 1200) + 'px';
  }

  getTires(axleTires: Observable<any>, axlePositionMappingIndex: number): Observable<Tire[]> {
    return this.vehicle.getTiresByIndex(axleTires, axlePositionMappingIndex).pipe(
      map((tires: Tire[]) => {

        if (this.tire) {
          this.updateSelectedTireBy(tires);
        }

        return tires;
      }));
  }

  private updateSelectedTireBy(tires: Tire[]) {
    const tyreId = _.get(this.tire, 'tyreId');

    if (tyreId) {
      const tire = _.find(tires, { tyreId });

      if (tire) {
        this.tire = tire;
      }
    }
  }

  calibrate() {
    this.showCalibration = true;

    this.modalService.dismissAll();
    this.modalService.open(this.showCalibrationModal, { centered: true, windowClass: 'calibration-modal'});

    this.vehicle.allTpmsSensorIds.pipe(
      take(1),
      mergeMap((tpmsSensorIds) => {
        return this.calibrationService.getAllCurrentSettingsBy(tpmsSensorIds);
      }), )
      .subscribe((tpmsSensors: { [tpmsSensorId: number]: TpmsSensor }) => {
        this.tpmsSensors = tpmsSensors;
      });
  }

  cancelCalibrate() {
    this.showCalibration = false;
    this.modalService.dismissAll();
  }

  calibrateBySettings(settings: UnitCalibrationSettings) {
    this.loadingCalibration = true;

    this.vehicle.selected.pipe(
      take(1),
      map((fleetUnit: any) => {
        return VehicleHelper.getCalibrationRequestBodiesByFleetUnit(fleetUnit, settings);
      }),
      mergeMap((bodies: any[]) => {
        if (bodies.length > 0) {
          const requests = _.map(bodies, (body) => {
            return this.vehicle.calibrateAll(body, _.get(settings, 'action'));
          });

          return forkJoin(requests);
        } else {
          return of(null);
        }
      }),
      take(1),
      finalize(() => {
        this.loadingCalibration = false;
      }), )
      .subscribe((res: any) => {
        console.log('calibrateBySettings res', res);
        this.vehicle.fetchSelectedFleetUnitInstance();

        this.showCalibration = false;
        this.modalService.dismissAll();
      }, (err) => {
        console.log(err);
        this.notification.remove();
        if (err.status === 404) {
          this.notification.error('Error', err.error.message);
        } else {
          this.notification.error('Error', 'Some errors occured during calibration!');
        }
      });

  }

  cancelCalibrationPending() {
    this.loadingCalibration = true;

    this.vehicle.selected.pipe(
      map((fleetUnit: any) => {
        return VehicleHelper.getIfmsBoxIdsOfFleetUnit(fleetUnit);
      }),
      mergeMap((ifmsBoxIds: number[]) => {
         const requests = _.map(ifmsBoxIds, (ifmsBoxId) => {
           return this.calibrationService.cancelCalibration({ ifmsBoxId });
         });

         return forkJoin(requests);
      }),
      take(1),
      finalize(() => {
        this.loadingCalibration = false;
        this.showCancelCalibrationDialog = false;
      }), )
      .subscribe((res: any) => {
        console.log('canceled pending calibration', res);
        this.vehicle.fetchSelectedFleetUnitInstance();
        this.showCalibration = false;
        this.modalService.dismissAll();
      }, (err) => {
        console.log(err);
      });
  }

  selectTire(tire: Tire, fleetVehicleType: string, content) {
    this.tire = tire;
    this.showTireInfoBox = true;

      this.modalService.dismissAll();
      this.modalService.open(content, { centered: true, windowClass: 'modal-h90'});
  }

  saveAxleThresholds(data) {
    this.vehicle.setThresholds(data);
    this.showCalibration = false;
    this.modalService.dismissAll();
  }

}
