import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';

import { ViewportService } from './shared/services/viewport.service';
import { Router, RoutesRecognized } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HostConfigService } from '@shared/services/host-config/host-config.service';
import { TranslateService } from '@ngx-translate/core';
import { VersionCheckService } from '@shared/services/version-check/version-check.service';

declare let window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  public notificationOptions: any = {
    maxStack: 3,
    preventDuplicates: true,
    timeOut: 3000,
    showProgressBar: true,
    theClass: 'ifms-fleet-notifications'
  };

  @HostListener('window:resize', ['$event'])
  resize(event) {
    this.viewportService.setWidth(window.innerWidth);
  }

  constructor(
      private hostConfig: HostConfigService,
      private viewportService: ViewportService,
      private router: Router,
      private modalService: NgbModal,
      private translateService: TranslateService,
      private versionCheckService: VersionCheckService,
  ) {

    this.hostConfig.initConfig();

    this.translateService.setDefaultLang('en');

    const userSelectedLanguage = localStorage.getItem('userSelectedLanguage');
    if (userSelectedLanguage) {
        this.translateService.use(userSelectedLanguage);
    } else {
        const userLang = navigator.language.replace(/[-_][a-z]+$/i, '');
        this.translateService.use(userLang)
    }

    this.versionCheckService.initVersionCheck('/version.json', 10)
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        // close all open modals
        if (this.modalService.hasOpenModals()) {
          this.modalService.dismissAll();
        }
      }
    });
  }

  ngAfterViewInit() {
    this.viewportService.setWidth(window.innerWidth);
  }

}
