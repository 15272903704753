import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ifms-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.logout();
  }

  private logout() {
    this.authService.logout().pipe(
      take(1)
    ).subscribe(
      (response) => {
        this.router.navigate(['/login']);
      },
      (error) => {
        this.router.navigate(['/login']);
      }
    );
  }
}
