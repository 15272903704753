import { finalize, map } from 'rxjs/operators';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { FleetUnit } from '../../../fleet-units/fleet-unit/fleet-unit.model';
import { Tire } from './tire.model';
import { HistorySensorData } from '../sensor-data/sensor-data.model';
import { UserService } from '@user/user.service';

import { VehicleService } from '@unit-state/vehicle.service';

import { subtract } from '../sensor-data/sensor-history-chart/sensor-history-chart.helper';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { toUTCTimezone } from '@shared/helpers/to-utc-timezone';

const SENSOR_PROPERTIES = [
    'adaptedSetpoint',
    'coldPressure',
    'currentPressure',
    'currentTemperature',
    'leackAlarm',
    'pressureAlarm',
    'pressureSetpoint',
    'temperatureAlarm',
    'temperatureThreshold',
    'timestamp',
];

@Component({
    selector: 'ifms-tire',
    templateUrl: './tire.component.html',
    styleUrls: ['./tire.component.scss']
})
export class TireComponent implements OnInit, OnChanges, OnDestroy {
    @Input() fleetUnit: FleetUnit;
    @Input() tire: Tire;
    @Input() visible: boolean;
    @Input() oneTimeLoginKey: string = null;

    public pressureUnit: string;
    private pressureUnitSub: Subscription;
    private testString: string;

    public view = 'tire';

    public actions: any[] = [
        {
            label: 'FLEET.PARTS.TIRE.SHOW_SENSOR_CALIBRATION_VIEW',
            view: 'calibration',
            selected: false,
        }
    ];

    public ranges: any[] = [
        {
            key: '48h',
            label: '48h',
            params: { count: 48 , unit: 'hours' },
        }, {
            key: '24h',
            label: '24h',
            params: { count: 24 , unit: 'hours' },
        },
        {
            key: '12h',
            label: '12h',
            params: { count: 12 , unit: 'hours' },
        },
        {
            key: '2h',
            label: '2h',
            params: { count: 2 , unit: 'hours' },
        },
    ];

    public range: any;
    public boundaries: any;
    public loadingSensorData = false;

    private currentTyreId: number;
    private currentSensorHistory: HistorySensorData[];

    private lastSensorHistoryUpdate: Date = new Date();
    private SENSOR_HISTORY_UPDATE_INTERVALL_IN_MILLISECONDS = 120000;
    public isSelectedByDateTimePicker = false;
    private isSelectedByRange = false;
    private dateTimePickerData: any;

    constructor(private vehicle: VehicleService, private userService: UserService) { }

    ngOnInit() {
        this.selectRange(this.ranges[1]);
        this.boundaries = this.getBoundariesBy(this.range);

        this.pressureUnitSub = this.userService.pressureUnitSign
            .subscribe((pressureUnit: string) => {
                this.pressureUnit = pressureUnit;
            })


    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.visible && !changes.visible.firstChange) {
            if (this.visible) {
                const tyreId = _.get(this.tire, 'tyreId');

                if (_.isNumber(tyreId) && this.currentTyreId === tyreId) {
                    this.getTyreSensorHistory(tyreId);
                }
            } else {
                setTimeout(() => {
                    this.currentSensorHistory = [];
                    this.vehicle.dispatchSensorHistory(this.currentSensorHistory);
                }, 0);
            }
        }

        if (_.has(changes.tire, 'currentValue.tyreId')) {
            const tyreId = changes.tire.currentValue.tyreId;

            if (this.currentTyreId !== tyreId) {
                this.view = 'tire';
                this.currentTyreId = tyreId;
                this.getTyreSensorHistory(tyreId);
            } else {
                if (this.sensorHistoryShouldBeUpdated()) {
                    if (this.isSelectedByRange) {
                        this.getTyreSensorHistory(tyreId);
                    } else if (this.isSelectedByDateTimePicker) {
                        this.getTyreSensorHistory(tyreId, this.dateTimePickerData);
                    }
                } else {
                    setTimeout(() => {
                        this.vehicle.dispatchSensorHistory(this.currentSensorHistory || []);
                    }, 0);
                }
            }
        }
    }

    private getTyreSensorHistory(tire: Tire, dateTimePickerData?) {
        const tyreId = _.get(this.tire, 'tyreId');

        if (this.fleetUnit) {
            this.loadingSensorData = true;

            let maxRange = this.ranges[0];
            let boundaries;
            if (dateTimePickerData) {
                maxRange = this.getDatePickerRange(dateTimePickerData);
                this.boundaries = boundaries = this.getBoundariesBy(maxRange, dateTimePickerData.endDate);
                _.forEach(this.ranges, (range) => {
                    range.selected = false;
                });
            } else {
                boundaries = this.getBoundariesBy(maxRange);
            }

            const body = this.getRangeBodyBy(boundaries);

            this.vehicle.getTyreSensorHistory(this.fleetUnit.vehicleGroupId, this.fleetUnit.fleetUnitId, tyreId, body, this.oneTimeLoginKey).pipe(
                map((sensorHistory: HistorySensorData[]) => {
                    // vehicle.getTyreSensorHistory jsut returs sensor history not the current sensorData included
                    // just convert the sensorData from tire to a sensor history entry and add it to the series
                    const current: HistorySensorData = this.createSensorHistoryEntryByTireSensordata(tire);

                    if (current) {
                        sensorHistory.push(current);
                    }

                    return sensorHistory;
                }),
                finalize(() => {
                    this.loadingSensorData = false;
                }), )
                .subscribe((sensorHistory: HistorySensorData[]) => {
                    this.currentSensorHistory = sensorHistory;
                    this.lastSensorHistoryUpdate = new Date();
                    this.vehicle.dispatchSensorHistory(sensorHistory);
                });
        }
    }

    private createSensorHistoryEntryByTireSensordata(tire: Tire): HistorySensorData {
        if (tire && tire.sensorData) {
            return _.assign({}, _.pick(tire.sensorData, SENSOR_PROPERTIES), {
                sensorDataHistoryId: null,
                tpmsSensorId: null,
                tripId: null,
            });
        }
    }

    private getRangeBodyBy(boundaries: any) {
        if (boundaries) {
            return {
                startDate: boundaries.startDate.valueOf(),
                endDate: boundaries.endDate.valueOf(),
            };
        }
    }

    private getBoundariesBy(range: any, endDate = null) {
        if (range) {
            if (endDate !== null) {
                endDate = new Date(endDate);
            } else {
                endDate = new Date();
            }
            const startDate = subtract(endDate, range.params.count, range.params.unit);

            return { startDate, endDate };
        }
    }

    private sensorHistoryShouldBeUpdated(): boolean {
        return (new Date()).valueOf() - this.lastSensorHistoryUpdate.valueOf() > this.SENSOR_HISTORY_UPDATE_INTERVALL_IN_MILLISECONDS;
    }

    selectRange(range: any) {
        const tyreId = _.get(this.tire, 'tyreId');
        if (this.isSelectedByDateTimePicker) {
            this.getTyreSensorHistory(tyreId);
        }

        this.isSelectedByRange = true;
        this.isSelectedByDateTimePicker = false;

        if (range.key !== _.get(this.range, 'key')) {
            _.forEach(this.ranges, (r) => {
                r.selected = false;
            });

            this.range = range;
            this.range.selected = true;

            if (_.isNumber(tyreId)) {
                this.boundaries = this.getBoundariesBy(this.range);
            }
        }
    }

    refreshTireDetails() {
        this.vehicle.getTireDetailsOfSelectedFleetUnit();
    }

    formatTimeToCritical() {
        const timeToCritical = this.tire ? this.tire.timeToCritical : null;

        switch (true) {
            case (timeToCritical >= 480):
                return '> 8h';
            case (timeToCritical >= 420):
                return '> 7h';
            case (timeToCritical >= 360):
                return '> 6h';
            case (timeToCritical >= 300):
                return '> 5h';
            case (timeToCritical >= 240):
                return '> 4h';
            case (timeToCritical >= 180):
                return '> 3h';
            case (timeToCritical >= 120):
                return '> 2h';
            case (timeToCritical >= 90):
                return '> 90 min';
            case (timeToCritical >= 60):
                return '> 60 min';
            case (timeToCritical >= 30):
                return '> 30 min';
            case (timeToCritical < 30 && timeToCritical !== null):
                return '< 30 min';
            default:
                return '-';
        }
    }

    getDataByDateTimePicker($event) {
        this.isSelectedByDateTimePicker = true;
        this.isSelectedByRange = false;
        this.range = null;

        const dateTimePickerData = {
            startDate: Date.UTC($event.from.year, $event.from.month - 1, $event.from.day, $event.from.hour, $event.from.minute),
            endDate: Date.UTC($event.to.year, $event.to.month - 1, $event.to.day, $event.to.hour, $event.to.minute),
        };

        this.getTyreSensorHistory(this.tire, {
            startDate: toUTCTimezone(dateTimePickerData.startDate),
            endDate: toUTCTimezone(dateTimePickerData.endDate),
        })
    }

    getDatePickerRange(body) {
        const diff = body.endDate - body.startDate;
        const rangeInHours = diff / 1000 / 60 / 60;

        const diagramRange: any = {};
        diagramRange.key = rangeInHours.toString() + 'h';
        diagramRange.label = rangeInHours.toString() + 'h';
        diagramRange.params = {'count': rangeInHours, 'unit': 'hours'};
        return diagramRange;
    }

    ngOnDestroy() {
        if (this.pressureUnitSub) {
            this.pressureUnitSub.unsubscribe();
        }
    }
}
