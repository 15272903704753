import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Internationalisation, PathValueJson } from './n4translate.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import * as _ from 'lodash';
import { HostConfigService } from '@shared/services/host-config/host-config.service';

@Injectable()
export class N4translateService {
    private host: string;

  constructor(private http: HttpClient) {
    this.host = HostConfigService.getHost();
  }

  getTranslationConfigKeys(): Observable<string[]> {
    return this.http.get(`${environment.apiUrl}/localisation/i18n/${this.host}`).pipe(
      map((langs: string[]) => {
        return _.sortBy(langs);
      }));
  }

  getTranslationConfig(key: string): Observable<Internationalisation> {
    return <Observable<Internationalisation>>this.http.get(`${environment.apiUrl}/localisation/i18n/${key}/${this.host}`);
  }

  createTranslationConfig(key: string): Observable<Internationalisation> {
    const url = `${environment.apiUrl}/localisation/i18n`;
    const body = {
      id: key,
      translationConfig: {},
      host: this.host,
    };

    return <Observable<Internationalisation>>this.http.post(url, body)
  }

  updateTranslationConfig(key: string, content: PathValueJson): Observable<Internationalisation> {
    const url = `${environment.apiUrl}/localisation/i18n/${key}/${this.host}`;

    return <Observable<Internationalisation>>this.http.put(url, content)
  }
}
