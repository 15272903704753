import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FleetUnit } from '../../fleet-units/fleet-unit/fleet-unit.model';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-fleet-units-listing',
  templateUrl: './fleet-units-listing.component.html',
  styleUrls: ['./fleet-units-listing.component.scss']
})
export class FleetUnitsListingComponent implements OnInit {
  @Input() fleetUnits: FleetUnit[];
  @Input() showMostImportantNotification = false;
  @Output() clickFleetUnit: EventEmitter<FleetUnit> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  trackByUnit(index: number, fleetUnit: FleetUnit) {
    return _.get(fleetUnit, 'fleetUnitId');
  }

  select(event) {
    this.clickFleetUnit.emit(event);
  }
}
