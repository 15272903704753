import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Condition } from '@unit-state/models/condition.model';
import { HttpClient } from '@angular/common/http';
import { getSize, getSrcPath } from '@configurator/vehicle-types/vehicle-types.helper';

import { default as olMap } from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { defaults as defaultControls, FullScreen } from 'ol/control';
import LineString from 'ol/geom/LineString';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import * as olExtent from 'ol/extent';
import * as _ from 'lodash';
import { Cluster } from 'ol/source';

@Component({
    selector: 'ifms-ol-map',
    templateUrl: './ol-map.component.html',
    styleUrls: ['./ol-map.component.css']
})
export class OlMapComponent implements OnInit, OnDestroy {
    private defaultColor = '#001ef1';
    private gpsDataInvalidColor = '#f9a825';
    private lineWidth = 3;

    private map: olMap;
    private fullScreenControl: FullScreen;

    private _markers = new ReplaySubject();
    private markerImageCache = [];
    private mapIsFullScreen: boolean;
    private markerPoints = [];
    private markerLayer = [];

    private _hisotryCoordinates = new ReplaySubject();
    private historyPoints = [];
    private historyLayer: any[];

    private pointStyles = new Map();

    @ViewChild('mapTarget', {static: true}) mapTarget: ElementRef;

    @Output() markerClick = new EventEmitter();

    @Input() center: string;
    @Input() mapAccess: any;
    @Input() clustered: boolean;
    @Input() showNumberPlates: boolean;
    @Input() centerOnInitOnly: boolean;

    private centeredOnInit: boolean;

    private static setCircleColor(point, style) {
        const feature = new Feature({
            labelPoint: new Point(point),
            name: 'My Polygon'
        });

        feature.setGeometryName('labelPoint');

        feature.setStyle(style);
        return feature;
    }

    @Input() set markers(markers: any[]) {
        // if (markers && markers.length > 0) {
        // workaround: removed > 0 check to force reload the map to prevent old map data is shown on depot with no access
        if (markers) {
            this._markers.next(markers);
        }
    }

    @Input() set historyCoordinates(coordinates: any[]) {
        if (coordinates && coordinates.length > 0) {
            this._hisotryCoordinates.next(coordinates);
        }
    }

    constructor(private http: HttpClient, private ngZone: NgZone) {
    }

    ngOnInit(): void {
        this.mapIsFullScreen = false;
        this.initMap();

        this._markers.subscribe((markers: any[]) => {
            for (const m of this.markerLayer) {
                this.map.removeLayer(m);
            }

            setTimeout(() => {
                if (this.clustered) {
                    this.setClusteredMarkersOnMap(markers);
                } else {
                    this.setMarkersOnMap(markers);
                }
            });
        })

        this._hisotryCoordinates.subscribe((coordinates: [{ color?, data }]) => {
            if (this.historyLayer && this.historyLayer.length > 0) {
                for (const hl of this.historyLayer) {
                    for (const f of hl.features) {
                        hl.source.removeFeature(f);
                    }
                    this.map.removeLayer(hl.layer);
                }
            }

            this.historyPoints = [];
            this.historyLayer = [];

            for (const coords of coordinates) {
                this.setHistory(coords.data, coords.color)
            }
        });
    }

    public ngOnDestroy() {
    }

    private initMap() {
        this.fullScreenControl = new FullScreen();
        this.map = new olMap({
            controls: defaultControls().extend([this.fullScreenControl]),
            layers: [
                new TileLayer({source:  new OSM()}),
            ],
            view: new View({
                center: [0, 0],
                zoom: 2
            }),
            target: this.mapTarget.nativeElement
        });

        this.map.on('click', (evt) => {
            this.ngZone.runOutsideAngular(() => {
                const feature = this.map.forEachFeatureAtPixel(evt.pixel, (feat, layer) => {
                    // you can add a condition on layer to restrict the listener
                    return feat;
                });

                if (feature) {
                    let fleetUnit = null;
                    if (this.clustered) {
                        const features = feature.get('features');
                        if (features.length === 1) {
                            fleetUnit = features[0].get('clickData');
                        } else {
                            this.zoomToClusteredPoints(features);
                        }
                    } else {
                        fleetUnit = feature.get('clickData');
                    }

                    if (fleetUnit) {
                        this.markerClick.emit(fleetUnit);
                    }
                }
            });
        });

        this.fullScreenControl.on('enterfullscreen', (evt) => {
            this.centerMap();
            this.mapIsFullScreen = true;
        });

        this.fullScreenControl.on('leavefullscreen', (evt) => {
            this.mapIsFullScreen = false;
            this.centerMap();
        });
    }

    private async setClusteredMarkersOnMap(markers) {
        this.markerPoints = [];
        const source = new VectorSource({});
        for (const marker of markers) {
            const feature = await this.createMarkers(marker);
            source.addFeature(feature);
        }

        const clusterSource = new Cluster({
            distance: 50,
            source: source,
        });

        const styleCache = {};
        const layer = new VectorLayer({
            source: clusterSource,
            style: (feature) => {
                const vehicleStates = [];
                const allFeatures = feature.get('features');
                const size = allFeatures.length;
                let style = null;
                if (size > 1) {
                    for (const f of allFeatures) {
                        vehicleStates.push(f.get('unitState'))
                    }

                    style = new Style({
                        image: new CircleStyle({
                            radius: 15,
                            stroke: new Stroke({
                                color: '#000000',
                            }),
                            fill: new Fill({
                                color: Condition.getWorstCaseColorBy(vehicleStates),
                            }),
                        }),
                        text: new Text({
                            text: size.toString(),
                            font: 'bold 12px Roboto, sans-serif',
                            fill: new Fill({
                                color: '#fff',
                            }),
                        }),
                    });
                    styleCache[size] = style;
                } else {
                    style = feature.get('features')[0].getStyle();
                }
                return style;
            },
        });

        this.markerLayer.push(layer);
        this.map.addLayer(layer);

        this.centerMap(this.markerPoints);
    }

    private async setMarkersOnMap(markers) {
        this.markerPoints = [];
        for (const marker of markers) {
            const source = new VectorSource({});
            const layer = new VectorLayer({source: source});
            this.markerLayer.push(layer);
            this.map.addLayer(layer);

            const feature = await this.createMarkers(marker);
            source.addFeature(feature);
        }

        this.centerMap(this.markerPoints);
    }

    private async createMarkers(marker): Promise<Feature<any>> {
        const coordinate = fromLonLat([marker.long, marker.lat]);
        this.markerPoints.push(coordinate);

        const feature = new Feature({
            geometry: new Point(coordinate)
        });

        if (marker.clickData) {
            feature.set('clickData', marker.clickData);
        }

        let vehicleType = 'CAR';
        let unitStatus = 'NORMAL';
        let unitStatusColor = this.defaultColor;
        let numberPlate = null;

        if (marker.fleetUnit) {
            unitStatus = marker.fleetUnit.unitStatus || 'NORMAL';

            if (this.clustered) {
                feature.set('unitState', unitStatus);
            }

            vehicleType = _.get(marker.fleetUnit, 'trailer.vehicleType');
            numberPlate = _.get(marker.fleetUnit, 'trailer.numberPlate');
            if (_.get(marker.fleetUnit, 'tractor') !== null) {
                if (_.get(marker.fleetUnit, 'trailer') !== null) {
                    vehicleType = 'TRUCK_TRAILER'
                    numberPlate = `${marker.fleetUnit.tractor.numberPlate} | ${marker.fleetUnit.trailer.numberPlate}`;
                } else {
                    vehicleType = _.get(marker.fleetUnit, 'tractor.vehicleType');
                    numberPlate = `${marker.fleetUnit.tractor.numberPlate}`;
                }
            }
            unitStatusColor = Condition.getColorBy(unitStatus)
        } else if (marker.fleetVehicle) {
            unitStatusColor = marker.color;
            vehicleType = _.get(marker.fleetVehicle, 'vehicle.type');
        }

        const src = getSrcPath(vehicleType, 'svg');
        const size = getSize(vehicleType)

        let data = this.markerImageCache[vehicleType];

        if (!data) {
            data = await this.http.get(`/${src}`, {responseType: 'text'}).toPromise();
            this.markerImageCache[vehicleType] = data;
        }

        const el = document.createElement('div');
        el.innerHTML = data;
        const height = size && size.length === 2 ? size[1] : 30;
        el.children[0].setAttribute('height', height + 'px');
        const width = (16 / 9) * height;
        el.children[0].setAttribute('width', width + 'px');
        const svg = `data:image/svg+xml;utf8,${el.innerHTML}`;

        const style = [
            new Style({
                image: new Icon({
                    src: svg,
                    opacity: 1,
                    color: unitStatusColor
                }),
            })
        ]


        if (this.showNumberPlates && numberPlate) {
            style.push(
                new Style({
                    text: new Text({
                        font: 'bold 12px Roboto, sans-serif',
                        padding: [5, 5, 2, 5],
                        offsetY: 30,
                        text: numberPlate,
                        fill: new Fill({
                            color: '#000000',
                        }),
                        backgroundFill: new Fill({
                            color: '#ffffff',
                        }),
                        backgroundStroke: new Stroke({
                            color: '#000000',
                            width: 2,
                        })
                    })
                })
            );
        }

        feature.setStyle(style);

        return feature;
    }

    private setHistory(coordinates, pointColor?) {
        const points = coordinates;

        const vectorLine = new VectorSource({});
        const vectorLineLayer = new VectorLayer({
            source: vectorLine
        });

        this.map.addLayer(vectorLineLayer);

        let previous = null;


        const lineStyleValid = new Style({
            stroke: new Stroke({
                color: pointColor ? pointColor : this.defaultColor,
                width: this.lineWidth
            })
        });

        const lineStyleInvalid = new Style({
            stroke: new Stroke({
                color: this.gpsDataInvalidColor,
                width: this.lineWidth
            })
        });

        const features = [];

        // use default point style color or color provided as parameter
        const defaultPointStyle = this.getPointStyle(pointColor ? pointColor : this.defaultColor);
        const invalidGpsPointStyle = this.getPointStyle(this.gpsDataInvalidColor);

        for (let i = 0; i < points.length; i++) {
            const coords = fromLonLat([points[i].longitude, points[i].latitude])
            this.historyPoints.push(coords);

            if (previous) {

                const featureLine = new Feature({
                    geometry: new LineString([previous, coords]),
                });

                let pointStyle = defaultPointStyle;
                if (!points[i].gpsDataValid && !pointColor) {
                    featureLine.setStyle(lineStyleInvalid);
                    pointStyle = invalidGpsPointStyle;
                } else {
                    featureLine.setStyle(lineStyleValid);
                }

                vectorLine.addFeature(featureLine);
                features.push(featureLine);
                vectorLine.addFeature(OlMapComponent.setCircleColor(coords, pointStyle));
            }

            previous = coords;
        }

        this.historyLayer.push({source: vectorLine, layer: vectorLineLayer, features});


        this.centerMap(this.historyPoints);
    }

    private centerMap(coordinates?) {
        if (this.centerOnInitOnly && !this.centeredOnInit) {
            this.centeredOnInit = true;
        } else if (this.centeredOnInit && this.centeredOnInit) {
            return;
        }

        if (!this.mapIsFullScreen) {
            if (this.center === 'markers' && this.markerPoints.length > 0) {
                coordinates = this.markerPoints;
            } else if (this.center === 'history' && this.historyPoints.length > 0) {
                coordinates = this.historyPoints;
            } else {
                coordinates = this.markerPoints.concat(this.historyPoints);
            }

            if (coordinates && coordinates.length > 0) {
                const ext = olExtent.boundingExtent(coordinates);
                this.map.getView().fit(ext, {minResolution: 10, size: this.map.getSize(), padding: [50, 50, 50, 50]});
            }
        }
    }

    private zoomToClusteredPoints(features: Feature<any>[]) {
        const coordinates = [];
        for (const feature of features) {
            coordinates.push(feature.get('geometry').flatCoordinates);
        }
        if (coordinates && coordinates.length > 0) {
            const ext = olExtent.boundingExtent(coordinates);
            this.map.getView().fit(ext, {size: this.map.getSize(), padding: [50, 50, 50, 50]});
        }
    }

    private getPointStyle(color) {
        const style = this.pointStyles.get(color);
        if (style) {
            return style;
        } else {
            const newStyle = new Style({
                image: new CircleStyle({
                    radius: 2.5,
                    fill: new Fill({color: color}),
                })
            });
            this.pointStyles.set(color, newStyle);
            return newStyle;
        }
    }
}
