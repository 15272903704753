import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { IfmsBoxCardComponent } from '../ifms-boxes/ifms-box-card/ifms-box-card.component';
import { IfmsBoxComponent } from '../ifms-boxes/ifms-box/ifms-box.component';
import { IfmsBoxEditorComponent } from '../ifms-boxes/ifms-box-editor/ifms-box-editor.component';
import { IfmsBoxHorizontalCardComponent } from '../ifms-boxes/ifms-box-horizontal-card/ifms-box-horizontal-card.component';
import { IfmsBoxesLazyLoadingListComponent } from '../ifms-boxes/ifms-boxes-lazy-loading-list/ifms-boxes-lazy-loading-list.component';
import { IfmsBoxesListComponent } from '../ifms-boxes/ifms-boxes-list/ifms-boxes-list.component';

import { SensorEquipmentComponent } from '../sensor-equipments/sensor-equipment/sensor-equipment.component';
import { SensorEquipmentCardComponent } from '../sensor-equipments/sensor-equipment-card/sensor-equipment-card.component';
import { SensorEquipmentEditorComponent } from '../sensor-equipments/sensor-equipment-editor/sensor-equipment-editor.component';
import { SensorEquipmentHorizontalCardComponent } from '../sensor-equipments/sensor-equipment-horizontal-card/sensor-equipment-horizontal-card.component';
import { SensorEquipmentsListComponent } from '../sensor-equipments/sensor-equipments-list/sensor-equipments-list.component';
import { SensorEquipmentsLazyLoadingListComponent } from '../sensor-equipments/sensor-equipments-lazy-loading-list/sensor-equipments-lazy-loading-list.component';
import { SensorPlaceholderComponent } from '../sensor-equipments/sensor-placeholder/sensor-placeholder.component';
import { TpmsSensorComponent } from '../sensor-equipments/sensor-equipment/tpms-sensor/tpms-sensor.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [
    IfmsBoxCardComponent,
    IfmsBoxComponent,
    IfmsBoxEditorComponent,
    IfmsBoxHorizontalCardComponent,
    IfmsBoxesLazyLoadingListComponent,
    IfmsBoxesListComponent,

    SensorEquipmentComponent,
    SensorEquipmentCardComponent,
    SensorEquipmentEditorComponent,
    SensorEquipmentHorizontalCardComponent,
    SensorEquipmentsListComponent,
    SensorEquipmentsLazyLoadingListComponent,
    SensorPlaceholderComponent,
    TpmsSensorComponent,
  ],
  exports: [
    IfmsBoxCardComponent,
    IfmsBoxComponent,
    IfmsBoxEditorComponent,
    IfmsBoxHorizontalCardComponent,
    IfmsBoxesLazyLoadingListComponent,
    IfmsBoxesListComponent,

    SensorEquipmentComponent,
    SensorEquipmentCardComponent,
    SensorEquipmentEditorComponent,
    SensorEquipmentHorizontalCardComponent,
    SensorEquipmentsListComponent,
    SensorEquipmentsLazyLoadingListComponent,
    SensorPlaceholderComponent,
    TpmsSensorComponent,
  ]
})
export class HardwareSharedModule {
  static forRoot(): ModuleWithProviders<HardwareSharedModule> {
    return {
      ngModule: HardwareSharedModule,
      providers: [

      ]
    };
  }
}
