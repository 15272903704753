import { AxlePositionMapping } from '@configurator//vehicle-configurator.model';
import { AxleFrameworkImageMapping, AxleItemsFrameGrid } from './axle-framework.model';
import { Tire } from '@parts/tire/tire.model';
import { TpmsSensor } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';

import * as _ from 'lodash';

const POSITIONS = ['a', 'b', 'c', 'd'];

const getTirePlaceHoldersByAxlePositionMapping = (frameworks: AxleFrameworkImageMapping, axlePositionMap: AxlePositionMapping, floatingAxlePosition: number) => {
  const pathToImage = _.get(axlePositionMap, 'axle.pathToImage');
  const framework = frameworks ? frameworks[pathToImage] : null;
  const positions = _.get(framework, 'positions');
  const axlePosition = floatingAxlePosition;

  if (!positions) {
    return [null, null, null, null];
  }

  if (positions === 2) {
    return [{ axlePosition, position: 'a' }, null, null, { axlePosition, position: 'd' }];
  }

  if (positions === 4) {
    return [
      { axlePosition, position: 'a' },
      { axlePosition, position: 'b' },
      { axlePosition, position: 'c' },
      { axlePosition, position: 'd' },
    ];
  }
};

const assignFloatingAxlePosition = (axlePositionMaps: AxlePositionMapping[], items: any[]) => {
  let axlePosition = 1;

  const groupedItems = _.groupBy(items, 'axlePosition');
  _.forEach(axlePositionMaps, (axlePositionMap, index) => {
    const group = groupedItems[axlePosition];

    if (group) {
      _.forEach(group, (item: any) => {
        item.floatingAxlePosition = index + 1;
      });
    }

    if (_.get(axlePositionMap, 'axle.type') !== 'FILLER') {
      axlePosition += 1;
    }
  });
};

const createTireGridByAxlePositions = (
  frameworks: AxleFrameworkImageMapping,
  axlePositions: AxlePositionMapping[],
  items?: any[]
): any[] => {
  if (items) {
    assignFloatingAxlePosition(axlePositions, items);
  }

  const groupedItems = _.groupBy(items, 'floatingAxlePosition');
  let floatingAxlePosition = 1;

  return _.map(axlePositions, (axlePositionMap: AxlePositionMapping) => {
    const placeholders = getTirePlaceHoldersByAxlePositionMapping(frameworks, axlePositionMap, floatingAxlePosition);

    if (items && _.get(axlePositionMap, 'axle.type') !== 'FILLER') {
      floatingAxlePosition += 1;
      const group = groupedItems[_.get(axlePositionMap, 'axlePosition', floatingAxlePosition)];

      if (group) {
        _.forEach(group, (item: any) => {
          const index = POSITIONS.indexOf(item.position);

          if (_.isNumber(index)) {
            placeholders[index] = item;
          }
        });
      }
    }

    return placeholders;
  });
};

export const getTirePlaceHoldersByAxlePositions = (
  frameworks: AxleFrameworkImageMapping,
  axlePositions: AxlePositionMapping[],
  tpmsSensors?: TpmsSensor[]
): TpmsSensor[][] => {
  return createTireGridByAxlePositions(frameworks, axlePositions, tpmsSensors);
};

export const getTiresGridByAxlePositions = (
  frameworks: AxleFrameworkImageMapping,
  axlePositions: AxlePositionMapping[],
  tires?: Tire[]
): Tire[][] => {
  return createTireGridByAxlePositions(frameworks, axlePositions, tires);
};

export const createFrameworkByAxlePositionsAndPositionables = (
  frameworks: AxleFrameworkImageMapping,
  axlePositions: AxlePositionMapping[],
  items: any[]
): AxleItemsFrameGrid => {
  if (items === null) {
      items = [];
  }
  const positionables = _.cloneDeep(items);
  let floatingAxlePosition = 1;

  const grid = _.map(axlePositions, (axlePositionMap: AxlePositionMapping) => {
    let placeholders = getTirePlaceHoldersByAxlePositionMapping(frameworks, axlePositionMap, floatingAxlePosition);

    if (positionables && _.get(axlePositionMap, 'axle.type') !== 'FILLER') {
      floatingAxlePosition += 1;

      placeholders = _.map(placeholders, (placeholder, index) => {
        if (placeholder) {
          const candidate = _.find(positionables, { axlePosition: placeholder.axlePosition, position: placeholder.position });
          if (candidate) {
            _.remove(positionables, candidate);
            return candidate;
          }
        }

        return placeholder;
      });
    }

    return placeholders;
  });

  return {
    grid,
    floatingAxlePosition: floatingAxlePosition - 1,
    leftovers: positionables,
  };
};
