import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Filter, FilterValuesMap } from '@fleet/fleet-overview/fleet-units-filter-panel/fleet-units-filter-panel.model';
import { FleetUnit } from './fleet-units/fleet-unit/fleet-unit.model';
import { FleetState } from './fleet.model';
import { FleetActions } from './fleet.reducer';

import { extractFilters, filterFleetUnits, filterFleetUnitsBySearchNeedle } from './fleet.helper';

@Injectable()
export class FleetService {
    public state: Observable<FleetState>;

    public vehicle: Observable<FleetUnit>;

    public recentDrives: Observable<FleetUnit[]>;

    public alarmingFleetUnits: Observable<FleetUnit[]>;
    public notAlarmingFleetUnits: Observable<FleetUnit[]>;

    public filters: Observable<Filter[]>;
    public filterPanelValues: Observable<FilterValuesMap>;
    public filteredFleetUnits: Observable<FleetUnit[]>;

    public querySearchNeedle: Observable<string>;
    public queriedAlarmingFleetUnits: Observable<FleetUnit[]>;
    public queriedNotAlarmingFleetUnits: Observable<FleetUnit[]>;

    constructor (private router: Router, private store: Store<any>) {

        this.state = this.store.select('fleet');

        this.vehicle = this.state.pipe(
          map((state: FleetState) => {
            if (state) {
              return state.vehicle
            }
          }));

        this.recentDrives = this.state.pipe(
          map((state: FleetState) => {
            if (state) {
              return state.recentDrives
            }
          }));

        this.querySearchNeedle = this.state.pipe(
          map((state: FleetState) => {
            if (state) {
              return state.querySearchNeedle
            }
          }));

        this.alarmingFleetUnits = this.state.pipe(
          map((state: FleetState) => {
            if (state) {
              return state.alarmingFleetUnits
            }
          }));

        this.queriedAlarmingFleetUnits = observableCombineLatest(
            this.querySearchNeedle,
            this.alarmingFleetUnits
          ).pipe(
          map(([querySearchNeedle, alarmingFleetUnits]) => {
            return filterFleetUnitsBySearchNeedle(querySearchNeedle, alarmingFleetUnits);
          }));

        this.notAlarmingFleetUnits = this.state.pipe(
          map((state: FleetState) => {
            if (state) {
              return state.notAlarmingFleetUnits
            }
          }));

        this.queriedNotAlarmingFleetUnits = observableCombineLatest(
            this.querySearchNeedle,
            this.notAlarmingFleetUnits
          ).pipe(
          map(([querySearchNeedle, notAlarmingFleetUnits]) => {
            return filterFleetUnitsBySearchNeedle(querySearchNeedle, notAlarmingFleetUnits);
          }));

        this.filters = this.notAlarmingFleetUnits.pipe(
          map((fleetUnits: FleetUnit[]) => {
            if (fleetUnits) {
              return extractFilters(fleetUnits);
            }
          }));

        this.filterPanelValues = this.state.pipe(
          map((state: FleetState) => {
            if (state) {
              return state.panelValues
            }
          }));

        this.filteredFleetUnits = observableCombineLatest(
            this.notAlarmingFleetUnits,
            this.filterPanelValues,
          ).pipe(
          map(([fleetUnits, filterValues]) => {
            if (!filterValues) {
              return fleetUnits;
            }

            return filterFleetUnits(fleetUnits, filterValues);
          }))
    }

    selectVehicle(vehicle: FleetUnit) {
      this.store.dispatch({
        type: FleetActions.SELECT_VEHICLE,
        payload: vehicle
      });
    }

    private findVehicleById() {
      return this.state.pipe(
        map((state: FleetState) => {
          if (state) {
            return state.vehicle
          }
        }));
    }

    clear() {
      this.store.dispatch({
        type: FleetActions.SET_DEFAULT,
      });
    }

    setPanelValues(panelValues: FilterValuesMap) {
      this.store.dispatch({
        type: FleetActions.SET_PANEL_VALUES,
        payload: panelValues
      });
    }

    setSearchQueryNeedle(needle: string) {
      this.store.dispatch({
        type: FleetActions.SET_QUERY_SEARCH_NEEDLE,
        payload: needle,
      });
    }

}
