import { Pipe, PipeTransform } from '@angular/core';

import { replace } from 'lodash';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: any, pattern?: any, replacement?: any): any {
    return replace(value, pattern, replacement);
  }

}
