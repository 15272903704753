import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18nService } from '@shared/services/ngb-datepicker-i18n/ngb-datepicker-i18n.service';
import { TranslateService } from '@ngx-translate/core';

interface NgbDateTimeFromTo {
  from: any;
  to: any;
}

export function NgbDatepickerI18nFactory(translateService: TranslateService) {
    const data = {
        months: translateService.instant('DATEPICKER.months'),
        weekdays: translateService.instant('DATEPICKER.weekdays')
    };
    return new NgbDatepickerI18nService(data);
}

@Component({
  selector: 'ifms-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
        {provide: NgbDatepickerI18n, useFactory: NgbDatepickerI18nFactory, deps: [TranslateService]}
      ],
})

export class DatepickerComponent implements OnInit {

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  todayTimestamp: number;
  myMaxDate: any;
  ngbMyMaxDate: any;
  myStartDate: any;
  noAutoNav = false;
  showCalender = false;
  disabledButton = true;

  timeFrom = {hour: 0, minute: 0, second: 0};
  timeTo = {hour: 23, minute: 59, second: 0};


  dateTimeFromTo: NgbDateTimeFromTo = {from: {}, to: {}};


  @Input() iconSelected = false;
  @Input() withTime = false;
  @Input() maxDays = null;
  @Output() dateTimeData = new EventEmitter<NgbDateTimeFromTo>();

  @ViewChild('timepicker', { static: false }) private timepicker;
  @Input() selectWholeWeek = false;
  private hoveredWeekFromDate: NgbDate;
  private hoveredWeekToDate: NgbDate;

  constructor(private elementRef: ElementRef) {
    const currentDate = new Date();
    this.myStartDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  }


  ngOnInit() {

  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.toggleCalender(true);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.selectWholeWeek) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
        this.dateTimeFromTo.from = Object.assign({}, this.fromDate, this.timeFrom);
        this.setMaxDate();
      } else if (this.fromDate && !this.toDate && (date.equals(this.fromDate) || date.after(this.fromDate))) {
        this.toDate = date;
        this.dateTimeFromTo.to = Object.assign({}, this.toDate, this.timeTo);

        const startMaxDate = new Date();
        const startMaxDateFullYear = startMaxDate.getFullYear() + 1;
        this.ngbMyMaxDate = {year: startMaxDateFullYear, month: 12, day: 31};
      } else {
        this.toDate = null;
        this.fromDate = date;
        this.dateTimeFromTo.from = Object.assign({}, this.fromDate, this.timeFrom);
        this.setMaxDate();
      }
      this.noAutoNav = true;
    } else {
      this.fromDate = this.hoveredWeekFromDate;
      this.toDate = this.hoveredWeekToDate;
      this.dateTimeFromTo.from = Object.assign({}, this.hoveredWeekFromDate, this.timeFrom);
      this.dateTimeFromTo.to = Object.assign({}, this.hoveredWeekToDate, this.timeTo);
    }

    this.checkDisabeldButton();
  }

  setMaxDate() {
    this.todayTimestamp = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0).getTime();
    if (this.maxDays) {
      this.myMaxDate = new Date(this.todayTimestamp + 86400 * this.maxDays * 1000);
      this.ngbMyMaxDate = {
        year: this.myMaxDate.getFullYear(),
        month: this.myMaxDate.getMonth() + 1,
        day: this.myMaxDate.getDate(),
      };
    }
  }

  isWholeWeekHovered(date: NgbDate) {
    if (this.selectWholeWeek && this.hoveredDate) {
      const weekDate = this.getWeekStartEndFromDate(date);
      this.hoveredWeekFromDate = weekDate.fromDate;
      this.hoveredWeekToDate = weekDate.toDate;
      return (date.after(weekDate.fromDate)
          && date.before(weekDate.toDate))
          || date.equals(weekDate.fromDate)
          || date.equals(weekDate.toDate);
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    if (this.maxDays) {
      return date.after(this.fromDate) && date.before(this.toDate) && date.before(this.ngbMyMaxDate) && this.checkSelectedTimestamp(date);
    } else {
      return date.after(this.fromDate) && date.before(this.toDate);
    }

  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  isFrom(date: NgbDate) {
    return date.equals(this.fromDate);
  }

  isTo(date: NgbDate) {
    return date.equals(this.toDate);
  }

  checkSelectedTimestamp(data: NgbDate) {
    const todayPlusOrMinusDays = this.myMaxDate;
    const selectedDateTimestamp = new Date(data.year, data.month - 1, data.day).getTime();
    if (selectedDateTimestamp < todayPlusOrMinusDays) {
      return true;
    } else {
      return false;
    }
  }

  preventNavigationOnDayClick($event) {
    if (this.noAutoNav) {
      $event.preventDefault();
      this.noAutoNav = false;
    }
  }

  sendData() {
    this.dateTimeData.emit(this.dateTimeFromTo);
    this.toggleCalender();
  }

  toggleCalender(forceClose?: boolean) {
    if (this.showCalender) {
      this.disabledButton = false;
      this.showCalender = false;
      this.fromDate = null;
      this.toDate = null;
      this.dateTimeFromTo = {from: {}, to: {}};
    } else if (!this.showCalender && !forceClose) {
      this.showCalender = true;
    }
  }

  timeSelectFrom($event) {
    if (this.timeFrom) {
      this.dateTimeFromTo.from = Object.assign({}, this.fromDate, $event);
      this.checkDisabeldButton();
    }
  }

  timeSelectTo($event) {
    if (this.timeTo) {
      this.dateTimeFromTo.to = Object.assign({}, this.toDate, $event);
      this.checkDisabeldButton();
    }
  }

  checkDisabeldButton () {
    if (!this.fromDate || !this.toDate) {
      this.disabledButton = true;
    } else if (this.fromDate.equals(this.toDate) && (this.timeFrom.hour >= this.timeTo.hour && this.timeFrom.minute >= this.timeTo.minute)) {
      this.disabledButton = true;
    } else {
      this.disabledButton = false;
    }
  }

  private getWeekStartEndFromDate(date: NgbDate): {fromDate: NgbDate, toDate: NgbDate} {
    let fromDate = new Date(this.hoveredDate.year + '-' + this.hoveredDate.month + '-' + this.hoveredDate.day);
    const time = fromDate.getDay() ? fromDate.getDay() - 1 : 6;
    fromDate = new Date(fromDate.getTime() - time * 24 * 60 * 60 * 1000);
    const f = new NgbDate(
        fromDate.getFullYear(),
        fromDate.getMonth() + 1,
        fromDate.getDate()
    );
    const toDate = new Date(fromDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    const t = new NgbDate(
        toDate.getFullYear(),
        toDate.getMonth() + 1,
        toDate.getDate()
    );

    return {fromDate: f, toDate: t};
  }

}
