import { Component, Input, OnInit } from '@angular/core';

import { IfmsBox } from '../ifms-box/ifms-box.model';

@Component({
  selector: 'ifms-box-card',
  templateUrl: './ifms-box-card.component.html',
  styleUrls: ['./ifms-box-card.component.scss']
})
export class IfmsBoxCardComponent implements OnInit {

  @Input() box: IfmsBox;
  @Input() disabled: boolean;

  constructor() { }

  ngOnInit() {
  }

}
