import { AxleConfig, AxlePositionMapping } from './vehicle-configurator.model';

export class VehicleAxleHelper {
  public static IMAGES: { [x: string]: string } = {
    '0': '/assets/images/vehicle/axel/LKW-03.svg',
    '2': '/assets/images/vehicle/axel/LKW-01.svg',
    '4': '/assets/images/vehicle/axel/LKW-04.svg',
  };

  public static prepareImagesOfTemplates(templates: AxleConfig[]) {
    templates.forEach((template) => {
      VehicleAxleHelper.prepareImagesOfVehicleAxles(template.axlePositionMaps);
    });
  }

  public static prepareImagesOfVehicleAxles(vehicleAxles: AxlePositionMapping[]) {
    vehicleAxles.forEach((vehicleAxle) => {
      vehicleAxle.axle.pathToImage = VehicleAxleHelper.IMAGES[vehicleAxle.axle.type];
    });
  }

  public static sortAxleByPositionsOfTemplates(templates: AxleConfig[]) {
    templates.forEach((template) => {
      VehicleAxleHelper.sortAxleByPositions(template.axlePositionMaps);
    });
  }

  public static sortAxleByPositions(vehicleAxles: AxlePositionMapping[]) {
    vehicleAxles.sort((a, b) => {
      return a.axlePosition - b.axlePosition;
    });
  }
}
