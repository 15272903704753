import * as _ from 'lodash';

const getPaths = (json, path = '') => {
    let paths: string[] = [];
    _.forOwn(json, (value, key) => {
        const valuePath = `${path}.${key}`;
        if (_.isPlainObject(value)) {
            paths = _.concat(paths, getPaths(value, valuePath));
        } else {
            paths.push(valuePath);
        }
    });
    return _.map(paths, path => _.trimStart(path, '.'));
};

export const getEntries = (json) => {
  return _
    .chain(getPaths(json))
    .map((path) => {
      return {
        key: path,
        value: _.get(json, path),
        valueIsArray: Array.isArray(_.get(json, path)),
      }
    })
    .sortBy('key')
    // .filter(entity => entity.value)
    .value();
};

export const buildJSONbyEntries = (entries) => {
  const json: { [key: string]: string; } = {};

  for (const entrie of entries) {
      // check if entry should be an array
      if (entrie.valueIsArray) {
          /*
              check if entry is now as string, happens when modifying in frontend
              because textarea transforms arrays to string
          */
          if (!Array.isArray(entrie.value)) {
              entrie.value = entrie.value.split(',');
          }
      }
      _.set(json, entrie.key, entrie.value);
  }

  return json;
};
