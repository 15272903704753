import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CommandHistory } from './command.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class BoxCommandsHistoryService {

  constructor(private http: HttpClient) { }

  getHistory(imei: string): Observable<CommandHistory[]> {
    return <Observable<CommandHistory[]>>this.http.get(`${environment.apiUrl}/commands/history/${imei}?size=10`);
  }

}
