import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
  public title: string;
  public currentYear: number;

  constructor(private titleService: Title) {
  }

  ngOnInit() {
    this.title = this.titleService.getTitle();
    this.currentYear = new Date().getFullYear();
  }

}
