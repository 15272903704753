import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // constructor(private router: Router) { }
  constructor() { }

  handleError(error) {
    //  console.log('Error Occured!')
     // IMPORTANT: Rethrow the error otherwise it gets swallowed
     throw error;
  }

}
