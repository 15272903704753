import { TYPES } from './vehicle-types.model';

import { get, isNumber } from 'lodash';
import { UnitStatusToIcon } from '@fleet/fleet-units/fleet-unit/fleet-unit.model';

export const getSrcPath = (type: string, fileType?: string, key?: string): string => {
  fileType = fileType || 'svg';

  // DON'T KNOW WHY OTHER PEOPLE CODED THIS! USELESS...
  /*if(type === 'LOADER') {
    fileType = 'png';
  }*/

  const name = get(TYPES, `${type}.folder`);

  if (name) {
    let filename = name;

    const iconStatus = get(UnitStatusToIcon, key);

    if (iconStatus) {
      filename = `${filename}-${iconStatus}`;
    }

    return `assets/images/vehicle-types/${name}/${filename}.${fileType}`;
  }
};

export const getSize = (type: string): number[] => {
  return get(TYPES, `${type}.size`);
};

export const getMarkerSrcPath = (type: string, key: number): string => {
  const name = get(TYPES, `${type}.folder`);

  if (name) {
    let filename = name;

    if (isNumber(key)) {
      filename = `${filename}-${key}`;
    }

    return `/assets/images/vehicle-types/${name}/markers/${filename}.png`;
  }
};
