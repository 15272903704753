import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FMS_Entry } from '@unit-state/vehicle-monitoring/vehicle-monitoring.model';

@Component({
  selector: 'ifms-monitoring-fms-entities',
  templateUrl: './monitoring-fms-entities.component.html',
  styleUrls: ['./monitoring-fms-entities.component.scss']
})
export class MonitoringFmsEntitiesComponent implements OnInit {

  @Input() chunks: FMS_Entry[][];
  @Output() selected: EventEmitter<FMS_Entry> = new EventEmitter<FMS_Entry>();

  constructor() { }

  ngOnInit() {
  }

}
