import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {

    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    public needsToReload: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private versionCheckInterval: any;

    constructor(private http: HttpClient) {
    }

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
    public initVersionCheck(url, frequency = 30) {
        this.checkVersion(url);
        frequency = 1000 * 60 * frequency;

        this.versionCheckInterval = setInterval(() => {
            this.checkVersion(url);
        }, frequency);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .pipe(
                first()
            )
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);

                    // If new version, do something
                    if (hashChanged) {
                        this.needsToReload.next(true);

                        // if version has changed, we clear the timeout. So we don't check again until reload
                        clearTimeout(this.versionCheckInterval);
                    }
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash) {
            return false;
        }
        return currentHash !== newHash;
    }
}
