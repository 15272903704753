import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';

import {
    CommandHistory,
    CommandPending,
    PressureUnit,
    TpmsSensor,
    TpmsSensorsUpdateMap
} from '../sensor-equipment.model';
import { Tire } from '@parts/tire/tire.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import * as _ from 'lodash';

@Injectable()
export class TpmsSensorService {
  private corePath = 'tpmsSensor';

  constructor(private http: HttpClient) { }

  initTpmsSensor(sensor: TpmsSensor): Observable<TpmsSensor> {
    return <Observable<TpmsSensor>>this.http.post(`${environment.apiUrl}/${this.corePath}/initilize`, sensor);
  }

  initAllTpmsSensors(sensors: TpmsSensor[]): Observable<TpmsSensor[]> {
    if (sensors && sensors.length > 0) {
      return forkJoin(sensors.map((sensor) => this.initTpmsSensor(sensor)));
    }

    return of([]);
  }

  updateExternalId(sensor: TpmsSensor): Observable<TpmsSensor> {
    const url = `${environment.apiUrl}/${this.corePath}/${sensor.tpmsSensorId}/externalId?externalId=${sensor.externalId}`;
    return <Observable<TpmsSensor>>this.http.put(url, {});
  }

  updateAllExternalIds(sensors: TpmsSensor[]): Observable<TpmsSensor[]> {
    if (sensors && sensors.length > 0) {
      return forkJoin(sensors.map((sensor) => this.updateExternalId(sensor)));
    }

    return of([]);
  }

  updateAllTpmsSensorsBySegments(segments: TpmsSensorsUpdateMap): Observable<TpmsSensor[][]> {
    return forkJoin(
      this.initAllTpmsSensors(segments.initialize),
      this.updateAllExternalIds(segments.update)
    )
  }

  getPressureUnits(): Observable<PressureUnit[]> {
    return <Observable<PressureUnit[]>>this.http.get(`${environment.apiUrl}/${this.corePath}/pressure-units`);
  }

  updateAllTpmsSensorsBySegmentsAndTire(fleetVehicleId: number, segments: TpmsSensorsUpdateMap): Observable<TpmsSensor[][]> {
    return forkJoin(
      this.initAllTpmsSensorByTire(fleetVehicleId, segments.initialize),
      this.updateAllExternalIds(segments.update)
    )
  }

  initAllTpmsSensorByTire(fleetVehicleId: number, sensors: TpmsSensor[]): Observable<TpmsSensor[]> {
    if (sensors && sensors.length > 0) {
      return this.http.get<Tire[]>(`${environment.apiUrl}/tyre?fleetVehicleId=${fleetVehicleId}`).pipe(
        mergeMap((tires: Tire[]) => {
            return forkJoin<TpmsSensor[]>(_.map(sensors, (sensor) => {
                const axlePosition = _.get(sensor, 'axlePosition');
                const position = _.get(sensor, 'position');

                if (axlePosition && position) {
                    const tire = _.find(tires, { axlePosition, position });
                    if (tire) {
                        return this.initTpmsSensorByTire(sensor, tire);
                    }
                }

                return of(null);
            }));
        }));
    }

    return of([]);
  }

  initTpmsSensorByTire(sensor: TpmsSensor, tire: Tire): Observable<TpmsSensor> {
    return <Observable<TpmsSensor>>this.http.post(`${environment.apiUrl}/${this.corePath}/create/${tire.tyreId}`, sensor);
  }

  getCommandsHistory(imei: number): Observable<CommandHistory[]> {
    return <Observable<CommandHistory[]>>this.http.get(`${environment.apiUrl}/commands/history/${imei}`);
  }

  getPendingCommands(fleetVehicleId: number): Observable<CommandPending> {
    return <Observable<CommandPending>>this.http.get(`${environment.apiUrl}/commands/pending-commands/${fleetVehicleId}`);
  }

  clearTpmsSensors(fleetVehicleId: number): Observable<any> {
    return <Observable<CommandPending>>this.http.put(`${environment.apiUrl}/tpmsSensor/${fleetVehicleId}/clear-sensors`, {});
  }

  detachTpmsSensor(tpmsSensorId: number): Observable<any> {
    return <Observable<any>>this.http.put(`${environment.apiUrl}/tpmsSensor/${tpmsSensorId}/detach-sensor`, {});
  }
}
