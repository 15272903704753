import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { HistorySensorData } from '../sensor-data.model';

import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@user/user.service';

import * as SensorChartHelper from './sensor-history-chart.helper';
import * as _ from 'lodash';
import { tireAxleThresholds } from '@parts/axle/axle-thresholds/axle-thresholds';
import { AxleThresholdsService } from '@parts/axle/axle-thresholds/axle-thresholds.service';
import { TpmsSensor } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';
import 'chartjs-adapter-moment';

declare const moment: any;

@Component({
  selector: 'ifms-sensor-history-chart',
  templateUrl: './sensor-history-chart.component.html',
  styleUrls: ['./sensor-history-chart.component.scss']
})
export class SensorHistoryChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('chartsWrapper', { static: true }) chartsWrapper: ElementRef;

  @Input() withoutThreshold = false;
  @Input() boundaries: any;
  @Input() sensorData: any;
  @Input() tpmsSensor: TpmsSensor;
  @Input() sensorHistory: HistorySensorData[];
  @Input() tireOfFleetUnit: {tireId: number, fleetUnitId: number};

  public unit = 'day';
  public timeFormat = 'MM/DD/YYYY HH:mm';
  // public timeFormat: string = 'HH:mm:ss';

  public options: any = {
    animation: {
      duration: 0,
    },
    scales: {
      xAxes: {
          type: 'time',
          time: {
              parser: this.timeFormat,
              tooltipFormat: 'll HH:mm',
              // distribution: 'linear',
          },
          scaleLabel: {
              display: true,
              labelString: this.translationService.instant('CHARTS.LEGEND.AXES.DATE')
          }
      }
    },
    plugins: {},
  };

  public temperatur: any = {
    options: this.cloneCoreOptions(),
    data: null
  };

  public pressure: any = {
    options: this.cloneCoreOptions(),
    data: null
  };

  public labels: Date[];

  private range: any;

  public showNormalizedPressure = false;

  public pressureUnit: string;
  private pressureUnitSub: Subscription;
  private thresholds: tireAxleThresholds;

  constructor(private translate: TranslateService,
              private userService: UserService,
              private axleTreshholdService: AxleThresholdsService,
              private translationService: TranslateService) {
    this.setOptions();
  }

  ngOnInit() {
    this.pressureUnitSub = this.userService.pressureUnitSign
      .subscribe((pressureUnit: string) => {
        this.pressureUnit = pressureUnit;
        this.setOptions();
        if (this.withoutThreshold) {
          this.update();
        }/* else {
          this.axleTreshholdService.getTyreThresholds(this.tireOfFleetUnit.fleetUnitId, this.tireOfFleetUnit.tireId)
              .subscribe((data: tireAxleThresholds) => {
                this.thresholds = data;
                this.update();
              });
        }*/
      });
  }

  ngOnChanges(changes) {
    if (_.has(changes, 'sensorData')) {
      this.axleTreshholdService.getTyreThresholds(this.tireOfFleetUnit.fleetUnitId, this.tireOfFleetUnit.tireId)
          .subscribe((data: tireAxleThresholds) => {
            this.thresholds = data;
            this.update();
          });
    }

    if (_.has(changes, 'sensorHistory')) {
      this.update();
    }

    if (_.has(changes, 'boundaries')) {
      this.update();
    }


  }

  ngOnDestroy() {
    if (this.pressureUnitSub) {
      this.pressureUnitSub.unsubscribe();
    }
  }

  toggleAdjustView() {
    this.showNormalizedPressure = !this.showNormalizedPressure;
    this.update();
  }

  private cloneCoreOptions(): any {
    const options = _.cloneDeep(this.options);

    options.plugins.legend = {
      labels: {
        filter: (legendItem, chartData) => {
          legendItem.fillStyle = legendItem.strokeStyle;
          if (legendItem.text) {
            const legendItemTextWithoutSpaces = legendItem.text.replace(/\s/g, '_').toUpperCase();
            legendItem.text = this.translationService.instant(`CHARTS.LEGEND.${legendItemTextWithoutSpaces}`);
          }
          return legendItem.text;
        }
      }
    };

    return options;
  }

  private setOptions() {
    this.temperatur.options.scales.yAxes = {
        scaleLabel: {
            display: true,
            labelString: this.translate.instant('FLEET.PARTS.VEHICLE.TEMPERATURE'),
        },
        ticks: {
            stepSize: 10,
            min: -10,
            max: 100
        }
    };

    this.pressure.options.scales.yAxes = {
        scaleLabel: {
            display: true,
            labelString: this.pressureUnit,
        },
        ticks: {
            stepSize: 1,
            min: 1,
            max: 12
        }
    };
  }

  private update() {
    if (_.has(this, 'temperatur.options.scales.yAxes')) {
      this.updateRange(this.sensorHistory, ['currentPressure', 'currentTemperature']);
      this.labels = this.getLabels(this.sensorHistory);
      if (!this.withoutThreshold && this.thresholds) {
        this.pressure.data = this.getPressureData(this.sensorHistory);
      } else if (this.withoutThreshold) {
        this.pressure.data = this.getPressureData(this.sensorHistory);
      }
      this.temperatur.data = this.getTemperaturData(this.sensorHistory);
    }
  }

  private updateRange(sensorHistory: HistorySensorData[], properties: string[]): any {
    let range: any = {};

    _.forEach(properties, (property) => {
      const start = SensorChartHelper.getPointByProperty(_.first(sensorHistory), property);
      const end = SensorChartHelper.getPointByProperty(_.last(sensorHistory), property);

      range = SensorChartHelper.updateRangeBy(range, _.get(start, 'x'), _.get(end, 'x'));
    });

    this.range = range;
  }

  private getPressureData(sensorHistory: HistorySensorData[]): any {
    let thresholds = null;
    if (!this.withoutThreshold) {
      thresholds = {originalThresholdValues: this.thresholds};
      thresholds.calculatedThresholds = {
        warning: {
          high: this.tpmsSensor.pressureSetpoint * this.thresholds.maintenanceThreshold,
          low: this.tpmsSensor.pressureSetpoint * this.thresholds.maintenanceThreshold,
        },
        alarming: {
          high: this.tpmsSensor.pressureSetpoint * this.thresholds.dangerThreshold,
          low: this.tpmsSensor.pressureSetpoint * this.thresholds.dangerThreshold,
        },
      }
    }

    return SensorChartHelper.getPressureOptions(
        this, sensorHistory, thresholds
    );
  }

  private getTemperaturData(sensorHistory: HistorySensorData[]): any {
    return SensorChartHelper.getTemperaturOptions(this, sensorHistory);
  }

  private getLabels(sensorHistory: HistorySensorData[]): any[] {
    return _.map(sensorHistory, (sensor) => {
      return SensorChartHelper.utcToLocalDate(sensor.timestamp);
    });
  }
}
