import { FleetUnit } from '@fleet/fleet-units/fleet-unit/fleet-unit.model';

import * as _ from 'lodash';

export class Condition {

  public static COLORS = {
    okay: '#00695c',
    maintenance: '#f9a825',
    warning: '#ff7818',
    danger: '#d52929',
    grey: '#aaaaaa',
    black: '#050407',
    blue: '#417ab7',
    okayOutdated: '#6fd0bc'
  };

  public static STATUS_MAPPING = {
    // grey = service toggled off
    'FLEET_VEHICLE_SERVICE': Condition.COLORS.grey,

    // black = undefined state
    'FLEET_VEHICLE_INACTIVE': Condition.COLORS.black,

    // sensor is outdated
    'SENSOR_DATA_OUT_DATED': Condition.COLORS.okayOutdated,

    // okays
    'OK': Condition.COLORS.okay,
    'PRESSURE_OK': Condition.COLORS.okay,
    'TEMPERATURE_OK': Condition.COLORS.okay,
    'CALIBRATION_NEEDED': Condition.COLORS.okay,
    'FLEET_VEHICLE_OKAY': Condition.COLORS.okay,
    'FLEET_VEHICLE_OK': Condition.COLORS.okay,
    'SENSOR_NUMBER_OUTDATED': Condition.COLORS.okay,

    // okay/outdated
    'FLEET_VEHICLE_OK_OUTDATED' : Condition.COLORS.okayOutdated,

    // warning
    'MAINTENANCE': Condition.COLORS.maintenance,
    'WARNING': Condition.COLORS.warning,
    'PRESSURE_HIGH': Condition.COLORS.warning,
    'PRESSURE_LOW': Condition.COLORS.warning,
    'SENSOR_CONNECTION_ERROR': Condition.COLORS.warning,
    'MISSING_SENSOR': Condition.COLORS.warning,
    'PRESSURE_MAINTENANCE': Condition.COLORS.maintenance,
    'PRESSURE_WARNING': Condition.COLORS.warning,
    'TEMPERATURE_WARNING': Condition.COLORS.warning,

    // danger state
    'DANGER': Condition.COLORS.danger,
    'PRESSURE_DANGER': Condition.COLORS.danger,
    'TEMPERATURE_DANGER': Condition.COLORS.danger,
    'WHEEL_LOSS': Condition.COLORS.danger,
  };

  // priority by desc order (higher index => higher priority)
  public static VALUE_MAPPING = [
    'FLEET_VEHICLE_SERVICE',
    'SENSOR_DATA_OUT_DATED',

    // okay
    'CALIBRATION_NEEDED',
    'FLEET_VEHICLE_OK',
    'FLEET_VEHICLE_OKAY',
    'OK',
    'PRESSURE_OK',
    'TEMPERATURE_OK',

    'FLEET_VEHICLE_OK_OUTDATED',

    // warning
    'MAINTENANCE',
    'MISSING_SENSOR',
    'PRESSURE_HIGH',
    'PRESSURE_LOW',
    'PRESSURE_MAINTENANCE',
    'PRESSURE_WARNING',
    'SENSOR_CONNECTION_ERROR',
    'TEMPERATURE_WARNING',
    'WARNING',

    'DANGER',
    'TEMPERATURE_DANGER',
    'PRESSURE_DANGER',
    'WHEEL_LOSS',
  ];

  public static getColor(fleetUnit: FleetUnit): string {
    if (fleetUnit && fleetUnit.unitStatus) {
      return Condition.getColorBy(fleetUnit.unitStatus);
    }
  }

  public static getColorBy(status: string): string {
    return Condition.STATUS_MAPPING[status];
  }

  public static getColorKeyBy(color: string): string {
    return _.findKey(Condition.COLORS, (value) => {
      return color === value;
    });
  }

  public static sort(status: string, compareStatus: string): number {
    return Condition.VALUE_MAPPING[status] - Condition.VALUE_MAPPING[compareStatus];
  }

  public static getWorstCaseStateBy(states: string[]): string {
    const candidates = _.intersection(Condition.VALUE_MAPPING, states);

    if (candidates.length > 0) {
      const values = _
        .chain(candidates)
        .uniq()
        .map(status => Condition.VALUE_MAPPING.indexOf(status))
        .value();

      const index = _.max(values);

      return Condition.VALUE_MAPPING[index] || 'OK';
    }

    return 'OK';
  }

  public static getWorstCaseColorBy(states: string[]): string {
    if (states) {
      const worstState = Condition.getWorstCaseStateBy(states);
      return Condition.getColorBy(worstState);
    }
  }
}
