import { Pipe, PipeTransform } from '@angular/core';

import { truncate } from 'lodash';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: any, length?: any): string {
    const options = length ? { length } : null;
    return truncate(value, options);
  }

}
