import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    CancelTpmsSensorUpdateResponse,
    MessageTpmsSensorLearningResponse,
    SensorEquipment
} from './sensor-equipment/sensor-equipment.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import * as _ from 'lodash';

@Injectable()
export class SensorEquipmentsService {
  private corePath = 'sensor-equipment';

  constructor(private http: HttpClient) {}

  getAll(): Observable<SensorEquipment[]> {
    return <Observable<SensorEquipment[]>>this.http.get(`${environment.apiUrl}/${this.corePath}/`);
  }

  getAllUnassigned(): Observable<SensorEquipment[]> {
    return <Observable<SensorEquipment[]>>this.http.get(`${environment.apiUrl}/${this.corePath}/no-vehicles`);
  }

  getBy(id: number): Observable<SensorEquipment> {
    return <Observable<SensorEquipment>>this.http.get(`${environment.apiUrl}/${this.corePath}/${id}`);
  }

  create(equipment: SensorEquipment): Observable<SensorEquipment> {
    return <Observable<SensorEquipment>>this.http
      .post(`${environment.apiUrl}/${this.corePath}/`, equipment);
  }

  update(equipment: SensorEquipment): Observable<SensorEquipment> {
    // const id = _.get(equipment, 'sensorEquipmentId');

    return <Observable<SensorEquipment>>this.http
      .put(`${environment.apiUrl}/${this.corePath}/`, equipment);
  }

  delete(equipment: SensorEquipment): Observable<boolean> {
    const id = _.get(equipment, 'sensorEquipmentId');
    return <Observable<boolean>>this.http.delete(`${environment.apiUrl}/${this.corePath}/${id}`);
  }

  trainSensorEquipment(equipment: SensorEquipment): Observable<MessageTpmsSensorLearningResponse> {
    return <Observable<MessageTpmsSensorLearningResponse>>this.http
      .put(`${environment.apiUrl}/${this.corePath}/${equipment.sensorEquipmentId}`, {});
  }

  cancelSensorUpdate(equipment: SensorEquipment): Observable<CancelTpmsSensorUpdateResponse> {
    return <Observable<CancelTpmsSensorUpdateResponse>>this.http
      .put(`${environment.apiUrl}/${this.corePath}/${equipment.sensorEquipmentId}/cancel-sensor-update`, {});
  }

}
