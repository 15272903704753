import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TabComponent } from './tab/tab.component';

import * as _ from 'lodash';

@Component({
    selector: 'app-tabset',
    templateUrl: 'tabset.component.html',
    styleUrls: ['tabset.component.scss']
})
export class TabsetComponent implements OnChanges {
    @Output() onActiveTab: EventEmitter<TabComponent> = new EventEmitter();
    @Input() index: any;

    public tabs: TabComponent[] = [];
    public selectedTab: TabComponent;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
      if (_.has(changes, 'index') && this.index) {
        const tab = _.find(this.tabs, { index: this.index });

        if (tab) {
          this.setTab(tab);
        }
      }
    }

    addTab(tab: TabComponent) {
        const me = this;

        if (me.tabs.length === 0) {
            tab.active = true;
            me.selectedTab = tab;
        }
        me.tabs.push(tab);
    }

    selectTab(tab: TabComponent) {
        const me = this;

        if (!tab.disabled) {
            me.setTab(tab);
            me.onActiveTab.emit(me.selectedTab);
        }
    }

    private setTab(tab: TabComponent) {
        const me = this;

        me.tabs.forEach((tab) => {
            tab.active = false;
        });
        tab.active = true;
        me.selectedTab = tab;

    }

}
