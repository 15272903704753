import {
    Badge,
    IdentifierNotificationCount,
    NotificationBadgeTooltipEntries,
    NotificationMap,
    NotificationWithComment
} from './notifications.model';

import * as _ from 'lodash';
import { UnitStatus } from '@fleet/fleet-units/fleet-unit/fleet-unit.model';

export const NOTIFICATIONS: NotificationMap = {
  UNKNOWN: {
    order: 0,
  },
  TIME: {
    keys: [
      'BOX_NOTIFICATION_NO_DATA_LESS_20',
      'BOX_NOTIFICATION_NO_DATA_MORE_20',
      'NO_CURRENT_DATE',
      'NO_DATA_SINCE_20_MINUTES',
      'OLD_STATUS',
      'OLD_STATUS_LEAK_ALERT',
      'OLD_STATUS_PRESSURE',
      'OLD_STATUS_TEMPERATURE',
      UnitStatus.sensorDataOutdated
    ],
    order: 1,
  },
  INFO: {
    keys: [
      'INITIALIZED_DATA',
      'PRESSURE_CALIBRATION',
      'PRESSURE_CALIBRATION_PENDING',
      UnitStatus.calibrationNeeded,
      UnitStatus.sensorNumberOutdated,
    ],
    order: 2,
  },
  DANGER: {
    keys: [
      'LEAKAGE_ALART_DANGER',
      'PRESSURE_LOW_NOTIFICATION',
      'PRESSURE_HIGH_NOTIFICATION',
      'TEMPERATURE_WARNING_NOTIFICATION',
      UnitStatus.temperatureDanger,
      UnitStatus.temperatureWarning,
      UnitStatus.pressureMaintenance,
      UnitStatus.pressureWarning,
      UnitStatus.pressureDanger
    ],
    order: 3,
  },
};

const OBSOLETE_NOTIFICATIONS = [
  'INITILIZED_DATA',
];

export const batchNotifications = (notifications: IdentifierNotificationCount[]): Badge[] => {
  return _
    .chain(notifications)
    .filter((notification) => {
      return _.indexOf(OBSOLETE_NOTIFICATIONS, _.get(notification, 'notification')) === -1;
    })
    .groupBy((notification) => {
      const candidate = _.findKey(NOTIFICATIONS, ({ keys }) => {
        return _.includes(keys, _.get(notification, 'notification'));
      });

      return candidate || 'UNKNOWN';
    })
    .map((notifications, code) => {
      return {
        notification: code,
        count: _.sumBy(notifications, 'count'),
      }
    })
    .sortBy(({ notification }) => {
      return _.get(NOTIFICATIONS, `${notification}.order`);
    })
    .value();
};

export const batchNotificationsWithComment = (notifications: NotificationWithComment[]): Badge[] => {
  return _
    .chain(notifications)
    .filter((notification) => {
      return _.indexOf(OBSOLETE_NOTIFICATIONS, _.get(notification, 'notification')) === -1;
    })
    .groupBy((notification) => {
      const candidate = _.findKey(NOTIFICATIONS, ({ keys }) => {
        return _.includes(keys, _.get(notification, 'notification'));
      });

      return candidate || 'UNKNOWN';
    })
    .map((notifications, code) => {
      return {
        notification: code,
        count: notifications.length,
        entries: notifications,
        tooltip: {
          code,
          entries: createBadgeTooltipEntries(notifications)
        },
      }
    })
    .filter(({ notification }) => {
      return notification !== 'UNKNOWN';
    })
    .sortBy(({ notification }) => {
      return _.get(NOTIFICATIONS, `${notification}.order`);
    })
    .value();
};

export const createBadgeTooltipEntries = (notifications: NotificationWithComment[]): NotificationBadgeTooltipEntries[] => {
  return _
    .chain(notifications)
    .groupBy((notification) => {
      return _.get(notification, 'notification');
    })
    .map((notifications, notification) => {
      const entries = _.reduce(notifications, (entries, { comment }) => {
        if (_.isEmpty(comment)) {
          entries.emptyComments += 1;
        } else {
          entries.comments.push(comment);
        }

        return entries;
      }, { comments: [], count: notifications.length, emptyComments: 0 });

      return { notification, entries };
    })
    .value();
};

export const getWorstNotificationType = (notifications: string[]): string => {
  return _
    .chain(notifications)
    .map((notification) => {
      return _.findKey(NOTIFICATIONS, ({ keys }) => {
        return _.includes(keys, notification);
      }) || 'UNKNOWN';
    })
    .sortBy((key) => {
      return _.get(NOTIFICATIONS, `${key}.order`);
    })
    .last()
    .value();
};
