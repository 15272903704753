import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { FleetVehicle } from '@depot/fleet-depot.model';
import { FMS_Entry, FMS_FleetVehicleProperty } from '@unit-state/vehicle-monitoring/vehicle-monitoring.model';

import { VehicleMonitoringService } from '@unit-state/vehicle-monitoring/vehicle-monitoring.service';
import {
    entriesToPropertySettings,
    prepareEntrySettings
} from '@unit-state/vehicle-monitoring/vehicle-monitoring.helper';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-vehicle-monitoring-property-settings',
  templateUrl: './vehicle-monitoring-property-settings.component.html',
  styleUrls: ['./vehicle-monitoring-property-settings.component.scss']
})
export class VehicleMonitoringPropertySettingsComponent implements OnInit, OnChanges {

  @Input() fleetVehicle: FleetVehicle;
  @Output() saved: EventEmitter<FMS_FleetVehicleProperty> = new EventEmitter<FMS_FleetVehicleProperty>();

  public property: FMS_FleetVehicleProperty;
  public fmsChunks: FMS_Entry[][];
  public obdiiChunks: FMS_Entry[][];

  constructor(public vehicleMonitoringService: VehicleMonitoringService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fleetVehicle) {
      if (_.has(this.fleetVehicle, 'fleetVehicleId')) {
        this.getFleetVehicleProperty();
      }
    }
  }

  private getFleetVehicleProperty() {
    if (_.has(this.fleetVehicle, 'fleetVehicleId')) {
      this.vehicleMonitoringService.getFleetVehicleProperty(this.fleetVehicle)
        .subscribe((property: FMS_FleetVehicleProperty) => {
          // console.log(property);
          this.property = property || {
            fleetVehiclePropertyId: undefined,
            fleetVehicleId: undefined,
            led: false,
            smsActive: false,
            ledActive: false,
          };

          this.fmsChunks = _.chunk(prepareEntrySettings(_.get(property, 'contentFms', {}), 'FMS'), 4) ;
          this.obdiiChunks = _.chunk(prepareEntrySettings(_.get(property, 'contentObdii', {}), 'OBD'), 4);
        }, (err) => {
          console.log(err);
        })
    }
  }

  toggleEntryProperty(entry: FMS_Entry) {
    console.log(entry);
    const selected = _.get(entry, 'selected', false);
    _.set(entry, 'selected', !selected);
  }

  saveSettings() {
    const property: FMS_FleetVehicleProperty = {
      fleetVehiclePropertyId: _.get(this.property, 'fleetVehiclePropertyId'),
      fleetVehicleId: _.get(this.fleetVehicle, 'fleetVehicleId'),
      contentFms: entriesToPropertySettings(_.flatten(this.fmsChunks)),
      contentObdii: entriesToPropertySettings(_.flatten(this.obdiiChunks)),
      smsActive: _.get(this.property, 'smsActive'),
      ledActive: _.get(this.property, 'ledActive')
    };

    if (_.has(this.property, 'ledActive')) {
      property.ledActive = this.property.ledActive;
    }

    this.vehicleMonitoringService.saveFleetVehicleProperty(property)
      .subscribe((property: FMS_FleetVehicleProperty) => {
        // console.log('saved FMS_FleetVehicleProperty', property);
        this.saved.emit(property);
      }, (err) => {
        console.log(err);
      })
  }

}
