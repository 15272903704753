import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';

import { VehicleGroup } from '@depot/fleet-depot.model';
import { FleetActions } from '@app/fleet/fleet.reducer';

import { FleetDepotService } from '@depot/fleet-depot.service';

import * as _ from 'lodash';

@Component({
  selector: 'fleet-depot-tree',
  templateUrl: './fleet-depot-tree.component.html',
  styleUrls: ['./fleet-depot-tree.component.scss']
})
export class FleetDepotTreeComponent implements OnInit, OnDestroy {
  @Input() group: VehicleGroup;
  @Input() clear = true;
  @Output() node = new EventEmitter();

  public graphSub: Subscription;
  public graph: VehicleGroup;

  public click: Function;

  constructor(private fleetDepot: FleetDepotService, private store: Store<any>) { }

  ngOnInit() {
    const that = this;

    this.click = ({ node }: { node: VehicleGroup }) => {
      that.group = node;
      that.node.emit(node);

      that.store.dispatch({
        type: FleetActions.SET_VEHICLE_GROUP_ID,
        payload: _.get(node, 'vehicleGroupId'),
      });
    };

    this.graphSub = this.fleetDepot
      .vehicleGroups
      .subscribe((vehicleGroups: VehicleGroup[]) => {
        this.graph = {
          vehicleGroupId: null,
          vehicleGroupName: null,
          type: null,
          level: null,
          mandator: null,
          childVehicleGroups: vehicleGroups,
        };
      });

    this.fleetDepot.load();
  }

  ngOnDestroy() {
    if (this.graphSub) {
      this.graphSub.unsubscribe();
    }

    if (this.clear) {
        this.fleetDepot.clear();
    }
  }
}
