import { catchError, finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ICredentials } from './interfaces/credentials';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

import { UserService } from '../../fleet/user/user.service';
import { get } from 'lodash';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';

declare let btoa;

@Injectable()
export class AuthService {
    public isLoggedIn = false;

    public isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public isLoggedInObservable: Observable<boolean> = this.isLoggedInSubject.asObservable();

    constructor(
        private userService: UserService,
        private http: HttpClient,
        private notification: NotificationsService,
        private router: Router,
        private translate: TranslateService
    ) {
        this.getCurrentUser().subscribe(
          (response) => {},
          (error) => {}
        );
    }

    getCurrentUser(): Observable<any> {
      return this.userService.getCurrentUser().pipe(
        map((response) => {
          this.isLoggedIn = get(response, 'status') === 200;
          this.isLoggedInSubject.next(this.isLoggedIn);

          return response;
        }),
        catchError((error: any) => {
          this.isLoggedIn = false;
          this.isLoggedInSubject.next(this.isLoggedIn);

          return throwError(error);
        }),
        finalize(() => {
          this.notification.remove();
        }), );
    }

    login(credentials?: ICredentials, rememberMe?: boolean): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded');

        const body = new HttpParams()
          .set('username', credentials.username)
          .set('password', credentials.password);

        return this.http.post(environment.apiUrl + '/login', body, { headers, observe: 'response' }).pipe(
          map((response) => {
            this.isLoggedIn = get(response, 'status') === 200;
            this.isLoggedInSubject.next(this.isLoggedIn);

            return true;
          }),
          finalize(() => {
            this.notification.remove();
          }), );
    }

    logout(): Observable<boolean> {
        this.isLoggedIn = false;
        this.isLoggedInSubject.next(this.isLoggedIn);

        return this.http.get(environment.apiUrl + '/logout', { observe: 'response' }).pipe(
          map((response) => {
            this.userService.clear();
            return response.status === 204;
          }),
          finalize(() => {
            this.notification.remove();
          }), );
    }

    setLoggedInState(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
      this.isLoggedInSubject.next(this.isLoggedIn);
    }
}
