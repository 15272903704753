import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FleetVehiclePendingCommandComponent } from '@depot/fleet-depot-vehicle/fleet-vehicle-pending-command/fleet-vehicle-pending-command.component';
import { FleetDepotTreeComponent } from '@depot/fleet-depot-tree/fleet-depot-tree.component';
import { GroupNodeComponent } from '@depot/group-node/group-node.component';
import { FleetVehicleEmailerSettingsService } from '@depot/fleet-depot-vehicle-wizard/fleet-vehicle-emailer-settings/fleet-vehicle-emailer-settings.service';
import { TyreService } from '@depot/tyre.service';
import { FleetVehicleTripHistoryService } from '@depot/fleet-vehicle-trip-history/fleet-vehicle-trip-history.service';
import { FleetVehicleMonitoringAnalysisService } from '@depot/fleet-vehicle-monitoring-analysis/fleet-vehicle-monitoring-analysis.service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  declarations: [
    GroupNodeComponent,
    FleetDepotTreeComponent,
    FleetVehiclePendingCommandComponent,
  ],
  exports: [
    GroupNodeComponent,
    FleetDepotTreeComponent,
    FleetVehiclePendingCommandComponent,
  ],
  providers: [
    FleetVehicleEmailerSettingsService,
    TyreService,
    FleetVehicleTripHistoryService,
    FleetVehicleMonitoringAnalysisService,
  ]
})
export class DepotSharedModule { }
