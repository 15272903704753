import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TireDetails } from '@unit-state/vehicle.model';
import { TireService } from '@shared/services/tire/tire.service';
import { getSrcPath } from '@configurator/vehicle-types/vehicle-types.helper';
import { VehicleService } from '@unit-state/vehicle.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { UserService } from '@user/user.service';

@Component({
  selector: 'ifms-vehicle-leak-rate-and-time-to-critical',
  templateUrl: './vehicle-leak-rate-and-time-to-critical.component.html',
  styleUrls: ['./vehicle-leak-rate-and-time-to-critical.component.scss']
})
export class VehicleLeakRateAndTimeToCriticalComponent implements OnInit, OnDestroy {

  @Input() vehicle: VehicleService;
  public tireDetails: TireDetails;
  public trailerImgSrc;
  public tractorImgSrc;
  private fileType = (navigator.appVersion.indexOf('MSIE 10') !== -1) ? 'png' : 'svg';
  public pressureUnit: string;
  private pressureUnitSub: Subscription;

  public tractorNoData = true;
  public trailerNoData =  true;
  public loaded: boolean;

  constructor(private tireService: TireService, private userService: UserService) { }

  ngOnInit() {
    this.pressureUnitSub = this.userService.pressureUnitSign
        .subscribe((pressureUnit: string) => {
          this.pressureUnit = pressureUnit;
        });


    this.loaded = false;
    this.vehicle.tireDetails.subscribe((data) => {
      this.tractorNoData = true;
      if (data !== null) {
        this.tireDetails = data;
        if (data.tractorTyresDetails === null) {
          this.tractorNoData = false;
        }

        _.forEach(this.tireDetails.tractorTyresDetails, (data) => {
          if (data.timeToCritical || data.pressureDropRate) {
            this.tractorNoData = false;
          }
        });

        if (data.trailerTyresDetails === null) {
          this.trailerNoData = false;
        }

        _.forEach(this.tireDetails.trailerTyresDetails, (data) => {
          if (data.timeToCritical || data.pressureDropRate) {
            this.trailerNoData = false;
          }
        });

        this.loaded = true;
      }
    });


    this.vehicle.tractorVehicle.subscribe((data) => {
      if (data && data.type !== '') {
        this.tractorImgSrc = getSrcPath(data.type, this.fileType);
      } else {
        this.tractorImgSrc = getSrcPath('TRUCK', this.fileType);
      }
    });

    this.vehicle.trailerVehicle.subscribe((data) => {
      if (data && data.type !== '') {
        this.trailerImgSrc = getSrcPath(data.type, this.fileType);
      } else {
        this.trailerImgSrc = getSrcPath('TRAILER', this.fileType);
      }
    });


  }

  ngOnDestroy() {
    if (this.pressureUnitSub) {
      this.pressureUnitSub.unsubscribe();
    }
  }
}
