import { Directive, ElementRef, HostListener, Input } from '@angular/core';

import * as _ from 'lodash';

const getKeyCodeFromKeydown = (event) => {
  let keyCode = event.keyCode;

  if (keyCode >= 96 && keyCode <= 105) {
      // Numpad keys
      keyCode -= 48;
  }

  if (keyCode === 108) {
      // Numpad keys
      keyCode = 188;
  }

  return keyCode;
};

@Directive({
  selector: '[ifmsOnlyNumber]'
})
export class OnlyNumberDirective {

  intRegexStr = '^[0-9]*$';
  floatRegexStr = '^[0-9]+(,|.)?([0-9])*$';

  @Input() ifmsOnlyNumber: boolean;
  @Input() float: number;
  @Input() ngModel: any;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
      const e = <KeyboardEvent> event;
      const keyCode = getKeyCodeFromKeydown(e);
      let ch = String.fromCharCode(keyCode);

      if (_.includes([8, 188, 190], keyCode) === false && /\d/.test(ch) === false) {
        e.preventDefault();
        return;
      }

      if (keyCode === 188 || keyCode === 190) {
        ch = '.';
      }

      if (ch === '.' && !this.float) {
        e.preventDefault();
        return;
      }

      let inputStr = _.isNumber(this.ngModel) ? this.ngModel + '' : this.ngModel;
      const hasDelimiter = _.includes(inputStr, ',') || _.includes(inputStr, '.');

      inputStr = hasDelimiter ? inputStr.replace(',', '.') : inputStr;
      const value = parseFloat(inputStr);

      console.log('value', this.ngModel, inputStr, value);

      if (_.isNaN(value)) {
        return;
      }

      if (this.ifmsOnlyNumber) {
        const valueStr = inputStr + ch;

        if (_.isNumber(this.float) && _.isNumber(value) && hasDelimiter) {
          if (_.filter(valueStr, c => c === '.').length > 1) {
            e.preventDefault();
            return;
          }

          if (keyCode === 188) {
            e.preventDefault();
            return;
          }

          const list = _.split(valueStr, '.');
          const restString = (list.length > 1) ? _.last(list) : null;

          if (keyCode !== 8 && restString && _.includes(restString, '0') && restString.length > this.float) {
            e.preventDefault();
            return;
          }

          const rest = (list.length > 1) ? parseInt(restString, 10) : 0;
          const leading = Math.pow(10, this.float);

          if (rest > leading) {
            e.preventDefault();
            return;
          }
        }

        if ([46, 8, 9, 27, 13, 110, 190].indexOf(keyCode) !== -1 ||
          // Allow: Ctrl+A
          (keyCode === 65 && e.ctrlKey === true) ||
          // Allow: Ctrl+C
          (keyCode === 67 && e.ctrlKey === true) ||
          // Allow: Ctrl+V
          (keyCode === 86 && e.ctrlKey === true) ||
          // Allow: Ctrl+X
          (keyCode === 88 && e.ctrlKey === true) ||
          // Allow: home, end, left, right
          (keyCode >= 35 && keyCode <= 39)) {
            // console.log('let it happen, dont do anything', keyCode);
            return;
        }

        const regEx = new RegExp(_.isNumber(this.float) ? this.floatRegexStr : this.intRegexStr);

        if (regEx.test(valueStr)) {
          return;
        } else {
          e.preventDefault();
        }
    }

  }

}
