import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
// import {CustomHttp} from "../../shared/interceptors/custom-http.interceptor";
import { HttpClient } from '@angular/common/http';

declare var window: any;

@Injectable()
export class PasswordRestService {

    constructor(
        private http: HttpClient
    ) {
    }

    resetPassword(username: string): Observable<any> {
        const data = {
            username: username,
            baseUrl: window.location.origin
        };

        return this.http.post(
            `${environment.apiUrl}/public/password/reset`,
            data
        );
    }

    setPassword(password: string, token: string): Observable<any> {
        const data = {
            password: password,
            token: token
        };

        return this.http.post(
            `${environment.apiUrl}/public/password/update`,
            data
        );
    }


}
