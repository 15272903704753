import { Component, OnDestroy, OnInit } from '@angular/core'
import { themes } from '@shared/services/host-config/themes';
import { HostConfigService } from '@shared/services/host-config/host-config.service';
import { ITheme } from '@shared/services/host-config/theme.interface';
import { UserService } from '@user/user.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'ifms-theme-switcher',
    templateUrl: './theme-switcher.component.html',
    styleUrls: ['./theme-switcher.component.scss']
})
export class ThemeSwitcherComponent implements OnInit, OnDestroy {
    public themes: any;
    public selectedTheme: ITheme;
    private storageKey: string;
    private usernameSub: Subscription;

    constructor(private hostConfigService: HostConfigService, private userService: UserService, private router: Router) {
        this.themes = themes;
        this.storageKey = 'theme_overwrite';
    }

    ngOnInit() {
        this.selectedTheme = this.hostConfigService.getSelectedTheme();

        this.usernameSub = this.userService.instance.pipe(
            filter(user => user !== null)
        ).subscribe((user) => {
            if (user.username !== 'ifms_admin') {
                this.router.navigateByUrl('/');
            }
        });
    }

    ngOnDestroy() {
        this.usernameSub.unsubscribe()
    }

    themeSelect($event) {
        this.hostConfigService.switchTheme($event);
    }
}
