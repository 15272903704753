import { Component, Input, OnInit } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';

import { VehicleGroup } from '@depot/fleet-depot.model';

@Component({
  selector: 'ifms-selectable-vehicle-group-tree',
  templateUrl: './selectable-vehicle-group-tree.component.html',
  styleUrls: ['./selectable-vehicle-group-tree.component.scss'],
  animations: [
    trigger('rotate', [
      state('default', style({ transform: 'rotate(-90deg)' })),
      state('rotated', style({ transform: 'rotate(0deg)' })),
      transition('default => rotated', animate('200ms ease-in')),
      transition('rotated => default', animate('200ms ease-out')),
    ]),
    trigger('shrink', [
      state('default', style({ height: 0, display: 'none' })),
      state('rotated', style({ height: '*' })),
      transition('default => rotated', [
        animate(200, style({ height: '*' }))
      ]),
      transition('rotated => default', [
        animate(200, style({ height: 0 }))
      ])
    ]),
  ]
})
export class SelectableVehicleGroupTreeComponent implements OnInit {

  @Input() parent: VehicleGroup;
  @Input() node: VehicleGroup;
  @Input() open: boolean;
  @Input() click: Function;
  @Input() disabled: boolean;


  public state = 'default';

  constructor() { }

  ngOnInit() {
    this.state = 'rotated';

    // if(this.open) {
    //   this.rotate();
    // }
  }

  rotate() {
      this.state = (this.state === 'default' ? 'rotated' : 'default');
  }

  clickNode() {
    if (!this.disabled) {
      this.node.hasAccess = !this.node.hasAccess;
    }

    // this.open = this.open ? !this.open : true;
    // this.rotate();

    if (this.click) {
      this.click({
        node: this.node,
        parent: this.parent
      });
    }
  }
}
