import { combineLatest as observableCombineLatest, Observable, of } from 'rxjs';

import { catchError, filter, finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { FleetUnitsState, FleetUnitsTree } from './fleet-units.model';
import { FleetUnit } from './fleet-unit/fleet-unit.model';
import { FleetUnitsActions } from './fleet-units.reducer';
import { VehicleGroup } from '@depot/fleet-depot.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { flattenFleetUnitTree, treefyFleetUnits } from './fleet-units.helper';
import * as _ from 'lodash';

@Injectable()
export class FleetUnitsService {

  public state: Observable<FleetUnitsState>;
  public units: Observable<FleetUnit[]>;
  public vehicleGroup: Observable<VehicleGroup>;
  public fleetUnitsTree: Observable<FleetUnitsTree[]>;
  public groupedFleetUnits: Observable<FleetUnit[][]>;

  public loadingFleetUnits = false;

  private fleetUnitPath = `${environment.apiUrl}/fleetUnits/`;

  public minimalRoleEditPermission = 'ROLE_FM_DISPATCHER';

  public showConfirmToggleServiceModal = false;

  constructor(private store: Store<any>, private http: HttpClient) {
    this.state = this.store.select('fleetUnits');

    this.units = this.state.pipe(
      map((state: FleetUnitsState) => {
        if (state) {
          return state.units;
        }
      }));

    this.vehicleGroup = this.state.pipe(
      map((state: FleetUnitsState) => {
        if (state) {
          return state.vehicleGroup;
        }
      }));

    this.fleetUnitsTree = observableCombineLatest(
        this.vehicleGroup.pipe(filter(vehicleGroup => _.isPlainObject(vehicleGroup))),
        this.units.pipe(filter(fleetUnits => _.isArray(fleetUnits)))
      ).pipe(
      map(([vehicleGroup, units]) => {
        return treefyFleetUnits(vehicleGroup, units);
      }));

    this.groupedFleetUnits = this.fleetUnitsTree.pipe(
      map((fleetUnitsTree: FleetUnitsTree[]) => {
        if (fleetUnitsTree) {
          return flattenFleetUnitTree(_.first(fleetUnitsTree));
        }
      }));
  }

  clear() {
    this.store.dispatch({
      type: FleetUnitsActions.SET_DEFAULT,
    });
  }

  setVehicleGroup(vehicleGroup: VehicleGroup) {
    this.store.dispatch({
      type: FleetUnitsActions.SET_VEHICLE_GROUP,
      payload: vehicleGroup
    });
  }

  getFleetUnits(vehicleGroupId: number, withLoadingIndicator?: boolean): Observable<FleetUnit[]> {
    withLoadingIndicator = _.isBoolean(withLoadingIndicator) ? withLoadingIndicator : false;

    let loadingTrigger;

    if (withLoadingIndicator) {
      this.store.dispatch({
        type: FleetUnitsActions.FLEET_UNITS_LOADED,
        payload: []
      });

      loadingTrigger = setTimeout(() => {
        this.loadingFleetUnits = true;
      }, 200);
    }

    return <Observable<FleetUnit[]>>this.http.get(`${environment.apiUrl}/vehiclegroups/${vehicleGroupId}/fleetUnits/?recursive=true`).pipe(
      map((units: FleetUnit[]) => {
        this.store.dispatch({
          type: FleetUnitsActions.FLEET_UNITS_LOADED,
          payload: units
        });

        return units;
      }),
      finalize(() => {
        clearTimeout(loadingTrigger);
        this.loadingFleetUnits = false;
      }),
      catchError((err) => {
        return of(err);
      }), );
  }

  getFleetUnit(fleetUnitId: number): Observable<FleetUnit> {
    return <Observable<FleetUnit>>this.http.get(`${this.fleetUnitPath}${fleetUnitId}`)
  }

  createFleetUnit(tractorId: number, trailerId: number): Observable<FleetUnit> {
    const body = { tractorId, trailerId };
    const url = `${this.fleetUnitPath}`;

    return <Observable<FleetUnit>>this.http.post(url, body)
  }

  updateFleetUnit(fleetUnitId: number, tractorId: number, trailerId: number): Observable<FleetUnit> {
    const body = { fleetUnitId, tractorId, trailerId };
    const url = `${this.fleetUnitPath}${fleetUnitId}`;

    return <Observable<FleetUnit>>this.http.put(url, body)
  }

  deleteFleetUnit(fleetUnitId: number): Observable<boolean> {
    const url = `${this.fleetUnitPath}${fleetUnitId}`;

    return <Observable<boolean>>this.http.delete(url)
  }

  returnFromService(fleetUnitId: number) {
      return this.http.post(`${environment.apiUrl}/fleetUnits/${fleetUnitId}/return-from-service`, {});
  }

  setActive(fleetUnitId: number) {
      return this.http.post(`${environment.apiUrl}/fleetUnits/${fleetUnitId}/set-active`, {});
  }

  setInService(fleetUnitId: number) {
      return this.http.post(`${environment.apiUrl}/fleetUnits/${fleetUnitId}/set-in-service`, {});
  }

  setInactive(fleetUnitId: number) {
      return this.http.post(`${environment.apiUrl}/fleetUnits/${fleetUnitId}/set-inactive`, {});
  }

    isInService(fleetUnit: FleetUnit): boolean {
        if (fleetUnit.unitStatus === 'FLEET_VEHICLE_INACTIVE') {
            return _.some(fleetUnit.notificationList, {'notification': 'FLEET_VEHICLE_SERVICE'});
        } else {
            return fleetUnit.unitStatus === 'FLEET_VEHICLE_SERVICE';
        }
    }

    toggleServiceState(fleetUnit: FleetUnit) {
        let request;

        if (this.isInService(fleetUnit)) {
            request = this.returnFromService(fleetUnit.fleetUnitId);
        } else {
            request = this.setInService(fleetUnit.fleetUnitId);
        }
        request.subscribe(() => {
            this.getFleetUnit(fleetUnit.fleetUnitId)
                .subscribe((updatedFleetUnit) => {
                    fleetUnit.unitStatus = updatedFleetUnit.unitStatus;
                    fleetUnit.notificationList = updatedFleetUnit.notificationList;
                });
        });
    }
}
