import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, flatMap, map, mergeMap, take } from 'rxjs/operators';

import { FleetVehicle } from '@depot/fleet-depot.model';
import { FleetVehicleMonitoringAnalysisActions } from './fleet-vehicle-monitoring-analysis.reducer';
import { FleetVehicleMonitoringAnalysisState } from './fleet-vehicle-monitoring-analysis.model';
import {
    CanBusDataFilter,
    EntryDataset,
    FMS_Data,
    FMS_Entry,
    FMS_FleetVehicleProperty
} from '@unit-state/vehicle-monitoring/vehicle-monitoring.model';

import { VehicleMonitoringService } from '@unit-state/vehicle-monitoring/vehicle-monitoring.service';

import * as _ from 'lodash';

@Injectable()
export class FleetVehicleMonitoringAnalysisService {

  public state: Observable<FleetVehicleMonitoringAnalysisState>;

  public fleetVehicle: Observable<FleetVehicle>;

  public property: Observable<FMS_FleetVehicleProperty>;
  public characteristics: Observable<string[]>;

  public canBusData: Observable<FMS_Data>;
  public entries: Observable<FMS_Entry[]>;

  public characteristicEntries: Observable<FMS_Entry[]>;

  constructor(private store: Store<any>,
              private monitoring: VehicleMonitoringService) {
    this.state = this.store.select('fleetVehicleMonitoringAnalysis');

    this.fleetVehicle = this.state.pipe(
      map((state) => {
        return _.get(state, 'fleetVehicle');
      }));

    this.property = this.state.pipe(
      map((state) => {
        return _.get(state, 'property');
      }));

    this.characteristics = this.property.pipe(
      map((property: FMS_FleetVehicleProperty) => {
        if (property) {
          const fmsEntities = _.get(property, 'contentFms');
          const obdiiEntities = _.get(property, 'contentObdii');

          return _
            .chain(_.assign({}, fmsEntities, obdiiEntities))
            .map((show, entity) => {
              return show ? entity : null;
            })
            .filter(_.isString)
            .value();
        }
      }));

    this.canBusData = this.state.pipe(
      map((state) => {
        return _.get(state, 'canBusData');
      }));

    this.entries = this.state.pipe(
      map((state) => {
        return _.get(state, 'entries');
      }));

    this.characteristicEntries = combineLatest(
      this.characteristics
        .pipe(
          distinctUntilChanged((previous, current) => { return _.difference(current, previous).length === 0; })
        ),
      this.entries
        .pipe(
          filter(_.isArray)
        ),
      (characteristics, entries) => {
        return _.filter(entries, (entry: FMS_Entry) => {
          const key = _.get(entry, 'artifact.key');
          return _.includes(characteristics, _.get(entry, 'artifact.key'));
        });
      }
    )

  }

  setFleetVehicle(fleetVehicle: FleetVehicle) {
    this.store.dispatch({
      type: FleetVehicleMonitoringAnalysisActions.SET_FLEET_VEHICLE,
      payload: fleetVehicle,
    });

    this.getProperty();
    this.getActiveCanBusData();
  }

  clear() {
    this.store.dispatch({
      type: FleetVehicleMonitoringAnalysisActions.SET_DEFAULT,
    });
  }

  getAllCanBusDataBy(options: { entries: FMS_Entry[], fromDate?: Date, toDate?: Date }): Observable<EntryDataset[]> {
    return this.fleetVehicle.pipe(
      map(fleetVehicle => _.get(fleetVehicle, 'fleetVehicleId')),
      take(1),
      flatMap((fleetVehicleId: number) => {
        const entries = <FMS_Entry[]>_.get(options, 'entries', []);

        const filter: CanBusDataFilter = {
          fleetVehicleId,
          before: _.get(options, 'fromDate'),
          after: _.get(options, 'toDate'),
        };

        if (_.isDate(filter.before)) {
          filter.before = filter.before.valueOf();
        }

        if (_.isDate(filter.after)) {
          filter.after = filter.after.valueOf();
        }

        return forkJoin(_.map(entries, (entry) => { return this.getCanBusHistoryBy(filter, entry); } ))
          .pipe(
            map((values) => {
              return _.map(entries, (entry, index) => {
                return { entry, values: _.get(values, `${index}.values`, []) };
              });
            })
          );
      })
    )
  }

  getCanBusHistoryBy(filter: CanBusDataFilter, entry: FMS_Entry): Observable<{ values: any }> {
    return this.monitoring.getHistoryBy('can-bus-data', filter, entry);
  }

  private getProperty() {
    this.fleetVehicle.pipe(
      take(1),
      mergeMap((fleetVehicle: FleetVehicle) => {
        if (_.has(fleetVehicle, 'fleetVehicleId') === false) {
          return of(null);
        } else {
          return this.monitoring.getFleetVehicleProperty(fleetVehicle)
        }
      }), )
      .subscribe((property: FMS_FleetVehicleProperty) => {
        this.store.dispatch({
          type: FleetVehicleMonitoringAnalysisActions.SET_FLEET_PROPERTY,
          payload: property,
        });
      }, (err) => {
        console.log(err);
      })
  }

  private getActiveCanBusData() {
    this.fleetVehicle.pipe(
      take(1),
      mergeMap((fleetVehicle: FleetVehicle) => {
        if (_.has(fleetVehicle, 'fleetVehicleId') === false) {
          return of(null);
        } else {
          return this.monitoring.getActiveCanBusData(fleetVehicle)
        }
      }), )
      .subscribe((canBusData: FMS_Data) => {
        this.store.dispatch({
          type: FleetVehicleMonitoringAnalysisActions.SET_CAN_BUS_DATA,
          payload: canBusData,
        });
      }, (err) => {
        console.log(err);
      })
  }

}
