import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SensorEquipment } from '../sensor-equipment/sensor-equipment.model';

import { ViewportService } from '@shared/services/viewport.service';

@Component({
  selector: 'ifms-sensor-equipments-list',
  templateUrl: './sensor-equipments-list.component.html',
  styleUrls: ['./sensor-equipments-list.component.scss']
})
export class SensorEquipmentsListComponent implements OnInit {

  @Input() equipments: SensorEquipment[];
  @Output() selected: EventEmitter<SensorEquipment> = new EventEmitter();

  constructor(public viewportService: ViewportService) { }

  ngOnInit() {
  }

}
