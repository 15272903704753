import { Injectable } from '@angular/core';

import * as _ from 'lodash';

@Injectable()
export class FaviconsService {

  private elementId: string;
  private favIconPath = '/assets/fav-icons/';

  constructor() {
    this.elementId = 'favicons-service-injected-node';
  }

  setFavicon(filename: string) {
    if (filename) {
        this.removeExternalLinkElements();
        this.setNode('image/x-icon', filename);
    }
  }

  private removeExternalLinkElements(): void {
      const linkElements = document.querySelectorAll('link[ rel ~= \'icon\' i]');

      _.forEach(linkElements, (linkElement: any) => {
          linkElement.parentNode.removeChild(linkElement);
      });
  }

  private addNode(type: string, href: string): void {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('id', this.elementId);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('href', this.favIconPath + href);
    document.head.appendChild(linkElement);
  }

  private setNode(type: string, href: string): void {
    this.removeNode();
    this.addNode(type, href);
  }

  private removeNode(): void {
    const linkElement = document.head.querySelector('#' + this.elementId);

    if (linkElement) {
        document.head.removeChild(linkElement);
    }
  }

}
