import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FleetStore } from './fleet.store';
import { SharedModule } from '@shared/shared.module';
import { FleetSharedModule } from './shared/fleet-shared.module';
import { HardwareSharedModule } from '@hardware/shared/hardware-shared.module';
import { FleetUnitStatesOverviewModule } from './fleet-overview/fleet-overview.module';
import { MandatorModule } from './mandator/mandator.module';
import { UnitStateModule } from './unit-state/unit-state.module';
import { FleetComponent } from './fleet.component';
import { VehicleService } from '@unit-state/vehicle.service';
import { FleetService } from './fleet.service';
import { RouterModule } from '@angular/router';
import { PersonService } from '@fleet/person/person.service';

import { MandatorService } from './mandator/mandator.service';
import { N4translateService } from './n4translate/n4translate.service';
import { UserManagementService } from './user-management/user-management.service';
import { UserComponent } from '@user/user.component';

import { SettingsComponent } from './settings/settings.component';
import { N4translateComponent } from './n4translate/n4translate.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { SupportComponent } from '../support/support.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserListComponent } from './user-management/user-list/user-list.component';
import { SelectableVehicleGroupTreeComponent } from '@user/selectable-vehicle-group-tree/selectable-vehicle-group-tree.component';
import { MandatorManagementComponent } from './mandator-management/mandator-management.component';
import { PersonComponent } from './person/person.component';
import { PersonFormInputContentComponent } from './person/person-form-input-content/person-form-input-content.component';
import { ChangeUserPasswordComponent } from '@user/change-user-password/change-user-password.component';
import { UsersVehicleGroupsComponent } from '@user/users-vehicle-groups/users-vehicle-groups.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DepotSharedModule } from '@depot/shared/fleet-depot-shared.module';
import { FleetDepotService } from '@depot/fleet-depot.service';
import { VehicleConfiguratorService } from '@configurator/vehicle-configurator.service';
import { FleetUnitsService } from '@fleet/fleet-units/fleet-units.service';
import { MandatorChildListComponent } from './mandator-management/mandator-child-list/mandator-child-list.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    FleetComponent,
    UserComponent,
    PageNotFoundComponent,
    SupportComponent,
    SettingsComponent,
    N4translateComponent,
    UserManagementComponent,
    UserListComponent,
    SelectableVehicleGroupTreeComponent,
    MandatorManagementComponent,
    PersonComponent,
    PersonFormInputContentComponent,
    ChangeUserPasswordComponent,
    UsersVehicleGroupsComponent,
    MandatorChildListComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        FleetSharedModule,
        RouterModule,
        FleetStore,
        FleetUnitStatesOverviewModule,
        HardwareSharedModule.forRoot(),
        MandatorModule,
        UnitStateModule,
        AngularSvgIconModule.forRoot(),
        DepotSharedModule,
        NgbTypeaheadModule,
    ],
    exports: [
        HardwareSharedModule,
    ],
  providers: [
    FleetService,
    FleetDepotService,
    MandatorService,
    N4translateService,
    PersonService,
    VehicleService,
    VehicleConfiguratorService,
    UserManagementService,
    FleetUnitsService
  ]
})
export class FleetModule {
}
