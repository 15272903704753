import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import * as ol from 'openlayers';
import { Layer } from '../openlayers.helper';

@Component({
  selector: 'openlayers-map',
  templateUrl: './openlayers-map.component.html',
  styleUrls: ['./openlayers-map.component.scss']
})
export class OpenlayersMapComponent implements OnInit {

  @ViewChild('map', { static: true }) mapTarget: ElementRef;

  private map: ol.Map;
  private markers: Layer;
  // private markersSource: ol.source.Vector;
  // private markers: ol.source.Vector[] = [];

  constructor() { }

  ngOnInit() {
    this.initMap();
    this.zoomToCurrentLocation();
  }

  private initMap() {
    this.map = new ol.Map({
      target: this.mapTarget.nativeElement,
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM()
        })
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat([37.41, 8.82]),
        zoom: 4
      })
    });

    this.markers = new Layer(this.map);

    this.markers.setStyle(new ol.style.Style({
      image: new ol.style.Icon({
        src: '/assets/images/truck.png'
      })
    }));

  }

  private zoomToCurrentLocation() {
    navigator.geolocation.getCurrentPosition((pos) => {
      const coords = ol.proj.fromLonLat([pos.coords.longitude, pos.coords.latitude]);
      this.map.getView().animate({ center: coords, zoom: 10 });
      this.setMark(coords);
    });
  }

  private setMark(coords: any) {
    this.markers.add(coords);

    // setTimeout(() => {
    //   this.markers.clear();
    // }, 3000);
  }

}
