import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FleetSharedModule } from '../shared/fleet-shared.module';

import { FleetOverviewComponent } from '@fleet/fleet-overview/fleet-overview.component';
import { FleetUnitsFilterPanelComponent } from '@fleet/fleet-overview/fleet-units-filter-panel/fleet-units-filter-panel.component';
import { FleetUnitsListingComponent } from '@fleet/fleet-overview/fleet-units-listing/fleet-units-listing.component';
import { FleetUnitNotificationIconComponent } from '@fleet/fleet-units/fleet-unit/fleet-unit-notifications/fleet-unit-notification-icon/fleet-unit-notification-icon.component';
import { FleetUnitNotificationsComponent } from '@fleet/fleet-units/fleet-unit/fleet-unit-notifications/fleet-unit-notifications.component';
import { RecentDriveUnitComponent } from '@fleet/fleet-overview/recent-drive-unit/recent-drive-unit.component';

import { FleetOverviewService } from '@fleet/fleet-overview/fleet-overview.service';
import { FleetOverviewSearchFiltersComponent } from '@fleet/fleet-overview/fleet-overview-search-filters/fleet-overview-search-filters.component';
import { MultiselectDropdownComponent } from './fleet-overview-search-filters/multiselect-dropdown/multiselect-dropdown.component';
import { CheckboxComponent } from './fleet-overview-search-filters/checkbox/checkbox.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FleetSharedModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [
    FleetOverviewComponent,
    FleetUnitsFilterPanelComponent,
    FleetUnitsListingComponent,
    RecentDriveUnitComponent,

    // TODO: share fleet unit related components
    FleetUnitNotificationIconComponent,
    FleetUnitNotificationsComponent,
    FleetOverviewSearchFiltersComponent,
    MultiselectDropdownComponent,
    CheckboxComponent,
  ],
    exports: [
        FleetOverviewComponent,
        FleetUnitsFilterPanelComponent,
        FleetUnitsListingComponent,
        RecentDriveUnitComponent,

        // TODO: share fleet unit related components
        FleetUnitNotificationIconComponent,
        FleetUnitNotificationsComponent,
    ],
  providers: [
    FleetOverviewService,
  ]
})
export class FleetUnitStatesOverviewModule {
  constructor (
    @Optional() @SkipSelf() parentModule: FleetUnitStatesOverviewModule
  ) {
    if (parentModule) {
      throw new Error('FleetUnitStatesOverviewModule is already loaded. Import only in FleetModule');
    }
  }
}
