import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UserService } from '@user/user.service';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-vehicle-calibration',
  templateUrl: './vehicle-calibration.component.html',
  styleUrls: ['./vehicle-calibration.component.scss']
})
export class VehicleCalibrationComponent implements OnInit {

  @Input() axlePositions: any;
  @Input() tires: any;
  @Input() tpmsSensors: any;
  @Input() axleThreshold: any;

  @Output() calibrateBySettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveThresholdSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  public settings: any = {
    tractor: [],
    trailer: []
  };

  public thresholdSettings: any = {
    fleetUnitId: 0,
    tractor: {},
    trailer: {}
  };

  public invalidSettings = false;

  constructor(public userService: UserService) { }

  ngOnInit() {
  }

  save(action?: string) {
    this.saveSettings.emit(_.assign({ action}, this.settings));
  }

  saveThresholds() {
    this.thresholdSettings.fleetUnitId = this.axleThreshold.fleetUnitId;
    this.saveThresholdSettings.emit(Object.assign({}, this.thresholdSettings));
  }

  validate() {
    const invalidTire = _
      .chain([...this.settings.tractor, ...this.settings.trailer])
      .flatten()
      .find((tire) => {
        const pressureSetpoint = tire.pressureSetpoint;
        return _.isNumber(pressureSetpoint) === false || _.isNaN(pressureSetpoint);
      })
      .value();

    this.invalidSettings = _.isUndefined(invalidTire) === false;
  }
}
