import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

import { AxlePositionMapping } from '@configurator//vehicle-configurator.model';
import { ThresholdSettings } from '../../parts/sensor/sensor.model';
import { Tire } from '../../parts/tire/tire.model';
import { TpmsSensor } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';

import { round } from './vehicle-calibration.helper';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-vehicle-calibration-axles',
  templateUrl: './vehicle-calibration-axles.component.html',
  styleUrls: ['./vehicle-calibration-axles.component.scss'],
})
export class VehicleCalibrationAxlesComponent implements OnInit {

  @Input() axlePositions: AxlePositionMapping[];
  @Input() tires: Tire[];
  @Input() tpmsSensors: TpmsSensor[];
  @Input() pressureUnitSign: string;
  @Input() axleThreshold: any;

  @Output() settingsChange: EventEmitter<ThresholdSettings[]> = new EventEmitter<ThresholdSettings[]>();
  @Output() thresholdSettingsChange: EventEmitter<ThresholdSettings[]> = new EventEmitter<ThresholdSettings[]>();

  public settings: ThresholdSettings[];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tiresChanged(changes) || changes.tpmsSensors || changes.axleThreshold) {
      this.update();
      this.emitThresholdSettings();
    }
  }

  emitSettings() {
    setTimeout(() => {
      const tireSettings = this.getAllTiresSettings();
      this.settingsChange.emit(tireSettings);
    }, 0);
  }

  getPressureMaxValue() {
    if (this.pressureUnitSign == 'psi') {
      return 189;
    } else if (this.pressureUnitSign == 'kPa') {
      return 1300;
    } else {
      return 13;
    }
  }

  private tiresChanged(changes: SimpleChanges): boolean {
    if (_.get(changes, 'tires.firstChange')) {
      return true;
    }

    const previous = this.getTireIds(_.get(changes, 'tires.previousValue'));
    const current = this.getTireIds(_.get(changes, 'tires.currentValue'));

    return previous.length !== current.length &&
           _.intersection(previous, current).length !== current.length;
  }

  private getTireIds(tires: Tire[]): number[] {
    return _
      .chain(tires)
      .flatten()
      .filter(tire => tire)
      .map('tyreId')
      .value();
  }

  private update() {
    this.settings = [];

    this.settings = _.map(this.tires, (axleTires, index) => {
      if (axleTires) {
        axleTires = _.filter(axleTires, tire => tire);

        const tire = _.first(axleTires);
        if (tire) {
          const tpmsSensorId = _.get(tire, 'tpmsSensor.tpmsSensorId');
          const sensor = _.get(this.tpmsSensors, tpmsSensorId);

          return {
            tpmsSensorId,
            pressureSetpoint: this.trimPressureSetpointByUnitSign(sensor),
            type: _.get(sensor, 'type')
          };
        }
      }

      return {};
    });

    this.emitSettings();
  }

  private trimPressureSetpointByUnitSign(sensor: ThresholdSettings): number {
    const value: number = _.get(sensor, 'pressureSetpoint', 0);

    if(this.pressureUnitSign === 'bar' || this.pressureUnitSign === 'kPa') {
      return round(value, 1);
    }

    if (this.pressureUnitSign === 'psi') {
      return Math.floor(value);
    }

    return value;
  }

  private getAllTiresSettings(): ThresholdSettings[] {
    return _
      .chain(this.tires)
      .map((axleTires, index) => {
        if (axleTires) {
          axleTires = _.filter(axleTires, tire => tire);
          const settings = _.get(this.settings, index);

          return _.map(axleTires, (tire) => {
            return {
              tpmsSensorId: _.get(tire , 'tpmsSensor.tpmsSensorId'),
              pressureSetpoint: _.get(settings, 'pressureSetpoint'),
            };
          });
        }

        return [];
      })
      .flatten()
      .filter(tire => tire)
      .map((tire) => {
        if (_.isString(tire.pressureSetpoint)) {
          tire.pressureSetpoint = parseFloat(tire.pressureSetpoint);
        }

        return tire;
      })
      .value();
  }

  emitThresholdSettings() {
    this.thresholdSettingsChange.emit(this.axleThreshold);
  }

  updateThresholdValue($event, position, thresholdType) {
    this.axleThreshold.axleThresholds[position][thresholdType] = parseFloat($event);
  }
}
