import { FMS_Artifact } from './vehicle-monitoring.model';

export const ARTIFACTS: FMS_Artifact[] = [
  { id: 'ACCEL',                   source: 'FMS', key: 'accel',                     active: true,  sortIndex: 1,  image: 'accel.svg',     font: 'icon-meter-fast' },
  { id: 'BRAKESWITCH',             source: 'FMS', key: 'brakeswitch',               active: true, sortIndex: 2,   image: 'brakes.svg',    font: 'icon-stopwatch' },
  { id: 'CRUISE',                  source: 'FMS', key: 'cruisectrl',                active: true, sortIndex: 3,   image: 'cruise.png',    font: 'icon-question-circle-o' },
  { id: 'DIST',                    source: 'FMS', key: 'totalVehicleDistance',      active: true, sortIndex: 4,   image: 'dist.svg',      font: 'icon-meter3', unit: 'km', unitFactor: 0.001, floatPrecision: 1 },
  { id: 'FUEL',                    source: 'FMS', key: 'fuelLevel',                 active: true,  sortIndex: 5,  image: 'fuel.svg',      font: 'icon-gas' },
  { id: 'FUEL_1',                  source: 'FMS', key: 'totalVehicleFuel',          active: true,  sortIndex: 6,  image: 'fuel.svg',      font: 'icon-gas' },
  { id: 'MAINTANCE',               source: 'FMS', key: 'maintenance',               active: true,  sortIndex: 7,  image: 'maintance.svg', font: 'icon-tools' },
  { id: 'PTO',                     source: 'FMS', key: 'powerTakeOff',              active: true,  sortIndex: 8,  image: 'pto.svg',       font: 'icon-question-circle-o' },
  { id: 'RPM',                     source: 'FMS', key: 'engineSpeed',               active: true,  sortIndex: 9,  image: 'rpm.svg',       font: 'icon-meter-slow' },
  { id: 'SPEED',                   source: 'FMS', key: 'vehicleSpeedWheelbased',    active: true,  sortIndex: 10, image: 'speed.svg',     font: 'icon-meter-medium', unit: 'km/h', floatPrecision: 0},
  { id: 'TEMP',                    source: 'FMS', key: 'engineTemperature',         active: true,  sortIndex: 11, image: 'temp.svg',      font: 'icon-thermometer-2' },
  { id: 'VEHICLEID',               source: 'FMS', key: 'vehicleIdentificationNr',   active: true,  sortIndex: 12, image: 'vehicle.svg',   font: 'icon-truck' },
  { id: 'VERSION',                 source: 'FMS', key: 'version',                   active: true,  sortIndex: 13, image: 'version.svg',   font: 'icon-question-circle-o' },
  { id: 'WEIGHT',                  source: 'FMS', key: 'axleWeight',                active: true,  sortIndex: 14, image: 'weight.svg',    font: 'icon-scale' },

  { id: 'OIL_ENGINE_TEMP',         source: 'OBD', key: 'engineOilTemperature',      active: true,  sortIndex: 15, image: 'weight.svg',    font: 'icon-oil-pressure' },
  { id: 'ENGINE_RPM',              source: 'OBD', key: 'engineRPM',                 active: true,  sortIndex: 16, image: 'weight.svg',    font: 'icon-meter-fast' },
  { id: 'ABS_THROTTLE_POSITION_B', source: 'OBD', key: 'absoluteThrottlePositionB', active: true,  sortIndex: 17, image: 'weight.svg',    font: 'icon-question-circle-o' },
  { id: 'VEHICLE_SPEED',           source: 'OBD', key: 'vehicleSpeed',              active: true,  sortIndex: 18, image: 'weight.svg',    font: 'icon-meter-medium' },
  { id: 'CALCULATED_ENGINE_LOAD',  source: 'OBD', key: 'calculatedEngineLoad',      active: true,  sortIndex: 19, image: 'weight.svg',    font: 'icon-question-circle-o' },
  { id: 'FUEL_TANK_INPUT',         source: 'OBD', key: 'fuelTankLevelInput',        active: true,  sortIndex: 20, image: 'weight.svg',    font: 'icon-meter3' },
  { id: 'ENGINE_COOLANT_TEMP',     source: 'OBD', key: 'engineCoolantTemperature',  active: true,  sortIndex: 21, image: 'weight.svg',    font: 'icon-coolant-temperature' },
  { id: 'RUN_TIME_ENGINE_START',   source: 'OBD', key: 'runTimeSinceEngineStart',   active: true,  sortIndex: 22, image: 'weight.svg',    font: 'icon-stopwatch' },
  { id: 'ENGINE_FUEL_RATE',        source: 'OBD', key: 'engineFuelRate',            active: true,  sortIndex: 23, image: 'weight.svg',    font: 'icon-gas' },
  { id: 'AMBIENT_AIR_TEMP',        source: 'OBD', key: 'ambientAirTemperature',     active: true,  sortIndex: 24, image: 'weight.svg',    font: 'icon-thermometer-2' },
];
