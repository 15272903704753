import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FleetOverviewSearchFiltersService {

  public data = [];
  private filters = new BehaviorSubject(null);

  constructor(private http: HttpClient, private translate: TranslateService) { }

  initData() {
    this.data = [];
    const arr = [];
    arr.push(this.loadFleetData());
    arr.push(this.loadVehicleGroupData());

    forkJoin(arr).subscribe((data: any) => {
      data.forEach((d) => {
        this.data.push(d);
        this.getSelectedDataAsList(d.name);
      });

      const inactiveCheckboxLabel = this.getTitleTranslation('FLEET.OVERVIEW.FILTERS.CHECKBOXES.INACTIVE');
      const serviceCheckboxLabel = this.getTitleTranslation('FLEET.OVERVIEW.FILTERS.CHECKBOXES.SERVICE');

      this.addCheckbox({'name': 'inactive', 'type': 'checkbox', 'selected': false, 'label': inactiveCheckboxLabel, 'order': 4});
      this.addCheckbox({'name': 'service', 'type': 'checkbox', 'selected': false, 'label': serviceCheckboxLabel, 'order': 3});

      this.data = _.orderBy(this.data, ['order'], ['asc']);

      this.setFilters();
    });
  }

  private addCheckbox(data) {
    const checkboxes = this.getFromLocalStorage('checkboxes');
    if (checkboxes) {
      data.selected = checkboxes[data.name];
    }
    this.data.push(data);
  }


  private loadVehicleGroupData() {
    const key = 'vehicleGroupIdList';

    return this.http.get(`${environment.apiUrl}/vehiclegroups/all-vehicle-groups`).pipe(
      map((data) => {
        let selectedIDs = [];

        if (this.getFromLocalStorage(key) !== undefined) {
          selectedIDs = this.getFromLocalStorage(key);
        }

        const vehicleGroupData = [];
        _.forEach(data, (vehicleGroup: any) => {
          let selected = false;
          if (_.includes(selectedIDs, vehicleGroup.vehicleGroupId)) {
            selected = true;
          }

          vehicleGroupData.push({'id': vehicleGroup.vehicleGroupId, 'name': vehicleGroup.vehicleGroupName, 'selected': selected});
        });

        return {'name': key, 'type': 'multi-dropdown', 'data': vehicleGroupData, 'selectedIDs': selectedIDs, 'title': this.getTitleTranslation('FLEET.OVERVIEW.MULTI_DROPDOWN.VEHICLE_GROUP.TITLE'), 'order': 2};

      })
    );
  }

  private loadFleetData() {
    const key = 'mandatorIdList';

    return this.http.get(`${environment.apiUrl}/mandators/`).pipe(
      map((data) => {
        let selectedIDs = [];

        if (this.getFromLocalStorage(key) !== undefined) {
          selectedIDs = this.getFromLocalStorage(key);
        }

        const fleetData = [];
        _.forEach(data, (mandator: any) => {
          let selected = false;
          if (_.includes(selectedIDs, mandator.mandatorId)) {
            selected = true;
          }

          fleetData.push({'id': mandator.mandatorId, 'name': mandator.mandatorName, 'selected': selected});
        });

        return {'name': key, 'type': 'multi-dropdown', 'data': fleetData, 'selectedIDs': selectedIDs, 'title': this.getTitleTranslation('FLEET.OVERVIEW.MULTI_DROPDOWN.MANDATORS.TITLE'), 'order': 1}
      })
    )
  }

  setData(event) {
      console.log('event', event);console.log('this.data', this.data);
    const data = _.find(this.data, {name: event.key});
    const dataCurrent = _.find(data['data'], {id: event.id});

    if (dataCurrent.selected) {
      dataCurrent.selected = false;
      data['selectedIDs'].splice(_.indexOf(data['selectedIDs'], event.id), 1);
    } else {
      dataCurrent.selected = true;
      console.log('data', data);
      data['selectedIDs'].push(event.id);
    }

    this.getSelectedDataAsList(event.key);
    this.addToLocalStorage(event.key, data['selectedIDs']);
  }

  remove(key) {
    const dataFromData = _.find(this.data, {name: key});
    dataFromData.selectedIDs = [];
    _.forEach(dataFromData.data, (data) => {
      data.selected = false;
    });

    this.getSelectedDataAsList(dataFromData.name);
    this.addToLocalStorage(dataFromData.name, []);
  }

  removeAll() {
      _.forEach(this.data, (data) => {
         data.selectedIDs = [];
         data.selected = false;
         _.forEach(data.data, (innerData) => {
            innerData.selected = false;
         });
         this.getSelectedDataAsList(data.name);
      });
      localStorage.removeItem('filters');
  }

  getSelectedDataAsList(key) {
    const dataFromData = _.find(this.data, {name: key});

    let listArr = _.map(dataFromData.data, (data) => {
      if (data.selected) {
        return data.name;
      }
    });

    listArr = _.filter(listArr, Boolean);

    if (listArr.length > 0) {
      dataFromData['selectedDataList'] = listArr.join(' | ');
    } else {
      dataFromData['selectedDataList'] = '';
    }
  }

  private addToLocalStorage(name: string, data) {
    if (name && data) {

      let filtersFromStorage = this.getFromLocalStorage();
      if (!filtersFromStorage) {
        filtersFromStorage = {};
      }

      filtersFromStorage[name] = data;
      localStorage.setItem('filters', JSON.stringify(filtersFromStorage));
    }
  }

  private getFromLocalStorage(name?) {
    const filtersFromStorage = JSON.parse(localStorage.getItem('filters'));
    if (name) {
      if (filtersFromStorage && filtersFromStorage[name]) {
        return filtersFromStorage[name];
      }
    } else {
      if (filtersFromStorage) {
        return filtersFromStorage;
      }
    }
  }

  private getTitleTranslation(key) {
    let translate = '';
    this.translate.get(key).subscribe((data) => {
      translate = data;
    });

    return translate;
  }

  setCheckbox(event) {
    const data = _.find(this.data, {name: event.name});
    let checkboxes = this.getFromLocalStorage('checkboxes');

    if (data.selected) {
      data.selected = false;
      checkboxes[event.name] = event.value;
    } else {
      data.selected = true;
    }

    if (!checkboxes) {
      checkboxes = {};
    }

    checkboxes[event.name] = event.value;
    this.addToLocalStorage('checkboxes', checkboxes);
  }

  public setFilters() {
    const filter = {};
    _.forEach(this.data, (data) => {
      if (data.type === 'multi-dropdown') {
        if (data.selectedIDs && data.selectedIDs.length > 0) {
          filter[data.name] = data.selectedIDs;
        } else {
          filter[data.name] = null;
        }
      }

      if (data.type === 'checkbox') {
        filter[data.name] = data.selected;
      }
    });

    this.filters.next(filter);
  }

  public getFilters() {
    return this.filters;
  }

  public applyFilters() {
    this.setFilters();
  }
}
