import { Tire } from './parts/tire/tire.model';
import { FleetUnit } from '../fleet-units/fleet-unit/fleet-unit.model';
import { AxlePositionMapping, VehicleTemplate } from '@configurator/vehicle-configurator.model';
import { UnitCalibrationSettings } from './vehicle.model';

import * as _ from 'lodash';

export const FLEET_UNIT_REFRESH_MILLISECONDS = 20000;

export interface TireMapping {
  axlePositionMap: AxlePositionMapping;
  tires: Tire[];
}

export const getIfmsBoxIdsOfFleetUnit = (fleetUnit: FleetUnit) => {
  if (fleetUnit) {
    const tractorIfmsBoxId = _.get(fleetUnit, 'tractor.ifmsBox.ifmsBoxId');
    const trailerIfmsBoxId = _.get(fleetUnit, 'trailer.ifmsBox.ifmsBoxId');

    return _
      .chain([tractorIfmsBoxId, trailerIfmsBoxId])
      .filter(_.isNumber)
      .value();
  }
};

const getAttributeOfTire = (tire: Tire, key: string) => {
  if (_.has(tire, key)) {
    return tire[key];
  }

  if (_.has(tire, `tpmsSensor.${key}`)) {
    return tire.tpmsSensor[key];
  }
};

export const prepareTirePositions = (tires: Tire[]): Tire[] => {
  const tirePositions = tires.map(tire => getAttributeOfTire(tire, 'position'));

  return ['a', 'b', 'c', 'd'].map((position) => {
    const tireIndex = tirePositions.indexOf(position);

    if (tireIndex !== -1) {
      return tires[tireIndex];
    } else {
      return null;
    }
  });
};

export const getTireMapping = (vehicle: VehicleTemplate, allTires: Tire[]): TireMapping[] => {
  if (_.has(vehicle, 'axleConfig.axlePositionMaps') && allTires) {
    const axlePositionMaps = vehicle.axleConfig.axlePositionMaps;

    let currentTireAxlePosition = 1;

    return axlePositionMaps.map((axlePositionMap: AxlePositionMapping) => {
      let tires: Tire[] = [];

      if (axlePositionMap.axle.type !== 'FILLER') {
        tires = allTires.filter((tire) => {
          return currentTireAxlePosition === getAttributeOfTire(tire, 'axlePosition');
        });

        currentTireAxlePosition += 1;
      }

      const axle = {
        axlePositionMap,
        tires: prepareTirePositions(tires)
      };

      return axle;
    });
  }

  return [];
};

export const getTiresByIndex = (tireMappings: TireMapping[], index: number): Tire[] => {
  if (tireMappings && index < tireMappings.length) {
    return tireMappings[index].tires;
  }

  return [null, null, null, null];
};

export const getCalibrationRequestBodiesByFleetUnit = (fleetUnit: FleetUnit, settings: UnitCalibrationSettings) => {
  const tractor = _.get(fleetUnit, 'tractor');
  const tractorHasSensorEquipment = _.has(tractor, 'sensorEquipment.sensorEquipmentId');
  const tractorHasIfmsBox = _.has(tractor, 'ifmsBox.ifmsBoxId');

  const trailer = _.get(fleetUnit, 'trailer');
  const trailerHasSensorEquipment = _.has(trailer, 'sensorEquipment.sensorEquipmentId');
  const trailerHasIfmsBox = _.has(trailer, 'ifmsBox.ifmsBoxId');

  // console.log(tractor, trailer);
  // console.log('tractorHasIfmsBox', tractorHasIfmsBox);
  // console.log('trailerHasIfmsBox', trailerHasIfmsBox);

  const requestBodies = [];

  if (tractorHasIfmsBox || trailerHasIfmsBox) {
    const tractorSensorEquipments = _.assign({}, _.get(tractor, 'sensorEquipment'), { tpmsSensors: settings.tractor });
    const trailerSensorEquipments = _.assign({}, _.get(trailer, 'sensorEquipment'), { tpmsSensors: settings.trailer });

    if (tractorHasIfmsBox) {
      if (trailerHasIfmsBox) {
        if (tractorHasSensorEquipment) {
          requestBodies.push({
            ifmsBoxId: _.get(tractor, 'ifmsBox.ifmsBoxId'),
            sensorEquipmentsList: [tractorSensorEquipments],
          });
        }

        if (trailerHasSensorEquipment) {
          requestBodies.push({
            ifmsBoxId: _.get(trailer, 'ifmsBox.ifmsBoxId'),
            sensorEquipmentsList: [trailerSensorEquipments],
          });
        }
      } else {
        const sensorEquipmentsList = [];
        if (tractorHasSensorEquipment) { sensorEquipmentsList.push(tractorSensorEquipments); }
        if (trailerHasSensorEquipment) { sensorEquipmentsList.push(trailerSensorEquipments); }

        requestBodies.push({
          ifmsBoxId: _.get(tractor, 'ifmsBox.ifmsBoxId'),
          sensorEquipmentsList
        });
      }
    } else if (trailerHasIfmsBox) {
      const body = {
        ifmsBoxId: _.get(trailer, 'ifmsBox.ifmsBoxId'),
        sensorEquipmentsList: [],
      };

      if (tractorHasSensorEquipment) {
        body.sensorEquipmentsList.push(tractorSensorEquipments);
      }

      if (trailerHasSensorEquipment) {
        body.sensorEquipmentsList.push(trailerSensorEquipments);
      }

      if (body.sensorEquipmentsList.length > 0) {
        requestBodies.push(body);
      }
    }

  }

  return requestBodies;
};
