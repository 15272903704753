import { ITheme } from '@shared/services/host-config/theme.interface';

export const themes: ITheme[] = [
    {
        name: 'default',
        title: 'IFMS',
        branding: 'TyreWatch',
        brandText: '',
        logo: 'assets/images/ifms-logo.png',
        theme: null,
        favIcon: 'favicon-ifms.ico',
        showSupportedByTyrewatchLogo: false,
    },
    {
        name: 'tyrewatch',
        title: 'TyreWatch',
        branding: 'TyreWatch',
        logo: 'assets/images/tyre-watch.png',
        theme: 'tyrewatch-ifmscloud-ci',
    },
    {
        name: 'michelin',
        title: 'MichelinSolutions',
        branding: 'MichelinSolutions',
        logo: 'assets/images/michelin-solutions.png',
        theme: 'michelin-ifmscloud-ci',
    },
    {
        name: 'giti',
        title: 'Giti',
        branding: 'Giti',
        logo: 'assets/images/giti.png',
        theme: 'giti-ifmscloud-ci',
    },
    {
        name: 'hankook',
        title: 'Hankook',
        branding: 'Hankook',
        brandText: 'Powered by Tyrewatch',
        logo: 'assets/images/hankook.png',
        theme: 'hankook-ifmscloud-ci',
        showSupportedByTyrewatchLogo: true,
    },
    {
        name: 'vaculug',
        title: 'VaculugFleetManagement',
        branding: 'Vaculug',
        logo: 'assets/images/vaculug.png',
        theme: 'vaculug-ifmscloud-ci',
    },
    {
        name: 'vaculugt',
        title: 'VaculugTechnologies',
        branding: 'Vaculugt',
        logo: 'assets/images/vaculugt.png',
        theme: 'vaculugt-ifmscloud-ci',
    },
    {
        name: 'valculug',
        title: 'ValculugFleetManagement',
        branding: 'Valculug',
        logo: 'assets/images/vaculug.png',
        theme: 'valculug-ifmscloud-ci',
    },
    {
        name: 'sentyre',
        title: 'Sentyre',
        branding: 'Sentyre',
        logo: 'assets/images/sentyre.svg',
        theme: 'sentyre-ci',
        favIcon: 'favicon-sentyre.ico',
    },
    {
        name: 'profile-nl',
        title: 'Profile',
        branding: 'Profile',
        logo: 'assets/images/profile-nl.svg',
        theme: 'profile-nl-ci',
        favIcon: 'favicon-profile-nl.ico',
    },
    {
        name: 'tyrehero',
        title: 'Tyrehero',
        branding: 'Tyrehero',
        logo: 'assets/images/tyrehero.jpg',
        theme: 'tyrehero-ci',
        favIcon: 'favicon-tyrehero.ico',
    },
    {
        name: 'prometeon',
        title: 'Prometeon',
        branding: 'Prometeon',
        logo: 'assets/images/prometeon.svg',
        theme: 'prometeon-ci',
        favIcon: 'favicon-prometeon.ico',
    }
];
