import { Mandator, MandatorCredentials } from './mandator.model';

import * as _ from 'lodash';

export const getAllGroupsAsList = (mandator: Mandator): Mandator[] => {
  let mandators = [mandator];

  for (let i = 0; i < mandator.childMandators.length; i += 1) {
    const child = mandator.childMandators[i];
    mandators = mandators.concat(getAllGroupsAsList(child));
  }

  return mandators;
};

export const mapMandatorsByIds = (mandator: Mandator): { [x: number]: Mandator; } => {
  if (mandator) {
    return _.keyBy(getAllGroupsAsList(mandator), 'mandatorId')
  }
};

export const mandatorToCredentials = (mandator: Mandator): MandatorCredentials => {
  return {
    mandatorId: _.get(mandator, 'mandatorId', null),
    mandatorName: _.get(mandator, 'mandatorName', null),
    mailserver: _.get(mandator, 'mailserver', null),
    mainContactPerson: null,
    mapActive: _.get(mandator, 'mapActive', true),
  };
};

export const findParent = (root: Mandator, mandatorId: number, parent: Mandator = root): Mandator => {
  for (let i = 0; i < root.childMandators.length; i += 1) {
    const mandator = root.childMandators[i];

    if (mandator.mandatorId === mandatorId) {
      return parent;
    } else {
      const candidate = findParent(mandator, mandatorId, mandator);
      if (candidate) { return candidate; }
    }
  }

  return null;
};

export const createMandatorCredentials = (mandator: MandatorCredentials, parent: Mandator): MandatorCredentials => {
  return _.assign(
    { name: mandator.mandatorName, parentMandatorId: _.get(parent, 'mandatorId') },
    _.pick(mandator, ['mandatorId', 'mainContactPerson', 'mailserver', 'mapActive'])
  );
};

export const findMandatorById = (root: Mandator, mandatorId: number): Mandator => {
  if (!root || !mandatorId) { return null; }

  if (_.has(root, 'mandatorId')) {
    if (root.mandatorId === mandatorId) {
      return root;
    }

    if (root.childMandators && root.childMandators.length > 0) {
      for (let i = 0; i < root.childMandators.length; i += 1) {
        const mandator = findMandatorById(root.childMandators[i], mandatorId);
        if (mandator) { return mandator; }
      }
    }
  }

  return null;
};
