import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Mandator, MandatorCredentials } from './mandator.model';
import { Person } from '@fleet/person/person.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { mandatorToCredentials, mapMandatorsByIds } from './mandator.helper';

import * as _ from 'lodash';

@Injectable()
export class MandatorService {

  constructor(private http: HttpClient) { }

  getGraph(mandatorId: number): Observable<Mandator> {
    return <Observable<Mandator>>this.http.get(`${environment.apiUrl}/mandators/${mandatorId}`)
  }

  getMapByIds(mandator: Mandator): { [mandatorId: number]: Mandator} {
    return mapMandatorsByIds(mandator);
  }

  saveMandator(mandator: MandatorCredentials): Observable<MandatorCredentials> {
    return <Observable<MandatorCredentials>>this.http.post(`${environment.apiUrl}/mandators/`, mandator)
  }

  getMandatorCredentials(mandator: Mandator): Observable<MandatorCredentials> {
    const personId = _.get(mandator, 'mainContactPersonId');
    return this.getPerson(personId).pipe(
      map((mainContactPerson: Person) => {
        return _.assign({}, mandatorToCredentials(mandator), { mainContactPerson });
      })
    );
  }

  getPerson(personId: number): Observable<Person> {
    return <Observable<Person>>this.http.get(`${environment.apiUrl}/person/${personId}`)
  }

}
