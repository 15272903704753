import { FleetUnitsState } from './fleet-units.model';

const defaultState: FleetUnitsState = {
  units: [],
  vehicleGroup: null,
};

export class FleetUnitsActions {
  public static SET_DEFAULT = 'FLEET_UNITS-SET_DEFAULT';

  public static SET_VEHICLE_GROUP = 'FLEET_UNITS-SET_VEHICLE_GROUP';

  public static FLEET_UNITS_LOADED = 'FLEET_UNITS-FLEET_UNITS_LOADED';
}

export function fleetUnitsReducer (state: FleetUnitsState = defaultState, { type, payload }) {
  switch (type) {
    case <string>FleetUnitsActions.SET_DEFAULT:
      return Object.assign({}, defaultState);

    case <string>FleetUnitsActions.SET_VEHICLE_GROUP:
      return Object.assign({}, state, { vehicleGroup: payload });

    case <string>FleetUnitsActions.FLEET_UNITS_LOADED:
      return Object.assign({}, state, { units: payload });

    default:
      return state;
  }
}
