import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IfmsBox } from '../ifms-box/ifms-box.model';

import { ViewportService } from '@shared/services/viewport.service';

@Component({
  selector: 'ifms-boxes-list',
  templateUrl: './ifms-boxes-list.component.html',
  styleUrls: ['./ifms-boxes-list.component.scss']
})
export class IfmsBoxesListComponent implements OnInit {

  @Input() boxes: IfmsBox[];
  @Output() selected: EventEmitter<IfmsBox> = new EventEmitter();

  constructor(public viewportService: ViewportService) { }

  ngOnInit() {
  }

}
