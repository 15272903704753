import { Pipe, PipeTransform } from '@angular/core';

declare const moment: any;

@Pipe({
  name: 'utcToLocalDate'
})
export class UtcToLocalDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const stillUtc = moment.utc(value).toDate();
    return moment(stillUtc).local().toDate();
  }

}
