import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

declare let document;

@Component({
    selector: 'app-searchable-dropdown',
    templateUrl: './searchable-dropdown.component.html',
    styleUrls: ['./searchable-dropdown.component.scss'],
    host: {
        '(document:click)': 'onOutsideClick($event)',
    }
})
export class SearchableDropdownComponent implements OnInit, OnChanges {
    @ViewChild('searchField', { static: false }) searchFieldInput: ElementRef;
    @Output() onItemSelect: EventEmitter<(string | number)> = new EventEmitter();
    @Input() items: any[];
    @Input() preSelectedItem: (string | number);
    @Input() placeholderText: string;
    @Input() dropdownDisabled: boolean;
    @Input() tabIndex: number;
    public filteredItems: any[];
    public showItems = false;
    public selectedItem: any = ' ';
    public showLabel = true;

    private keyNavIndex = 0;
    private searchInputValue: string;

    constructor(
        private _eref: ElementRef
    ) {
    }

    ngOnInit() {
        this.filteredItems = this.items;

        if (this.preSelectedItem) {
            this.selectedItem = this.preSelectedItem;
        }
    }

    ngOnChanges(changes: any) {
        if (changes.placeholderText && !changes.placeholderText.firstChange) {
            this.placeholderText = changes.placeholderText.currentValue;
        }

        if (changes.preSelectedItem && !changes.preSelectedItem.firstChange) {
            // needed to reset selected item
            if (!changes.preSelectedItem.currentValue) {
                this.selectedItem = undefined;
            }
        }

        if (changes.items && !changes.items.firstChange) {
            this.searchInputValue = '';
            this.selectedItem = '';
            this.onEscape();
        }
    }

    public onSearch(event: any) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            return;
        }

        if (!this.searchInputValue) {
            this.filteredItems = this.items;
            return;
        }

        this.filteredItems = [];

        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].toString().toLowerCase().indexOf(this.searchInputValue.toLowerCase()) > -1) {
                this.filteredItems.push(this.items[i]);
            }
        }
        this.keyNavIndex = 0;
    }

    public onEnter() {
        if (this.filteredItems.length > 0) {
            this.onSelectItem(this.filteredItems[this.keyNavIndex]);
        }
    }

    public onEscape() {
        this.filteredItems = this.items;
        this.showLabel = true;
        this.showItems = false;
        this.keyNavIndex = 0;
    }

    public onSelectItem(item: any) {
        this.searchInputValue = '';
        this.selectedItem = item;
        this.showItems = false;
        this.showLabel = true;
        this.keyNavIndex = 0;
        this.onItemSelect.emit(item);
    }

    public onLabelClick() {
        if (this.dropdownDisabled) {return; }

        this.showLabel = false;
        this.showItems = true;
        this.filteredItems = this.items;

        // we need a small timeout because the element we want is not instant visible
        setTimeout(() => {
            if (this.searchFieldInput && this.searchFieldInput.nativeElement) {
                this.searchFieldInput.nativeElement.focus();
            }
        }, 200);
    }

    public onArrowDown() {
        if (this.keyNavIndex < this.filteredItems.length) {
            this.keyNavIndex++;
        }
    }

    public onArrowUp() {
        if (this.keyNavIndex > 0) {
            this.keyNavIndex--;
        }
    }

    public onOutsideClick(event: any) {
        if (!this._eref.nativeElement.contains(event.target) && this.showLabel) {
            this.showItems = false;
        }
    }



}
