import { Component, Input, OnInit } from '@angular/core';

import { CommandPending } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';

@Component({
  selector: 'ifms-fleet-vehicle-pending-command',
  templateUrl: './fleet-vehicle-pending-command.component.html',
  styleUrls: ['./fleet-vehicle-pending-command.component.scss']
})
export class FleetVehiclePendingCommandComponent implements OnInit {

  @Input() command: CommandPending;

  constructor() { }

  ngOnInit() {
  }

}
