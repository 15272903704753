import { UserState } from './user.model';

const defaultState: UserState = {
  user: null,
};

export class UserActions {
  public static SET_DEFAULT = 'USER-SET_DEFAULT';
  public static USER_LOADED = 'USER-DEPOT_LOADED';
}

export function userReducer (state: UserState = defaultState, { type, payload }) {
  switch (type) {
    case <string>UserActions.SET_DEFAULT:
      return Object.assign({}, defaultState);
    case <string>UserActions.USER_LOADED:
      return Object.assign({}, state, { user: payload });
    default:
      return state;
  }
}
