import { filter, finalize } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { PressureUnit } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';
import { User } from '@user/user.model';

import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@user/user.service';
import { TpmsSensorService } from '@hardware/sensor-equipments/sensor-equipment/tpms-sensor/tpms-sensor.service';
import { NotificationsService } from 'angular2-notifications';

import * as _ from 'lodash';
import { N4translateService } from '@fleet/n4translate/n4translate.service';

@Component({
  selector: 'ifms-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  public profile: { languageCode: string; } = {
    languageCode: null
  };

  public availableLanguageCodes: string[] = [ 'en', 'de'];
  public user: User;

  private subscriptions: Subscription[] = [];
  public onRequest = false;

  public pressureUnitSign: string;
  public pressureUnits: PressureUnit[];
  public pressureUnitsSigns: string[];

  constructor(public userService: UserService,
              private notification: NotificationsService,
              private tpmsSensorService: TpmsSensorService,
              private translate: TranslateService,
              private n4translateService: N4translateService) { }

  ngOnInit() {

    this.n4translateService.getTranslationConfigKeys().subscribe((data) => {
       if (data !== null && data !== undefined) {
           this.availableLanguageCodes = data;
       }
    });

    const userSub = this.userService
      .instance.pipe(
      filter(user => _.isNull(user) === false))
      .subscribe((user: User) => {
        this.user = user;

        this.profile.languageCode = _.get(user, 'person.locale', this.translate.currentLang);
        this.pressureUnitSign = _.get(user, 'person.pressureUnit.sign', 'bar');
      });

    this.subscriptions.push(userSub);

    this.tpmsSensorService.getPressureUnits()
      .subscribe((pressureUnits: PressureUnit[]) => {
        this.pressureUnits = pressureUnits;
        this.pressureUnitsSigns = _.map(this.pressureUnits, 'sign');
      });
  }

  ngOnDestroy() {
    _.forEach(this.subscriptions, subscription => subscription.unsubscribe());
  }

  onLanguageCodeSelection(value: string) {
    this.onRequest = true;
    this.userService.setLocale(value).pipe(finalize(() => {
        this.onRequest = false;
    }))
      // .delay(500)
      .subscribe(() => {
        localStorage.setItem('userSelectedLanguage', value);
        this.notification.remove();
        this.notification.success('Success', 'Language preference has been set up!');
      });
  }

  onPressureUnitSelection(sign: string) {
    const pressureUnit = _.find(this.pressureUnits, { sign });

    if (pressureUnit) {
      this.onRequest = true;

      this.userService.setPressureUnit(pressureUnit)
      .pipe(finalize(() => {
          this.onRequest = false;
      }))
      .subscribe(() => {
        this.notification.remove();
        this.notification.success('Success', 'Pressure unit preference has been set up!');
      });
    }
  }

  private reset() {

  }

}
