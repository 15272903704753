import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { menuData } from '@app/header/menu/menu';
import { IMenu } from '@app/header/menu/menu.interface';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ifms-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
    public menuData: IMenu[];
    public user: User;
    private userSub: Subscription;
    private subMenusToggled = [];

    @Output() mobileMenuToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    private static setVhForMobile() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    constructor(private userService: UserService) {
        // Solution from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        MenuComponent.setVhForMobile();
        window.addEventListener('resize', () => {
            MenuComponent.setVhForMobile();
        });
        // End solution
    }

    ngOnInit() {
      this.userSub = this.userService.instance.subscribe((user) => {
        this.user = user;
      });

      this.menuData = menuData;
    }

    ngOnDestroy() {
      if (this.userSub) {
        this.userSub.unsubscribe();
      }
    }

    public toggleSubMenu(i) {
        this.subMenusToggled[i] = !this.subMenusToggled[i];
    }

    public toggleMobileMenu() {
        this.mobileMenuToggle.next(true);
    }

}
