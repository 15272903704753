import { Component, Input, OnInit } from '@angular/core';
import { TabsetComponent } from '../tabset.component';

@Component({
    selector: 'app-tab',
    templateUrl: 'tab.component.html',
    styleUrls: ['tab.component.scss']
})
export class TabComponent implements OnInit {
    @Input() disabled: boolean;
    @Input() private tabTitle: string;
    @Input() private index: any;

    public active = false;

    constructor(tabs: TabsetComponent) {
        tabs.addTab(this);
    }

    ngOnInit() {
    }

}
