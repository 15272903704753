import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

declare let window;

@Injectable()
export class ViewportService {
    windowWidth: number = window.innerWidth;
    windowHeight: number = window.innerHeight;

    public widthSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    public width: Observable<number> = this.widthSubject.asObservable();

    constructor() {

    }

    setWidth(width: number) {
      this.windowWidth = width;
      this.widthSubject.next(this.windowWidth);
    }

    public isMobile() {
        return this.windowWidth < 992;
    }

    public isWindowWidthLowerThan(mark: number) {
        return this.windowWidth < mark;
    }
}
