import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from '../fleet/user/user.service';

import { FormsModule } from '@angular/forms';
import { PasswordForgottenComponent } from './password-forgotten/password-forgotten.component';
import { PasswordRestService } from './services/password.rest.service';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LogoutComponent } from '@app/auth/logout/logout.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        TranslateModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'en-US' },
        AuthService,
        UserService,
        PasswordRestService,
    ],
    declarations: [
        AuthComponent,
        LoginComponent,
        PasswordForgottenComponent,
        ResetPasswordComponent,
        LogoutComponent
    ],
    exports: [AuthComponent]
})
export class AuthModule {
}
