import { combineLatest as observableCombineLatest, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { delay, map, take } from 'rxjs/operators';

import { FleetDepotActions } from '../fleet-depot/fleet-depot.reducer';
import { Mandator } from '@fleet//mandator/mandator.model';
import { Person } from '@fleet/person/person.model';
import { PressureUnit } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';
import { VehicleGroup } from '@depot/fleet-depot.model';
import { User, UserState } from './user.model';
import { UserActions } from './user.reducer';

import { TranslateService } from '@ngx-translate/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import * as _ from 'lodash';
import { HostConfigService } from '@shared/services/host-config/host-config.service';

@Injectable()
export class UserService {
  public static ROLES = [
    'ROLE_SUPER_ADMIN',
    'ROLE_FM_SUPER_ADMIN',
    'ROLE_FM_ADMIN',
    'ROLE_FM_DISPATCHER',
    'ROLE_FM_USER',
  ];

  public state: Observable<UserState>;
  public instance: Observable<User>;

  public isUser: Observable<boolean>;
  public isAdmin: Observable<boolean>;
  public isSuperAdmin: Observable<boolean>;

  public pressureUnitSign: Observable<string>;

  constructor(private http: HttpClient,
              private store: Store<any>,
              private translate: TranslateService) {
    this.state = this.store.select('user');

    this.instance = this.state.pipe(
      map((state: UserState) => {
        if (state) {
          return state.user;
        }
      }));

    this.isUser = this.instance.pipe(
        map((user: User) => {
          if (user) {
            return this.hasRole(user.roles, 'ROLE_FM_USER');
          }

          return false;
        }));

    this.isAdmin = this.instance.pipe(
      map((user: User) => {
        if (user) {
          return this.hasRole(user.roles, 'ROLE_FM_ADMIN');
        }

        return false;
      }));

    this.isSuperAdmin = this.instance.pipe(
      map((user: User) => {
        if (user) {
          return this.hasRole(user.roles, 'ROLE_SUPER_ADMIN');
        }

        return false;
      }));

    this.pressureUnitSign = this.instance.pipe(
      map((user: User) => {
        return _.get(user, 'person.pressureUnit.sign');
      }));
  }

  setUser(user: User) {
    const lang = _.get(user, 'person.locale');

    localStorage.setItem('userSelectedLanguage', lang);

    if (lang) {
      this.translate.use(lang);
    }

    this.store.dispatch({
      type: UserActions.USER_LOADED,
      payload: user
    });
  }

  getCurrentUser() {
    return this.http.get(`${environment.apiUrl}/users/current`, { observe: 'response' }).pipe(
      map((res: HttpResponse<User>) => {
        const user: User = <User>_.get(res, 'body');

        if (user) {
          this.setUser(user);
        }

        return res;
      }));
  }

  private hasRole(roles: string[], role): boolean {
    return roles.indexOf(role) !== -1;
  }

  private belongsToRolesPool(roles: string[], pool: string[]): boolean {
    for (let i = 0; i < pool.length; i += 1) {
      const role = pool[i];

      if (this.hasRole(roles, role)) {
        return true;
      }
    }

    return false;
  }

  belongsToRoles(pool: string[]): Observable<boolean> {
    return this.instance.pipe(
      map((user: User) => {
        if (user) {
          return this.belongsToRolesPool(user.roles, pool);
        }

        return false;
      }));
  }

  hasAtLeastRole(role: string): Observable<boolean> {
    return this.instance.pipe(
      map((user: User) => {
        if (user) {
          return this.hasRole(user.roles, role);
        }

        return false;
      }));
  }

  hasAtLeastOneRole(roles: string[]): Observable<boolean> {
    return this.instance.pipe(
      map((user: User) => {
        for (const role of roles) {
          if (user) {
            return this.hasRole(user.roles, role);
          }
        }

        return false;
      }));
  }

  clear() {
    this.store.dispatch({
      type: UserActions.SET_DEFAULT
    });

    this.store.dispatch({
      type: FleetDepotActions.SET_DEFAULT
    });
  }

  setLocale(lang: string) {
    lang = lang.trim();

    const host = HostConfigService.getHost();

    return observableCombineLatest(
        <Observable<Person>>this.http.put(`${environment.apiUrl}/users/setcurrentuserlocale/${lang}/${host}`, {}),
        <Observable<User>>this.instance.pipe(map(_.cloneDeep))
      ).pipe(
      take(1),
      map(([person, user]) => {
        const lang = _.get(person, 'locale');

        if (lang) {
          _.set(user, 'person.locale', lang);
          this.setUser(user);
        }
      }), )
  }

  setPressureUnit(pressureUnit: PressureUnit) {
    return observableCombineLatest(
        <Observable<Person>>this.http.put(`${environment.apiUrl}/users/set-current-user-pressure-unit/${pressureUnit.pressureUnitId}`, {}),
        <Observable<User>>this.instance.pipe(map(_.cloneDeep))
      ).pipe(
      take(1),
      map(([person, user]) => {
        _.set(user, 'person.pressureUnit', _.get(person, 'pressureUnit'));
        this.setUser(user);
      }), )
  }

  create(user: User): Observable<User> {
    return <Observable<User>>this.http.post(`${environment.apiUrl}/users/`, user);
  }

  update(user: User): Observable<User> {
    return <Observable<User>>this.http.put(`${environment.apiUrl}/users/`, user);
  }

  delete(user: User): Observable<boolean> {
    return of(true).pipe(delay(1000));
  }

  getMandatorGraph(): Observable<Mandator> {
    return <Observable<Mandator>>this.http.get(`${environment.apiUrl}/users/mandators`)
  }

  getAllUserByMandatorId(mandatorId: number, recursive: boolean = true): Observable<User[]> {
    return <Observable<User[]>>this.http.get(`${environment.apiUrl}/users/mandators/${mandatorId}?recursive=${recursive}`)
  }

  isSameUser(user: User): Observable<boolean> {
    return this.instance.pipe(
      map((current: User) => {
        return _.get(user, 'userId') === _.get(current, 'userId');
      })
    );
  }

  getVehicleGroups(userId: number): Observable<VehicleGroup> {
    return <Observable<VehicleGroup>>this.http
      .get(`${environment.apiUrl}/users/vehicle-groups/assign/${userId}`)
      .pipe(
        map((vehicleGroups: VehicleGroup[]) => {
          return {
            vehicleGroupName: null,
            childVehicleGroups: vehicleGroups
          };
        })
      );
  }

  updateVehicleGroups(userId: number, vehicleGroups: VehicleGroup[]): Observable<VehicleGroup> {
    return <Observable<VehicleGroup>>this.http
      .put(`${environment.apiUrl}/users/vehicle-groups/assign/${userId}`, vehicleGroups);
  }

  hasRoleBy(role: string, user: User): boolean {
    return this.hasRole(_.get(user, 'roles', []), role);
  }

  isSuperAdminBy(user: User): boolean {
    return this.hasRoleBy('ROLE_SUPER_ADMIN', user);
  }

  isUsernameUnique(username) {
      return <Observable<boolean>>this.http.get(`${environment.apiUrl}/users/is-user-name-unique?username=${username}`);
  }

}
