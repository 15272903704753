import * as ol from 'openlayers';
import * as _ from 'lodash';

export const getFullLocationBy = (source: any, path?: string) => {
  path = path || '';

  const hasPath = path !== '';
  const longitude = _.get(source, hasPath ? `${path}.longitude` : 'longitude');
  const latitude = _.get(source, hasPath ? `${path}.latitude` : 'latitude');

  if (_.isNumber(longitude) && _.isNumber(latitude)) {
    return { longitude, latitude };
  }
};

export const getCoordinatesBy = (source: any, path?: string) => {
  const location = getFullLocationBy(source, path);

  if (location) {
    return ol.proj.fromLonLat([
      location.longitude,
      location.latitude,
    ]);
  }
};

export const getCoordinatesFromFeauture = (feature: ol.Feature) => {
  return feature.getGeometry().getCoordinates();
};

export const boundingExtent = (coordinates: ol.Coordinate[]) => {
  const ext = ol.extent.boundingExtent(coordinates);
  return ol.proj.transformExtent(ext, ol.proj.get('EPSG:3857'), ol.proj.get('EPSG:3857'));
};

export class Layer {
  public features: ol.Feature[] = [];

  private source: ol.source.Vector = new ol.source.Vector();
  private vector: ol.layer.Vector = new ol.layer.Vector({ source: this.source });

  constructor(public map: ol.Map) {
    this.map.addLayer(this.vector);

    // const hoverInteraction = new ol.interaction.Select({
    //     condition: ol.events.condition.pointerMove,
    //     layers:[this.vector]
    // });
    // this.map.addInteraction(hoverInteraction);
  }

  getLayer(): ol.layer.Vector {
    return this.vector;
  }

  setStyle(style: any) {
    this.vector.setStyle(style);
  }

  add(coords: ol.proj.fromLonLat): ol.Feature {
    const feature = new ol.Feature(new ol.geom.Point(coords));

    return this.addFeature(feature);
  }

  addFeature(feature: ol.Feature): ol.Feature {
    this.source.addFeature(feature);
    this.features.push(feature);

    return feature;
  }

  clear() {
    this.features.forEach((feauture) => {
      this.source.removeFeature(feauture);
    });

    this.features = [];
  }

  hideAll() {
    const emptyImgStyle = new ol.style.Style({
      image: '',
      visibility: 'hidden'
    });

    for (let i = 0; i < this.features.length; i += 1) {
      const feature = this.features[i];
      feature.setStyle(emptyImgStyle);
    }
  }

}

export interface LabeledLayers {
  key: string;
  markers: Layer;
}

export class LabeledLayersGroup {
  private mapping: { [name: string]: Layer; } = {};

  constructor(public map: ol.Map) {

  }

  add(node: LabeledLayers) {
    this.mapping[node.key] = node.markers;
  }

  clear() {
    for (const key in this.mapping) {
      if (this.mapping.hasOwnProperty(key)) {
        this.mapping[key].clear();
      }
    }
  }

}
