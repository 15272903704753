import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Filter, FilterSelection, FilterValuesMap } from './fleet-units-filter-panel.model';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-fleet-units-filter-panel',
  templateUrl: './fleet-units-filter-panel.component.html',
  styleUrls: ['./fleet-units-filter-panel.component.scss']
})
export class FleetUnitsFilterPanelComponent implements OnInit {
  @Input() filters: Filter[];

  @Output() panelValuesChanged: EventEmitter<FilterValuesMap> = new EventEmitter();

  public currentFilterPanelValues: FilterValuesMap = {
    'COUNTRY_CODE': [],
    'EXTERNAL_ID': [],
    'NUMBER_PLATE': [],
    'VEHICLE_TYPE': [],
  };

  constructor() { }

  ngOnInit() {
  }

  onFilterChanged(selection: FilterSelection, filterName: string) {
    this.panelValuesChanged.emit(this.currentFilterPanelValues);
  }

  resetAllFilters() {
    _.forOwn(this.currentFilterPanelValues, (values, key) => {
      this.currentFilterPanelValues[key] = [];
    });

    this.panelValuesChanged.emit(this.currentFilterPanelValues);
  }

}
