import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class NgbDatepickerI18nService extends NgbDatepickerI18n {
    private readonly months: string[];
    private readonly weekdays: string[];

    constructor(data: any) {
        super();
        this.months = data.months;
        this.weekdays = data.weekdays;
    }

    getWeekdayShortName(weekday: number): string {
        return this.weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return this.months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return '';
    }

    public getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
        return this.weekdays[weekday-1];
    }

}
