import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { FleetVehicle } from '@depot/fleet-depot.model';
import { IfmsBox } from './ifms-box.model';

import { IfmsBoxesService } from '../ifms-boxes.service';
import { NotificationsService } from 'angular2-notifications';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-box',
  templateUrl: './ifms-box.component.html',
  styleUrls: ['./ifms-box.component.scss']
})
export class IfmsBoxComponent implements OnInit {
  @Input() fleetVehicle: FleetVehicle;
  @Input() box: IfmsBox;

  @Output() created: EventEmitter<IfmsBox> = new EventEmitter<IfmsBox>();
  @Output() updated: EventEmitter<IfmsBox> = new EventEmitter<IfmsBox>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  public disabled = false;
  public pending = false;
  public showingDeleteDialog = false;

  constructor(private ifmsBoxesService: IfmsBoxesService,
              private notification: NotificationsService) { }

  ngOnInit() {
  }

  create() {
    this.pending = true;
    const candidate = _.assign({}, this.box, { fleetVehicle: this.fleetVehicle });

    this.ifmsBoxesService.createBox(candidate).pipe(
      finalize(() => {
        this.pending = false;
      }))
      .subscribe((box: IfmsBox) => {
        this.box = box;
        this.created.emit(this.box);
        this.notification.success('Success', 'Ifms box has been created!');
      }, err => this.catchError(err));
  }

  update() {
    this.pending = true;

    this.ifmsBoxesService.updateBox(this.box).pipe(
      finalize(() => {
        this.pending = false;
      }))
      .subscribe((box: IfmsBox) => {
        this.box = box;
        this.updated.emit(this.box);
        this.notification.success('Success', 'Ifms box has been updated!');
      }, err => this.catchError(err));
  }

  delete() {
    this.pending = true;

    this.ifmsBoxesService.deleteBox(this.box).pipe(
      finalize(() => {
        this.pending = false;
      }))
      .subscribe((res: boolean) => {
        console.log(res);
      }, err => this.catchError(err));
  }

  private catchError(err: HttpErrorResponse) {
    console.log(err);
  }

}
