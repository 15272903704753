import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { FleetVehicle } from '../../fleet-depot/fleet-depot.model';
import { FMS_FleetVehicleProperty } from '@unit-state/vehicle-monitoring/vehicle-monitoring.model';

import { VehicleMonitoringService } from '@unit-state/vehicle-monitoring/vehicle-monitoring.service';

import * as _ from 'lodash';
import { FleetUnitsService } from '@fleet/fleet-units/fleet-units.service';
import { FleetUnit } from '@fleet/fleet-units/fleet-unit/fleet-unit.model';

@Component({
  selector: 'ifms-vehicle-led-state',
  templateUrl: './vehicle-led-state.component.html',
  styleUrls: ['./vehicle-led-state.component.scss']
})
export class VehicleLedStateComponent implements OnInit, OnChanges {
  @Input() fleetVehicle: FleetVehicle;
  @Input() ifmsBox: any;
  @Input() fleetUnit: FleetUnit;

  wheelLoss: boolean;

  private KEYS = [
    'START_UP_FLASHING',
    'SHOULD_BE_OFF',
    'OFF',
    'SHOULD_BE_UNKNOWN',
    'UNKNOWN',
    'SHOULD_BE_ON',
    'ON',
    'SHOULD_BE_FLASHING',
    'FLASHING',
  ];

  public states: any = _
    .chain(this.KEYS)
    .map((key) => {
      return {
        state: key,
        message: `FLEET.DEPOT.VEHICLE.LED.${key}`,
      };
    })
    .keyBy('state')
    .value();

  public currentStatus: any = this.states['UNKNOWN'];
  public online = false;
  public hasLed: boolean;

  constructor(private vehicleMonitoring: VehicleMonitoringService, private fleetUnitsService: FleetUnitsService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fleetVehicle) {
      if (_.has(this.fleetVehicle, 'fleetVehicleId')) {
        this.vehicleMonitoring.getFleetVehicleProperty(this.fleetVehicle)
          .subscribe((property: FMS_FleetVehicleProperty) => {
            this.hasLed = _.get(property, 'ledActive', false);
          });

        this.vehicleMonitoring.getWheelLoss(this.fleetVehicle)
          .subscribe(result => {
            this.wheelLoss = result;
          });
      }
    }

    if (this.ifmsBox) {
      this.online = _.get(this.ifmsBox, 'online');

      this.currentStatus = null;

      const ledStatus = _.get(this.ifmsBox, 'led1Status');

      if (ledStatus) {
        this.currentStatus = _.get(this.states, ledStatus);
      }

      if (!this.currentStatus) {
        this.currentStatus = this.states['UNKNOWN'];
      }

    }
  }

  get isInService() {
      return this.fleetUnitsService.isInService(this.fleetUnit);
  }

}
