import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { AxleElement, AxleFramework } from './axle-framework.model';
import { AxleTire } from '../axle-tire.model';

import { AxleFrameworksService } from './axle-frameworks.service';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-axle-framework',
  templateUrl: './axle-framework.component.html',
  styleUrls: ['./axle-framework.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AxleFrameworkComponent implements OnInit, OnChanges {
  @Input() pathToImage: string;
  @Input() tires: AxleTire[];
  @Output() clickTirePosition: EventEmitter<number> = new EventEmitter();


  public framework: AxleFramework;
  public elements: AxleElement[];
  public style: { [property: string]: string; } = {};

  constructor(private frameworks: AxleFrameworksService) { }

  ngOnInit() {
    this.framework = this.frameworks.get(this.pathToImage);

    if (this.framework) {
      this.elements = this.framework.elements;
    }

    if (this.framework && this.framework.style) {
      this.style = this.framework.style;
    }

    if (this.elements) {
      this.setPaths();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.elements && changes.tires) {
      this.setPaths();
    }
  }

  private setPaths() {
    _.map(this.elements, ({ position, dPath }) => {
      if (_.isNumber(position)) {
        _.set(this.tires, `${position}.dPath`, dPath);
      }
    });
  }

  trackByIndex(index: number) {
    return index;
  }
}
