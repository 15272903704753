import { Component, Input, OnChanges } from '@angular/core';
import { getWorstNotificationType } from '@fleet/fleet-units/fleet-unit/fleet-unit-notifications/notifications.helper';

@Component({
  selector: '[ifms-axle-tire-notification-icon]',
  templateUrl: './axle-tire-notification-icon.component.html',
  styleUrls: ['./axle-tire-notification-icon.component.scss']
})
export class AxleTireNotificationIconComponent implements OnChanges {
  @Input() notifications: string[];

  public notificationType: string;

  constructor() { }

  ngOnChanges() {
    if (this.notifications) {
      this.notificationType = getWorstNotificationType(this.notifications);
    }
  }

}
