import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { HistorySensorData } from './sensor-data.model';

import { VehicleService } from '@unit-state/vehicle.service';

@Component({
  selector: 'ifms-sensor-data',
  templateUrl: './sensor-data.component.html',
  styleUrls: ['./sensor-data.component.scss']
})
export class SensorDataComponent implements OnInit, OnDestroy {
  @Input() boundaries: any;
  @Input() data: any;
  @Input() tpmsSensor: any;
  @Input() tireOfFleetUnit: {tireId: number, fleetUnitId: number};

  public sensorHistory: HistorySensorData[];
  private sensorHistorySub: Subscription;

  constructor(private vehicle: VehicleService) { }

  ngOnInit() {
    this.sensorHistorySub = this.vehicle.sensorHistory
      .subscribe((sensorHistory: HistorySensorData[]) => {
        this.sensorHistory = sensorHistory;
      });
  }

  ngOnDestroy() {
    if (this.sensorHistorySub) {
      this.sensorHistorySub.unsubscribe();
    }

    this.vehicle.dispatchSensorHistory([]);
  }

}
