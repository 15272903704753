import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { FleetVehicle } from '@depot/fleet-depot.model';
import { IfmsBox } from '../ifms-box/ifms-box.model';

import { IfmsBoxesService } from '../ifms-boxes.service';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-box-editor',
  templateUrl: './ifms-box-editor.component.html',
  styleUrls: ['./ifms-box-editor.component.scss']
})
export class IfmsBoxEditorComponent implements OnInit, OnDestroy {
  @Input() fleetVehicle: FleetVehicle;

  @Output() created: EventEmitter<IfmsBox> = new EventEmitter<IfmsBox>();
  @Output() updated: EventEmitter<IfmsBox> = new EventEmitter<IfmsBox>();
  @Output() cancel: EventEmitter<IfmsBox> = new EventEmitter<IfmsBox>();

  public box: IfmsBox;
  public loadingBox = false;
  public errorMessage: string;

  private routeSub: Subscription;

  constructor(private ifmsBoxesService: IfmsBoxesService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      let ifmsBoxId = params.ifmsBoxId;

      if (ifmsBoxId) {
        ifmsBoxId = parseInt(ifmsBoxId, 10);

        if (ifmsBoxId === 0) {
          this.box = {
            imei: null,
            mobilNumber: null,
          };
        } else {
          this.getBox(ifmsBoxId);
        }
      } else {
        this.box = {
          imei: null,
          mobilNumber: null,
        };
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  emitCancel(event: IfmsBox) {
    this.navigateToHardware();
    this.cancel.emit(event);
  }

  emitCreated(event: IfmsBox) {
    this.navigateToHardware();
    this.created.emit(event);
  }

  emitUpdated(event: IfmsBox) {
    this.navigateToHardware();
    this.updated.emit(event);
  }

  private navigateToHardware() {
    if (_.includes(this.router.url, '/hardware/ifms-boxes/')) {
      this.router.navigate(['/fleet/hardware/ifms-boxes']);
    }
  }

  private getBox(id: number) {
    this.loadingBox = true;

    this.ifmsBoxesService.getBox(id).pipe(
      finalize(() => {
        this.loadingBox = false;
      }))
      .subscribe((box: IfmsBox) => {
        this.box = box;
      }, err => this.catchError(err));
  }

  private catchError(err: HttpErrorResponse) {
    if (err.status === 403) {
      this.errorMessage = 'FORBIDDEN';
    }
  }

}
