import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { FleetVehicle } from '@depot/fleet-depot.model';
import { SensorEquipment, TpmsSensor, TpmsSensorEntry } from './sensor-equipment.model';

import { SensorEquipmentsService } from '../sensor-equipments.service';
import { NotificationsService } from 'angular2-notifications';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-sensor-equipment',
  templateUrl: './sensor-equipment.component.html',
  styleUrls: ['./sensor-equipment.component.scss']
})
export class SensorEquipmentComponent implements OnInit, OnChanges {
  @Input() fleetVehicle: FleetVehicle;
  @Input() equipment: SensorEquipment;

  @Output() created: EventEmitter<SensorEquipment> = new EventEmitter<SensorEquipment>();
  @Output() updated: EventEmitter<SensorEquipment> = new EventEmitter<SensorEquipment>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  public disabled = false;
  public pending = false;
  public showingDeleteDialog = false;

  public sensorEntry: TpmsSensorEntry;
  public showingSelectedSensorEntry = false;

  private positions = ['a', 'b', 'c', 'd'];

  public axles: TpmsSensorEntry[] = [];

  constructor(private sensorEquipmentsService: SensorEquipmentsService,
              private notification: NotificationsService) { }

  ngOnInit() {
    this.axles = _
      .chain([1, 2, 3, 4, 5, 6])
      .map((axlePosition) => {
        return _.map(this.positions, (position) => {
          return {
            axlePosition,
            position,
            payload: {
              axlePosition,
              position,
            }
          };
        })
      })
      .value();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.equipment) {
      const tpmsSensors: TpmsSensor = _.get(this.equipment, 'tpmsSensors');
      _.forEach(tpmsSensors, (tpmsSensor: TpmsSensor) => {
        const axlePosition = _.get(tpmsSensor, 'axlePosition');
        const position = _.get(tpmsSensor, 'position');

        const positionIndex = _.indexOf(this.positions, position);

        _.set(this.axles, `${axlePosition - 1}.${positionIndex}.payload`, tpmsSensor);
      });
    }
  }

  selectEntry(entry: TpmsSensorEntry) {
    this.showingSelectedSensorEntry = true;
    this.sensorEntry = entry;
  }

  initiatedTpmsSensor(tpmsSensor: TpmsSensor) {
    _.set(this.sensorEntry, 'payload', tpmsSensor);

    this.showingSelectedSensorEntry = false;
    this.sensorEntry = null;
  }

  create() {
    this.pending = true;
    const candidate = _.assign({}, this.equipment, { fleetVehicle: this.fleetVehicle });

    this.sensorEquipmentsService.create(candidate).pipe(
      finalize(() => {
        this.pending = false;
      }))
      .subscribe((equipment: SensorEquipment) => {
        this.equipment = equipment;
        this.created.emit(this.equipment);
        this.notification.success('Success', 'Ifms equipment has been created!');
      }, err => this.catchError(err));
  }

  update() {
    this.pending = true;

    this.sensorEquipmentsService.update(this.equipment).pipe(
      finalize(() => {
        this.pending = false;
      }))
      .subscribe((equipment: SensorEquipment) => {
        this.equipment = equipment;
        this.updated.emit(this.equipment);
        this.notification.success('Success', 'Ifms equipment has been updated!');
      }, err => this.catchError(err));
  }

  delete() {
    this.pending = true;

    this.sensorEquipmentsService.delete(this.equipment).pipe(
      finalize(() => {
        this.pending = false;
      }))
      .subscribe((res: boolean) => {
        console.log(res);
      }, err => this.catchError(err));
  }

  private catchError(err: HttpErrorResponse) {
    console.log(err);
  }

}
