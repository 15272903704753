import { AxleTireCalibration } from './axle-tire.model';
import { Tire } from '../tire/tire.model';

import * as _ from 'lodash';

import { NOTIFICATIONS } from '@fleet/fleet-units/fleet-unit/fleet-unit-notifications/notifications.helper';
import { NotificationWithComment } from '@fleet/fleet-units/fleet-unit/fleet-unit-notifications/notifications.model';

declare const moment: any;

const SENSOR_TIME_NOTIFICATIONS = NOTIFICATIONS.TIME.keys;

export const createCalibrationData = (tire: Tire): AxleTireCalibration => {
  const pressure = {
    value: _.get(tire, 'tpmsSensor.pressureSetpoint'),
    setpoint: _.get(tire, 'tpmsSensor.pressureSetpoint'),
    emittedSetpoint: _.get(tire, 'tpmsSensor.sensorData.pressureSetpoint'),
  };

  const temperature = {
    value: _.get(tire, 'tpmsSensor.temperatureThreshold'),
    setpoint: _.get(tire, 'tpmsSensor.temperatureThreshold'),
    emittedSetpoint: _.get(tire, 'tpmsSensor.sensorData.temperatureThreshold'),
  };

  return {
    tpmsSensorId: _.get(tire, 'tpmsSensor.tpmsSensorId'),
    pressure,
    temperature,
  };
};

export const includesSensorTimeNotifications = (notifications: NotificationWithComment[]) => {
  if (_.isEmpty(notifications)) {
    return false;
  }

  return _.intersection(SENSOR_TIME_NOTIFICATIONS, notifications.map(notification => notification.notification)).length > 0;
};
