import { VehicleGroup } from '@depot/fleet-depot.model';
import { FleetUnit } from './fleet-unit/fleet-unit.model';
import { FleetUnitsTree } from './fleet-units.model';

import { getSize, getSrcPath } from '@configurator/vehicle-types/vehicle-types.helper';
import * as _ from 'lodash';
import * as ol from 'openlayers';
import { Condition } from '@unit-state/models/condition.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const sortFleetUnits = (fleetUnits: FleetUnit[]): FleetUnit[] => {
  return _.sortBy(fleetUnits, (fleetUnit) => {
    const tractorNumberPlate = _.get(fleetUnit, 'tractor.numberPlate');
    const trailerNumberPlate = _.get(fleetUnit, 'trailer.numberPlate');
    const numberPlate = tractorNumberPlate ? tractorNumberPlate : trailerNumberPlate;

    return _.toLower(numberPlate);
  });
};

const treefyFleetUnitsByGroups = (groups: VehicleGroup[], fleetUnitsByGroupId: { [x: string]: FleetUnit[] }): FleetUnitsTree[] => {
  return _.map(groups, (group: VehicleGroup) => {
    const groupId = _.get(group, 'vehicleGroupId');

    const node: FleetUnitsTree = {
      fleetUnits: [],
      group: null,
    };

    if (groupId) {
      node.group = group;
      node.fleetUnits = sortFleetUnits(fleetUnitsByGroupId[groupId]);

      if (_.has(group, 'childVehicleGroups') && group.childVehicleGroups.length > 0) {
        node.children = treefyFleetUnitsByGroups(group.childVehicleGroups, fleetUnitsByGroupId);
      }

      return node;
    }
  });
};

export const treefyFleetUnits = (group: VehicleGroup, fleetUnits: FleetUnit[]): FleetUnitsTree[] => {
  const fleetUnitsByGroupId = _.groupBy(fleetUnits, 'vehicleGroupId');
  return treefyFleetUnitsByGroups([group], fleetUnitsByGroupId);
};

export const flattenFleetUnitTree = (node: FleetUnitsTree, list?: FleetUnit[][]): FleetUnit[][] => {
  list = list || [];
  list.push(node.fleetUnits);

  const children = _
    .chain(node.children)
    .map(child => flattenFleetUnitTree(child))
    .flatten()
    .value();

  return _
    .chain(list)
    .concat(children)
    .filter(units => units.length > 0)
    .value();
};

export const getTypeKey = (fleetUnit: FleetUnit): string => {
  if (_.get(fleetUnit, 'tractor') !== null) {
    if (_.get(fleetUnit, 'trailer') !== null) {
      return 'TRUCK_TRAILER';
    } else {
      return _.get(fleetUnit, 'tractor.vehicleType');
    }
  } else {
    return _.get(fleetUnit, 'trailer.vehicleType');
  }
};

const createUnitIconStyle = (options: { src: string; typeKey: string, color?: string }): ol.style.Style => {
  return new ol.style.Style({
    image: new ol.style.Icon({
      src: _.get(options, 'src'),
      scale: 0.8,
      opacity: 1,
        color: _.get(options, 'color', '#000000')
    })
  });
};

export const getUnitIconStyle = (fleetUnit: FleetUnit): ol.style.Style => {
  const typeKey = getTypeKey(fleetUnit);

  const unitStatus = fleetUnit.unitStatus || 'NORMAL';
  const alert = (unitStatus !== 'NORMAL') ? _.toLower(unitStatus) : undefined;
  const src = getSrcPath(typeKey, 'png', alert);

  return createUnitIconStyle({ src, typeKey });
};

export const getDefaultUnitIconStyle = (fleetUnit: FleetUnit): ol.style.Style => {
  const typeKey = getTypeKey(fleetUnit);
  const src = getSrcPath(typeKey, 'svg');

  return createUnitIconStyle({ src, typeKey });
};

export const getDefaultUnitIconStyleSVG = (fleetUnit: FleetUnit, http: HttpClient): Observable<ol.style.Style> => {
    const typeKey = getTypeKey(fleetUnit);
    const src = getSrcPath(typeKey, 'svg');
    const size = getSize(typeKey)

    const color = Condition.getColorBy(fleetUnit.unitStatus || 'NORMAL');

    return http.get(`/${src}`, {responseType: 'text'}).pipe(
        map((data: string) => {
            const el = document.createElement('div');
            el.innerHTML = data;
            const height = size && size.length === 2 ? size[1] : 30;
            el.children[0].setAttribute('height', height + 'px');
            const width = (16 / 9) * height;
            el.children[0].setAttribute('width', width + 'px');
            const svg = `data:image/svg+xml;utf8,${el.innerHTML}`;
            return createUnitIconStyle({src: svg, typeKey, color});
        })
    );
};
