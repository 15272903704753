import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ICredentials } from '../services/interfaces/credentials';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { HistoryActivate } from '@shared/services/history.active';
import { UserService } from '../../fleet/user/user.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public title: string;

  public loginData: ICredentials = {
      username: '',
      password: ''
  };

  public stayLoggedIn = false;
  public erroneous = false;
  public errorMessage = 'LOGIN.RETYPE_WARNING';

  public credentialsSub: Subscription;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authService: AuthService,
      private history: HistoryActivate,
      private fleetUser: UserService,
      private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.title = this.titleService.getTitle();

    this.credentialsSub = this.route.queryParams
      .subscribe((params) => {
        if (params.username && params.password) {
          this.loginData.username = params.username;
          this.loginData.password = params.password;
          this.tryLogin();
        }
      });
  }

  ngOnDestroy() {
    if (this.credentialsSub) {
      this.credentialsSub.unsubscribe();
    }
  }

  public tryLogin() {
      this.authService.login(this.loginData, this.stayLoggedIn).subscribe(
          (response: any) => {
              this.erroneous = false;
              this.fleetUser.getCurrentUser().subscribe((user: any) => {
                // console.log(user);
              });

              const previousUrl = this.history.getPreviousUrl();
              this.router.navigate([previousUrl]);
          },
          (error: any) => {
              console.log('error', error);
              this.erroneous = true;

              if (error.status === 0) {
                  this.errorMessage = 'LOGIN.MAINTANCE';
              } else {
                  this.errorMessage =  'LOGIN.RETYPE_WARNING';
              }
          }
      );
  }

}
