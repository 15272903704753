import { Component, OnInit } from '@angular/core';
import { AxleEmitter } from '../axle.model';

import { AxleFrameworksService } from '../axle-framework/axle-frameworks.service';

@Component({
  selector: 'ifms-axle-frameworks-listing',
  templateUrl: './axle-frameworks-listing.component.html',
  styleUrls: ['./axle-frameworks-listing.component.scss']
})
export class AxleFrameworksListingComponent implements OnInit {

  public axle: AxleEmitter;
  public axleKeys: string[];

  constructor(public frameworks: AxleFrameworksService) {
    this.axleKeys = Object.keys(this.frameworks.frameworks)
  }

  ngOnInit() {
    this.axle = new AxleEmitter();
  }

}
