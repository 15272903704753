import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, take } from 'rxjs/operators';

import { Mandator } from '@fleet/mandator/mandator.model';
import { User } from '@user/user.model';

import { MandatorService } from '@fleet/mandator/mandator.service';
import { UserService } from '@user/user.service';
import * as _ from 'lodash';

@Injectable()
export class UserManagementService {

  public manager: Observable<User>;

  constructor(private userService: UserService,
              private mandatorService: MandatorService) {
    this.manager = this.userService.instance
      .pipe(
        filter(user => _.has(user, 'userId')),
        distinctUntilChanged((current: User, previous: User) => {
          return _.get(current, 'userId') === _.get(previous, 'userId');
        })
      );
  }

  getMandator(): Observable<Mandator> {
    return this.manager
      .pipe(
        map(user => _.get(user, 'mandator')),
        take(1)
      );
  }

  getUsers(mandatorId?: number, recursive = false): Observable<User[]> {
    if (_.isNumber(mandatorId)) {
      return this.userService.getAllUserByMandatorId(mandatorId, recursive);
    }

    return this.manager
      .pipe(
        map(user => _.get(user, 'mandator.mandatorId')),
        filter(mandatorId => _.isNumber(mandatorId)),
        take(1),
        mergeMap(mandatorId => this.userService.getAllUserByMandatorId(mandatorId, false))
      );
  }

  getMandators(): Observable<Mandator> {
    return this.manager
      .pipe(
        map(user => _.get(user, 'mandator.mandatorId')),
        filter(mandatorId => _.isNumber(mandatorId)),
        take(1),
        mergeMap(mandatorId => this.mandatorService.getGraph(mandatorId))
      );
  }

}
