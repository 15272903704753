import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IfmsBox, IfmsBoxFilter } from './ifms-box/ifms-box.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import * as _ from 'lodash';

@Injectable()
export class IfmsBoxesService {
  private corePath = 'ifmsboxes';
  constructor(private http: HttpClient) {}

  getBoxes(imei= '', page= 0, boxesPerPage= 20): Observable<IfmsBoxFilter> {
    return <Observable<IfmsBoxFilter>>this.http.get(`${environment.apiUrl}/${this.corePath}/?imei=${imei}&page=${page}&boxesPerPage=${boxesPerPage}`);
  }

  getUnassignedBoxes(): Observable<IfmsBox[]> {
    return <Observable<IfmsBox[]>>this.http.get(`${environment.apiUrl}/${this.corePath}/no-vehicles`);
  }

  getBox(id: number): Observable<IfmsBox> {
    return <Observable<IfmsBox>>this.http.get(`${environment.apiUrl}/${this.corePath}/${id}`);
  }

  createBox(box: IfmsBox): Observable<IfmsBox> {
    return <Observable<IfmsBox>>this.http
      .post(`${environment.apiUrl}/${this.corePath}/`, box);
  }

  updateBox(box: IfmsBox): Observable<IfmsBox> {
    // const id = _.get(box, 'ifmsBoxId');

    return <Observable<IfmsBox>>this.http
      .put(`${environment.apiUrl}/${this.corePath}/`, box);
  }

  deleteBox(box: IfmsBox): Observable<boolean> {
    const id = _.get(box, 'ifmsBoxId');

    return <Observable<boolean>>this.http.delete(`${environment.apiUrl}/${this.corePath}/${id}`);
  }

}
