import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AxleThresholdsService {

  constructor(private http: HttpClient) { }

  public getThresholds(fleetUnitId) {
    return this.http.get(`${environment.apiUrl}/fleetUnits/${fleetUnitId}/thresholds`);
  }

  public getTyreThresholds(fleetUnitId, tyreId) {
    return this.http.get(`${environment.apiUrl}/fleetUnits/${fleetUnitId}/thresholds/${tyreId}`);
  }

  public setThresholds(fleetUnitId, data) {
    this.http.put(`${environment.apiUrl}/fleetUnits/${fleetUnitId}/thresholds`, data).subscribe((data) => { console.log('put', data)});
  }
}
