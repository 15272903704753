import { HistorySensorData } from '../sensor-data.model';

import * as _ from 'lodash';

declare const moment: any;

const LINE_POINT_RADIUS = 1;
const LINE_POINT_HIT_RADIUS = 4;

const WARNING_SCALAR = 0.9; // wird noch benutzt?
const ALARM_SCALAR = 0.8; // wird noch benutzt?
const ADAPTED_SETPOINT_PROPERTY = 'adaptedSetpoint';
const BORDER_WIDTH = 1;

const thresholdArea = {
  type: 'line',
  hiddenLegend: true,
  backgroundColor: 'rgba(255, 0, 0, 0.3)',
  // borderColor: '#fff',
  borderWidth: 2,
  borderColor: 'rgba(255, 0, 0, 0.05)',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 0,
  pointRadius: 0,
  lineTension: 0,
};

export const appendThresholdAreaToDatasets = (range, datasets, threshold, boundaries?: any, color?: string) => {
  const startDate = _.get(boundaries, 'startDate');
  const endDate = _.get(boundaries, 'endDate');

  const top: any = Object.assign({}, thresholdArea, {
    fill: '+1',
    data: [{
      x: startDate || range.start,
      y: threshold.top
    }, {
      x: endDate || range.end,
      y: threshold.top
    }]
  });

  if (color) {
    _.set(top, 'backgroundColor', color);
    _.set(top, 'borderColor', color);
  }

  datasets.push(top);

  const bottom: any = Object.assign({}, thresholdArea, {
    fill: false,
    data: [{
      x: startDate || range.start,
      y: threshold.bottom
    }, {
      x: endDate || range.end,
      y: threshold.bottom
    }]
  });

  datasets.push(bottom);
};

export const getPressureThresholdWarning = (pressurePoint: number) => {
  return {
    high: pressurePoint * 1.1,
    low: pressurePoint * 0.9,
  };
};

export const getPressureThresholdAlarm = (pressurePoint: number) => {
  return {
    high: pressurePoint * 1.2,
    low: pressurePoint * 0.8,
  };
};

export const getDataByProperty = (sensorHistory: HistorySensorData[], property: string, prepare?: Function): any => {
  const data = sensorHistory
    .map((sensor) => {
      return getPointByProperty(sensor, property);
    })
    .filter((point: any) => {
      return _.isNumber(point.y);
    });

  if (prepare) {
    return _.map(data, prepare);
  }

  return data;
};

export const getPointByProperty = (sensor: HistorySensorData, property: string): any => {
  if (sensor) {
    return {
      x: utcToLocalDate(sensor.timestamp),
      y: sensor[property],
    };
  }
};

export const utcToLocalDate = (timestamp) => {
  const stillUtc = moment.utc(timestamp).toDate();
  // const stillUtc = environment.production ? moment.utc(timestamp).toDate() : moment.utc(timestamp).add(2, 'hour').toDate();

  return moment(stillUtc).local().toDate();
};

export const updateRangeBy = (range, startDate, endDate) => {
  range = updateRangeProperty('start', range, startDate);
  range = updateRangeProperty('end', range, endDate);

  return range;
};

export const updateXAxesByBoundaries = (options, boundaries) => {
  const startDate = _.get(boundaries, 'startDate');
  const endDate = _.get(boundaries, 'endDate');

  if (startDate && endDate) {
    _.set(options, 'scales.xAxes.ticks.min', startDate);
    _.set(options, 'scales.xAxes.ticks.max', endDate);
  } else {
    _.set(options, 'scales.xAxes.ticks.min', undefined);
    _.set(options, 'scales.xAxes.ticks.max', undefined);
  }
};

export const updateRangeProperty = (property, range, date) => {
  if (date) {
    if (!range[property]) {
      range[property] = date;
    } else {
      if (range[property].valueOf() - date.valueOf() > 0) {
        range[property] = date;
      }
    }
  }

  return range;
};

export const updateTicks = (data, options) => {
  if (data.length > 0) {
    const values = data.map(point => point.y);
    const max = Math.max(...values);

    const ticks = options.scales.yAxes.ticks;

    ticks.max = Math.ceil(Math.max(max, ticks.max));
  }
};

export const subtract = (date, count, unit) => {
  return moment(date).subtract(count, unit).toDate();
};

export const format = (date, pattern) => {
  return moment(date).format(pattern);
};

const find = (path: string, series: any[], date: Date) => {
  const value = date.valueOf();

  return _.findIndex(series, (point) => {
    const target = _.get(point, path);

    if (target) {
      return target.valueOf() >= value;
    }

    return false;
  });
};

const sliceData = (data: any[], boundaries) => {
  if (!boundaries) {
    return data;
  }

  const start = find('x', data, boundaries.startDate);
  return _.slice(data, _.max([0, start - 1]), data.length + 1);
};

const getPointRadius = (data: any[]) => {
  if (data.length < 100) {
    return 2;
  }

  return LINE_POINT_RADIUS;
};

export const getPressureOptions = (scope: any, sensorHistory: HistorySensorData[], thresholds: any = null): any => {
  const boundaries = scope.boundaries;
  const data = getDataByProperty(sensorHistory, 'currentPressure');

  const filteredData = getScaledData(data, scope.boundaries);
  scope.labels = getScaledLabels(scope.label, scope.boundaries);
  console.log('scope.labels', scope.labels);
  const pointRadius = getPointRadius(filteredData);

  const options: any = {};

  if (scope.showNormalizedPressure) {
    options.labels = scope.labels;
    options.datasets = [{
      label: 'pressure',
      data: filteredData,
      fill: false,
      borderWidth: BORDER_WIDTH,
      borderColor: '#4bc0c0',
      pointRadius,
      pointHitRadius: LINE_POINT_HIT_RADIUS,
      lineTension: 0,
    }];
  } else {
    options.datasets = [{}];
  }

  if (scope.pressureUnit === 'psi') {
    _.set(scope.pressure.options, 'scales.yAxes.ticks.max', 180);
    _.set(scope.pressure.options, 'scales.yAxes.ticks.stepSize', 20);
  } else if (scope.pressureUnit === 'kPa') {
    _.set(scope.pressure.options, 'scales.yAxes.ticks.max', 1200);
    _.set(scope.pressure.options, 'scales.yAxes.ticks.stepSize', 100);
  } else {
    _.set(scope.pressure.options, 'scales.yAxes.ticks.max', 12);
    _.set(scope.pressure.options, 'scales.yAxes.ticks.stepSize', 1);
  }

  updateXAxesByBoundaries(scope.pressure.options, boundaries);
  updateTicks(data, scope.pressure.options);

  if (_.has(scope, 'sensorData.pressureSetpoint')) {
    const pressureSetpoint = _.get(scope, 'sensorData.pressureSetpoint');
    let warningScales = getPressureThresholdWarning(pressureSetpoint);
    let alarmScales = getPressureThresholdAlarm(pressureSetpoint);
    if (thresholds) {
      warningScales = thresholds.calculatedThresholds.warning;
      alarmScales = thresholds.calculatedThresholds.alarming;
    }

    if (data.length > 1) {
      if (scope.showNormalizedPressure) {
        options.datasets.push(createWarningAdaptedSetpointDataset(scope, sensorHistory, thresholds.originalThresholdValues));
        options.datasets.push(createAlarmAdaptedSetpointDataset(scope, sensorHistory, thresholds.originalThresholdValues));
      } else {
        const alarmThreshold = { top: alarmScales.low, bottom: 0 };
        appendThresholdAreaToDatasets(scope.range, options.datasets, alarmThreshold, boundaries);
        _.set(options.datasets, '1.backgroundColor', 'rgba(255, 0, 0, 0)');
        _.set(options.datasets, '1.borderColor', 'rgba(255, 0, 0, 0.7)');

        const warningThreshold = { top: warningScales.low, bottom: alarmScales.low };
        appendThresholdAreaToDatasets(scope.range, options.datasets, warningThreshold, boundaries, 'rgba(255, 255, 0, 0.3)');
        _.set(options.datasets, '3.backgroundColor', 'rgba(255, 0, 0, 0)');
        _.set(options.datasets, '3.borderColor', 'rgba(255, 111, 0, 0.7)');

        const coldPressureDataset = createColdPressureDataset(scope, sensorHistory);
        options.datasets.push(coldPressureDataset);
      }
    }

    // if(data.length > 1) {
    //   const warningThreshold = {
    //     top: alarmScales.high,
    //     bottom: warningScales.high
    //   };
    //
    //   appendThresholdAreaToDatasets(scope.range, options.datasets, warningThreshold, boundaries, 'rgba(255, 255, 0, 0.3)');
    //
    //   const alarmThreshold = {
    //     top: scope.pressure.options.scales.yAxes[0].ticks.max,
    //     bottom: alarmScales.high
    //   };
    //
    //   appendThresholdAreaToDatasets(scope.range, options.datasets, alarmThreshold, boundaries);
    // }
  } else {
    if (data.length > 1) {
      if (scope.showNormalizedPressure === false) {
        const coldPressureDataset = createColdPressureDataset(scope, sensorHistory);
        options.datasets.push(coldPressureDataset);
      }
    }
  }

  return options;
};

const createDatasetByProperty = (options, scope: any, sensorHistory: HistorySensorData[], prepareDatapoints?: Function) => {
  const boundaries = scope.boundaries;
  const data = getDataByProperty(sensorHistory, options.property, prepareDatapoints);

    const filteredData = getScaledData(data, scope.boundaries);
    scope.labels = getScaledLabels(scope.label, scope.boundaries);

    return _.assign({
        labels: scope.labels
    }, options.chartOptions, {
    fill: false,
    data: filteredData
  });
};

const createWarningAdaptedSetpointDataset = (scope: any, sensorHistory: HistorySensorData[], thresholds: any = null) => {
  const options = {
    property: ADAPTED_SETPOINT_PROPERTY,
    chartOptions: {
      label: 'warning',
      fill: false,
      borderWidth: BORDER_WIDTH,
      borderColor: 'rgba(255, 111, 0, 1)',
      pointHitRadius: 0,
      pointRadius: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
      color: 'rgba(255, 255, 0, 1)',
      lineTension: 0,
    }
  };

  let warningScales = WARNING_SCALAR;
  if (thresholds) {
    warningScales = thresholds.maintenanceThreshold;
  }

  const dataset = createDatasetByProperty(options, scope, sensorHistory, (point: any) => {
     point.y *= warningScales;
     return point;
  });

  // console.log(dataset.data);
  // const shownData = sliceData(dataset.data, scope.boundaries);
  // console.log('sliceData', shownData);

  return dataset;
};

const createAlarmAdaptedSetpointDataset = (scope: any, sensorHistory: HistorySensorData[], thresholds: any = null) => {
  const options = {
    property: ADAPTED_SETPOINT_PROPERTY,
    chartOptions: {
      label: 'alarm',
      fill: false,
      borderWidth: BORDER_WIDTH,
      borderColor: 'rgba(255, 0, 0, 1)',
      pointHitRadius: 0,
      pointRadius: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
      color: 'rgba(255, 0, 0, 1)',
      lineTension: 0,
    }
  };

  let alarmScales = ALARM_SCALAR;
  if (thresholds) {
    alarmScales = thresholds.dangerThreshold;
  }

  return createDatasetByProperty(options, scope, sensorHistory, (point: any) => {
     point.y *= alarmScales;
     return point;
  });
};

const createColdPressureDataset = (scope: any, sensorHistory: HistorySensorData[]) => {
  const options = {
    property: 'coldPressure',
    chartOptions: {
      label: 'cold pressure',
      fill: false,
      borderColor: '#565656',
      borderWidth: BORDER_WIDTH,
      pointHitRadius: LINE_POINT_HIT_RADIUS,
      pointRadius: LINE_POINT_RADIUS,
      lineTension: 0,
    }
  };

  return createDatasetByProperty(options, scope, sensorHistory);
};

export const getTemperaturOptions = (scope: any, sensorHistory: HistorySensorData[]): any => {
  const boundaries = scope.boundaries;
  const data = getDataByProperty(sensorHistory, 'currentTemperature');

  const filteredData = getScaledData(data, scope.boundaries);
  scope.labels = getScaledLabels(scope.label, scope.boundaries);

  const pointRadius = getPointRadius(filteredData);

  const options = {
    labels: scope.labels,
    datasets: [{
      label: 'temperature',
      data: filteredData,
      fill: false,
      borderWidth: BORDER_WIDTH,
      borderColor: '#565656',
      pointRadius,
      pointHitRadius: LINE_POINT_HIT_RADIUS,
      lineTension: 0,
    }]
  };

  updateXAxesByBoundaries(scope.temperatur.options, boundaries);
  updateTicks(data, scope.temperatur.options);

  if (data.length > 1 && _.has(scope, 'sensorData.temperatureThreshold')) {
    // const warningThreshold = {
    //   top: 90,
    //   // bottom: scope.sensorData.temperatureThreshold || 40
    //   bottom: 80
    // };
    //
    // appendThresholdAreaToDatasets(scope.range, options.datasets, warningThreshold, boundaries, 'rgba(255, 255, 0, 0.3)');

    const alarmThreshold = {
      top: scope.temperatur.options.scales.yAxes.ticks.max,
      bottom: 90
    };

    appendThresholdAreaToDatasets(scope.range, options.datasets, alarmThreshold, boundaries);
  }

  return options;
};

export const getScaledData = (data, boundaries) => {
    if (boundaries) {
      return _.filter(data, (date) => {
        return date.x.getTime() >= boundaries.startDate.getTime() && date.x.getTime() <= boundaries.endDate.getTime();
      });
    } else {
      return data;
    }
};

export const getScaledLabels = (labels, boundaries) => {
    if (boundaries) {
      return _.filter(labels, (date) => {
        return date.getTime() >= boundaries.startDate.getTime() && date.getTime() <= boundaries.endDate.getTime();
      });
    } else {
      return labels;
    }
};
