import { FMS_Artifact, FMS_Entry } from './vehicle-monitoring.model';
import { ARTIFACTS } from './vehicle-monitoring.artifacts';
import { utcToLocalDate } from '@parts/sensor-data/sensor-history-chart/sensor-history-chart.helper';

import * as _ from 'lodash';

const filterArtifacts = (): FMS_Artifact[]  => {
  return _
    .chain(ARTIFACTS)
    .filter({ active: true })
    .sortBy(['sortIndex'])
    .value();
};

const FILTERED_ARTIFACTS = filterArtifacts();

const getEntry = (artifact: FMS_Artifact, fmsData: any): FMS_Entry => {
  return {
    artifact,
    payload: _.get(fmsData, `canBusProperty.${artifact.key}`),
  };
};

export const prepareEntries = (fmsData: any): FMS_Entry[] => {
  return _
    .chain(FILTERED_ARTIFACTS)
    .map((artifact) => {
      const entry = getEntry(artifact, fmsData);
        // convert value to unit using unit factor
        if (entry.payload && entry.artifact.unitFactor) {
            entry.payload = entry.payload * entry.artifact.unitFactor;
        }
        // round float values down to specified precision
        if (entry.artifact.floatPrecision >= 0) {
            const multiplier = Math.pow(10, entry.artifact.floatPrecision);
            entry.payload = Math.floor(entry.payload * multiplier) / multiplier;
        }
      return entry;
    })
    // filter if no data has been provided by http request
    // .filter((entry: FMS_Entry) => {
    //   return _.isUndefined(entry.payload) === false;
    // })
    .value();
};

const prepareEntrySetting = (artifact: FMS_Artifact, data: any): FMS_Entry => {
  const selected = _.get(data, artifact.key, false);

  return { artifact, selected };
};

export const prepareEntrySettings = (data: any, source: string): FMS_Entry[] => {
  return _
    .chain(FILTERED_ARTIFACTS)
    .filter((artifact) => {
      return artifact.source === source;
    })
    .map((artifact) => {
      return prepareEntrySetting(artifact, data);
    })
    .value();
};

export const entriesToPropertySettings = (entries: FMS_Entry[]): { [x: string]: boolean; } => {
  return _
    .chain(entries)
    .reduce((settings: any, entry: FMS_Entry) => {
      _.set(settings, entry.artifact.key, entry.selected);
      return settings;
    }, {})
    .value();
};

export const serializeData = (data: any[]): any[] => {
  return _
    .chain(data)
    .map((point) => {
      return {
        x: utcToLocalDate(_.get(point, 'timestamp')),
        y: parseFloat(_.get(point, 'value')),
      }
    })
    .filter((point) => {
      return _.isNaN(point.y) === false;
    })
    .value();
};

export const serializeValues = (data: any[]): any[] => {
  return _
    .chain(data)
    .map((point) => {
      return {
        date: utcToLocalDate(_.get(point, 'timestamp')),
        value: _.get(point, 'value'),
      }
    })
    .filter((point) => {
      return _.isNull(point.value) === false && _.isUndefined(point.value) === false;
    })
    .value();
};

export const prepareTimeseriesHistoryResponse = (response: any): any => {
  if (!response) {
    return { values: [] };
  }

  if (_.includes(['Integer', 'Double', 'Long'], _.get(response, 'classType'))) {
    response.values = serializeData(response.values);
  } else {
    response.values = serializeValues(response.values);
  }

  return response;
};
