import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ThresholdSettings } from '../sensor.model';
import { TpmsSensor } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';

import { SensorCalibrationService } from './sensor-calibration.service';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-sensor-calibration',
  templateUrl: './sensor-calibration.component.html',
  styleUrls: ['./sensor-calibration.component.scss']
})
export class SensorCalibrationComponent implements OnInit {
  @Input() sensor: TpmsSensor;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() update: EventEmitter<void> = new EventEmitter<void>();

  public settings: TpmsSensor = null;
  public threshold: ThresholdSettings = {};
  public loading = false;

  constructor(private calibration: SensorCalibrationService) { }

  ngOnInit() {
    this.getCurrentSettings();
  }

  getCurrentSettings() {
    this.loading = true;

    const tpmsSensorId = _.get(this.sensor, 'tpmsSensorId');
    this.calibration.getCurrentSettings(tpmsSensorId).pipe(
      finalize(() => {
        this.loading = false;
      }))
      .subscribe(
        (settings: TpmsSensor) => {
          this.settings = settings;
          // console.log('settings', settings);

          this.threshold.tpmsSensorId = tpmsSensorId;
          this.threshold.pressureSetpoint = _.get(this.settings, 'pressureSetpoint');
          this.threshold.temperatureThreshold = _.get(this.settings, 'temperatureThreshold');
        },
        (error) => {
          this.settings = null;
          console.log(error);
        }
      );
  }

  setCurrentSettings() {
    this.loading = true;

    this.calibration.setCurrentSettings(this.threshold).pipe(
      finalize(() => {
        this.loading = false;
        this.update.emit();
      }))
      .subscribe(
        (settings) => {
          // console.log(settings);
          this.settings = settings;
        },
        (error) => {
          console.log(error);
        }
      );
  }

}
