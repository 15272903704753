import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AxleTire } from '../axle-tire.model';

import { UserService } from '@user/user.service';

@Component({
  selector: '[ifms-axle-tire-part]',
  templateUrl: './axle-tire-part.component.html',
  styleUrls: ['./axle-tire-part.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AxleTirePartComponent {
  @Input() tire: AxleTire;
  @Output() clickTirePosition: EventEmitter<number> = new EventEmitter();

  constructor(public userService: UserService) { }
}
