import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ifms-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() data: any;
  @Output() selectedData = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  setCheckbox(event) {
    this.selectedData.emit({'name': this.data.name, 'value': event.target.checked});
  }
}
