import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TpmsSensorEntry } from '../sensor-equipment/sensor-equipment.model';

@Component({
  selector: 'ifms-sensor-placeholder',
  templateUrl: './sensor-placeholder.component.html',
  styleUrls: ['./sensor-placeholder.component.scss']
})
export class SensorPlaceholderComponent implements OnInit {

  @Input() entry: TpmsSensorEntry;
  @Input() position: string;

  @Output() selected: EventEmitter<TpmsSensorEntry> = new EventEmitter<TpmsSensorEntry>();

  constructor() { }

  ngOnInit() {
  }

}
