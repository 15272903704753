import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { User } from '@user/user.model';

@Component({
  selector: 'ifms-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit  {

  @Input() users: User[];
  @Output() selected: EventEmitter<User> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
