import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { forkJoin, fromEvent, Observable, Subscription } from 'rxjs';

import { Entry, Internationalisation, PathValueJson } from './n4translate.model';
import { buildJSONbyEntries, getEntries } from './n4translate.helper';

import { N4translateService } from './n4translate.service';
import { TranslateService } from '@ngx-translate/core';
import { Paginator } from 'primeng/paginator';

import * as _ from 'lodash';
import { TabsetComponent } from '@shared/components/tabset/tabset.component';
import { debounceTime, finalize, map, mergeMap } from 'rxjs/operators';

export interface Language {
  key?: string;
}

export interface PaginationEvent {
  first?: number;
  rows?: number;
  page?: number;
  pageCount?: number;
}

@Component({
  selector: 'ifms-n4translate',
  templateUrl: './n4translate.component.html',
  styleUrls: ['./n4translate.component.scss']
})
export class N4translateComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('translationTarget', { static: false }) translationInput: ElementRef;
  @ViewChild('paginator', { static: false }) paginator: Paginator;
  @ViewChild('tabSet', { static: true }) tabSet: TabsetComponent;

  public codes: string[];
  public selectedCode: string;

  public translations: Internationalisation;
  public tanslationsAsText: string;
  public tanslationsAsTextIsValidJSON: boolean;

  public loading = false;

  public showCreateDialog = false;
  public language: Language = {};

  public needle: string;
  public entries: Entry[] = [];
  public filteredEntries: Entry[] = [];
  public visibleEntries: Entry[];

  public view = 'table';

  public subscriptions: Subscription[] = [];

  private lastAdded = '';

  constructor(private n4translate: N4translateService,
              private route: ActivatedRoute,
              private translate: TranslateService) { }

  ngOnInit() {
    this.getCodes().subscribe((codes) => {
      this.selectedCode = _.first(codes);
      this.getTranslation(this.selectedCode);
    });

    const jsonViewSub: Subscription = this.route.queryParams
      .subscribe((params) => {
        this.view = (_.get(params, 'json') === 'true') ? 'json' : 'table';
      });

    this.subscriptions.push(jsonViewSub);
  }

  ngAfterViewInit() {
    if (this.translationInput) {


        const translationInputSub: Subscription = fromEvent(this.translationInput.nativeElement, 'keydown').pipe(
            debounceTime(1000)
        ).subscribe((event: KeyboardEvent) => {
            this.validateTranslationInput();
        });

      this.subscriptions.push(translationInputSub);
    }
  }

  ngOnDestroy() {
    _.forEach(this.subscriptions, subscription => subscription.unsubscribe());
  }

  getCodes(): Observable<string[]> {
    this.loading = true;

    return this.n4translate.getTranslationConfigKeys().pipe(
        map((codes: string[]) => {
            this.codes = codes;
            return this.codes;
        }),
        finalize(() => {
            this.loading = false;
        })
    );

  }

  getTranslationByTab(tab: Component) {
    const code = _.get(tab, 'tabTitle');

    if (code) {
      this.selectedCode = code;
      this.getTranslation(code);
    }
  }

  getTranslation(code: string) {
    if (_.isUndefined(code)) { return; }

    this.loading = true;

    this.getDefaultConfig(code).subscribe();
  }

  updateTranslationConfig() {
    this.loading = true;

    try {
      const body: PathValueJson = {
        path: '',
        value: JSON.parse(this.tanslationsAsText)
      };

      this.updateTranslationByBody(body);
    } catch (err) {
      this.loading = false;
    }
  }

  updateTranslationConfigByEntries() {
    this.loading = true;

    const content = _.merge(buildJSONbyEntries(this.entries), buildJSONbyEntries(this.visibleEntries));

    const body: PathValueJson = {
      path: '',
      value: content
    };

    this.updateTranslationByBody(body);
  }

  private updateTranslationByBody(body: PathValueJson) {
    this.n4translate.updateTranslationConfig(this.selectedCode, body).pipe(
            map((translations: Internationalisation) => {
                if (this.selectedCode) {
                    this.translate.setTranslation(this.selectedCode, _.get(translations, 'translationConfig', {}));
                }
            }),
            finalize(() => {
                this.loading = false;
            })
        ).subscribe();
  }

  createTranslationConfig() {
    if (_.has(this, 'language.key') === false) { return; }

    this.loading = true;

    this.n4translate.createTranslationConfig(this.language.key).pipe(
        mergeMap((translations: Internationalisation) => {
            this.selectedCode = this.language.key;

            this.getTranslation(this.selectedCode);

            return this.getCodes();
        }),
        finalize(() => {
            this.language = {};
            this.showCreateDialog = false;
        })
    ).subscribe(() => {
        this.getDefaultConfig('en').subscribe(() => {

          this.translations.translationConfig.FLEET.LANGUAGE_CODES[this.selectedCode] = this.selectedCode;
          this.lastAdded = this.selectedCode;

          const content = _.get(this.translations, 'translationConfig', {});
          this.tanslationsAsText = JSON.stringify(content, null, 2);
          this.updateTranslationConfig();
          this.translate.setTranslation(this.selectedCode, _.get(this.translations, 'translationConfig', {}));

          this.entries = getEntries(content);
          this.filteredEntries = this.entries;
          this.needle = null;
          this.paginate();
          this.resetPagination();
            this.setNewLanguageToAllOtherLanguages().subscribe(() => {
                this.tabSet.tabs.forEach((tab) => {
                    if (_.get(tab, 'tabTitle') === this.lastAdded) {
                        this.tabSet.selectTab(tab);
                    }
                });
            });

        });
      });
  }

  codeIsOccupied(code: string): boolean {
    return _.includes(this.codes, code);
  }

  validateTranslationInput() {
    this.tanslationsAsTextIsValidJSON = this.textInputIsValidJSON();
  }

  private textInputIsValidJSON(): boolean {
    try {
      JSON.parse(this.tanslationsAsText);
    } catch (err) {
      return false;
    }

    return true;
  }

  paginate(event: PaginationEvent = { first: 0, rows: 5 }) {
    this.visibleEntries = _.slice(this.filteredEntries, event.first, event.first + event.rows);
  }

  filter() {
    if (_.isString(this.needle)) {
      const needle = _.toLower(this.needle);
      this.filteredEntries = _.filter(this.entries, (entry: Entry) => {
        return _.includes(_.toLower(entry.value), needle)
            || _.includes(_.toLower(entry.key), needle);
      });
    } else {
      this.filteredEntries = this.entries;
    }

    this.resetPagination();
    this.paginate();
  }

  private resetPagination() {
    if (this.paginator && this.paginator.changePage) {
      this.paginator.changePage(0);
    }
  }

  private getDefaultConfig(code) {
    return this.n4translate.getTranslationConfig(code).pipe(
        map((translations: Internationalisation) => {

            this.translations = translations;

            const content = _.get(translations, 'translationConfig', {});
            this.tanslationsAsText = JSON.stringify(content, null, 2);

            this.entries = getEntries(content);
            this.filteredEntries = this.entries;
            this.needle = null;
            this.paginate();
            this.resetPagination();
        }),
        finalize(() => {
            this.loading = false;
        })
    );
  }

  private setNewLanguageToAllOtherLanguages() {

    const arr = [];

    for (const code of this.codes) {
      if (code === this.lastAdded) {
        continue;
      }
        arr.push(this.n4translate.getTranslationConfig(code).pipe(
                mergeMap((translations: Internationalisation) => {
                    translations.translationConfig.FLEET.LANGUAGE_CODES[this.lastAdded] = this.lastAdded;

                    const body: PathValueJson = {
                        path: '',
                        value: translations.translationConfig
                    };
                    return this.n4translate.updateTranslationConfig(code, body)
                }),
                map((translations: Internationalisation) => {
                    this.translate.setTranslation(code, _.get(translations, 'translationConfig', {}));
                    if (this.selectedCode === code) {
                        this.translations = translations;
                        this.entries = getEntries(translations.translationConfig);
                    }
                })
            )
        );
    }
      return forkJoin(arr);
  }
}
