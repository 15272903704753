import { Pipe, PipeTransform } from '@angular/core';

import { isNumber } from 'lodash';

@Pipe({
  name: 'getNumber'
})
export class GetNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return isNumber(value) ? value : args;
  }

}
