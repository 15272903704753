import { finalize } from 'rxjs/operators';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { IfmsBox } from '../ifms-box/ifms-box.model';

import { IfmsBoxesService } from '../ifms-boxes.service';

@Component({
  selector: 'ifms-boxes-lazy-loading-list',
  templateUrl: './ifms-boxes-lazy-loading-list.component.html',
  styleUrls: ['./ifms-boxes-lazy-loading-list.component.scss']
})
export class IfmsBoxesLazyLoadingListComponent implements OnInit {

  @Output() selected: EventEmitter<IfmsBox> = new EventEmitter();

  public boxes: IfmsBox[];
  public box: IfmsBox[];
  public loading = false;
  public onCreatingBox = false;

  constructor(private ifmsBoxesService: IfmsBoxesService) { }

  ngOnInit() {
    this.load();
  }

  private load() {
    this.loading = true;

    // this.ifmsBoxesService.getBoxes()
    this.ifmsBoxesService.getUnassignedBoxes().pipe(
      finalize(() => {
        this.loading = false;
      }))
      .subscribe((boxes: IfmsBox[]) => {
        this.boxes = boxes;
      })
  }

}
