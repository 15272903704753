import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Cropper from 'cropperjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { DepotReportsLogoInterface } from '@depot/fleet-depot-tire-and-wheel-monitoring-report/fleet-depot-tire-and-wheel-monitoring-report.interfaces';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit, AfterViewInit {

    @Input() imageOutputSettings: Cropper.GetCroppedCanvasOptions = {} as Cropper.GetCroppedCanvasOptions;
    @Input() cropperSettingsInput: Cropper.Options;
    @Input() uploadURL: string;
    @Input() fileType = 'image/png';
    @Input() quality = 80;
    @Input() responsive = true;
    @Output() uploadedImageSrc = new EventEmitter<DepotReportsLogoInterface>();

    private cropper: Cropper;
    private cropperOptions: Cropper.Options = {} as Cropper.Options;
    private croppedCanvasOptions: Cropper.GetCroppedCanvasOptions = {} as Cropper.GetCroppedCanvasOptions;

    public instanceId: number;
    public canBeUploaded = false;
    public configError = false;
    public configErrorMessages: string[] = [];

    private static calculateRatioForImageTags(instanceId, aspectRatio: number) {
        const imageCropperHTML = document.getElementsByClassName(`image-cropper__image-to-crop-container ${instanceId}`);
        const imageCropperHTMLWidth = imageCropperHTML[0].clientWidth;
        const imageCropperHTMLHeight = imageCropperHTMLWidth / (aspectRatio);
        imageCropperHTML[0].setAttribute('style', `width: ${imageCropperHTMLWidth}px; height: ${imageCropperHTMLHeight}px`);

        const imagePreviewHTML = document.getElementsByClassName(`image-cropper__preview ${instanceId}`);
        const imagePreviewHTMLWidth = imagePreviewHTML[0].clientWidth;
        const imagePreviewHTMLHeight = imagePreviewHTMLWidth / (aspectRatio);
        imagePreviewHTML[0].setAttribute('style', `width: ${imagePreviewHTMLWidth}px; height: ${imagePreviewHTMLHeight}px`);
    }

    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
    ) {
        this.instanceId = _.uniqueId('preview_');
    }

    ngOnInit(): void {
        if (!this.uploadURL) {
            this.configError = true;
            this.configErrorMessages.push('No upload URL provided');
        }

        this.cropperOptions = {
            aspectRatio: 16 / 9,
            zoomable: false,
            scalable: false,
            preview: `.image-cropper__preview.${this.instanceId}`,
        };

        if (this.cropperSettingsInput) {
            this.cropperOptions = Object.assign(this.cropperOptions, this.cropperSettingsInput);
        }

        if (this.imageOutputSettings) {
            this.croppedCanvasOptions = Object.assign(this.croppedCanvasOptions, this.imageOutputSettings);
            this.croppedCanvasOptions.height = this.croppedCanvasOptions.width / (this.cropperOptions.aspectRatio);
            if (this.fileType === 'image/jpeg') {
                this.croppedCanvasOptions.fillColor = '#ffffff';
            }
        } else {
            this.configError = true;
            this.configErrorMessages.push('Please provide output width and height');
        }
    }

    ngAfterViewInit() {
        if (!this.configError && !this.responsive) {
            ImageCropperComponent.calculateRatioForImageTags(this.instanceId, this.cropperOptions.aspectRatio);
        }
    }

    public selectFileToUpload(files: FileList) {
        if (files.length === 0) {
            return;
        }

        this.canBeUploaded = true;

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = ($event) => {

            // create image HTML element. This element is used by the cropper
            const img = new Image();
            img.src = $event.target.result as string;

            const parent: HTMLCollectionOf<Element> = document.getElementsByClassName(
                `image-cropper__image-to-crop-container ${this.instanceId}`
            );
            const parentElement: Element = parent.item(0);

            /* Remove all old child's from parent container
            * must only be done if choosing another image without reloading the page
            * CropperJS replaces the img tag with his own HTML code
            * so we have to remove it and put our img tag again
            */
            if (parentElement.childElementCount > 1) {
                while (parentElement.lastElementChild) {
                    parentElement.removeChild(parentElement.lastElementChild);
                }
            }

            parentElement.appendChild(img);

            this.initCropper(img, this.cropperOptions);
        };
    }

    private initCropper(image, cropperSettings) {
        this.cropper = new Cropper(image, cropperSettings);
    }

    upload() {
        // Get the cropped image as blob and send it as multipart/form-data to the provided backend URL.
        this.cropper.getCroppedCanvas(this.croppedCanvasOptions).toBlob((blob) => {

            const formData = new FormData();
            formData.append('userfile', blob);

            const headers = new HttpHeaders();
            headers.set('enctype', 'multipart/form-data');
            headers.set('Accept', 'application/json');

            this.http.post(this.uploadURL, formData, {headers}).subscribe((data: DepotReportsLogoInterface) => {
                this.uploadedImageSrc.next(data);
                this.notificationsService.success(this.translateService.instant('IMAGE-CROPPER.UPLOAD_SUCCESSFUL'));
            });

        }, this.fileType, this.quality);
    }
}
