import { Component, OnInit } from '@angular/core';

import { MandatorService } from './mandator.service';

@Component({
  selector: 'ifms-mandator',
  templateUrl: './mandator.component.html',
  styleUrls: ['./mandator.component.scss']
})
export class MandatorComponent implements OnInit {

  constructor(public mandatorService: MandatorService) { }

  ngOnInit() {
  }

}
