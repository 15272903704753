import * as _ from 'lodash';

import {
    Filter,
    FilterValue,
    FilterValuesMap
} from '@fleet/fleet-overview/fleet-units-filter-panel/fleet-units-filter-panel.model';
import { FleetUnit } from './fleet-units/fleet-unit/fleet-unit.model';

const FILTERS = {
  countryCode: { name: 'COUNTRY_CODE' },
  externalId: { name: 'EXTERNAL_ID' },
  numberPlate: { name: 'NUMBER_PLATE' },
  usage: { name: 'USAGE' },
  vehicleType: { name: 'VEHICLE_TYPE' },
};

export const extractFilters = (fleetUnits: FleetUnit[]): Filter[] => {
  const filters = _.cloneDeep(FILTERS);

  _.forOwn(filters, (filter, key) => {
    filter.values = _
      .chain(fleetUnits)
      .map((fleetUnit) => {
        return [
          _.get(fleetUnit, `tractor.${key}`),
          _.get(fleetUnit, `trailer.${key}`),
        ];
      })
      .flatten()
      .filter(value => _.isEmpty(value) === false)
      .uniq()
      .sortBy()
      .value();
  });

  return _.values(filters);
};

export const filterFleetUnits = (fleetUnits: FleetUnit[], filterValues: FilterValuesMap): FleetUnit[] => {
  const selectedValues: { [name: string]: FilterValue[]; } = {};
  const keys = _.keys(FILTERS);

  _.map(FILTERS, ({ name }, key) => {
    selectedValues[key] = _.map(filterValues[name], 'value');
  });

  const allValues = _
    .chain(_.values(selectedValues))
    .flatten()
    .value();

  if (allValues.length === 0) {
    return fleetUnits;
  }

  return  _
    .chain(fleetUnits)
    .filter((fleetUnit) => {
      return _.find(keys, (key) => {
        const values = [
          _.get(fleetUnit, `tractor.${key}`),
          _.get(fleetUnit, `trailer.${key}`),
        ];

        return _.intersection(values, selectedValues[key]).length > 0;
      });
    })
    .value();
};

export const filterFleetUnitsBySearchNeedle = (needle: string, fleetUnits: FleetUnit[]): FleetUnit[] => {
  if (!fleetUnits) {
    return null;
  }

  needle = _.toLower(_.trim(needle));

  return _.filter(fleetUnits, (fleetUnit) => {
    const numberPlate = _.get(fleetUnit, 'tractor.numberPlate') || _.get(fleetUnit, 'trailer.numberPlate');
    const mandatorName = _.get(fleetUnit, 'mandator.mandatorName');
    const imeiTractor = _.get(fleetUnit, 'tractor.imei');
    const imeiTrailer = _.get(fleetUnit, 'trailer.imei');
    const sensorEquipmentExternalIdTractor = _.get(fleetUnit, 'tractor.sensorEquipmentExternalId');
    const sensorEquipmentExternalIdTrailer = _.get(fleetUnit, 'trailer.sensorEquipmentExternalId');

    return _.includes(_.toLower(numberPlate), needle)
        || _.includes(_.toLower(mandatorName), needle)
        || _.includes(_.toLower(imeiTractor), needle)
        || _.includes(_.toLower(imeiTrailer), needle)
        || _.includes(_.toLower(sensorEquipmentExternalIdTractor), needle)
        || _.includes(_.toLower(sensorEquipmentExternalIdTrailer), needle);

  });
};
