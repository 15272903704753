import { ActionReducerMap } from '@ngrx/store';

// export interface State {
//
// }


export const reducers: ActionReducerMap<any> = {

};
