import { StoreModule } from '@ngrx/store';

import { userReducer } from './user/user.reducer';
import { fleetReducer } from './fleet.reducer';
import { fleetDepotReducer } from './fleet-depot/fleet-depot.reducer';
import { fleetVehicleMonitoringAnalysisReducer } from '@depot/fleet-vehicle-monitoring-analysis/fleet-vehicle-monitoring-analysis.reducer';
import { fleetUnitsReducer } from './fleet-units/fleet-units.reducer';
import { vehicleReducer } from './unit-state/vehicle.reducer';
import { vehicleConfiguratorReducer } from '@configurator/vehicle-configurator.reducer';

const reducers = {
    fleet: fleetReducer,
    fleetDepot: fleetDepotReducer,
    fleetVehicleMonitoringAnalysis: fleetVehicleMonitoringAnalysisReducer,
    fleetUnits: fleetUnitsReducer,
    vehicle: vehicleReducer,
    vehicleConfigurator: vehicleConfiguratorReducer,
    user: userReducer,
};

// https://github.com/ngrx/platform/issues/2109
export const FleetStore = StoreModule.forRoot(reducers, {
    runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
    }
});
