import { combineLatest as observableCombineLatest, Observable, of } from 'rxjs';

import { filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';


import { FleetState } from '@fleet/fleet.model';

import {
    CalculationGraph,
    Currency,
    FleetDepotState,
    FleetVehicle,
    FleetVehicleTree,
    OverallReport,
    VehicleGroup,
    VehicleGroupCreate,
    VehicleGroupHardware,
    VehicleGroupsByIdsMap
} from './fleet-depot.model';

import { FleetDepotActions } from './fleet-depot.reducer';
import { VehicleTemplate } from '@configurator/vehicle-configurator.model';
import { Mandator } from '../mandator/mandator.model';
import * as FleetDepot from './fleet-depot.helper';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import * as _ from 'lodash';

@Injectable()
export class FleetDepotService {

  public state: Observable<FleetDepotState>;
  public currencies: Observable<Currency[]>;
  public depot: Observable<CalculationGraph>;

  public rootMandator: Observable<any>;
  public vehicleGroups: Observable<VehicleGroup[]>;
  public rootVehicleGroup: Observable<VehicleGroup>;
  public mandator: Observable<Mandator>;

  public selectedVehicleGroupId: Observable<number>;
  public selectedVehicleGroup: Observable<VehicleGroup>;

  public vehicles: Observable<FleetVehicle[]>;
  public fleetVehiclesTree: Observable<FleetVehicleTree[]>;
  public groupedFleetVehicles: Observable<FleetVehicle[][]>;
  public vehicleGroupsByIds: Observable<VehicleGroupsByIdsMap>;

  public loadingVehicles = false;

  constructor(private store: Store<any>, private http: HttpClient) {
    this.state = this.store.select('fleetDepot');

    this.currencies = this.state.pipe(
      map((state: FleetDepotState) => {
        if (state) {
          return state.currencies;
        }
      }));

    this.depot = this.state.pipe(
      map((state: FleetDepotState) => {
        if (state) {
          return state.depot;
        }
      }));

    this.rootMandator = this.depot.pipe(
      map((depot: CalculationGraph) => {
        if (depot) {
          return depot.mandator;
        }
      }));

    this.vehicleGroups = this.depot.pipe(
      map((depot: CalculationGraph) => {
        if (depot) {
          return depot.vehicleGroupDtos;
        }
      }));

    this.vehicleGroupsByIds = this.depot.pipe(
      map((depot: CalculationGraph) => {
        if (depot) {
          return depot.vehicleGroupsByIds;
        }
      }));

    this.rootVehicleGroup = this.vehicleGroups.pipe(
      map((vehicleGroups: VehicleGroup[]) => {
        if (vehicleGroups && vehicleGroups.length > 0) {
          return vehicleGroups[0];
        }
      }));

    this.vehicles = this.state.pipe(
      map((state: FleetDepotState) => {
        if (state) {
          return state.vehicles;
        }
      }));

    this.selectedVehicleGroupId = this.store.select('fleet').pipe(
      map((state: FleetState) => {
        const candidate = _.get(state, 'vehicleGroupId');
        if (candidate) { return parseInt(candidate, 10); }
      }),
      filter(_.isNumber), );

    this.selectedVehicleGroup = observableCombineLatest(
        this.selectedVehicleGroupId,
        this.vehicleGroups,
      ).pipe(
      map(([vehicleGroupId, vehicleGroups]) => {
        return FleetDepot.findVehicleGroup(vehicleGroups, vehicleGroupId);
      }));

    this.fleetVehiclesTree = observableCombineLatest(
        this.selectedVehicleGroup.pipe(filter(vehicleGroup => _.isPlainObject(vehicleGroup))),
        this.vehicles.pipe(filter(fleetVehicles => _.isArray(fleetVehicles)))
      ).pipe(
      map(([vehicleGroup, fleetVehicles]) => {
        return FleetDepot.treefyFleetVehicles(vehicleGroup, fleetVehicles);
      }));

    this.groupedFleetVehicles = this.fleetVehiclesTree.pipe(
      map((fleetVehiclesTree: FleetVehicleTree[]) => {
        if (fleetVehiclesTree) {
          return FleetDepot.flattenFleetVehiclesTree(_.first(fleetVehiclesTree));
        }
      }));

    this.mandator = this.depot.pipe(
      map((depot: CalculationGraph) => {
        if (depot) {
          return depot.mandator;
        }
      }));

    this.refreshCurrencies();
  }

  load() {
    this.getCalculationGraph().subscribe();
  }

  clear() {
    this.store.dispatch({
      type: FleetDepotActions.SET_DEFAULT,
    });

    this.refreshCurrencies();
  }

  refreshCurrencies() {
    this.loadCurrencies().pipe(
      map((currencies: Currency[]) => {
        this.store.dispatch({
          type: FleetDepotActions.DEPOT_LOAD_CURRENCIES,
          payload: currencies
        });
      }))
      .subscribe();
  }

  getCalculationGraph(): Observable<CalculationGraph> {
    return this.http.get(`${environment.apiUrl}/vehiclegroups/`).pipe(
      map((graph: CalculationGraph) => {
        _.set(graph, 'vehicleGroupsByIds', FleetDepot.mapGroups(graph.vehicleGroupDtos));

        this.store.dispatch({
          type: FleetDepotActions.DEPOT_LOADED,
          payload: graph
        });

        return graph;
      }));
  }

  getVehicles(vehicleGroupId: number) {
    const loadingTrigger = setTimeout(() => {
      this.loadingVehicles = true;
    }, 200);

    this.http.get(`${environment.apiUrl}/vehiclegroups/${vehicleGroupId}/fleetVehicles/?recursive=true`)
      .subscribe((vehicles: FleetVehicle[]) => {
        clearTimeout(loadingTrigger);
        this.loadingVehicles = false;

        if (vehicles) {
          this.store.dispatch({
            type: FleetDepotActions.VEHICLES_LOADED,
            payload: vehicles
          });
        }
      }, (err) => {
        clearTimeout(loadingTrigger);
        this.loadingVehicles = false;

        return of(err);
      });
  }

  getVehicle(vehicleGroupId: number, fleetVehicleId: number) {
    this.http.get(`${environment.apiUrl}/vehiclegroups/${vehicleGroupId}/fleetVehicles/${fleetVehicleId}`)
      .subscribe((vehicle: FleetVehicle) => {
        if (vehicle) {
          this.store.dispatch({
            type: FleetDepotActions.SET_VEHICLE,
            payload: vehicle
          });
        }
      });
  }

  private dispatchVehicle(fleetVehicle: FleetVehicle) {
    if (fleetVehicle) {
      this.store.dispatch({
        type: FleetDepotActions.SET_VEHICLE,
        payload: fleetVehicle
      });
    }
  }

  searchFleetVehicles(vehicleGroupId: number): Observable<FleetVehicle[]> {
    return this.http.get(`${environment.apiUrl}/vehiclegroups/${vehicleGroupId}/fleetVehicles/?recursive=true`).pipe(
      map((vehicles: FleetVehicle[]) => {
        return vehicles;
      }));
  }

  searchFleetVehiclesWithoutUnits(): Observable<FleetVehicle[]> {
    return this.http.get(`${environment.apiUrl}/fleetvehicles/withoutFleetUnits`).pipe(
      map((vehicles: FleetVehicle[]) => {
        return vehicles;
      }));
  }

  getVehicleGroup(vehicleGroupId: number): Observable<VehicleGroup> {
    return <Observable<VehicleGroup>>this.http.get(`${environment.apiUrl}/vehiclegroups/${vehicleGroupId}`);
  }

  createVehicleGroup(node: VehicleGroupCreate): Observable<VehicleGroup> {
    let vehicleGroup: VehicleGroup;

    return this.http.post(`${environment.apiUrl}/vehiclegroups/`, node).pipe(
      map((group: VehicleGroup) => {
        vehicleGroup = group;
      }),
      mergeMap(() => {
        return this.getCalculationGraph();
      }),
      map(() => {
        return vehicleGroup;
      }), );
  }

  updateVehicleGroup(node: VehicleGroupCreate): Observable<VehicleGroup> {
    let vehicleGroup: VehicleGroup;

    return this.http.put(`${environment.apiUrl}/vehiclegroups/${node.vehicleGroupId}`, node).pipe(
      map((group: VehicleGroup) => {
        vehicleGroup = group;
      }),
      mergeMap(() => {
        return this.getCalculationGraph();
      }),
      map(() => {
        return vehicleGroup;
      }), );
  }

  deleteVehicleGroup(vehicleGroupId: number): Observable<CalculationGraph> {
    return this.http.delete(`${environment.apiUrl}/vehiclegroups/${vehicleGroupId}`, { responseType: 'text' }).pipe(
      mergeMap(() => {
        return this.getCalculationGraph();
      }));
  }

  getFleetVehicles(): Observable<FleetVehicle[]> {
    return <Observable<FleetVehicle[]>>this.http.get(`${environment.apiUrl}/fleetvehicles/`)
  }

  getFleetVehicle(fleetVehicleId: number): Observable<FleetVehicle> {
    return <Observable<FleetVehicle>>this.http.get(`${environment.apiUrl}/fleetvehicles/${fleetVehicleId}`)
  }

  createFleetVehicle(fleetVehicle: FleetVehicle): Observable<FleetVehicle> {
    return this.createFleetVehicleByRequest(fleetVehicle).pipe(
      map((fleetVehicle: FleetVehicle) => {
        this.getVehicle(fleetVehicle.vehicleGroupId, fleetVehicle.fleetVehicleId);
        return fleetVehicle;
      }));
  }

  createFleetVehicleByRequest(fleetVehicle: FleetVehicle): Observable<FleetVehicle> {
    if (fleetVehicle.vehicle) {
      fleetVehicle.vehicleId = fleetVehicle.vehicle.vehicleId;
      fleetVehicle.vehicle = undefined;
    }

    return <Observable<FleetVehicle>>this.http.post(`${environment.apiUrl}/fleetvehicles/`, fleetVehicle);
  }

  updateFleetVehicle(fleetVehicle: FleetVehicle): Observable<FleetVehicle> {
    return this.updateFleetVehicleByRequest(fleetVehicle).pipe(
      map((fleetVehicle: FleetVehicle) => {
        this.getVehicle(fleetVehicle.vehicleGroupId, fleetVehicle.fleetVehicleId);
        return fleetVehicle;
      }));
  }

  updateFleetVehicleByRequest(fleetVehicle: FleetVehicle, method?): Observable<FleetVehicle> {
    if (fleetVehicle.vehicle) {
      fleetVehicle.vehicleId = fleetVehicle.vehicle.vehicleId;
    }

    if (method !== undefined) {
      return <Observable<FleetVehicle>>this.http.put(`${environment.apiUrl}/fleetvehicles/${fleetVehicle.fleetVehicleId}/` + method, fleetVehicle);
    }
    return <Observable<FleetVehicle>>this.http.put(`${environment.apiUrl}/fleetvehicles/${fleetVehicle.fleetVehicleId}`, fleetVehicle);
  }

  findVehicleGroupById(vehicleGroupId: number): Observable<VehicleGroup> {
    return this.vehicleGroups.pipe(
      map((vehicleGroups: VehicleGroup[]) => {
        if (vehicleGroups) {
          return FleetDepot.findVehicleGroup(vehicleGroups, vehicleGroupId);
        }
      }));
  }

  getVehicleTemplateBy(fleetVehicleId: number): Observable<VehicleTemplate> {
    return <Observable<VehicleTemplate>>this.http.get(`${environment.apiUrl}/fleetvehicles/${fleetVehicleId}/vehicletemplate`)
  }

  // deleteFleetVehicle(fleetVehicleId: number): Observable<FleetVehicle> {
  //   return <Observable<FleetVehicle>>this.http.delete(`${environment.apiUrl}/fleetvehicles/${fleetVehicleId}`);
  // }

  getFleetVehicleDetailedStatusBy(fleetVehicleId: number) {
    return <Observable<any>>this.http.get(`${environment.apiUrl}/fleetvehicles/${fleetVehicleId}/detailed-status`);
  }

  checkIfHardwareIsEmpty(vehicleGroupId: number): Observable<VehicleGroupHardware> {
      return <Observable<VehicleGroupHardware>>this.http.get(`${environment.apiUrl}/vehiclegroups/${vehicleGroupId}/isHardwareEmpty`);
  }

  getReportsForVehicleGroup(vehicleGroupId: number): Observable<OverallReport> {
      return <Observable<OverallReport>>this.http.get(`${environment.apiUrl}/vehicle-group-reports/${vehicleGroupId}`);
  }

  loadCurrencies(): Observable<Currency[]> {
      return <Observable<Currency[]>>this.http.get(`${environment.apiUrl}/localisation/currency`);
  }

  getAllDeactivatedFleetVehicles(): Observable<FleetVehicle[]> {
      return <Observable<FleetVehicle[]>>this.http.get(`${environment.apiUrl}/fleetvehicles/deactivated/`);
  }

  deactivateFleetVehicle(fleetVehicleId: number): Observable<FleetVehicle> {
    return <Observable<FleetVehicle>>this.http.put(`${environment.apiUrl}/fleetvehicles/${fleetVehicleId}/deactivate`, {});
  }

  reActivateFleetVehicle(fleetVehicleId: number): Observable<any> {
      return <Observable<any>>this.http.put(`${environment.apiUrl}/fleetvehicles/${fleetVehicleId}/re-activate`, {});
  }

}
