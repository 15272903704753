import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Badge, NotificationWithComment } from './notifications.model';

import { batchNotificationsWithComment } from './notifications.helper';

@Component({
  selector: 'ifms-fleet-unit-notifications',
  templateUrl: './fleet-unit-notifications.component.html',
  styleUrls: ['./fleet-unit-notifications.component.scss']
})
export class FleetUnitNotificationsComponent implements OnInit, OnChanges {

  @Input() notifications: NotificationWithComment[];
  @Input() status: string;
  @Input() color: string;

  public badges: Badge[];

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notifications) {
      this.badges = batchNotificationsWithComment(this.notifications);
    }
  }

}
