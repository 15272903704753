import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { internationalPrefix } from '@fleet/shared/helpers/international-number-prefix.helper';
import { Person } from '../person.model';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-person-form-input-content',
  templateUrl: './person-form-input-content.component.html',
  styleUrls: ['./person-form-input-content.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class PersonFormInputContentComponent implements OnInit {

  @Input() person: Person;
  // @Input() disabled: boolean = false;

  public internationalPrefix = _.orderBy(internationalPrefix, ['value']);
  public phone1Suffix: string = null;
  public selectedPrefixNumber1: any;
  public phone2Suffix: string = null;
  public selectedPrefixNumber2: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.person) {
      this.splitNumbers();
    }
  }

  ngOnInit() {
  }

  change(selectedPrefix, value, name) {
    if (name === 'phone1') {
      this.selectedPrefixNumber1 = selectedPrefix;
    } else if (name === 'phone2') {
      this.selectedPrefixNumber2 = selectedPrefix;
    }

    if (selectedPrefix && value) {
      this.person.contact[name] = `${selectedPrefix.value}::${value}`;
    }
  }

  private splitNumbers() {
    const splittedNumber1 = _.split(this.person.contact.phone1, '::');
    this.selectedPrefixNumber1 = _.find(this.internationalPrefix, { 'value': splittedNumber1[0]});
    if (splittedNumber1[1] !== undefined) {
      this.phone1Suffix = splittedNumber1[1];
    }

    const splittedNumber2 = _.split(this.person.contact.phone2, '::');
    this.selectedPrefixNumber2 = _.find(this.internationalPrefix, { 'value': splittedNumber2[0]});
    if (splittedNumber2[1] !== undefined) {
      this.phone2Suffix = splittedNumber2[1];
    }
  }

  removeNumber(name: string) {
    if (name === 'phone1') {
      this.selectedPrefixNumber1 = null;
      this.phone1Suffix = null;
      this.person.contact.phone1 = null;
    } else if (name === 'phone2') {
      this.selectedPrefixNumber2 = null;
      this.phone2Suffix = null;
      this.person.contact.phone2 = null;
    }
  }
}
