import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { User } from '../user.model';

import { UserService } from '../user.service';
import { NotificationsService } from 'angular2-notifications';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.scss']
})
export class ChangeUserPasswordComponent implements OnInit {

  @Input() user: User = <User>{};

  @Output() pending: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() updated: EventEmitter<User> = new EventEmitter();

  public passwordValidated = false;

  constructor(private userService: UserService,
              private notification: NotificationsService) { }

  ngOnInit() {
  }

  validate() {
    this.passwordValidated = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/
        .test(_.get(this.user, 'rawPassword1'))
        && _.get(this.user, 'rawPassword1') === _.get(this.user, 'rawPassword2');
  }

  update() {
    this.pending.emit(true);

    this.userService.update(this.user)
      .subscribe((user: User) => {
        this.pending.emit(false);
        this.updated.emit(user);
        this.notification.success('Success', 'User password has been changed!');
      }, (err) => {
        this.pending.emit(false);
        this.notification.remove();
        this.notification.error('Error on creation', 'Could not be changed!');
      });
  }

}
