import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: ['./mailing-list.component.scss']
})
export class MailingListComponent implements OnInit, OnChanges {

  @Input() mailingList: string;
  @Input() editMode = true;
  @Output() mailingListChange = new EventEmitter<string>();

  public emails: string[];
  public email: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.emails = _
      .chain(_.split(this.mailingList, ';'))
      .map(_.trim)
      .filter(email => _.isString(email) && email !== '')
      .value();
  }

  add() {
    this.emails.push(this.email);
    this.email = null;

    this.emitMailingList();
  }

  remove(email) {
    _.remove(this.emails, (entry) => {
      return entry === email;
    });

    this.emitMailingList();
  }

  private emitMailingList() {
    const newMailingList = this.emails.join(';');
    this.mailingListChange.emit(newMailingList);
  }

}
