import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { User } from '../user.model';
import { VehicleGroup } from '@depot/fleet-depot.model';

import { UserService } from '../user.service';
import { NotificationsService } from 'angular2-notifications';

import * as _ from 'lodash';

@Component({
  selector: 'ifms-users-vehicle-groups',
  templateUrl: './users-vehicle-groups.component.html',
  styleUrls: ['./users-vehicle-groups.component.scss']
})
export class UsersVehicleGroupsComponent implements OnInit {

  @Input() user: User;

  @Output() pending: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() updated: EventEmitter<VehicleGroup> = new EventEmitter();

  public rootVehicleGroup: VehicleGroup;
  public isSuperAdmin: boolean;
  public disabled: boolean;

  constructor(private userService: UserService,
              private notification: NotificationsService) { }

  ngOnInit() {
    this.getVehicleGroups();

    this.isSuperAdmin = this.userService.isSuperAdminBy(this.user);

    this.userService.isSameUser(this.user).pipe(
      take(1))
      .subscribe((isSameUser: boolean) => {
        this.disabled = isSameUser || this.isSuperAdmin;
      });
  }

  getVehicleGroups() {
    const userId = _.get(this.user, 'userId');

    if (userId) {
      this.userService.getVehicleGroups(userId)
        .subscribe((rootVehicleGroup: VehicleGroup) => {
          this.rootVehicleGroup = rootVehicleGroup;
        });
    }
  }

  update() {
    const userId = _.get(this.user, 'userId');

    if (userId) {
      this.pending.emit(true);

      this.userService.updateVehicleGroups(userId, _.get(this.rootVehicleGroup, 'childVehicleGroups'))
        .subscribe((rootVehicleGroup: VehicleGroup) => {
          this.pending.emit(false);
          this.updated.emit(rootVehicleGroup);
          this.notification.success('Success', 'User password has been changed!');
        }, (err) => {
          this.pending.emit(false);
          this.notification.remove();
          this.notification.error('Error on creation', 'Could not be changed!');
        });
    }
  }

}
