import { Pipe, PipeTransform } from '@angular/core';
import { isNull, isUndefined } from 'lodash';

@Pipe({
  name: 'bounceValue'
})
export class BounceValuePipe implements PipeTransform {

  transform(value: any, defaultValue?: any): any {
    if (isNull(value) || isUndefined(value) || isNaN(value)) {
      return defaultValue || '-';
    }

    return value;
  }

}
