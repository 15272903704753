import { catchError, map } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';

import { Observable, throwError } from 'rxjs';

import { environment } from '@env/environment';
import { HostConfigService } from '@shared/services/host-config/host-config.service';
import { HttpClient } from '@angular/common/http';

export class CustomLoader implements TranslateLoader {

  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const host = HostConfigService.getHost();

    return this.http.get(`${environment.apiUrl}/localisation/i18n/${lang}/${host}`).pipe(
      map((translations: any) => {
        if (translations && translations.translationConfig) {
          return translations.translationConfig
        }

        return throwError(new Error('empty'));
      }),
      catchError((err: any) => {
        return this.http.get(`/assets/i18n/${lang}.json`);
      }), );
  }
}
