import { Directive, ElementRef, HostListener, Input } from '@angular/core';

import * as _ from 'lodash';

@Directive({
  selector: '[ifmsTrimInputNumber]'
})
export class TrimInputNumberDirective {

  @Input() ngModel: any;

  constructor(private el: ElementRef) { }

  @HostListener('keyup', ['$event']) onKeyDown(event) {
    if (_.isNumber(this.ngModel) && _.isNaN(this.ngModel) === false) {
      const inputValue = _.get(this.el, 'nativeElement.value');
      const list = _.split(inputValue, '.');
      const restString = (list.length > 1) ? _.last(list) : '';

      // console.log('inputValue', inputValue);
      // console.log('restString', restString);

      if ((this.ngModel !== _.floor(this.ngModel, 2)) || restString.length > 2) {
        _.set(this.el, 'nativeElement.value', _.floor(this.ngModel, 2))
      }
    }
  }

}
