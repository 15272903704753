import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ifms-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent implements OnInit {

  @Input() height: number;

  constructor() { }

  ngOnInit() {
  }

}
