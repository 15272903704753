import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Axle, AxleConfig, VehicleConfiguratorState, VehicleTemplate } from './vehicle-configurator.model';
import { VehicleConfiguratorActions } from './vehicle-configurator.reducer';

import * as _ from '../shared/helpers/object';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class VehicleConfiguratorService {
  private basePath = `${environment.apiUrl}/vehicles`;

  public state: Observable<VehicleConfiguratorState>;
  public vehicles: Observable<VehicleTemplate[]>;
  public vehicle: Observable<VehicleTemplate>;
  public axleConfigs: Observable<AxleConfig[]>;
  public axles: Observable<Axle[]>;
  public axle: Observable<Axle>;

  constructor(public store: Store<any>, private http: HttpClient) {
    this.state = this.store.select('vehicleConfigurator');

    this.vehicles = this.state.pipe(
      map((state: VehicleConfiguratorState) => {
        if (state) {
          return state.vehicles;
        }
      }));

    this.vehicle = this.state.pipe(
      map((state: VehicleConfiguratorState) => {
        if (state) {
          return state.vehicle;
        }
      }));

    this.axleConfigs = this.state.pipe(
      map((state: VehicleConfiguratorState) => {
        if (state) {
          return state.axleConfigs;
        }
      }));

    this.axles = this.state.pipe(
      map((state: VehicleConfiguratorState) => {
        if (state) {
          return state.axles;
        }
      }));

    this.axle = this.state.pipe(
      map((state: VehicleConfiguratorState) => {
        if (state) {
          return state.axle;
        }
      }));
  }

  getAllVehicleTemplates(): Observable<VehicleTemplate[]> {
    return this.http.get(`${this.basePath}/`).pipe(
      map((templates: VehicleTemplate[]) => {
        this.store.dispatch({
          type: VehicleConfiguratorActions.VEHICLE_TEMPLATES_LOADED,
          payload: templates
        });

        return templates;
      }));
  }

  getVehicleTemplate(vehicleId: number) {
    this.requestVehicleTemplate(vehicleId)
      .subscribe((template: VehicleTemplate) => {
        this.store.dispatch({
          type: VehicleConfiguratorActions.VEHICLE_TEMPLATE_LOADED,
          payload: template
        });
      });
  }

  requestVehicleTemplate(vehicleId: number): Observable<VehicleTemplate> {
    return <Observable<VehicleTemplate>>this.http.get(`${this.basePath}/${vehicleId}`);
  }

  createVehicleTemplate(vehicle: VehicleTemplate): Observable<VehicleTemplate> {
    const body = _.cloneDeep(vehicle);

    body.axleConfigId = vehicle.axleConfig.axleConfigId;
    body.axleConfig = undefined;

    return this.http.post(`${this.basePath}/`, body).pipe(
      map((template: VehicleTemplate) => {
        this.getAllVehicleTemplates();

        return template;
      }));
  }

  updateVehicleTemplate(vehicle: VehicleTemplate): Observable<VehicleTemplate> {
    const body = _.cloneDeep(vehicle);

    body.axleConfigId = vehicle.axleConfig.axleConfigId;
    body.axleConfig = undefined;

    return this.http.put(`${this.basePath}/${vehicle.vehicleId}`, body).pipe(
      map((template: VehicleTemplate) => {
        this.getAllVehicleTemplates();

        return template;
      }));
  }

  deleteVehicleTemplate(vehicle: VehicleTemplate): Observable<boolean> {
    return <Observable<boolean>>this.http.delete(`${this.basePath}/${vehicle.vehicleId}`).pipe(
      map((res) => {
        this.getAllVehicleTemplates();
        return res;
      }));
  }

  dispatchEmptyVehicleTemplate() {
    this.store.dispatch({
      type: VehicleConfiguratorActions.SET_EMPTY_VEHICLE_TEMPLATE
    });
  }

  getAllAxleConfigs() {
    this.http.get(`${this.basePath}/axle-configs`)
      .subscribe((templates: AxleConfig[]) => {
        this.store.dispatch({
          type: VehicleConfiguratorActions.AXLE_CONFIGS_LOADED,
          payload: templates
        });
      });
  }

  getAxleConfig(axleConfigId: number): Observable<AxleConfig> {
    return <Observable<AxleConfig>>this.http.get(`${this.basePath}/axle-configs/${axleConfigId}`)
  }

  createAxleConfig(template: AxleConfig) {
    const body = _.cloneDeep(template);

    body.axlePositionMaps.forEach((axlePositionMap) => {
      axlePositionMap.axleId = axlePositionMap.axle.axleId;
      axlePositionMap.axle = undefined;
    });

    const url = `${this.basePath}/axle-configs`;

    return this.http.post(url, body).pipe(
      map((res) => {
        this.getAllAxleConfigs();
      }));
  }

  updateAxleConfig(template: AxleConfig) {
    const body = _.cloneDeep(template);

    body.axlePositionMaps.forEach((axlePositionMap) => {
      axlePositionMap.axleId = axlePositionMap.axle.axleId;
      axlePositionMap.axle = undefined;
    });

    const url = `${this.basePath}/axle-configs/${template.axleConfigId}`;

    return this.http.put(url, body).pipe(
      map((res) => {
        this.getAllAxleConfigs();
      }));
  }

  deleteAxleConfig(template: AxleConfig) {
    const url = `${this.basePath}/axle-configs/${template.axleConfigId}`;
    return this.http.delete(url).pipe(
      map((res) => {
        this.getAllAxleConfigs();

        return res;
      }));
  }

  getAllAxles() {
    this.http.get(`${this.basePath}/axles`)
      .subscribe((axles: Axle[]) => {
        this.store.dispatch({
          type: VehicleConfiguratorActions.AXLES_LOADED,
          payload: axles
        });
      });
  }

  getAxle(axleId: number) {
    this.http.get(`${this.basePath}/axles`)
      .subscribe((axles: Axle[]) => {
        const candidates = axles.filter((axle) => {
          return axleId === axle.axleId;
        });

        if (candidates.length > 0) {
          this.selectAxel(candidates[0]);
        }
      });
  }

  selectAxel(axle: Axle) {
    this.store.dispatch({
      type: VehicleConfiguratorActions.AXLE_LOADED,
      payload: axle
    });
  }

  createAxle(axle: Axle): Observable<Axle> {
    return <Observable<Axle>>this.http.post(`${this.basePath}/axles`, axle).pipe(
      map((axle: Axle) => {
        this.selectAxel(axle);

        return axle;
      }));
  }

  updateAxle(axle: Axle): Observable<Axle> {
    return this.http.put(`${this.basePath}/axles/${axle.axleId}`, axle).pipe(
      map((axle: Axle) => {
        this.selectAxel(axle);

        return axle;
      }));
  }

  deleteAxle(axle: Axle): Observable<boolean> {
    return <Observable<boolean>>this.http.delete(`${this.basePath}/axles/${axle.axleId}`).pipe(
      map((res) => {
        this.getAllAxles();

        return res;
      }));
  }

  findVehicleTemplateById(vehicleId): Observable<VehicleTemplate> {
    return this.vehicles.pipe(
      take(1),
      map((vehicles: VehicleTemplate[]) => {
        if (vehicles) {
          const candidates = vehicles.filter(vehicle => vehicle.vehicleId === vehicleId);
          if (candidates.length > 0) {
            return candidates[0];
          }
        }
      }), );
  }
}
