export const TYPES = {
  'BUS': {
    folder: 'bus'
  },
  'CAR': {
    folder: 'car'
  },
  'COACH': {
    folder: 'coach'
  },
  'DIGGER': {
    folder: 'digger'
  },
  'DOC_CRANE': {
    folder: 'doc-crane'
  },
  'DOC_TUG': {
    folder: 'doc-tug'
  },
  'FIRE': {
    folder: 'fire'
  },
  'LIGHT_TRUCK': {
    folder: 'light-truck'
  },
  'LOADER': {
    folder: 'loader',
    size: [64, 29]
  },
  'OFF_ROAD': {
    folder: 'off-road'
  },
  'ORT_DUMPER': {
    folder: 'otr-dumper'
  },
  'ORT_LOADER': {
    folder: 'otr-loader'
  },
  'ORT_SCRAPER': {
    folder: 'otr-scraper'
  },
  'SUV': {
    folder: 'off-road'
  },
  'TRACTOR': {
    folder: 'tractor'
  },
  'THREE_AXL_TRACTOR': {
    folder: 'three-axl-tractor'
  },
  'TRAILER': {
    folder: 'trailer',
    size: [108, 48]
  },
  'SMALL_TRAILER': {
    folder: 'small-trailer'
  },
  'TRUCK': {
    folder: 'truck'
  },
  'SMALL_TRUCK': {
    folder: 'small-truck'
  },
  'TRUCK_TRAILER': {
    folder: 'truck-trailer',
    size: [108, 48]
  },
  'TRUCK_TRAILER_2': {
    folder: 'truck-trailer-2',
    size: [108, 48]
  },
  'VAN': {
    folder: 'van'
  },
  'CRANE': {
    folder: 'crane'
  },
};
