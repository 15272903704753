import { Injector, NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomHttp } from '../interceptors/custom-http.interceptor';

import { AuthModule } from '../../auth/auth.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomLoader } from '../custom.loader';

import { StoreModule } from '@ngrx/store';
import { reducers } from './core.reducer';
import { HostConfigService } from '@shared/services/host-config/host-config.service';

export function HttpLoaderFactory(http: any) {
    return new TranslateHttpLoader(http);
}

export function HttpClientLoaderFactory(injector: Injector) {
    return new CustomHttp(injector);
}

@NgModule({
  imports: [
    AuthModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient, HostConfigService]
      }
    }),
    StoreModule.forRoot(reducers),
  ],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttp,
      useFactory: HttpClientLoaderFactory,
      multi: true,
      deps: [Injector]
    },
  ]
})
export class CoreModule {
  constructor (
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
