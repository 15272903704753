import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

import { MandatorComponent } from './mandator.component';
import { MandatorFormInputComponent } from './mandator-form-input/mandator-form-input.component';
import { MandatorNodeComponent } from './mandator-node/mandator-node.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    MandatorComponent,
    MandatorFormInputComponent,
    MandatorNodeComponent,
  ],
  exports: [
    MandatorComponent,
    MandatorFormInputComponent,
    MandatorNodeComponent,
  ],
  providers: []
})
export class MandatorModule { }
