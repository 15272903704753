import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ThresholdSettings } from '../sensor.model';
import { TpmsSensor } from '@hardware/sensor-equipments/sensor-equipment/sensor-equipment.model';

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import * as _ from 'lodash';

@Injectable()
export class SensorCalibrationService {

  constructor(private http: HttpClient) { }

  getCurrentSettings(tpmsSensorId: number): Observable<TpmsSensor> {
    return <Observable<TpmsSensor>>this.http.get(`${environment.apiUrl}/tpmsSensor/${tpmsSensorId}`)
  }

  setCurrentSettings(body: ThresholdSettings): Observable<TpmsSensor> {
    return <Observable<TpmsSensor>>this.http.put(`${environment.apiUrl}/tpmsSensor/calibrate`, body)
  }

  getAllCurrentSettingsBy(tpmsSensorIds: number[]): Observable<{ [tpmsSensorId: number]: TpmsSensor }> {
    return <Observable<TpmsSensor[]>>this.http.post(`${environment.apiUrl}/tpmsSensor/ids`, tpmsSensorIds).pipe(
      map((settings: TpmsSensor[]) => {
        return _.keyBy(settings, 'tpmsSensorId');
      }))
  }

  cancelCalibration(ifmsBox: { ifmsBoxId: number; }): Observable<boolean> {
    const ifmsBoxId = _.get(ifmsBox, 'ifmsBoxId');
    return <Observable<boolean>>this.http.put(`${environment.apiUrl}/ifmsboxes/${ifmsBoxId}/cancelcalibration`, {});
  }

}
