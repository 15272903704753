import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PasswordRestService } from '../services/password.rest.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    public token: string;
    public newPassword: string;
    public sendSuccess = false;

    constructor(
        private route: ActivatedRoute,
        private passwordRestService: PasswordRestService,
        private notificationService: NotificationsService
    ) {
    }

    ngOnInit() {
        if (this.route.snapshot.params['token']) {
            this.token = decodeURI(this.route.snapshot.params['token']);
        }
    }

    public onUpdatePassword() {
        this.passwordRestService.setPassword(this.newPassword, this.token).subscribe(
            (response) => {
                this.sendSuccess = true;
            },
            (error) => {
                console.log(error);
                this.notificationService.error('Error', 'Invalid token!');
            }
        )
    }
}
