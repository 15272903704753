import { Component, OnDestroy, OnInit } from '@angular/core';
import { VersionCheckService } from '@shared/services/version-check/version-check.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ifms-version-check-banner',
    templateUrl: './version-check-banner.component.html',
    styleUrls: ['./version-check-banner.component.css']
})
export class VersionCheckBannerComponent implements OnInit, OnDestroy {

    private versionCheckSub: Subscription;
    public reload: boolean;

    constructor(private versionCheckService: VersionCheckService) {
    }

    ngOnInit(): void {
        this.versionCheckSub = this.versionCheckService.needsToReload.subscribe((reload) => {
            this.reload = reload;
        });
    }

    ngOnDestroy(): void {
        this.versionCheckSub.unsubscribe();
    }

    public reloadClient() {
        location.reload();
    }
}
