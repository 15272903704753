import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnInit {
  @Input() fleetUnit: any;

  constructor() { }

  ngOnInit() {
  }

}
