import { Component, Input, OnInit } from '@angular/core';
import { VehicleGroup } from '../fleet-depot.model';
import * as FleetDepot from '../fleet-depot.helper';

import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'depot-group-node',
  templateUrl: './group-node.component.html',
  styleUrls: ['./group-node.component.scss'],
  animations: [
    trigger('rotate', [
      state('default', style({ transform: 'rotate(-90deg)' })),
      state('rotated', style({ transform: 'rotate(0deg)' })),
      transition('default => rotated', animate('200ms ease-in')),
      transition('rotated => default', animate('200ms ease-out')),
    ]),
    trigger('shrink', [
      state('default', style({ height: 0, display: 'none' })),
      state('rotated', style({ height: '*' })),
      transition('default => rotated', [
        animate(200, style({ height: '*' }))
      ]),
      transition('rotated => default', [
        animate(200, style({ height: 0 }))
      ])
    ]),
  ]
})
export class GroupNodeComponent implements OnInit {

  @Input() parent: VehicleGroup;
  @Input() node: VehicleGroup;
  @Input() selected: VehicleGroup;
  @Input() open: boolean;
  @Input() service: { click?: Function };
  @Input() showMandator = true;

  public state = 'default';

  constructor() { }

  ngOnInit() {
    if (this.childrenIncludeSelectedNode()) {
      this.open = true;
    }

    if (this.open) {
      this.rotate();
    }

  }

  rotate() {
      this.state = (this.state === 'default' ? 'rotated' : 'default');
  }

  clickNode() {
    this.open = this.open ? !this.open : true;
    this.rotate();
  }

  emit(event: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    if (this.service && this.service.click) {
      this.service.click({
        node: this.node,
        parent: this.parent
      });
    }
  }

  private childrenIncludeSelectedNode(): boolean {
    if (this.selected && this.node.childVehicleGroups && this.node.childVehicleGroups.length > 0) {
      for (let i = 0; i < this.node.childVehicleGroups.length; i += 1) {
        const found = FleetDepot.findVehicleGroup(this.node.childVehicleGroups, this.selected.vehicleGroupId);

        if (found) {
          return true;
        }
      }
    }

    return false;
  }
}
