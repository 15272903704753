import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
    @Input() public labelText: string;
    @Output() private onChange: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public onValueChange(event: any) {
        this.onChange.emit(event.target.checked);
    }

}
